import { Component, Input, OnChanges, SimpleChange } from '@angular/core';

import { ListItem } from '../models/list-item';

enum Stlye { none=0, hilite=1, break=2 }

class TextRun {
  public t: string;
  public s: number;
  constructor(text: string, style: number) {
    this.t = text;
    this.s = style;
  }
}

@Component ({
  selector: 'edx-list-item-preview',
  template: `
    <ng-template ngFor let-run [ngForOf]="runs" let-i="index">
      <br *ngIf="i!==0 && hasStyle(run, 2)"/>
      <span *ngIf="!hasStyle(run, 2)" [ngClass]="{edx_hilite:hasStyle(run, 1)}">{{run.t}}</span>
    </ng-template>
  `
})
export class ListItemPreviewComponent implements OnChanges {
  @Input() desc: ListItem;
  @Input() data: any;
  public runs: TextRun[];

  ngOnChanges(changes: {[propertyName: string]: SimpleChange}) {
    if (this.desc && !!this.desc['preview']) {
      this.runs = (this.desc['preview']['runs'] as TextRun[]);
    } else if (!!this.data) {
      this.runs = this.data as TextRun[];
    } else {
      this.runs = [];
    }
  }

  public hasStyle(run: TextRun, style: Stlye): boolean {
    return (run.s & style) === style;
  }
}
