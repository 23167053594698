/**
 * Created by kevin on 2016-09-28.
 *
 *  Pipe to transform localization keys into locale-specific strings;
 *
 */

import { Pipe, PipeTransform } from '@angular/core';
import { LocalizeService } from '../services/localize.service';

@Pipe({
  name: 'localize'
})

// add new languages to the languages array here, with a corresponding JSON files in /assets/i18n/lang.json
export class TranslatePipe implements PipeTransform {
  constructor(public localizer: LocalizeService) { }

  transform(value: any, args: any): any {
    return this.localizer.getTranslation(value);
  }
}
