import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';


import { WindowModalComponent } from './window-modal.component';
import { Util } from '../utils/utils.module';

@Component({
  selector: 'edx-window-modal-form-login',
  styleUrls: [ 'window-modal.component.scss', 'window-modal-form-login.component.scss' ],
  template: `
    <div *ngIf="showLogin()" class="window-modal-popup-login  window-modal-popup  window-modal" [ngClass]="{mobile: ui!=0&&ui!=1, oai: isOfficeAddin}">
      <edx-form-login></edx-form-login>
    </div>
    <edx-spinner *ngIf="runSpinner()" ></edx-spinner>
  `
})

export class WindowModalFormLoginComponent extends WindowModalComponent implements OnInit {
  constructor(protected location: Location, protected router: Router, protected route: ActivatedRoute) {
    super(location,router,route);
  }

  ngOnInit() {
    //do not call super
    //super.ngOnInit();
  }

  public runSpinner(): boolean {
    return !Util.Device.bUseWebLogin && Util.RestAPI.nConnectErrors<=3;
  }

  public showLogin(): boolean {
    return Util.Device.bUseWebLogin;
  }
}
