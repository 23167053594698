/**
 * Created by kevin on 2016-11-29.
 *
 * Visual component that contains either 1 or 2 form fields - a primary field and an extension field
 *
 */
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormField } from '../models/form-field';
import { DynamicFormComponent } from './dynamic-form.component';
import { Util, UserInterface } from '../utils/utils.module';

@Component({
  selector: '[edx-dynamic-form-row]',
  styleUrls: ['dynamic-form-row.component.scss'],
  template: `
    <div *ngIf="parent.canShowField(field) && (showExtras || !field.isOption)" class="row" [ngClass]="{columnview:formLayout === 'column', mobile:ui>=2, phone:phone,
            header:formLayout === 'header', readonly:isReadonly(), invalid:field.errormessage, indialog:inDialog, innotify:inNotify, single:single,
            nolabel:((!field.label && field.controltype!='box') || (field.controltype=='push' && !field.checkLabel)), inlineform:inlineForm}">
      <div class="main" [ngClass]="{indialog:inDialog, innotify:inNotify, heading:field.fldtype === 'text' || field.fldtype === 'guidetext' || field.fldtype === 'box',
            listfield:field.fldtype === 'list', readonly:isReadonly(), header:formLayout === 'header', fullwidth:(field.isMultiline && !field.hasExtension) || fullWidth,
            mobile:ui>=2, phone:phone, hasextension:hasExtension(), oai:officeAddin, multiext:tablet&&getExtensionsCount()>1, exportform:isExportForm()}">
        <edx-dynamic-form-field [formLayout]="formLayout" [rerender]="rerender" [field]="field" [form]="form" [parent]="parent" [inDialog]="inDialog"
            [itemIndex]="itemIndex" [fieldLabel]="fieldLabel" [fieldsLength]="fieldsLength" [inNotify]="inNotify" [showExtras]="showExtras" [formReadonly]="formReadonly" [first]="first" [extensionShown]="showExtension('0')" [single]="single"></edx-dynamic-form-field>
      </div>
      <ng-template [ngIf]="hasExtension()">
        <ng-template ngFor let-subfield [ngForOf]="field.fields" let-i="index">
          <div *ngIf="showExtension(i)" class="extension" [ngClass]="{indialog:inDialog, innotify:inNotify, multiext:tablet&&getExtensionsCount()>1, readonly:formReadonly, exportform:isExportForm(), oai:officeAddin}">
            <div *ngIf="formReadonly" class="associator"></div>
            <edx-dynamic-form-field [formLayout]="formLayout" [field]="subfield" [form]="form" [parent]="parent" [inDialog]="inDialog"
                [inNotify]="inNotify" [showExtras]="showExtras" [formReadonly]="formReadonly"></edx-dynamic-form-field>
          </div>
        </ng-template>
      </ng-template>
    </div>
  `
})
export class DynamicFormRowComponent {
  private ui: UserInterface = Util.Device.ui;
  private phone: boolean  = Util.Device.ui===2 || Util.Device.ui===4;
  private tablet: boolean  = Util.Device.ui===3 || Util.Device.ui===5;
  @Input() field: FormField;
  @Input() form: FormGroup;
  @Input() parent: DynamicFormComponent;
  @Input() inDialog: boolean;
  @Input() inNotify: boolean;
  @Input() showExtras: boolean;
  @Input() formReadonly: boolean;
  @Input() single: boolean;
  @Input() fullWidth: boolean;
  @Input() inlineForm: boolean;
  @Input() first: boolean;
  @Input() formLayout: string;
  @Input() rerender: number;
  @Input() fieldLabel?: string = null;
  @Input() fieldsLength?: number = -1;
  @Input() itemIndex?: number = -1;
  public officeAddin = Util.Device.bIsOfficeAddin;

  private isReadonly(): boolean {
    return (this.formReadonly || (this.field.isReadonly && !this.formReadonly));
  }

  private hasExtension(): boolean {
    return this.field.hasExtension && (!this.single || (!!this.field.fields && this.field.fields[0].isVisible));
  }

  private showExtension(index: number): boolean {
    if (this.phone && index>1) {
      return false;
    }
    return this.hasExtension() && this.field.fields[index].isVisible;
  }

  private getExtensionsCount(): number {
    let nExtensions = 0;
    if (this.hasExtension() && this.field.fields && this.field.fields.length) {
      for (const sub of this.field.fields) {
        if (sub.isVisible) {
          ++nExtensions;
        }
      }
    }
    return nExtensions;
  }

  private isExportForm(): boolean {
    return this.parent.formKind === '__local_exportresults';
  }
}
