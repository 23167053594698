import { Directive, HostListener, ElementRef, Input, Renderer2 } from '@angular/core';

import { Util } from '../utils/utils.module';

const kDoubleTapTime = 500;
const kLongPressTime = 500;
const kSwipeTime = 300;

export enum GestureKind { None=0, DoubleTap=1, LongPress=2, SwipeLeft=3, SwipeRight=4, PinchOut=5, PinchIn=6 }

export interface GestureListener {
  handleGesture(gesture: GestureKind, elem: ElementRef, target: any): boolean;  // return true to prevent any defaults
}

class GestureDirective {
  protected _elemBounds: ClientRect;
  protected _gestureInProgress = false;
  protected _gestureHandled = false;
  protected _originalTarget: any = null;
  protected _startTime = 0;
  protected _startX: number;
  protected _startY: number;
  protected _nFingersDown = 0;
  protected _isDoubleTap = false;
  protected _minDragMove: number = Util.Device.bIsTouchDevice ? 6 : 3;

  constructor(protected _elem: ElementRef, protected _renderer: Renderer2) { }

  private touchToMouse(event) {
    const type = event.type;
    const timeStamp: number = event.timeStamp;
    event = event.touches && event.touches.length ? event.touches[0] : event;
    if (type!=='touchend') {
      try {
        event.timeStamp = timeStamp;
      } catch (e) { }
    }
    return event;
  }

  protected onMousedown(event): boolean {
    this._gestureInProgress = event.which === 1;
    this._gestureHandled = false;
    this._isDoubleTap = (event.timeStamp-this._startTime)<kDoubleTapTime;
    this._startTime = event.timeStamp;
    this._startX = event.clientX;
    this._startY = event.clientY;
    this._elemBounds = this._elem.nativeElement.getBoundingClientRect();
    this._nFingersDown = 1;
    this._originalTarget = event.target;
    return true;
  }

  protected onTouchstart(event): boolean {
    event = this.touchToMouse(event);
    event.which = 1;
    return this.onMousedown(event);
  }

  protected onMousemove(event): void { }  // over ride if you want to handle move

  protected onTouchmove(event): void {
    this.onMousemove(this.touchToMouse(event));
  }

  protected onMouseup(event): boolean {
    this._gestureInProgress = false;
    this._nFingersDown = 0;
    return !this._gestureHandled;
  }

  protected onTouchend(event): boolean {
    return this.onMouseup(this.touchToMouse(event));
  }

  protected onTouchcancel(event): boolean {
    this.onMouseup(this.touchToMouse(event));
    return false;
  }
}

@Directive({
  selector: '[edx-gesture-longpress]'
})
export class LongPressGestureDirective extends GestureDirective {
  @Input('edx-gesture-longpress') listener: GestureListener;

  constructor(protected _elem: ElementRef, protected _renderer: Renderer2) {
    super(_elem, _renderer);
  }

  @HostListener('mousedown', ['$event'])
  onMousedown(event) {
    setTimeout(() => {
      this._gestureInProgress = this._gestureInProgress && (this._startX<(Util.Device.width-16));
      if (this._gestureInProgress && !!this.listener && this.listener.handleGesture) {
        this._gestureHandled = this.listener.handleGesture(GestureKind.LongPress, this._elem, this._originalTarget);
      }
    }, kLongPressTime);
    return super.onMousedown(event);
  }
  @HostListener('touchstart', ['$event'])
  onTouchstart(event) {
    return super.onTouchstart(event);
  }

  @HostListener('mousemove', ['$event'])
  onMousemove(event) {
    if (this._gestureInProgress && (Math.abs(this._startX-event.clientX) > this._minDragMove || (Math.abs(this._startY-event.clientY) > this._minDragMove))) {
      this._gestureInProgress = false;
    }
  }
  @HostListener('touchmove', ['$event'])
  onTouchmove(event) {
    super.onTouchmove(event);
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseup(event) {
    return super.onMouseup(event);
  }
  @HostListener('touchend', ['$event'])
  onTouchend(event) {
    return super.onTouchend(event);
  }
  @HostListener('document:touchcancel', ['$event'])
  onTouchcancel(event) {
    return super.onTouchcancel(event);
  }
}

@Directive({
  selector: '[edx-gesture-swipe]'
})
export class SwipeGestureDirective extends GestureDirective {
  @Input('edx-gesture-swipe') listener: GestureListener;

  constructor(protected _elem: ElementRef, protected _renderer: Renderer2) {
    super(_elem, _renderer);
  }

  @HostListener('mousedown', ['$event'])
  onMousedown(event) {
    return super.onMousedown(event);
  }
  @HostListener('touchstart', ['$event'])
  onTouchstart(event) {
    return super.onTouchstart(event);
  }

  @HostListener('mousemove', ['$event'])
  onMousemove(event) {
    if (this._gestureInProgress && this._nFingersDown===1) {
      const deltaT: number = event.timeStamp-this._startTime;
      const deltaX: number = this._startX-event.clientX;
      if (deltaT<kSwipeTime && (Math.abs(deltaX)>10 && (Math.abs(this._startY-event.clientY)<4))) {
        this._gestureInProgress = false;
        if (!!this.listener && this.listener.handleGesture) {
          this._gestureHandled = this.listener.handleGesture(deltaX>0 ? GestureKind.SwipeLeft : GestureKind.SwipeRight, this._elem, this._originalTarget);
        }
      }
    }
  }
  @HostListener('touchmove', ['$event'])
  onTouchmove(event) {
    super.onTouchmove(event);
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseup(event) {
    return super.onMouseup(event);
  }
  @HostListener('touchend', ['$event'])
  onTouchend(event) {
    return super.onTouchend(event);
  }
  @HostListener('document:touchcancel', ['$event'])
  onTouchcancel(event) {
    return super.onTouchcancel(event);
  }
}

@Directive({
  selector: '[edx-gesture-pinch]'
})
export class PinchGestureDirective extends GestureDirective {
  @Input('edx-gesture-pinch') listener: GestureListener;
  private _gestureWasHandled = false;

  constructor(protected _elem: ElementRef, protected _renderer: Renderer2) {
    super(_elem, _renderer);
  }

  @HostListener('touchstart', ['$event'])
  onTouchstart(event) {
    return super.onTouchstart(event);
  }

  @HostListener('mousemove', ['$event'])
  onMousemove(event) {
    this._gestureInProgress = this._nFingersDown===2;
    if (this._gestureInProgress) {
      const deltaX: number = this._startX-event.clientX;
      const deltaY: number = this._startY-event.clientY;
      if ((Math.abs(deltaX)>10 || (Math.abs(deltaY)>10))) {
        this._startX = event.clientX;
        this._startY = event.clientY;
        if (!!this.listener && !!this.listener.handleGesture) {
          this._gestureHandled = this.listener.handleGesture(deltaX<0 ? GestureKind.PinchOut : GestureKind.PinchIn, this._elem, this._originalTarget);
        } else {
          this._gestureHandled = false;
        }
      }
    }
  }
  @HostListener('touchmove', ['$event'])
  onTouchmove(event) {
    this._nFingersDown = event.touches.length;
    super.onTouchmove(event);
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseup(event) {
    return super.onMouseup(event);
  }
  @HostListener('touchend', ['$event'])
  onTouchend(event) {
    // double tap reset
    if (this._gestureWasHandled && !this._gestureHandled && this._nFingersDown===1 && this._isDoubleTap) {
      if (!!this.listener && !!this.listener.handleGesture) {
        this.listener.handleGesture(GestureKind.DoubleTap, this._elem, this._originalTarget);
      }
      this._gestureWasHandled = false;
    }
    if (!this._gestureWasHandled && this._gestureHandled) {
      this._gestureWasHandled = true;
    }
    return super.onTouchend(event);
  }
  @HostListener('document:touchcancel', ['$event'])
  onTouchcancel(event) {
    return super.onTouchcancel(event);
  }
}

@Directive({
  selector: '[edx-gesture-doubletap]'
})
export class DoubleTapGestureDirective extends GestureDirective {
  @Input('edx-gesture-doubletap') listener: GestureListener;

  constructor(protected _elem: ElementRef, protected _renderer: Renderer2) {
    super(_elem, _renderer);
  }

  @HostListener('touchstart', ['$event'])
  onTouchstart(event) {
    return super.onTouchstart(event);
  }

  @HostListener('touchend', ['$event'])
  onTouchend(event) {
    if (this._isDoubleTap && !!this.listener && !!this.listener.handleGesture) {
      this.listener.handleGesture(GestureKind.DoubleTap, this._elem, this._originalTarget);
    }
    return super.onTouchend(event);
  }
}
