import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';

import { Util, UserInterface } from '../utils/utils.module';
import { LocalizeService } from '../services/localize.service';
import { ListInterface, FilterListInterface } from '../models/list-item';
import { SelectItem } from '../models/form-field';
import { SelectComponent } from './select.component';
import { _Transforms } from '../utils/transforms';

@Component({
  selector: 'edx-search-filter',
  styleUrls: ['search-filter.component.scss' ],
  template: `
    <form *ngIf="!loading" class="edx-form indialog" [ngClass]="{mobile:ui>=2, lookup:desc.type==='lookups'}">
      <div class="search-wrapper" [ngClass]="{notvisible:ui<2&&hideFilter, reverse: ui>=2&&desc.type==='lookups'}">
        <ng-template [ngIf]="desc.type==='lookups'">
          <label *ngIf="ui<2" for="sel">
            <span>{{searchBy}}</span>
          </label>
          <edx-select #sel [value]="selValue" [ariaLabel]="searchBy" [items]="items" class="edx-select" [justButton]="ui>=2" [tabIndex]="ui>=2?(tabIndex+1):tabIndex" (change)="selChanged($event)" [id]="'edx_form_list_filter_select'"></edx-select>
        </ng-template>
        <div class="search-bar" [ngClass]="{spaced:ui<2&&!asFilter,filter:ui<2&&asFilter,wide:ui>=2}">
          <input #searchField [id]="globalSearch?'edx_list_box_global_search':'edx_list_box_search'" [tabindex]="tabIndex" placeholder="{{searchPlaceHolder}}" type="text" name="search" autocomplete="off" aria-autocomplete="none" [(ngModel)]="searchValue" (keyup)="keyUp($event)" (keyup.enter)="searchClicked()" [disabled]="searchDisabled()"/>
          <div role="button" [attr.aria-label]="clearSearch" *ngIf="!!searchValue" class="searchclear" [tabindex]="tabIndex" (click)="clear($event)" (keyup.enter)="clear($event)" (keyup.space)="clear($event)" title="{{this.localizer.getTranslation('REFINE_SEARCH.CLEAR')}}"></div>
        </div>
        <button [id]="globalSearch?'edx_list_btn_global_search':'edx_list_btn_search'" class="secondary" [ngClass]="{notvisible:(ui>=2 && desc.type==='lookups'),readonly:searchDisabled()}" [tabindex]="(ui>=2 && desc.type==='lookups')?-1:tabIndex" [disabled]="searchDisabled()" (click)="searchClicked()" (keyup.enter)="searchClicked()" (keyup.space)="searchClicked()">{{search}}</button>
      </div>
    </form>
  `
})
export class SearchFilterComponent extends FilterListInterface implements OnInit, OnDestroy {
  @Input() searchValue?: string = '';
  @Input() selValue?: any;
  @Input() noWildcard?: boolean = false;
  @Input() lookupIsNumeric?: boolean = false;
  @Input() lookupFilter?: string;
  @Input() desc?: any = {};
  @Input() list: ListInterface;
  @Input() globalSearch?: boolean = false;
  @Input() asFilter?: boolean = false;
  @Input() hideFilter?: boolean = false;
  @Input() tabIndex?: number = 0;
  @ViewChild(SelectComponent) private sel: SelectComponent;
  @ViewChild('searchField') private searchField: ElementRef;
  public searchPlaceHolder: string;
  public search = '';
  public ui: UserInterface;
  public loading = true;
  private items: SelectItem[] = [];
  private searchBy = '';
  private transforms: _Transforms = Util.Transforms;
  private lastSearchTime: number = new Date().valueOf();
  private clearSearch = '';
  private searchKeyItem: SelectItem = null;

  constructor(private localizer: LocalizeService) {
    super();
    this.searchBy = this.localizer.getTranslation('FORMS.LOOKUPS.SEARCH_BY');
    this.search = this.localizer.getTranslation('FORMS.LOOKUPS.SEARCH');
    this.ui = Util.Device.ui;
    this.clearSearch = this.localizer.getTranslation('FORMS.LOOKUPS.CLEAR_SEARCH');
  }

  ngOnInit() {
    this.search = this.asFilter ? this.localizer.getTranslation('TOOLTIP.FILTER') : this.localizer.getTranslation('FORMS.LOOKUPS.SEARCH');
    if (this.desc && this.desc.type === 'lookups') {
      this.loadFilterCols(true);
    } else {
      this.loading = false;
    }
  }

  ngOnDestroy() {
  }

  private keyUp(event: KeyboardEvent) {
    if (event.key==='Enter' && this.searchField && this.searchField.nativeElement === event.target) {
      event.preventDefault();
      event.stopPropagation();
      this.searchClicked();
    } else {
      this.fitlerTextChanged(event);
    }
  }

  private loadFilterCols(pageLoad?: boolean): void {
    const cols: any = this.list ? this.list.getFilterColumnsFromSchema() : null;
    if (cols) {
      const items: SelectItem[] = [];
      if (Util.RestAPI.restAPIVersion() >= 0x00160500) {
        Util.FieldMappings.mergePrimaryAndSecondary(cols, this.localizer);
      }
      const nCols: number = cols.length;
      for (let i = 0; i < nCols; i++) {
        const col = cols[i];
        if (!!col) {
          const label = col.label || col.text || col.property;
          items.push({ display: label, value: col.property });
          if (i === 0 && !this.selValue) {
            this.selValue = col.property;
          }
        }
      }
      this.items = items;
      this.lastSearchTime = 0; // force change
      this.postChange(pageLoad);
      this.loading = false;
      setTimeout(() => {
        this.updatePlaceHolder(true);
      }, 10);
    } else {
      setTimeout(() => {
        this.loadFilterCols(pageLoad);
      }, 100);
    }
  }

  private postChange(pageLoad?: boolean): void {
    const now = new Date().valueOf();
    const elapsedTime = now - this.lastSearchTime;
    if (elapsedTime > 1000) {
      if (this.globalSearch && this.desc?.type !== 'searches') {
        this.list.handleFilterSearch(this.searchValue);
      } else {
        let searchFilter: string = null;
        const searchKey: string = this.searchKeyItem?.value ?? this.selValue ?? this.sel?.value ?? (this.desc?.type === 'searches' ? 'DESCRIPTION' : 'DOCNAME');
        let filterKey = this.sel?.value || this.selValue || searchKey;
        let lookupSearchValue: string = this.searchValue || '';
        const multiValueLookup: boolean = lookupSearchValue.indexOf('|') > 0 ? true : false;
        let commaHandledValue: string;
        let secondarySelKey: string = null;
        if (Util.RestAPI.restAPIVersion() >= 0x00160500) {
          if (searchKey.includes('$edx_outlook_email') ) {
            if (searchKey === filterKey) {
              filterKey = Util.FieldMappings.getLookupContactsFilterKey(searchKey);
            }
          }
          secondarySelKey = Util.FieldMappings.getLookupSecondaryKey(filterKey);
          // Check whether SecondarySelKey is a correct description field on the profile form, otherwise return the mapped decsription field
          const secondaryKeyField = this.list?.getField?.(secondarySelKey);
          if (!secondaryKeyField || secondaryKeyField?.endsWith?.('_ignore')) {
            const secondarySelKey2 = Util.FieldMappings.getLookupColumnPropName(secondarySelKey);
            if (!!this.list?.getField?.(secondarySelKey2)) {
              secondarySelKey = secondarySelKey2;
            }
          }
        }
        if (lookupSearchValue === '' && !this.lookupIsNumeric) {
          this.noWildcard = false;
        } else {
          // Leave as-is since it is not going to be used for the filter
          if (!this.noWildcard && lookupSearchValue.indexOf(',') > -1) {
            commaHandledValue = lookupSearchValue.replace(/,/g, '\\,');
          }
          if (!multiValueLookup) {
            lookupSearchValue = this.transforms.validateQueryValue(lookupSearchValue, '');
            if (!!commaHandledValue) {
              commaHandledValue = this.transforms.validateQueryValue(commaHandledValue, '');
            }
          }
        }
        if (this.list && (searchKey || !this.sel)) { // only lookups have a select field
          if (searchKey) {
            if (this.lookupFilter && this.items.length) {
              searchFilter = this.lookupFilter;
              if (!pageLoad) {
                if (secondarySelKey) {
                  searchFilter += ' and ' + filterKey + '=' + lookupSearchValue + (this.noWildcard ? '' : '*') + ' or ' + secondarySelKey + '=' + lookupSearchValue + (this.noWildcard ? '' : '*') + (!commaHandledValue ? '' : ' or ' + filterKey + '=' + commaHandledValue + ' or ' + secondarySelKey + '=' + commaHandledValue);
                } else if (!lookupSearchValue || !this.lookupIsNumeric) {
                  searchFilter += ' and ' + filterKey + '=' + lookupSearchValue + (this.noWildcard ? '' : '*') + (!commaHandledValue ? '' : ' or ' + filterKey + '=' + commaHandledValue);
                }
              }
            } else if (!pageLoad && lookupSearchValue && !multiValueLookup) {
              if (Util.FieldMappings.isLookupColumnPropName(filterKey)) {
                const columnProp = Util.FieldMappings.getLookupColumnPropName(filterKey);
                filterKey = columnProp;
              }
              // If no lookupSearchValue then avoid searchFilter since wildcard as search value
              // On loading the lookup dialog, avoid searchFilter
              // does not work in all cases!? Compliments od DM Server oddity (yet another!!)
              if (secondarySelKey) {
                searchFilter = filterKey + '=' + lookupSearchValue + (this.noWildcard ? '' : '*') + ' or ' + secondarySelKey + '=' + lookupSearchValue + (this.noWildcard ? '' : '*') + (!commaHandledValue ? '' : ' or ' + filterKey + '=' + commaHandledValue + ' or ' + secondarySelKey + '=' + commaHandledValue);
              } else {
                searchFilter = filterKey + '=' + lookupSearchValue + (this.noWildcard ? '' : '*') + (!commaHandledValue ? '' : ' or ' + filterKey + '=' + commaHandledValue);
              }
            }
          }
          if (this.list.handleFilterChange(searchFilter, searchKey) && this.sel) {
            this.sel.value = '';
          }
        }
      }
    }
    this.lastSearchTime = now;
  }

  private searchDisabled(): boolean {
    let disabled = false;
    if (this.desc && this.desc.lib && Util.isExternalLib(this.desc.lib)) {
      disabled = true;
    }
    return disabled;
  }

  private searchClicked(): void {
    const groupTable = this.list.getGroupTable();
    if (!!groupTable) {
      groupTable.setOpenedItem(null);
      groupTable.parent.usersTitle = this.localizer.getTranslation('SECURITY.USER_GROUP.USERS');
      groupTable.parent.showResetBtn = false;
    }
    this.postChange();
  }

  private fitlerTextChanged(event: Event): void {
    this.noWildcard = false;
  }

  private updatePlaceHolder(forceFocus?: boolean): void {
    if (this.ui >= 2 || !!forceFocus) {
      setTimeout(() => {
        const element = this.searchField.nativeElement;
        if (!!element) {
          element.focus();
        }
      }, 1);
    }
    if (this.desc && this.desc.type==='lookups' && this.sel && this.sel.value) {
      this.searchPlaceHolder = null;
      const item: SelectItem = this.sel.getItems().find(i => i.value===this.sel.value);
      if (item && item.display) {
        this.searchPlaceHolder = this.searchBy + ' ' + item.display;
      }
    }
  }

  private selChanged(sel: SelectComponent): void {
    this.updatePlaceHolder();
  }

  // *** FilterListInterface ***
  public clear(event?: Event): void {
    this.searchValue = '';
    this.updatePlaceHolder(true);
    if (!!event) {
      // user has done an explicit clear so reload
      this.postChange();
    }
  }

  public setSelKey(searchKey: string): void {
    this.selValue = searchKey;
    setTimeout(() => {
      this.updatePlaceHolder();
    }, 1);
  }

  public setList(items: SelectItem[]): void {
    this.items = items;
    setTimeout(() => {
      this.updatePlaceHolder();
    }, 1);
  }

  public setSearchKeyItem(selectedKeyItem: SelectItem) {
    if (!!selectedKeyItem) {
      this.searchKeyItem = selectedKeyItem;
    }
  }

  public getList(): SelectItem[] {
    return this.items;
  }

  public updateCols(): void {
   this.list.updateView();
   this.loadFilterCols();
 }
}
