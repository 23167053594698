/**
 * Created by kevin on 2016-11-10.
 */


import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { FormField } from '../models/form-field';
import { FormGroup } from '@angular/forms';
import { DynamicFormComponent } from './dynamic-form.component';
import { Util } from '../utils/utils.module';

@Component({
 selector: 'edx-group-box-field',
  styleUrls: ['group-box-field.component.scss'],
  template: `
    <ng-template [ngIf]="field.label && showExtras">
      <ng-template [ngIf]="hasVisibleFields()">
        <details *ngIf="useDetails" #details [ngClass]="{columnview: formLayout === 'column', topborder: hasTopBorder(), bottomborder: hasBottomBorder(), groupingroup: groupInGroup, phone: phone, oai: officeAddin}" [open]="bIsOpen" (toggle)="detailsToggled($event, details)">
          <summary *ngIf="field.label && field.label!==' '" [attr.aria-label]="field.label" [tabindex]="tabIndex">{{field.label}}</summary>
          <summary *ngIf="!field.label || field.label===' '" [tabindex]="tabIndex">&nbsp;</summary>
          <ng-template ngFor let-subfield [ngForOf]="fields" let-i=index>
            <div edx-dynamic-form-row *ngIf="parent && parent.canShowField(subfield)" [itemIndex]="i" [fieldLabel]="field.label" [fieldsLength]="fields.length" [field]="subfield" [form]="form" [inDialog]="inDialog" [formReadonly]="formReadonly" [parent]="parent" [formLayout]="formLayout" [rerender]="parent.useForceRender(subfield)" [showExtras]="showExtras"></div>
          </ng-template>
        </details>
        <div *ngIf="!useDetails" class="edx-details" [ngClass]="{columnview: formLayout === 'column', topborder: hasTopBorder(), bottomborder: hasBottomBorder(), groupingroup: groupInGroup, phone: phone, oai: officeAddin, open:bIsOpen}">
          <div class="edx-summary" [tabindex]="tabIndex" (click)="toggleOpen()" (keyup.space)="toggleOpen()">{{field.label || "&nbsp;"}}</div>
          <ng-template [ngIf]="bIsOpen">
            <ng-template ngFor let-subfield [ngForOf]="fields" let-i=index>
              <div edx-dynamic-form-row *ngIf="parent && parent.canShowField(subfield)" [itemIndex]="i" [fieldLabel]="field.label" [fieldsLength]="fields.length" [field]="subfield" [form]="form" [inDialog]="inDialog" [formReadonly]="formReadonly" [parent]="parent" [formLayout]="formLayout" [rerender]="parent.useForceRender(subfield)" [showExtras]="showExtras"></div>
            </ng-template>
          </ng-template>
        </div>
      </ng-template>
    </ng-template>
    <ng-template [ngIf]="!field.label || !showExtras">
      <ng-template ngFor let-subfield [ngForOf]="fields" let-i=index>
        <div edx-dynamic-form-row *ngIf="parent && parent.canShowField(subfield)" [itemIndex]="i" [fieldLabel]="field.label" [fieldsLength]="fields.length" [field]="subfield" [form]="form" [inDialog]="inDialog" [inNotify]="inNotify" [formReadonly]="formReadonly" [parent]="parent" [formLayout]="formLayout" [rerender]="parent.useForceRender(subfield)" [showExtras]="showExtras"></div>
      </ng-template>
    </ng-template>
  `
})
export class GroupBoxFieldComponent implements OnInit, OnChanges {
  @Input() field: FormField;
  @Input() form: FormGroup;
  @Input() inDialog: boolean;
  @Input() inNotify: boolean;
  @Input() parent: DynamicFormComponent;
  @Input() showExtras: boolean;
  @Input() formReadonly: boolean;
  @Input() formLayout: string;
  @Input() rerender: number;
  @Input() tabIndex = 0;
  private fields: FormField[];
  private phone: boolean = Util.Device.isPhoneLook();
  private officeAddin: boolean = Util.Device.bIsOfficeAddin;
  private useDetails: boolean = !Util.Device.bIsEdge && !Util.Device.bIsIE;
  private bIsOpen: boolean;
  private boxBorders: number;
  private groupInGroup: boolean;

  ngOnInit(): void {
    this.bIsOpen = this.isInitiallyOpen();
    this.boxBorders = this.calcBorders();
    this.groupInGroup = this.parent ? this.parent.isGroupInGroup(this.field) : false;
    this.fields = [];
    if (this.field.fields && this.field.fields.length) {
      this.fields = this.field.fields;
    }
  }

  ngOnChanges(): void {
    this.bIsOpen = this.isInitiallyOpen();
    this.boxBorders = undefined;
  }

  private hasTopBorder(): boolean {
    return (this.boxBorders & 0x1)!==0;
  }

  private hasBottomBorder(): boolean {
    return (this.boxBorders & 0x2)!==0;
  }

  private hasVisibleFields(): boolean {
    if (this.fields && this.fields.length && this.parent) {
      for (const field of this.fields) {
        if (this.parent.canShowField(field)) {
          return true;
        }
      }
    }
    return false;
  }

  private isInitiallyOpen(): boolean {
    if (!!this.parent) {
      return this.parent.isInitiallyOpen(this.field);
    }
    return true;
  }

  private calcBorders(): number {
    if (!!this.parent) {
      return this.parent.calcBorders(this.field);
    }
    return 0;
  }

  private toggleOpen(): void {
    this.bIsOpen = !this.bIsOpen;
    if (!!this.parent) {
      this.parent.groupBoxOpenToggled(this.field, this.bIsOpen);
    }
  }

  private detailsToggled(event: Event, details: HTMLDetailsElement): void {
    if (!!this.parent) {
      this.parent.groupBoxOpenToggled(this.field, details.open);
    }
  }
}
