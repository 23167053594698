import { Component, Input, OnInit } from '@angular/core';
import { MenuService, MenuId, MenuDef } from '../services/menu.service';
import { MenuItem } from '../models/menu-item';
import { LocalizeService } from '../services/localize.service';
import { CommandHandler } from '../models/command-handler';
import { Util, UserInterface } from '../utils/utils.module';
import { BaseDesc } from '../models/base';
import { ListItem } from './../models/list-item';

@Component({
  selector: 'edx-inline-action-bar',
  styleUrls: ['inline-action-bar.component.scss'],
  template: `
      <otc-inline-actions *ngIf="isShown()" (animationend)="animationComplete()" class="inline-action-bar"
                          [ngClass]="{mobile:ui >= 2 && !isOfficeAddin, oai:isOfficeAddin, showing:showing, hiding:hiding}">
        <ng-template ngFor let-item [ngForOf]="items" let-i="index">
          <otc-inline-action-item *ngIf="item.enabled" role="menuitem" tabindex="0" id="{{item.name}}" href=""
                                  title="{{item.name}}" icon="{{item.icon}}" (keyup.enter)="itemClick(items.indexOf(item), $event)"
                                  (keydown.space)="itemClick(items.indexOf(item), $event)" (click)="itemClick(items.indexOf(item), $event)">
          </otc-inline-action-item>
        </ng-template>
        <otc-inline-action-item *ngIf="enabledCustomMenuItemCount > 0 && isShown()" id="more_menu" title="More"
                                href="" icon="action:action_more32" (click)="showHideMoreMenu($event)">
        </otc-inline-action-item>
        <otc-menu id="moreMenu" buttonid="more_menu" [isopen]="isOpen" role="menu">
          <ng-template ngFor let-menuItem [ngForOf]="customItems" let-i="index" let-length="count">
            <otc-menuitem role="menuitem" tabindex="0" id="{{menuItem.name}}" title="{{menuItem.name}}"
                          (keyup.enter)="itemClick(customItems.indexOf(menuItem), $event, true)"
                          (keydown.space)="itemClick(customItems.indexOf(menuItem), $event, true)"
                          (click)="itemClick(customItems.indexOf(menuItem), $event, true)">
              {{menuItem.name}}
            </otc-menuitem>
          </ng-template>
        </otc-menu>
      </otc-inline-actions>
  `
})
export class InlineActionBarComponent implements OnInit {
  private _shown = false;
  private showing = true;
  private hiding = false;
  private isOpen = false;
  private ui: UserInterface;
  private isOfficeAddin: boolean = Util.Device.bIsOfficeAddin;
  private hasEnabledItems = false;
  private enabledCustomMenuItemCount = 0;
  private menuDef: MenuDef;
  public items: MenuItem[] = [];
  public customItems: MenuItem[] = [];
  public listItem: ListItem = null;
  public desc: BaseDesc;
  @Input() resid: MenuId;
  @Input() target: CommandHandler;
  @Input('shown')
  set setter(value: boolean) {
    this.showing = value;
    this.hiding = this._shown && value;
    this._shown = value;
  }

  constructor(private menuService: MenuService, private localizer: LocalizeService) {
    this.ui = Util.Device.ui;
  }

  ngOnInit(): void {
    this.menuDef = this.menuService.getMenu(this.resid);
    this.initActionItems();
  }

  public updateActionItems(): void {
    this.hasEnabledItems = false;
    for (const menuItem of this.items) {
      menuItem.enabled = true;
      if (this.target.commandEnabled) {
        menuItem.enabled = this.target.commandEnabled(menuItem.cmd);
      }
      if (menuItem.enabled) {
        this.hasEnabledItems = true;
      }
    }
  }

  public initActionItems(): void {
    this.items = [];
    for (const itemDef of this.menuDef.items) {
      const item: MenuItem = new MenuItem(itemDef);
      item.name = this.localizer.getTranslation(item.name);
      item.icon = item.icon;
      if (item.cmd && item.cmd.startsWith('_custom_')) {
        const effectiveRights: any = Util.RestAPI.getLoginReply() && Util.RestAPI.getLoginReply().EFFECTIVE_RIGHTS ? Util.RestAPI.getLoginReply().EFFECTIVE_RIGHTS : {};
        const enableFunc = Util.RestAPI.getCustomCommandFunction(item.cmd, 'enable');
        if (!!enableFunc) {
          item.enabled = enableFunc(this.desc, [this.listItem], effectiveRights);
          if (item.enabled) {
            this.customItems.push(item);
            this.enabledCustomMenuItemCount++;
          }
        }
      } else {
        item.enabled = true;
        this.items.push(item);
      }
    }
  }

  private itemClick(itemIndex: number, event: Event, isCustomMenuItem?: boolean): void {
    if (isCustomMenuItem && itemIndex >= 0 && itemIndex < this.customItems.length) {
      if (this.target) {
        this.target.doCommand(this.customItems[itemIndex].cmd);
      }
    } else if (itemIndex >= 0 && itemIndex < this.items.length) {
      if (this.target) {
        this.target.doCommand(this.items[itemIndex].cmd);
      }
    }
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  private animationComplete(): void {
    if (this.showing) {
      this.showing = false;
    }
    if (this.hiding) {
      this.hiding = false;
    }
  }

  public isShown(): boolean {
    return this.hiding || (this._shown && this.hasEnabledItems);
  }

  private showHideMoreMenu(event: Event): void {
    this.isOpen = !this.isOpen;
    event.stopPropagation();
    event.preventDefault();
  }
}
