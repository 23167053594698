import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FormLoginComponent } from './form-login.component';
import { DynamicFormFieldComponent } from './dynamic-form-field.component';
import { DynamicFormComponent } from './dynamic-form.component';
import { GroupBoxFieldComponent } from './group-box-field.component';
import { FormWrapperComponent } from './form-wrapper.component';
import { FormFieldListComponent } from './form-field-list.component';
import { WidgetsModule } from '../widgets/widgets.module';
import { ListsModule } from '../lists/lists.module';
import { DynamicFormRowComponent } from './dynamic-form-row.component';
import { NumericFieldComponent } from './numeric-field.component';
import { CheckboxFieldComponent } from './checkbox-field.component';
import { RadioGroupComponent } from './radio-group.component';
import { RadioFieldComponent } from './radio-field.component';
import { ProgressFieldComponent } from './progress-field.component';
import { SelectFieldComponent } from './select-field.component';
import { SegmentFieldComponent } from './segment-field.component';
import { ReadOnlyTextFieldComponent } from './read-only-text-field.component';
import { LinkFieldComponent } from './link-field.component';
import { LocationChooserFieldComponent } from './location-chooser-field.component';
import { FilterSidebarComponent } from './filter-sidebar.component';
import { DirectivesModule } from '../directives/directives.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    WidgetsModule,
    ListsModule,
    ReactiveFormsModule,
    DirectivesModule
  ],
  declarations: [
    FormLoginComponent,
    DynamicFormComponent,
    DynamicFormFieldComponent,
    DynamicFormRowComponent,
    GroupBoxFieldComponent,
    FormWrapperComponent,
    FormFieldListComponent,
    NumericFieldComponent,
    CheckboxFieldComponent,
    RadioFieldComponent,
    RadioGroupComponent,
    ProgressFieldComponent,
    SelectFieldComponent,
    SegmentFieldComponent,
    ReadOnlyTextFieldComponent,
    LinkFieldComponent,
    LocationChooserFieldComponent,
    FilterSidebarComponent
  ],
  exports:[
    FormLoginComponent,
    DynamicFormComponent,
    DynamicFormFieldComponent,
    DynamicFormRowComponent,
    GroupBoxFieldComponent,
    FormWrapperComponent,
    FormFieldListComponent,
    NumericFieldComponent,
    CheckboxFieldComponent,
    RadioFieldComponent,
    RadioGroupComponent,
    ProgressFieldComponent,
    SelectFieldComponent,
    SegmentFieldComponent,
    ReadOnlyTextFieldComponent,
    LinkFieldComponent,
    LocationChooserFieldComponent,
    FilterSidebarComponent
  ]
})

export class EDXFormsModule { }
