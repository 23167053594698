<ng-template #searchListTemplate let-searchTemplateShown="searchTemplateShown">
  <ng-template [ngIf]="searchTemplateShown">
    <div class="search-content">
      <ng-template [ngIf]="!loadingSearches">
        <li *ngIf="tabId==='recent' && !!savedSearchesData.list && !!savedSearchesData.list.length" role="button" tabindex="6" (click)="clearRecent($event)" (keyup.enter)="clearRecent($event)" (keyup.space)="clearRecent($event)">
          <img class="search-icon" src="assets/images/formfield_clear.svg">
          <div class="text clear">{{this.localizer.getTranslation('FORMS.BUTTONS.CLEAR_ALL')}}</div>
        </li>
        <li *ngFor="let search of savedSearchesData.list" class="searchitem" tabindex="6" [title]="search.DESCRIPTION" (click)="executeSearch(search, $event)" (keyup.enter)="executeSearch(search, $event)" (keyup.space)="executeSearch(search, $event)">
          <img *ngIf="search['UNSAVED']!=='Y'" class="search-icon" src="assets/images/mime_saved_search.svg" alt="{{this.localizer.getTranslation('ALT_TEXT.SAVED_SEARCH')}}">
          <img *ngIf="search['UNSAVED']==='Y'" class="search-icon" src="assets/images/mime_unsaved_search.svg" alt="{{this.localizer.getTranslation('ALT_TEXT.UNSAVED_SEARCH')}}">
          <div class="text">{{search.DESCRIPTION}}</div>
        </li>
        <li *ngIf="!loadingSearches && (!savedSearchesData.list || !savedSearchesData.list.length)" class="searchitem">
          <div class="text center" tabindex="6">{{this.localizer.getTranslation('HERO.SECTIONS.NO_ITEMS')}}</div>
        </li>
      </ng-template>
      <edx-spinner *ngIf="loadingSearches" class="spinner" [mini]="true"></edx-spinner>
    </div>
  </ng-template>
</ng-template>

<ng-template #optionsTemplate let-optionsTemplateShown="optionsTemplateShown">
  <ng-template [ngIf]="optionsTemplateShown">
    <div class="searchheader" [ngClass]="{first: !mobile}">
      <div class="search-title" tabindex="6">{{this.localizer.getTranslation('GLOBAL_SEARCHSCOPE.SEARCH_SCOPE')}}</div>
    </div>
    <li *ngFor="let item of items" role="menuitemcheckbox" [attr.aria-checked]="item.checked" class="searchitem" [ngClass]="{separator:item.separator, hidden:!item.enabled}" tabindex="6" (click)="execute(items.indexOf(item), $event)" (keyup.enter)="execute(items.indexOf(item), $event)" (keyup.space)="execute(items.indexOf(item), $event)">
      <div *ngIf="item.checkable" class="checkmark" [ngClass]="{checked:item.checked}" (click)="execute(items.indexOf(item), $event)"></div>
      <div class="text">{{item.name}}</div>
    </li>
    <div class="searchheader">
      <div class="search-title" tabindex="6">{{this.localizer.getTranslation('GLOBAL_SEARCHSCOPE.MORE_SEARCHES')}}</div>
    </div>
    <li *ngFor="let item of moreSearches" role="link" class="searchitem">      
      <div class="checkmark"></div>
      <div id="item.id" class="text" [ngClass]="{link: item.isLink, graytext: !item.isLink}" tabindex="6" (click)="doCommand(item.id, item)" (keyup.enter)="doCommand(item.id, item)" (keyup.space)="doCommand(item.id, item)">{{item.resource}}</div>      
    </li>
    <div class="searchheader">
      <div class="search-title" tabindex="6">{{this.localizer.getTranslation('GLOBAL_SEARCHSCOPE.LIBRARIES_TO_SEARCH')}}</div>
    </div>
    <div id="librariesList" class="libraries-list">
      <ng-template ngFor let-item [ngForOf]="libraries" let-index="index">
        <li *ngIf="item.DISABLED!='Y'" class="searchitem" (keyup.enter)="doCommand('libraries', item)" (keyup.space)="doCommand('libraries', item)" (keydown.tab)="onTab($event,index)" (keydown.shift.tab)="onTab($event,index)">
          <input type="checkbox" [id]="item.LIBRARY_NAME" (click)="doCommand('libraries', item)" class="checkbox" [ngClass]="{checked: item.checked}">
          <span for [id]="item.LIBRARY_NAME" class="text lib-text" role="checkbox" tabindex="6" [attr.aria-checked]="item.checked" (click)="doCommand('libraries', item)">{{item.LIBRARY_NAME}} </span>
        </li>
      </ng-template>
    </div>
  </ng-template>
</ng-template>

<div class="menubutton menubuttonsearch" id="edx_search_menu" [attr.aria-expanded]="isOpen" title="{{this.localizer.getTranslation('ALT_TEXT.SEARCH_MENU')}}" [ngClass]="{mobile: mobile}" tabindex="6" (click)="toggleMenuOpen($event)" (keyup.enter)="toggleMenuOpen($event)" (keyup.arrowdown)="toggleMenuOpen($event)" (keyup.space)="toggleMenuOpen($event)" (keydown.shift.tab)="closeMenu(false)">
  <div class="dropdowncaret" [ngClass]="{rotateIcon:isOpen}" title="{{this.localizer.getTranslation('TOOLTIP.SEARCH_OPTIONS')}}"></div>
  <div *ngIf="isOpen" class="menublocker" [ngClass]="{blocktop:drawUp, blockright:drawToRight}"></div>
</div>
<div *ngIf="isOpen" class="menu-overlay" (click)="overlayClick($event)"></div>
<ul *ngIf="isOpen" role="menu" class="search-dropdown" [ngClass]="{opening: isOpening, closing: isClosing, drawright: drawToRight, drawup: drawUp, mobile: mobile}"
    (animationend)="transitionComplete()" (keyup.escape)="closeMenu(false)">
  <div class="leftbody" role="menuitem">
    <div class="tabcontainer">
      <edx-tab-selector #tabSelector [receiver]="this" [tabIndex]="6" [tabdefs]="tabList"></edx-tab-selector>
    </div>
    <div [ngSwitch]="tabId" class="dropdown">
      <ng-template [ngSwitchCase]="'recent'">
        <ng-container *ngTemplateOutlet="searchListTemplate;context:{searchTemplateShown:true}"></ng-container>
      </ng-template>
      <ng-template [ngSwitchCase]="'saved'">
        <ng-container *ngTemplateOutlet="searchListTemplate;context:{searchTemplateShown:true}"></ng-container>
      </ng-template>
      <ng-template [ngSwitchCase]="'options'">
        <ng-container *ngTemplateOutlet="optionsTemplate;context:{optionsTemplateShown:true}"></ng-container>
      </ng-template>
    </div>
  </div>
  <div *ngIf="!mobile" class="rightbody" role="menuitem">
    <div class="dropdown">
      <ng-container *ngTemplateOutlet="optionsTemplate;context:{optionsTemplateShown:true}"></ng-container>
    </div>
  </div>
</ul>
