import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Inject, ViewChild, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

import { WindowModalComponent } from './window-modal.component';
import { WindowViewFoldersComponent } from './window-view-folders.component';
import { Util } from '../utils/utils.module';
import { AppComponent } from '../app.component';

@Component({
  selector: 'edx-window-modal-folders',
  styleUrls: [ 'window-modal.component.scss', 'window-modal-folders.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div [ngClass]="{ window_modal: true, open_tile: isOpening, close_tile: isClosing, web: ui==0, desktop: ui==1, mobile: ui!=0&&ui!=1, tablet: ui==3||ui==5, oai: isOfficeAddin }" (animationend)="animateDone()" id="edx_app_content_container">
      <edx-window-view-folders [desc]="getDesc()" [name]="getName()"></edx-window-view-folders>
    </div>
  `
})
export class WindowModalFoldersComponent extends WindowModalComponent implements OnDestroy {
  @ViewChild(WindowViewFoldersComponent) viewFolders: WindowViewFoldersComponent;
  public isOpening = false;
  public isClosing = false;
  private appComponent: AppComponent = null;

  constructor(protected location: Location, protected router: Router, protected route: ActivatedRoute, private cdr: ChangeDetectorRef) {
    super(location,router,route);
    this.appComponent = Util.RestAPI.getAppComponent();
    this.isOpening = router.url.indexOf('edx_noanimate') === -1;
  }

  ngOnDestroy() {
    this.appComponent.deregisterWindow(this);
  }

  protected loadURL(url: string): void {
    // block the base class from loading the url
    // our list does that
    this.appComponent.registerWindow(this, url);
    this.cdr.markForCheck();
  }

  public setIsOpening(): void {
    this.isOpening = true;
  }

  public clearIsOpening(): void {
    this.isOpening = false;
  }

  public gotFocus(): void {
    if (this.viewFolders) {
      this.viewFolders.doCommand('list_selectmode_off');
    }
  }

  public refresh(cmd?: string, value?: any): void {
    if (this.viewFolders) {
      this.viewFolders.refresh(cmd, value);
    }
  }

  public animateDone(): void {
    if (this.isOpening || this.isClosing) {
      this.isOpening = false;
      this.appComponent.removeClone();
      if (this.isClosing) {
        Util.RestAPI.navHome();
      }
    }
  }
}
