import { Component, Input, Output, OnInit, OnChanges, ElementRef, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter } from '@angular/core';

import { Util, UserInterface } from '../utils/utils.module';
import { BaseDesc } from '../models/base';
import { ListItem } from '../models/list-item';
import { AccessRights, AccessLevel, AccessSearch } from '../models/security-control';
import { SelectItem } from '../models/form-field';
import { LocalizeService } from '../services/localize.service';
import { ListService } from '../services/list.service';
import { MenuId } from '../services/menu.service';
import { FormService } from '../services/form.service';
import { FooterDisplayItem } from '../widgets/list-info-footer.component';
import { ActionBarComponent } from '../widgets/action-bar.component';
import { ListTableComponent, ListTableParent } from './list-table.component';
import { DynamicFormComponent } from '../forms/dynamic-form.component';

@Component ({
  selector: 'edx-list-security',
  styleUrls: [ 'list-security.component.scss' ],
  providers: [ListService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'list-security.component.html'
})
export class ListSecurityComponent implements OnInit, OnChanges, ListTableParent {
  public ui: UserInterface;
  public isChecked = false;
  private isCheckedBoxReadOnly = false;
  private inheritFlexFolderFrom: string;
  private inheritedFrom: string;
  private actionMenuId: MenuId;
  private rightsLabels: any[];
  private allowedList: string = null;
  private selectedListItems: ListItem[];
  private footerItems: FooterDisplayItem[] = [];
  private footerHeading: string = null;
  private deniedList: string = null;
  private isDirty = false;
  private detailView = false;
  private permissions: string;
  private tablet: boolean = Util.Device.isTabletLook();
  private mobile: boolean = Util.Device.isMobile();
  private iOS: boolean = Util.Device.bIsIOSDevice;
  private isWorkspaceOnMobile = false;
  private disableInherited = false;
  private trusteesDirectives: SelectItem[];
  private securedFlexFolderLookupIDs = Util.RestAPI.getLoginReply().LOOKUP_SECURITY;
  public inheritedFlexTrusteeList: ListItem[] = null;
  public previousCheckStatus: string;
  public isIneritCheckClicked: boolean;
  public isCancelClicked = false;
  public canShowInheritance: boolean;
  @Input() kind: string;
  @Input() desc?: BaseDesc;
  @Input() showFooter?: boolean;
  @Input() isReadOnly: boolean;
  @Input() securityList: ListItem[];
  @Input() splitView?: boolean;
  @Input() inDialog?: boolean;
  @Input() autoAdd?: boolean;
  @Input() massProfile?: boolean;
  @Input() trusteesDirective?: string;
  @Input() parent: DynamicFormComponent;
  @Output() notifySecurityDirty: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() notifySecurityReadOnly: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() trusteesDirectiveChange: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('actionbar') actionBar: ActionBarComponent;
  @ViewChild('table') private table: ListTableComponent;
  @ViewChild('inheritCheckbox') inheritCheckbox: ElementRef<HTMLInputElement> | undefined;
  @Input() securedFlexKeyValueList?: ListItem[];

  constructor(protected localizer: LocalizeService, protected listService: ListService, protected formService: FormService, private cdr: ChangeDetectorRef) {
    this.ui = Util.Device.ui;
    this.actionMenuId = this.ui<2 ? MenuId.MENU_SECURITY_ACTIONS : MenuId.MENU_MOBILE_SECURITY_ACTIONS;
    this.permissions = this.localizer.getTranslation('FORMS.LOCAL.PERMISSIONS_SELECTOR.PERMISSIONS');
    this.trusteesDirectives = [
      {display: this.localizer.getTranslation('FORMS.LOCAL.CHECK_IN.REPLACE_TRUSTEES'), value: 'replace'},
      {display: this.localizer.getTranslation('FORMS.LOCAL.CHECK_IN.ADD_TRUSTEES'), value: 'add'},
      {display: this.localizer.getTranslation('FORMS.LOCAL.CHECK_IN.REMOVE_TRUSTEES'), value: 'remove'}
    ];
  }

  ngOnInit(): void {
    if (this.showFooter) {
      this.setFooterLabels();
    }
    if (Util.RestAPI.restAPIVersion() <= 0x00160301) {
      this.disableInherited = true;
    }
    this.isWorkspaceOnMobile = (!!this.desc && this.desc.type === 'workspaces') && Util.Device.isPhoneLook();
    this.inheritFlexFolderFrom = this.parent?.profileDefaultInfo?.['%SECURITY_INHERITS_FROM'] ||
                                 this.parent?.data?.['%SECURITY_INHERITS_FROM'];
    this.inheritedFlexTrusteeList = this.getInheritedTrustees();
    this.inheritedFrom =  this.getInheritedFromName();
    this.isChecked = this.getCheckedValue();
    this.isCheckedBoxReadOnly = this.canEditFlexSecurity() ? false : true;
    this.previousCheckStatus = '';
    this.isIneritCheckClicked = false;
    this.canShowInheritance = this.canInheritSecurity();

    setTimeout(() => {
      if (this.isCheckedBoxReadOnly) {
        this.inheritCheckbox?.nativeElement.setAttribute('readonly', 'true');
      } else {
        this.inheritCheckbox?.nativeElement.removeAttribute('readonly');
      }
    }, 100);
  }

  ngOnChanges(): void {
    this.updateActionBar();
  }

  private setFooterLabels(): void {
    this.rightsLabels = [];
    this.rightsLabels.push({ value: AccessRights.ACCESS_VIEW_PROFILE, label: this.localizer.getTranslation('SECURITY.RIGHTS.VIEW_PROFILE')});
    this.rightsLabels.push({ value: AccessRights.ACCESS_EDIT_PROFILE, label: this.localizer.getTranslation('SECURITY.RIGHTS.EDIT_PROFILE')});
    this.rightsLabels.push({ value: AccessRights.ACCESS_VIEW_DOCUMENT, label: this.localizer.getTranslation('SECURITY.RIGHTS.VIEW_DOCUMENT')});
    this.rightsLabels.push({ value: AccessRights.ACCESS_RETRIEVE_DOCUMENT, label: this.localizer.getTranslation('SECURITY.RIGHTS.RETRIEVE_DOCUMENT')});
    this.rightsLabels.push({ value: AccessRights.ACCESS_EDIT_CONTENT, label: this.localizer.getTranslation('SECURITY.RIGHTS.EDIT_CONTENT')});
    this.rightsLabels.push({ value: AccessRights.ACCESS_COPY, label: this.localizer.getTranslation('SECURITY.RIGHTS.COPY')});
    this.rightsLabels.push({ value: AccessRights.ACCESS_DELETE, label: this.localizer.getTranslation('SECURITY.RIGHTS.DELETE')});
    this.rightsLabels.push({ value: AccessRights.ACCESS_CONTROL_ACCESS, label: this.localizer.getTranslation('SECURITY.RIGHTS.CONTROL_ACCESS')});
    this.rightsLabels.push({ value: AccessRights.ACCESS_ALLOW_VIEW_PUBLISHED, label: this.localizer.getTranslation('SECURITY.RIGHTS.ALLOW_VIEW_PUBLISHED')});
  }

  private setRrusteesDirective(sel: SelectItem): void {
    this.trusteesDirective = sel.value;
    this.trusteesDirectiveChange.emit(this.trusteesDirective);
  }

  private onTemplateRightChange(right: number): void {
    if (right === -1) {
      this.detailView = !this.detailView;
      this.securityList  = this.table.getList();
      setTimeout(() => {
        this.table.setTrusteeList(this.securityList);
      }, 300);
    }
    this.isDirty = true;
    this.notifySecurityDirty.emit(this.isDirty);
    this.actionBar.updateActions();
  }

  private onGranularRightChange(right: boolean): void {
    this.isDirty = true;
    this.notifySecurityDirty.emit(this.isDirty);
    this.actionBar.updateActions();
  }

  private updateListFooter(): void {
    this.footerItems = [];
    this.footerHeading = null;
    if (this.selectedListItems && this.selectedListItems.length === 1) {
      const listItem: ListItem = this.selectedListItems[0];
      this.enumerateRights(listItem['rights']);
      this.footerHeading = this.localizer.getTranslation('METADATA.FOOTER.SECURITY.HEADER', [ listItem['USER_ID'] ]);
      if (this.desc.type === 'workspaces') {
        let accessRighsInfo = 'METADATA.FOOTER.SECURITY.ALLOW_WORKSPACE_VIEW';
        if ((listItem['rights'] & AccessLevel.ACCESS_LEVEL_MANAGE) === AccessLevel.ACCESS_LEVEL_MANAGE) {
          accessRighsInfo = 'METADATA.FOOTER.SECURITY.ALLOW_WORKSPACE_MANAGE';
        } else if ((listItem['rights'] & AccessLevel.ACCESS_LEVEL_COLLABORATE) === AccessLevel.ACCESS_LEVEL_COLLABORATE) {
          accessRighsInfo = 'METADATA.FOOTER.SECURITY.ALLOW_WORKSPACE_COLLABORATE';
        }
        this.footerItems.push({ label: this.localizer.getTranslation('METADATA.FOOTER.SECURITY.ALLOW'), value: this.localizer.getTranslation(accessRighsInfo) });
      } else {
        this.footerItems.push({ label: this.localizer.getTranslation('METADATA.FOOTER.SECURITY.ALLOW'), value: this.allowedList });
        this.footerItems.push({ label: this.localizer.getTranslation('METADATA.FOOTER.SECURITY.DENY'), value: this.deniedList });
      }
    }
  }

  private enumerateRights(rights: number): void {
    this.allowedList = this.rightsLabels.filter(r => rights & r.value).map(r => r.label).join(', ');
    this.deniedList = this.rightsLabels.filter(r => (rights >> 16) & r.value).map(r => r.label).join(', ');
  }

  public getList(): ListItem[] {
    return this.securityList = this.table.getList();
  }

  public setDirty(dirty: boolean): void {
    this.isDirty = dirty;
  }

  private checkUserRights(): void {
    const trustees: ListItem[] = this.getList();
    const thisUser: string = Util.RestAPI.getUserID();
    for (const trustee of trustees) {
      if (Util.Transforms.isCaseInsensitiveEqual(trustee['USER_ID'], thisUser)) {
        const rights = trustee['rights'];
        const canEdit =
              rights === AccessLevel.ACCESS_LEVEL_FULL ||
              rights === AccessLevel.ACCESS_LEVEL_FULL_RM ||
              rights === AccessLevel.ACCESS_LEVEL_CREATOR ||
              (this.desc && this.desc.type === 'searches' &&
                (rights & AccessSearch.VIEW_EDIT) === AccessSearch.VIEW_EDIT);
        if ((!canEdit && !this.isReadOnly) || (canEdit && this.isReadOnly)) {
          this.isReadOnly = !canEdit;
          this.notifySecurityReadOnly.emit(this.isReadOnly);
        }
        break;
      }
    }
    if (
      this.isChecked &&
      this.canShowInheritance &&
      (this.inheritedFlexTrusteeList?.length ||
        this.parent?.desc['type'] === 'preferences' ||
        (this.desc['id'] !== '0' &&
          (this.desc[':INH_LUP_SEC_FROM'] !== '-1' ||
            this.desc['INH_LUP_SEC_FROM'] !== '-1')))
    ) {
      this.isReadOnly = true;
      this.notifySecurityReadOnly.emit(this.isReadOnly);
    }
    if (this.desc['STORAGE'] === 'T') {
      const effectiveRights: any = Util.RestAPI.getLoginReply() && Util.RestAPI.getLoginReply().EFFECTIVE_RIGHTS ? Util.RestAPI.getLoginReply().EFFECTIVE_RIGHTS : {};
      if (effectiveRights.TEMPLATE_MANAGER === 'N') {
        this.isReadOnly = true;
        this.notifySecurityReadOnly.emit(this.isReadOnly);
      }
    }
    if (this.desc && (this.desc['RECORD'] === 'Y' || (this.desc['READONLY'] === 'Y' || ['3', '18', '19'].includes(this.desc['STATUS'])))) {
      this.isReadOnly = true;
      this.notifySecurityReadOnly.emit(this.isReadOnly);
    }
    this.cdr.markForCheck();
  }

  // **** ListTableParent implementation
  public listUpdated(table: ListTableComponent): void {
    this.selectedListItems = this.table.getSelections();
    this.checkUserRights();
    this.updateActionBar();
    if (this.showFooter) {
      this.updateListFooter();
    }
    if (this.autoAdd) {
      this.doCommand('security_add');
      this.autoAdd = false;
    }
  }

  public selectionsUpdated(table: ListTableComponent): void {
    this.listUpdated(table);
  }

  public canSelectListItem(table: ListTableComponent, item: ListItem): boolean {
    if (!!item && (item['USER_ID']!==this.desc['AUTHOR_ID']) && (Util.RestAPI.getUserID()!==item['USER_ID'])) {
      return true;
    }
    return false;
  }

  public updateActionBar(): void {
    if (this.actionBar) {
      this.actionBar.updateActions();
    }
  }

  public initialList(table: ListTableComponent): ListItem[] {
    return this.securityList;
  }

  public reloadList(): void {
    this.table.clearList();
    this.desc['edx_selected_security_choice']='';
    setTimeout(() => {
      this.table.loadList(true);
     }, 10);
    this.securityList = this.table.getList();
  }

  // **** CommandHandler implementation
  public commandEnabled(cmd: string): boolean {
    let enabled = false;
    if (!!this.desc) {
      switch (cmd) {
      case 'security_add':
        if (!this.isReadOnly) {
          enabled = true;
        }
        break;
      case 'security_save':
        enabled = this.isDirty;
        break;
      case 'security_summary':
        enabled = this.detailView;
        break;
      case 'security_details':
        enabled = this.desc.type !== 'searches' && this.desc.type !== 'workspaces' && this.desc['isWorkspace']!== '1' && !this.detailView;
        break;
      case 'security_remove':
        enabled = false;
        if (this.selectedListItems && (this.selectedListItems.length > 0)) {
          const myAuthorID = !!this.desc['AUTHOR_ID'] ? this.desc['AUTHOR_ID'].toUpperCase() : '';
          const myUserID = Util.RestAPI.getUserID();
          enabled = !this.selectedListItems.some(item => {
            const userID = item['USER_ID'].toUpperCase();
            if (userID === myUserID || userID === myAuthorID) {
              return true;
            }
            return false;
          });
        }
        break;
      }
    }
    return enabled;
  }

  public doCommand(cmd: string): boolean {
    let handled = true;
    let data = null;
    const newcmd: string = cmd;

    switch (cmd) {
    case 'security_save':
      handled = false;
      this.isDirty = false;
      if (this.isReadOnly && !this.isChecked) {
        handled = true;
        this.table.clearList();
        setTimeout(() => {
          this.table.loadList();
        }, 300);
        this.isDirty = true;
      } else if (this.desc['SECURITY'] !== '1' || this.desc['SEC_REASON_LINK'] !== '1') {
        this.desc['SECURITY'] = '1';
        this.desc['SEC_REASON_LINK'] = '1';
      }
      this.table.spinnerOn();
      this.securityList = this.table.getList();
      data = this.securityList;
      Util.RestAPI.setCurList([new ListItem(this.desc)], null);
      Util.RestAPI.setCurListComponent(this.table);
      setTimeout(() => {
        this.notifySecurityDirty.emit(this.isDirty);
      }, 300);
      break;
    case 'security_summary':
    case 'security_details':
      this.detailView = !this.detailView;
      this.securityList = this.table.getList();
      setTimeout(() => {
        this.table.setTrusteeList(this.securityList);
      }, 300);
    break;
    case 'security_add':
      Util.RestAPI.pickGroupsUsers((pickedList: any[], success: boolean) => {
        if (success && pickedList && pickedList.length) {
          const newTrusteeOrGroupAdded: boolean = this.table.addTrusteeToList(pickedList);
          this.securityList = this.table.getList();
          if (newTrusteeOrGroupAdded) {
            this.isDirty = true;
            this.notifySecurityDirty.emit(this.isDirty);
            this.actionBar.updateActions();
          }
        }
      }, this.table.getList());
      break;
    case 'security_remove':
      const selections: ListItem[] = this.table.getSelections();
      const list: ListItem[] = this.table.getList();
      if (list && list.length && selections && selections.length) {
        const indexesToDelete: number[] = [];
        for (const listItem of selections) {
          indexesToDelete.push(list.indexOf(listItem));
        }
        // we want big index to little index for delete so list does not reindex when deleting
        indexesToDelete.sort((a,b) => b-a);
        for (const index of indexesToDelete) {
          list.splice(index, 1);
        }
        this.securityList = list;
        this.isDirty = true;
        this.notifySecurityDirty.emit(this.isDirty);
        this.table.clearSelection();
      }
      break;
    }
    this.selectedListItems = [];
    if (this.actionBar) {
      this.actionBar.updateActions();
    }
    if (!handled) {
      let serverData: any;
      if ((this.desc['INH_LUP_SEC_FROM'] === '-1' || this.desc[':INH_LUP_SEC_FROM'] === '-1') && this.isChecked) {
        serverData = {'%INHERIT_LOOKUP_SECURITY_FROM':'0'};
        this.formService.putToServer(this.desc, serverData, 'profile', null, false, null, null);
        handled = true;
      } else if (((this.desc['INH_LUP_SEC_FROM'] !== '-1' && this.desc['INH_LUP_SEC_FROM'] !== '0') ||
                  (this.desc[':INH_LUP_SEC_FROM'] !== '-1' && this.desc[':INH_LUP_SEC_FROM'] !== '0')) && !this.isChecked) {
        serverData = {
          '%INHERIT_LOOKUP_SECURITY_FROM': '-1',
          _restapi: {
            security: this.securityList,
          },
        };
        this.formService.putToServer(this.desc, serverData, 'profile', null, false, null, null);
        handled = true;
      } else {
        handled = Util.RestAPI.getAppComponent().doCommand(newcmd, data);
      }
    }
    return handled;
  }

  private canInheritSecurity(): boolean {
    if (!!this.securedFlexFolderLookupIDs) {
      if (this.desc.id === '0' && this.parent?.desc['type'] !== 'preferences') {
        return (this.inheritedFlexTrusteeList?.length > 0);
      } else {
        return (
          (!!this.desc[':INH_LUP_SEC_FROM'] && this.desc[':INH_LUP_SEC_FROM'] !== '0') ||
          (!!this.desc['INH_LUP_SEC_FROM'] && this.desc['INH_LUP_SEC_FROM'] !== '0') ||
          (!!this.parent?.data[':INH_LUP_SEC_FROM'] && this.parent?.data[':INH_LUP_SEC_FROM'] !== '0') ||
          (!!this.parent?.data['INH_LUP_SEC_FROM'] && this.parent?.data['INH_LUP_SEC_FROM'] !== '0' ||
           this.inheritedFlexTrusteeList?.length > 0 ||
           (!!this.parent?.data?.['%SECURITY_INHERITS_FROM'] &&
            this.parent?.data?.['%SECURITY_INHERITS_FROM'].startsWith('DV')))
        );
      }
    } else {
      return false;
    }
  }

  public onCheckChange(event: Event) {
    const doOnCheckChange = () => {
      if (this.isChecked) {
        if (this.parent?.isSecurityChanged) {
          this.inheritedFlexTrusteeList = Util.deepCopy(this.parent.inheritedFlexTrusteeList);
        }
        this.securityList = Util.deepCopy(this.inheritedFlexTrusteeList);
        this.table.setTrusteeList(this.securityList);
        this.isReadOnly = true;
        this.notifySecurityReadOnly.emit(this.isReadOnly);
        this.isDirty = true;
        this.notifySecurityDirty.emit(this.isDirty);
        this.updateActionBar();
      } else {
        this.isReadOnly = false;
        this.notifySecurityReadOnly.emit(this.isReadOnly);
        this.updateActionBar();
      }
    };
    if (!this.isIneritCheckClicked) {
      this.previousCheckStatus = this.isChecked ? 'true' : 'false';
    }
    this.isIneritCheckClicked = true;
    if (this.isCancelClicked) {
      this.isIneritCheckClicked = false;
      this.isCancelClicked = false;
    } else {
      this.isChecked = !this.isChecked;
      if (this.desc[':INH_LUP_SEC_FROM'] !== '0' ||
          this.parent?.data[':INH_LUP_SEC_FROM'] !== '0' ||
          this.desc['INH_LUP_SEC_FROM'] !== '0' ||
          this.parent?.data['INH_LUP_SEC_FROM'] !== '0') {
        if (!this.inheritedFlexTrusteeList) {
          this.getInheritedFlexTrustees();
        }
        setTimeout(() => {
          doOnCheckChange();
        }, 1000);
      } else {
        doOnCheckChange();
      }
    }
  }

  public securityReadOnly(readOnly: boolean): void {
    if (this.isChecked) {
      this.isIneritCheckClicked = false;
      this.isCancelClicked = false;
    } else {
      this.inheritCheckbox?.nativeElement.removeAttribute('checked');
      this.inheritCheckbox?.nativeElement.click();
      this.isReadOnly = false;
      this.notifySecurityReadOnly.emit(this.isReadOnly);
    }
    this.cdr.markForCheck();
  }

  private canEditFlexSecurity(): boolean {
    let userFlexRights = null;
    const loginReply: any = Util.RestAPI.getLoginReply();
    const group = loginReply?.['GROUP'];
    const user = loginReply?.['USER_ID'];
    if (!this.parent?.userFlexRights && this.parent?.userFlexRights !== 0 && this.inheritedFlexTrusteeList) {
      userFlexRights =
        this.inheritedFlexTrusteeList?.find(
          (trustee) => trustee['USER_ID'].toLocaleUpperCase() === user
        )?.['rights'] ||
        this.inheritedFlexTrusteeList?.find(
          (trustee) => trustee['USER_ID'].toLocaleUpperCase() === group
        )?.['rights'];
    }
    if (userFlexRights || this.parent?.userFlexRights || Number(this.parent?.userFlexRights) === 0) {
      return (
        Number(userFlexRights) === AccessLevel.ACCESS_LEVEL_FULL ||
        Number(userFlexRights) === AccessLevel.ACCESS_LEVEL_CREATOR ||
        Number(this.parent?.userFlexRights) === AccessLevel.ACCESS_LEVEL_FULL ||
        Number(this.parent?.userFlexRights) === AccessLevel.ACCESS_LEVEL_CREATOR
      );
    } else if (this.parent?.desc['type'] === 'preferences') {
      return true;
    } else {
      return (
        Number(this.desc['rights']) === AccessLevel.ACCESS_LEVEL_FULL ||
        Number(this.desc['rights']) === AccessLevel.ACCESS_LEVEL_CREATOR ||
        Number(this.desc['%EFFECTIVE_RIGHTS']) === AccessLevel.ACCESS_LEVEL_FULL ||
        Number(this.desc['%EFFECTIVE_RIGHTS']) === AccessLevel.ACCESS_LEVEL_CREATOR
      );
    }
  }

  private getInheritedFromName() {
    if (this.parent && (!!this.parent.inheritFromPrimaryKey || !!this.inheritFlexFolderFrom)) {
      let inheritFlexFolderFromKey = '';
      if (this.inheritFlexFolderFrom) {
        inheritFlexFolderFromKey = this.inheritFlexFolderFrom.slice(this.inheritFlexFolderFrom.indexOf('=')+1,this.inheritFlexFolderFrom.length);
      }
      const inheritFromPrimaryKey = this.parent.inheritFromPrimaryKey || inheritFlexFolderFromKey;
      const securedLookupIndex = this.parent.securedLookupKeyList.findIndex(
        (item) =>
          Number(item['lookupPrimaryKey']) === Number(inheritFromPrimaryKey)
      );
      return this.parent.securedLookupKeyList[securedLookupIndex]?.['lookupID'];
    }

    const inheritFromList = [];
    const securityLink = this.desc[':INH_LUP_SEC_FROM'] ||
                          this.desc['INH_LUP_SEC_FROM'] ||
                          this.desc['SECURITY_LINK'] ||
                          this.parent?.data[':INH_LUP_SEC_FROM'] ||
                          this.parent?.data['INH_LUP_SEC_FROM'];
    if (securityLink === '-1') {
      if (this.parent) {
        this.parent.securedLookupKeyList.forEach(item => {
          Object.values(this.parent.data).forEach(value => {
            if (item['lookupPrimaryKey'] === value) {
              inheritFromList.push(item);
            }
          });
        });
      } else {
        this.securedFlexKeyValueList?.forEach(item => {
          Object.values(this.desc).forEach(value => {
            if (item['lookupPrimaryKey'] === value) {
              inheritFromList.push(item);
            }
          });
        });
      }
    } else {
      if (this.parent && this.parent.securedLookupKeyList.length) {
        this.parent.securedLookupKeyList.forEach(item => {
          if (item['lookupPrimaryKey'] === securityLink) {
            inheritFromList.push(item);
          }
        });
      } else if (this.securedFlexKeyValueList?.length) {
        this.securedFlexKeyValueList?.forEach(item => {
          if (item['lookupPrimaryKey'] === securityLink) {
            inheritFromList.push(item);
          }
        });
      } else if (!this.parent?.securedLookupKeyList.length) {
        const data = this.parent?.data || this.desc;
        Object.keys(data).forEach(key => {
          if (data[key] === securityLink) {
            inheritFromList.push(key);
          }
        });
        return inheritFromList.pop();
      }
    }
    if (inheritFromList.length > 1) {
      return inheritFromList.find(item => !!item['childLookupID'])['childLookupID'];
    } else if (inheritFromList.length) {
      return inheritFromList[0]['lookupID'];
    }
  }

  private getInheritedTrustees() {
    let inheritedFlexTrusteeList = null;
    if (this.parent?.inheritedFlexTrusteeList?.length > 0 ||
        this.inheritFlexFolderFrom?.startsWith('DV=')) {
      if (this.parent?.inheritedFlexTrusteeList?.length > 0 && !this.inheritedFlexTrusteeList) {
        inheritedFlexTrusteeList = Util.deepCopy(this.parent?.inheritedFlexTrusteeList);
      } else if (this.inheritFlexFolderFrom?.startsWith('DV=') &&
                 this.parent?.inheritFromTrustees?.length &&
                 !this.inheritedFlexTrusteeList) {
        inheritedFlexTrusteeList = Util.deepCopy(this.parent?.inheritFromTrustees);
        this.securityList = Util.deepCopy(this.parent?.inheritFromTrustees);
      } else {
        inheritedFlexTrusteeList = null;
      }
    }
    return inheritedFlexTrusteeList;
  }

  private getCheckedValue(): boolean {
    return (
      this.parent?.inheritSecurityIsChecked ||
      (this.inheritedFlexTrusteeList?.length > 0 && !this.parent?.inheritedFlexTrusteeList) ||
      (!!this.inheritFlexFolderFrom && !this.parent?.isSecurityChanged) ||
      ((!!this.desc['INH_LUP_SEC_FROM'] &&
        this.desc['INH_LUP_SEC_FROM'] !== '-1') ||
      (!!this.desc[':INH_LUP_SEC_FROM'] &&
        this.desc[':INH_LUP_SEC_FROM'] !== '-1') ||
      (!!this.parent?.data['INH_LUP_SEC_FROM'] &&
        this.parent?.data['INH_LUP_SEC_FROM'] !== '-1') ||
      (!!this.parent?.data[':INH_LUP_SEC_FROM'] &&
        this.parent?.data[':INH_LUP_SEC_FROM'] !== '-1')) &&
      !this.parent?.isSecurityChanged
    );
  }

  // Create flex lookup Url
  private makeFlexFolderLookupUrl(): any {
    let lookups = '';
    let filter = '';
    let url = '';
    const formName = this.desc['formName'] || this.desc['FORMNAME'] ||
                     this.parent.data['formName'] || this.parent.data['FORMNAME'];
    const securedList = this.parent?.securedLookupKeyList || this.securedFlexKeyValueList;
    if (securedList) {
      securedList.forEach((el) => {
        if (!!el['lookupPrimaryKey']) {
          lookups += `${el['lookupID']}=${el['lookupPrimaryKey']},`;
        }
      });
      filter = `&filter=LOOKUP_IDS=${lookups.slice(0, lookups.length - 1)}`;
    } else {
      const inheritFrom = this.getInheritedFromName();
      const securityLink = this.desc[':INH_LUP_SEC_FROM'] ||
                           this.desc['INH_LUP_SEC_FROM'] ||
                           this.parent?.data[':INH_LUP_SEC_FROM'] ||
                           this.parent?.data['INH_LUP_SEC_FROM'];
      if (securityLink !== '-1') {
        lookups += `${inheritFrom}=${securityLink}`;
        filter = `&filter=LOOKUP_IDS=${lookups}`;
      }
    }
    if (!lookups) {
      return null;
    } else {
      return url = `/forms/${formName}/defaults?library=${this.desc.lib}${filter}`;
    }
  }

  private getInheritedFlexTrustees() {
    const url = this.makeFlexFolderLookupUrl();
    if (url) {
      Util.RestAPI.get(url).subscribe((data) => {
        const newTrustees = data?.['trustees'];
        if (newTrustees.length) {
          this.inheritedFlexTrusteeList = newTrustees.filter(el => el['USER_ID'] !== '@ID_USED');
        } else {
          this.inheritedFlexTrusteeList = [];
        }
      });
    }
  }
}
