import { Component, OnInit, Input, ViewChild, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AWFileSystem, AWMenu, AWHeaderBar } from 'appworks-js';
import * as microsoftTeams from '@microsoft/teams-js';
import { Subscription } from 'rxjs';

import { Util, UserInterface } from './utils/utils.module';
import { LocalizeService } from './services/localize.service';
import { DataService } from './services/data.service';
import { MenuService, MenuId, MenuDef } from './services/menu.service';
import { NavService } from './services/nav.service';
import { TileService } from './services/tile.service';
import { Tile } from './models/tile';
import { NavItem } from './models/nav-item';
import { MenuItem } from './models/menu-item';
import { ListData } from './models/base';
import { AppBreadcrumbsComponent } from './app-breadcrumbs.component';
import { AppNavSidebarComponent } from './app-nav-sidebar.component';
import { CommandHandler, MenuItemSetter, SearchWhere } from './models/command-handler';
import { AppComponent } from './app.component';
import { MenuComponent } from './widgets/menu.component';
import { SearchMenuComponent } from './widgets/search-menu.component';
import { ListBaseComponent } from './lists/list-base.component';
import { WindowModalComponent } from './windows/window-modal.component';
import { SecurityControl } from './models/security-control';
import { ListItem } from './models/list-item';
import { KeyCommand, ShortcutsService } from './services/shortcuts.service';

class HeaderCommand {
  public title: string;
  public leftText: string;
  public rightText: string;
  public cmd: string;
  public handler: CommandHandler;
  public actionMenuID: number;
  public actionMenuTitle: string;
  public actionMenuIcon: string;
  public forceActionIcon: boolean;
  constructor(handler: CommandHandler, cmd: string, leftText: string, title: string, rightText: string, actionMenuID: number, actionMenuTitle: string, actionMenuIcon: string) {
    this.handler = handler;
    this.cmd = cmd;
    this.leftText = leftText;
    this.title = title;
    this.rightText = rightText;
    this.actionMenuID = actionMenuID;
    this.actionMenuTitle = actionMenuTitle;
    this.actionMenuIcon = actionMenuIcon;
    this.forceActionIcon = false;
  }

  public reset(handler: CommandHandler, title: string): void {
    this.handler = handler;
    this.title = title;
    this.cmd = null;
    this.leftText = null;
    this.rightText = null;
    this.actionMenuID = -1;
    this.actionMenuTitle = null;
    this.actionMenuIcon = null;
    this.forceActionIcon = false;
  }
}

@Component({
  selector: 'edx-app-header',
  styleUrls: [ 'app-header.component.scss' ],
  templateUrl: 'app-header.component.html'
})
export class AppHeaderComponent implements CommandHandler, OnInit, OnDestroy {
  public ui: UserInterface;
  public sections = false;
  public maskHeaderCount = 0;
  public navDisabledCount = 0;
  public hasOfficeInfoButton: boolean;
  public isOfficeAddin: boolean;
  protected searchWhere: SearchWhere = SearchWhere.Global;
  private showSearchWhere = false;
  private searchTypingTimer: number = null;
  private searchText = '';
  private showSearchBar = false;
  private searchbarShowing = false;
  private searchbarHiding = false;
  private showClearIcon = false;
  private searchPlaceHolder: string;
  private showBCStr: string;
  private hideBCStr: string;
  private reloadStr: string;
  private navLoc = '';
  private tiles: Tile[] = [];
  private bcEnabled = true;
  private searchOption='FULLTEXT_CONTENT_PROFILE';
  private appBreadcrumbs: AppBreadcrumbsComponent;
  private hdrCommands: HeaderCommand[] = [];
  private cordovaCmd: string = null;
  private cordovaCmdArg: any = null;
  private awFileSystem: AWFileSystem = null;
  private awMenu: AWMenu = null;
  private awHeaderBar: AWHeaderBar = null;
  private settingAWHeaderMask = 0;
  private curSearchSuggestion: string = null;
  private searchSuggestions: string[] = null;
  private loadingSuggestionsCount = 0;
  private searchSuggestionsShown = false;
  private hasSearchSuggestions = false;
  private searchIgnoreIndex = 0;
  private mobileSearchTextInput: ElementRef = null;
  private startLoc: string = location.href;
  private bTeamsInitialNav = false;
  private broacastChannel: BroadcastChannel;
  private waitingBroadcast = false;
  private shortcutsSubscription: Subscription;
  @ViewChild('actionMenu') actionMenu: MenuComponent;
  @ViewChild('userMenu') userMenu: MenuComponent;
  @ViewChild('helpMenu') helpMenu: MenuComponent;
  @ViewChild('searchWhereMenu') searchWhereMenu: MenuComponent;
  @ViewChild(SearchMenuComponent) searchMenu: SearchMenuComponent;
  @ViewChild('cordovaCmdButton') cordovaCmdButton;
  @ViewChild('searchTextInput') searchTextInput: ElementRef;
  @Input() navsidebar: AppNavSidebarComponent;
  @Input() app: AppComponent;
  @Input() target: HeaderTarget;

  constructor(private tileService: TileService, private localizer: LocalizeService, private menuService: MenuService, private dataService: DataService, private navService: NavService, private router: Router, shortcutsService: ShortcutsService) {
    Util.Device.init();
    this.ui = Util.Device.ui;
    this.isOfficeAddin = Util.Device.bIsOfficeAddin;
    setTimeout(() => {
      this.hasOfficeInfoButton = Util.Device.bIsOfficeAddin && Util.Device.bIsOfficeAddinDesktop && !Util.Device.bIsOfficeAddinOutlook;
    }, 300);
    this.pushCommandHandler(this, null, null, this.appTitle(), null, -1, null, null);
    this.shortcutsSubscription = shortcutsService.commands.subscribe(c => this.handleCommand(c));
  }

  handleCommand(command: KeyCommand) {
    if (command.name !== 'header-trigger-hamburger' && command.type !== 'HAMBURGER-MENU') {
      this.closeHamburger();
    }
    if (command.name !== 'header-open-search-menu' && command.type !== 'SEARCH-MENU') {
      if (this.searchMenu.isOpen) {
        this.searchMenu.closeMenu(true);
      }
    }
    if (command.type === 'GLOBAL') {
      switch (command.name) {
        case 'header-focus-search':
          this.focusSearchBar();
          break;
        case 'header-trigger-hamburger':
          this.hamburgerClicked(command.ev);
          break;
        case 'header-admin':
          if (this.canUserAccessAdminMaintenance()) {
            this.doCommand('adminmaintenance');
          }
          break;
        case 'header-refresh':
          this.refresh();
          break;
        case 'header-open-prev-window':
          this.getBreadcrumbs().navBack();
          break;
        case 'header-open-search-menu':
          this.searchMenu.toggleMenuOpen(command.ev);
          break;
        default:
          const cmd = command.name.split('-')[1];
          if (this.commandEnabled(cmd)) {
            this.doCommand(cmd);
          }
          break;
      }
    }
  }

  ngOnDestroy() {
    if (this.shortcutsSubscription) {
      this.shortcutsSubscription.unsubscribe();
    }
  }

  @HostListener('window:orientationchange')
  public onOrientationChange(): void {
    setTimeout(() => {
      this.postCordovaCommand('cordovaoOrientationchange');
    }, 1);
  }

  ngOnInit(): void {
    if (Util.Device.bIsCordova || Util.Device.bIsElectron) {
      this.awMenu = new AWMenu(cmd => {}, err => {});
      if (Util.Device.bIsCordova) {
        this.awHeaderBar = new AWHeaderBar(cmd => {}, err => {});
      }
    }
    this.app.ui = Util.Device.ui;
    this.app.iOS = Util.Device.bIsIOSDevice;
    this.app.isAndroid = Util.Device.bIsAndroid;
    this.app.isOfficeAddin = Util.Device.bIsOfficeAddin;
    this.app.headerHeight = this.ui<2 ? 'calc(100% - 4rem)' : this.ui<4 ? 'calc(100% - 6rem)' : 'calc(100% - 3rem)';
    this.navLoc = Util.RestAPI.getLoginURL();
    this.showBCStr = this.localizer.getTranslation('HEADER.SHOWCRUMBS');
    this.hideBCStr = this.localizer.getTranslation('HEADER.HIDECRUMBS');
    if (Util.Device.bIsElectron) {
      const menuSections: any[] = [
        {
          subhead: null,
          items: [
            {
              title: this.localizer.getTranslation('SETTINGS.PREFERENCES'),
              action: () => {
                if (this.app && !this.app.isAppModal()) {
                  this.doElectronCommand('preferences');
                }
              },
              visible: true,
              hasBadge: false
            }
          ]
        }
      ];
      this.awMenu.setMenu(menuSections);
    }
    this.setSearchResources('FULLTEXT_CONTENT_PROFILE');
    this.app.registerHeader(this);
  }

  private refresh(): void {
    if (this.navLoc===Util.RestAPI.getHomeURL()) {
      Util.RestAPI.reloadTiles();
    } else {
      const curWindow: WindowModalComponent = Util.RestAPI.getCurWindow();
      if (!!curWindow) {
        curWindow.refresh();
      }
    }
  }

  private launchAdminMaintenance(): void {
    this.doCommand('adminmaintenance');
  }

  public routeChanged(): void {
    const curCmd: HeaderCommand = this.getCurHdrCommand();
    const bIsOffline: boolean = Util.RestAPI.offline();
    this.navLoc = this.router.url;
    if (this.navLoc==='/') {
      if (!Util.Device.bIsElectron && !Util.Device.bIsCordova) {
        setTimeout(() => {
          if (this.navLoc==='/' && Util.Device.bUseWebLogin) {
            Util.RestAPI.navLogin();
          }
        }, 1000);
      }
    } else if (this.navLoc===Util.RestAPI.getHomeURL() || this.navLoc.indexOf('/home/iclink') >= 0) {
      let startUpDesc: any = null;
      if (curCmd) {
        curCmd.reset(this, this.localizer.getTranslation('HEADER.HOME'));
        this.setupCordovaHeader();
      }
      if (!this.tiles || !this.tiles.length) {
        const startUpLocStr: string = Util.RestAPI.getPreference('edx_start_location');
        if (startUpLocStr && startUpLocStr.length) {
          try {
            startUpDesc = JSON.parse(startUpLocStr);
          } catch (e) {
            startUpDesc = null;
          }
        }
        this.tileService.getTiles().then((tiles) => {
          this.tiles = tiles;
          this.registerOnFileOpen();
          if (this.isOfficeAddin && (!!startUpDesc || Util.Device.bIsOfficeAddinOutlookMessageEditable)) {  // if we are an editable message add in then start in recent edits
            if (!startUpDesc) {
              startUpDesc = {id:'recentedits', type:'folders', lib:Util.RestAPI.getPrimaryLibrary(), name:this.localizer.getTranslation('TILE_NAMES.RECENTLY_EDITED'),DOCNAME: this.localizer.getTranslation('TILE_NAMES.RECENTLY_EDITED')};
            }
            this.navService.openItem(startUpDesc, true);
          }
        });
        // no tiles so init the menu as well
        this.setupCordovaMenu(bIsOffline);
      }
      Util.RestAPI.refreshDownloads(false);// requires no server access so do it always when we go home
      if (Util.RestAPI.isLoggedIn()) {
        this.finishNavHome();
      } else if (!!this.startLoc && this.startLoc.split('/#').length>1 && (this.startLoc.split('/#')[1] === Util.RestAPI.getHomeURL() || this.startLoc.split('/#')[1].startsWith('/home/iclink'))) {
        const waitForLogin = () => {
          if (Util.RestAPI.isLoggedIn()) {
            this.finishNavHome();
          } else if (Util.RestAPI.canAutoConnect()) {
            setTimeout(waitForLogin, 100);
          } else {
            Util.RestAPI.navLogin();
          }
        };
        setTimeout(waitForLogin, 100);
      }
    } else if (this.navLoc===Util.RestAPI.getLoginURL()) {
      this.tileService.reset();
      this.tiles = [];
      this.clearSearch(true);
      this.bTeamsInitialNav = false;
      if (curCmd) {
        curCmd.title = this.appTitle();
        curCmd.leftText = curCmd.rightText = null;
        curCmd.cmd = null;
      }
      this.hasSearchSuggestions = false;
      this.showHideSearchTextBar(false);
    } else if (Util.RestAPI.isLoggedIn()) {
      const childRoute = Util.RestAPI.decodeChildRouteURL(this.navLoc);
      if (curCmd) {
        if (this.hdrCommands.length===1) {
          const desc = Util.RestAPI.getDescFromURL(childRoute);
          if (desc && Util.isExternalLib(desc.lib)) {
            this.showSearchBar = false;
          }
          curCmd.title = Util.RestAPI.getNameFromURL(childRoute);
        }
        this.setupCordovaHeader();
        this.reloadStr = this.localizer.getTranslation('HEADER.REFRESH');
      }
    }
  }

  private doItemAction(listItem: ListItem, action: string, version: string, cb: (success) => void): void {
    const rights: SecurityControl = listItem ? Util.RestAPI.getRightsForItem(listItem) : null;
    // check if user has rights to open document
    if ((!rights || (rights && !rights.canViewDocument)) && (action === 'open')) {
      const title = this.localizer.getTranslation('FOLDER_ACTIONS.OPEN');
      const body = this.localizer.getTranslation('GENERIC_ERRORS.NO_OPEN_PERMISSION');
      const OkText = this.localizer.getTranslation('FORMS.BUTTONS.OK');
      const url = Util.RestAPI.getHomeURL();
        Util.Notify.warning(title, body, OkText, null, false, true, true).then((confirm) => {
          if (confirm) {
              Util.RestAPI.navToURL(url);
          }
        });
    }
    if (!!listItem) {
      let queries: string = '?library='+listItem.lib;
      let bDoNav = false;
      let param: string = null;
      let pathName: string;
      if (action==='download' || (action==='open' && !Util.isContainer(listItem.type))) {
        const done = (errStr?: string): void => {
          if (!!errStr) {
            console.log(errStr);
          }
          if (!!cb) {
            cb(false);
          }
        };
        const startTime = new Date();
        if (this.router.url !== Util.RestAPI.getHomeURL()) {
          Util.RestAPI.navToURL(Util.RestAPI.getHomeURL());
        }
        const waitForPFTA = () => {
          const now: Date = new Date();
          const elapsedTime: number = (now.valueOf() - startTime.valueOf());
          if (Util.Device.bIsElectron || Util.Device.bIsCordova || Util.RestAPI.hasPFTA()) {
            if (action==='download') {
              Util.RestAPI.downloadFile(listItem, version, queries, null, false, false, cb);
            } else {
              Util.RestAPI.viewOrOpenFilesWithStatusCheck([listItem], [version], false, cb);
            }
          } else if (elapsedTime < 20000) {
            Util.RestAPI.probePFTA().then(vers => {
              if (vers !== 0) {
                setTimeout(waitForPFTA, 1000);
              } else {
                if (action==='download' || action==='open') {
                  Util.RestAPI.downloadFileWithBrowserAnchor(listItem, version, null);
                } else {
                  done('no pfta for open file link');
                }
              }
            });
          } else {
            done('no pfta to handle link');
          }
        };
        waitForPFTA();
      } else if (!!action && action.startsWith('profile')) {
        let tab: string = action.indexOf('_')>0 ? action.split('_')[1] : '';
        if (!Util.RestAPI.canShowPreview() && tab === 'preview') {
          tab='profile';
        }
        param = 'profile';
        pathName = 'metadata';
        queries += '&name='+Util.RestAPI.encodeChildRouteName(this.localizer.getTranslation('FOLDER_ACTIONS.PROFILE'))+'&id='+listItem.id+'&type='+listItem.type+'&lib='+listItem.lib+'&appid='+encodeURIComponent(listItem.APP_ID)+'&docname='+encodeURIComponent(listItem.DOCNAME)+(!!tab ? '&tab='+tab : '');
        bDoNav = true;
      } else if (Util.isContainer(listItem.type)) {
        pathName = listItem.type;
        queries += '&name='+Util.RestAPI.encodeChildRouteName(listItem.DOCNAME)+'&max='+Util.RestAPI.getDefualtMaxItems();
        bDoNav = true;
      }
      if (bDoNav) {
        const url = Util.RestAPI.makeChildRouteURL('home', 'tcc_outlet', pathName, listItem, param, queries);
        setTimeout(() => {
          Util.RestAPI.navToURL(url);
        }, Util.kPopupDismissMS);
      }
    }
  }

  private searchForDoc(docNum: string, libToSearch: string, action: string, version: string, setAsStart: boolean, cb: (success) => void): void {
    const errHandler = () => {
      this.doItemAction(null, action, version, null);
    };
    const found = (listData: ListData) => {
      if (docNum.startsWith('DV-%DV')) {
        if (!!listData && !!listData.set && !!listData.set.flexinfo && !!listData.set.flexinfo.levels && !!listData.set.flexinfo.levels.length) {
          const level = listData.set.flexinfo.levels[listData.set.flexinfo.levels.length-1];
          if (level.NODE_TEXT) {
            listData.list = [{id:docNum, type:'flexfolders', lib:libToSearch, DOCNAME: level.NODE_TEXT}];
          } else {
            listData = null;
          }
        } else {
          listData = null;
        }
      }
      if (!!listData && !!listData.list && listData.list.length) {
        this.doItemAction(listData.list[0], action, version, cb);
        if (setAsStart && !!this.navsidebar) {
          this.navsidebar.startLocChanged(listData.list[0]);
        }
      } else {
        errHandler();
      }
    };
    if (docNum.startsWith('DV-%DV')) {
      Util.RestAPI.get({id:docNum,type:'flexfolders',lib:libToSearch},null,'start=0&max=1&ascending=SYSTEM_ID').subscribe(found, errHandler);
    } else {
      const searchCriteria: any = {};
      const criteria: any = {};
      const searchForms: any[] = Util.RestAPI.getSearchForms();
      if (searchForms && searchForms.length) {
        searchCriteria['FORM_NAME'] = searchForms[0]['%FORM_NAME'];
      }
      searchCriteria['DESCRIPTION'] = docNum;
      criteria['DOCNUM'] = docNum;
      searchCriteria['criteria'] = criteria;
      Util.RestAPI.post({id:'evaluation',lib:libToSearch,type:'searches'},searchCriteria,'','libs='+libToSearch+'&start=0&max=1&ascending=SYSTEM_ID').subscribe(found, errHandler);
    }
  }

  private doSearchLink(url: string): void {
    const description = Util.RestAPI.getQueryFromURL(url, 'description');
    const criteriaStr = Util.RestAPI.getQueryFromURL(url, 'criteria');
    if (!!criteriaStr) {
      const criteriaParts = criteriaStr.split(',');
      const nCriteria = criteriaParts.length;
      const criteria = {};
      const searchCriteria = {};
      if (!!description) {
        searchCriteria['DESCRIPTION'] = description;
      }
      const searchForms: any[] = Util.RestAPI.getSearchForms();
      if (searchForms && searchForms.length) {
        searchCriteria['FORM_NAME'] = searchForms[0]['%FORM_NAME'];
      }
      try {
        for (let i=0; i<nCriteria; i++) {
          const comp = decodeURIComponent(criteriaParts[i]);
          const [k, v] = comp.split('=');
          criteria[k] = v;
        }
        searchCriteria['criteria'] = criteria;
        setTimeout(() => {
          Util.RestAPI.navToSearchURL('folders', searchCriteria);
        }, Util.kPopupDismissMS);
      } catch (e) {
        console.log('Bad search link bad criteria, ');
        console.log(e);
      }
    } else {
      console.log('Bad search link no criteria');
    }
  }

  private doICLinkUrl(startUrl: string): void {
    const action = Util.RestAPI.getQueryFromURL(startUrl, 'action');
    const version = Util.RestAPI.getQueryFromURL(startUrl, 'vers') || 'C';
    const name = decodeURIComponent(Util.RestAPI.getQueryFromURL(startUrl, 'name'));
    const type = decodeURIComponent(Util.RestAPI.getQueryFromURL(startUrl, 'type'));
    const idPart = Util.RestAPI.getQueryFromURL(startUrl, 'id');
    const id = type === 'flexfolders' ? unescape(idPart) : decodeURIComponent(idPart);
    const appid = decodeURIComponent(Util.RestAPI.getQueryFromURL(startUrl, 'appid'));
    const lib = Util.RestAPI.getQueryFromURL(startUrl, 'lib');
    const listItem: ListItem = {
      AUTHOR_ID: '',
      DOCNUM: id,
      IS_SHARED: '',
      LAST_EDIT_DATE: '',
      TYPIST_ID: '',
      checkout: '',
      correlator: '',
      id, type, lib, DOCNAME:name, APP_ID:appid
    };
    const libraryInLowerCase = lib.toLocaleLowerCase();
    const libraries = Util.RestAPI.getLibraryNamesListInLowerCase();
    if (!!libraryInLowerCase && (libraryInLowerCase === Util.RestAPI.getPrimaryLibrary() || (!!libraries && libraries.indexOf(libraryInLowerCase) !== -1))) {
      if (type === 'searches' && id === 'evaluation') {
        this.doSearchLink(startUrl);
      } else if (!!type && !!id && !!lib && action !== 'open') {
        this.doItemAction(listItem, action, version, null);
      } else if (!!id) {
        this.searchForDoc(id, lib || Util.RestAPI.getPrimaryLibrary(), action, version, false, null);
      }
    } else {
      Util.RestAPI.getFormServiceTemplate('__local_link', lib).then((formTemplate) => {
        if (!!formTemplate && !!formTemplate.defs) {
          const actionDropdown: any = Util.FieldMappings.templateField(formTemplate.defs, '$edx_link_action');
          const actionOption = actionDropdown.selections.find(s => s.value === action);
          if (!!actionOption) {
            const itemType = this.localizer.getTranslation('FORMS.LOCAL.LINK.TYPE_' + type.toUpperCase());
            const docInfo = Util.RestAPI.decodeFileName(name);
            const docName = !!docInfo ? docInfo.name : name;
            const body = this.localizer.getTranslation('FORMS.LOCAL.LINK.ERROR_DIFFERENT_LIBRARY', [itemType, docName, itemType]);
            const okText = this.localizer.getTranslation('FORMS.BUTTONS.OK');
            Util.RestAPI.navHome();
            Util.Notify.warning(actionOption.display, body, okText, null, false, true, true);
          }
        }
      });
    }
  }

  private finishNavHome(): void {
    const loginReply: any = Util.RestAPI.getLoginReply();
    this.hasSearchSuggestions = loginReply && loginReply.SEARCH_CAPABILITIES && loginReply.SEARCH_CAPABILITIES.SUGGESTIONS === 'Y';
    if (this.app && this.app.desc && this.app.desc.type === 'requests') {
      // Must reload requests after exiting since DM server can remove items from requests
      // list leaving the tile unschanged
      setTimeout(() => {
        Util.RestAPI.refreshRequests();
      }, 1000);
    }
    if (!this.startLoc) {
      this.startLoc = window.location.href;
    }
    this.setSearchScope('home');
    this.showHideSearchTextBar(true);
    this.reloadStr = this.localizer.getTranslation('HERO.RELOAD_TILES');
    if (!!Util.Device.teamsContext && !!Util.Device.teamsContext.subEntityId) {
      const subEntityId = Util.Device.teamsContext.subEntityId;
      Util.Device.teamsContext.subEntityId = null;
      this.startLoc = null;
      try {
        const listItem: any = JSON.parse(subEntityId);
        this.searchForDoc(listItem.id, listItem.lib, listItem.action, listItem.version, false, (success => {
          setTimeout(() => {
            microsoftTeams.executeDeepLink(Util.RestAPI.makeTeamsBaseDeepLink());
          }, 5000);
        }));
      } catch (e) {
        microsoftTeams.executeDeepLink(Util.RestAPI.makeTeamsBaseDeepLink());
      }
    } else if (!!Util.Device.teamsContext && !this.bTeamsInitialNav && !!Util.Device.teamsContext.entityId && Util.Device.teamsContext.entityId.startsWith('edx_desc_')) {
      try {
        const parts = Util.Device.teamsContext.entityId.substr(9).split('__');
        this.searchForDoc(parts[0], decodeURIComponent(parts[2]), parts[3], 'C', true, null);
      } catch (e) { }
      this.bTeamsInitialNav = true;
    } else if (!!this.startLoc) {
      const iclinkStart: number = this.startLoc.indexOf('/iclink');
      const homeOutletStart: number = this.startLoc.indexOf('/home/(tcc_outlet:');
      if (iclinkStart >= 0) {
        const startUrl = Util.getRootSiteUrl() + this.startLoc.substr(iclinkStart); // get rid of the hash
        if (this.waitingBroadcast) {
          const waitForBroadcast = () => {
            setTimeout(() => {
              if (this.waitingBroadcast) {
                waitForBroadcast();
              } else {
                this.doICLinkUrl(startUrl);
              }
            }, 100);
          };
          waitForBroadcast();
        } else {
          this.doICLinkUrl(startUrl);
        }
      } else if (homeOutletStart >= 0) {
        const url = this.startLoc.substr(homeOutletStart);
        setTimeout(() => {
          Util.RestAPI.navToURL(url);
        }, Util.kPopupDismissMS);
      }
      this.startLoc = null;
    }
  }

  public setAppBreadcrumbs(bcc: AppBreadcrumbsComponent): void {
    this.appBreadcrumbs = bcc;
  }

  public getBreadcrumbs(): AppBreadcrumbsComponent {
    return this.appBreadcrumbs;
  }

  public displayBranding(): boolean {
    return (this.ui===UserInterface.web || this.ui===UserInterface.desktop || this.ui===UserInterface.phone || this.ui===UserInterface.tablet);
  }

  public breadcrumbsShown(): boolean {
    let rc = false;
    if (this.getBreadcrumbs()) {
      rc = this.getBreadcrumbs().canBeShown();
    }
    return rc;
  }

  public focusSearchBar(): boolean {
    const searchTextBar = this.getSearchBar();
    if (searchTextBar) {
      searchTextBar.focus();
      return true;
    }
     return false;
  }

  private appTitle(): string {
    return this.localizer.getTranslation(this.isOfficeAddin ? 'APP_TITLE_SHORT' : 'APP_TITLE');
  }

  private registerOnFileOpen(): void {
    if (Util.Device.bIsElectron && !this.awFileSystem) {
      this.awFileSystem = new AWFileSystem();
      this.awFileSystem.onFileOpen(filePath => {
        if (filePath) {
          this.awFileSystem.exists(filePath, exists => {
            if (exists) {
              Util.RestAPI.uploadFilesWithUI(null, [filePath]);
            }
          }, err => {});
        }
      },
      (success) => {},
      (error) => {});
    }
  }

  private getSearchBar(): any {
    return this.searchTextInput ? this.searchTextInput.nativeElement : this.mobileSearchTextInput ? this. mobileSearchTextInput.nativeElement : null;
  }

  private showRightActionIcon(): boolean {
    return !this.getRightText() && this.getActionMenuID()!==-1;
  }

  private enableRightActionIcon(): boolean {
    if (this.app && this.app.desc && this.app.desc.type === 'fileplans') {
      if (!!this.app.desc['PD_FILEPT_NO']) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  private getRightBGIcon(): string {
    const curHdrCmd: HeaderCommand = this.getCurHdrCommand();
    if (curHdrCmd && !curHdrCmd.rightText && curHdrCmd.actionMenuID === -1 && !!curHdrCmd.actionMenuIcon && curHdrCmd.actionMenuIcon.indexOf('assets/images') === -1) {
      return 'url(../assets/images/'+curHdrCmd.actionMenuIcon+')';
    }
    return undefined;
  }

  private getCurHdrCommand(): HeaderCommand {
    return this.hdrCommands[this.hdrCommands.length-1];
  }

  private getCurHdrCommandHandler(): CommandHandler {
    return this.getCurHdrCommand().handler;
  }

  private getLeftText(): string {
    return this.getCurHdrCommand().leftText;
  }

  private getTitle(): string {
    return this.ui<2 ? ('| ' + this.appTitle()) : this.getCurHdrCommand().title;
  }

  private getRightText(): string {
    return this.getCurHdrCommand().rightText;
  }

  private getActionMenuID(): number {
    return this.getCurHdrCommand().actionMenuID;
  }

  private getActionMenuTitle(): string {
    return this.getCurHdrCommand().actionMenuTitle;
  }

  private getActionMenuIcon(): string {
    return this.getCurHdrCommand().actionMenuIcon;
  }

  private showMobileHamburger(): boolean {
    return this.ui>=2 && !this.getLeftText() && !!this.navLoc && this.navLoc!==Util.RestAPI.getLoginURL();
  }

  private canUserAccessAdminMaintenance(): boolean {
    return Util.RestAPI.canUserAccessAdminMaintenance();
  }

  private showBack(): boolean {
    return (this.ui===UserInterface.phone || this.ui===UserInterface.tablet) && !!this.navLoc && this.navLoc.length>1 && !(this.navLoc===Util.RestAPI.getHomeURL() || this.navLoc===Util.RestAPI.getLoginURL());
  }

  private homeButtonClicked(event: Event) {
    if (this.navLoc!==Util.RestAPI.getLoginURL()) {
      this.doCommand('home');
    }
  }

  private hamburgerClicked(event: Event) {
    if (this.navLoc!==Util.RestAPI.getLoginURL()) {
      this.navsidebar.toggle(event);
    }
  }

  private closeHamburger() {
    this.navsidebar.closeMenu();
  }

  private closeMenuOnFocusOut(menu: MenuComponent) {
    if (menu && menu.isOpen) {
      menu.closeMenuOnFocusOut(-2);
    }
  }

  private leftClicked(event: Event) {
    const doBack = () => {
      if (this.getBreadcrumbs()) {
        this.getBreadcrumbs().navBack();
      }
    };
    const curHdrCmd: HeaderCommand = this.getCurHdrCommand();
    if (curHdrCmd.cmd === 'save' || curHdrCmd.cmd === 'security_save' || curHdrCmd.cmd === 'popupok') {
      if (!!curHdrCmd.handler) {
        curHdrCmd.handler.doCommand(curHdrCmd.cmd === 'popupok' ? 'back' : 'cancel');
      } else {
        doBack();
      }
    } else {
      doBack();
    }
  }

  private userButtonClicked(event: Event): void {
    if (this.userMenu) {
      this.userMenu.toggleMenuOpen(event);
    }
  }

  private helpButtonClicked(event: Event): void {
    if (this.helpMenu) {
      this.helpMenu.toggleMenuOpen(event);
    }
  }

  private searchWhereButtonClicked(event: Event): void {
    if (this.searchWhereMenu) {
      this.searchWhereMenu.toggleMenuOpen(event);
    }
  }

  private rightClicked(event: Event): void {
    const curHdrCmd: HeaderCommand = this.getCurHdrCommand();
    if (curHdrCmd.handler) {
      if (this.getActionMenuID()!==-1) {
        if (!event) {
          event = new MouseEvent('click', { view: window, bubbles: true, cancelable: true, clientY: 20 });
        }
        this.actionMenu.toggleMenuOpen(event);
      } else if ((!curHdrCmd.handler.commandEnabled || curHdrCmd.handler.commandEnabled(curHdrCmd.cmd)) && curHdrCmd.handler.doCommand(curHdrCmd.cmd)) {
        if (curHdrCmd.cmd!=='security_save' && curHdrCmd.cmd!=='save') {
          if (curHdrCmd.handler['kind'] !== '__local_footer_options' && (curHdrCmd.cmd!=='savesearch' || !this.isOfficeAddin)) {
            curHdrCmd.handler = null;  // null handler out so it does not get a back event
            if (curHdrCmd.cmd !== 'popupok') {
              // popups go back by themselves so go back
              this.leftClicked(event);
            }
          }
          const listComponent: ListBaseComponent = Util.RestAPI.getCurListComponent();
          if (listComponent) {
            listComponent.clearSelection();
          }
        }
      }
    }
  }

  private rightEnabled(): boolean {
    const curHdrCmd: HeaderCommand = this.getCurHdrCommand();
    if (curHdrCmd.handler) {
      if (this.getActionMenuID()!==-1) {
        return true;
      } else if (!!curHdrCmd.cmd && !!curHdrCmd.handler.commandEnabled && curHdrCmd.handler.commandEnabled(curHdrCmd.cmd)) {
        return true;
      }
    }
    return false;
  }

  private showHideSearchTextBar(show: boolean): void {
    if (this.showSearchBar !== show) {
      if (show) {
        this.searchbarShowing = true;
        this.setSearchTextBarFocus();
      } else {
        this.searchbarHiding = true;
        this.hideSearchSuggestions();
      }
    }
  }

  private searchTextBlur(event: Event): void {
    setTimeout(() => {
      this.hideSearchSuggestions();
    }, 500);
  }

  private nav(inc: number): void {
    const item: string = this.curSearchSuggestion;
    let index: number = item!==null ? this.searchSuggestions.indexOf(item) : -1;
    index += inc;
    if (index<0) {
      index = 0;
    } else if (index>=this.searchSuggestions.length) {
      index = this.searchSuggestions.length-1;
    }
    this.curSearchSuggestion = this.searchSuggestions[index];
  }

  private showSearchSuggestions(): void {
    if (!this.searchSuggestionsShown) {
      ++MenuComponent.nMenusShown;
      this.searchSuggestionsShown = true;
    }
    this.loadingSuggestionsCount++;
  }

  private hideSearchSuggestions(): void {
    if (!!this.searchSuggestionsShown) {
      --MenuComponent.nMenusShown;
      this.searchSuggestions = null;
      this.searchSuggestionsShown = false;
      this.curSearchSuggestion = null;
      this.loadingSuggestionsCount = 0;
    }
  }

  public searchTextEntered(event: KeyboardEvent): void {
    if (!!event) {
      const searchTextBar = this.getSearchBar();
      if (event.key === 'ArrowDown'||event.key==='Down') {
        if (this.searchSuggestions) {
          this.nav(1);
        } else if (searchTextBar) {
          searchTextBar.blur();
        }
      } else if (event.key === 'ArrowUp' && this.searchSuggestions) {
        this.nav(-1);
      } else if (event.key === 'Enter' && this.searchSuggestions && this.curSearchSuggestion) {
        this.suggestionClicked(this.curSearchSuggestion);
      } else if (event.key === 'Escape') {
        this.hideSearchSuggestions();
      } else {
        const afterTypingDelay = () => {
          const prevSearchText = this.searchText;
          if (searchTextBar) {
            this.searchText = searchTextBar.value;
            this.showClearIcon = searchTextBar.value ? true : false;
          }
          window.clearTimeout(this.searchTypingTimer);
          this.searchTypingTimer = null;
          if (event.key === 'Enter') {
            this.doCommand('search');
            this.hideSearchSuggestions();
            if (searchTextBar && (!Util.Device.bIsOfficeAddin && Util.Device.isMobile())) {
              searchTextBar.blur();
            }
          } else {
            if (this.hasSearchSuggestions && prevSearchText !== this.searchText) {
              if (this.searchText.length>=3) {
                let serarchTerm: string = null;
                if (this.searchIgnoreIndex > this.searchText.length) {
                  this.searchIgnoreIndex = this.searchText.length;
                }
                serarchTerm = this.searchText.substr(this.searchIgnoreIndex);
                if (!!serarchTerm) {
                  this.showSearchSuggestions();
                  Util.RestAPI.get('searches/suggestions','profile',('term='+encodeURIComponent(serarchTerm))).subscribe((suggestions: string[]) => {
                    if (this.loadingSuggestionsCount>0) {
                      this.loadingSuggestionsCount--;
                      if (suggestions && suggestions.length && (searchTextBar.value.endsWith(suggestions[0]) || !this.searchSuggestions)) {
                        this.searchSuggestions = suggestions.slice(1);
                        if (!this.searchSuggestions || !this.searchSuggestions.length) {
                          this.hideSearchSuggestions();
                        }
                      }
                    }
                  }, err => {
                    this.hideSearchSuggestions();
                  });
                }
              } else {
                this.searchIgnoreIndex = 0;
              }
            }
          }
        };
        if (!!this.searchTypingTimer) {
          window.clearTimeout(this.searchTypingTimer);
        }
        this.searchTypingTimer = window.setTimeout(afterTypingDelay, 300);
      }
    }
  }

  private suggestionClicked(suggestion: string): void {
    const searchTextBar = this.getSearchBar();
    if (searchTextBar) {
      let searchText: string = this.searchText;
      if (this.searchIgnoreIndex) {
        searchText = searchText.substr(0,this.searchIgnoreIndex);
        if (searchText[searchText.length-1]!==' ') {
          searchText += ' ';
        }
      } else {
        searchText = '';
      }
      this.searchText = searchText + suggestion + ' ';
      this.searchIgnoreIndex = this.searchText.length;
      searchTextBar.value = this.searchText;
      searchTextBar.focus();
      this.showClearIcon = true;
    }
    this.hideSearchSuggestions();
  }

  public textEntered(event: KeyboardEvent): void {
    this.searchTextEntered(event);
  }

  public textCleared(): void {
    this.clearSearch();
  }

  public setTextInputElement(textEl: ElementRef): void {
    this.mobileSearchTextInput = textEl;
  }

  private clearSearch(clearEverything?: boolean): void {
    const searchTextBar = this.getSearchBar();
    if (searchTextBar) {
      searchTextBar.value = '';
      this.showClearIcon = false;
    }
    this.searchText = '';
    this.searchIgnoreIndex = 0;
    this.hideSearchSuggestions();
    if (clearEverything) {
      this.showSearchWhere = false;
      this.searchWhere = SearchWhere.Global;
    }
  }

  private setSearchResources(cmd: string): void {
    switch (cmd) {
      case 'FULLTEXT_PROFILE':
        this.searchPlaceHolder = this.localizer.getTranslation('GLOBAL_SEARCHSCOPE.PLACE_HOLDER.PROFILE');
        break;
      case 'FULLTEXT_CONTENT_PROFILE':
        this.searchPlaceHolder = this.localizer.getTranslation('GLOBAL_SEARCHSCOPE.PLACE_HOLDER.PROFILE_AND_CONTENT');
        break;
      case 'FULLTEXT_EASY_SEARCH':
        this.searchPlaceHolder = this.localizer.getTranslation('GLOBAL_SEARCHSCOPE.PLACE_HOLDER.CONTENT');
        break;
      default:
        this.searchPlaceHolder = this.localizer.getTranslation('GLOBAL_SEARCHSCOPE.PLACE_HOLDER.PROFILE');
        break;
    }
  }

  public setSearchScope(cmd?: string): void {
    const url: string = this.router.url;
    if (cmd !== 'home' && url !== Util.RestAPI.getHomeURL()) {
      const decodedUrl: string = Util.RestAPI.decodeChildRouteURL(url);
      const desc: any = Util.RestAPI.getDescFromURL(decodedUrl);
      if ((Util.isFolderWithId(desc) && !Util.isExternalLib(desc.lib)) || Util.isFlexfolderLevelNode(desc) || decodedUrl.indexOf(Util.kSearchFolderQuery)>0) {
        this.showSearchWhere = true;
        const searchWhereStr: string = Util.RestAPI.getPreference('edx_search_where');
        if (Util.isInteger(searchWhereStr)) {
          this.searchWhere = parseInt(searchWhereStr);
        } else {
          this.searchWhere = SearchWhere.ContainerPlusSubs;
        }
        if (Util.isFolderWithId(desc)) {
         this.clearSearch(false);
        }
      } else {
        this.showSearchWhere = false;
        this.searchWhere = SearchWhere.Global;
      }
    } else {
      this.showSearchWhere = false;
      this.searchWhere = SearchWhere.Global;
      this.clearSearch(true);
    }
  }

  public canSearchWhere(): boolean {
    return this.showSearchWhere;
  }

  public showMobileSearchHeader(): boolean {
    return !Util.RestAPI.offline();
  }

  // **** CommandHandler implementation

  doCommand(cmd: string, data?: any): boolean {
    let handled = false;
    const tile: Tile = this.getTileByCmd(cmd);
    if (tile) {
      this.tileService.openTile(tile);
      handled = true;
    }
    if (!handled) {
      handled = true;
      if (cmd.startsWith('search_scope_')) {
        switch (cmd) {
        case 'search_scope_global':
          this.searchWhere = SearchWhere.Global;
          break;
        case 'search_scope_foldersubs':
          this.searchWhere = SearchWhere.ContainerPlusSubs;
          break;
        case 'search_scope_folder':
          this.searchWhere = SearchWhere.Container;
          break;
        }
        Util.RestAPI.setPreference('edx_search_where', this.searchWhere.toString());
      } else if (cmd.startsWith('cordovaLoadAppIndex_')) {
        const app: NavItem = Util.RestAPI.getLoginReply().APPLICATIONS[parseInt(cmd.substr(20))];
        this.navService.openItem(app);
      } else {
        switch (cmd) {
          case 'cordovaoOrientationchange':
            Util.Device.deviceDidRotate();
            break;
          case 'home':
            Util.RestAPI.navHome();
            break;
          case 'FULLTEXT_PROFILE':
          case 'FULLTEXT_CONTENT_PROFILE':
          case 'FULLTEXT_EASY_SEARCH':
            break;
          case 'hdr_setstarthere':
            let curDesc: any = null;
            let bIsHome: boolean = this.router.url.indexOf('tcc_') === -1;
            if (!bIsHome) {
              curDesc = Util.RestAPI.getCurDesc();
              if (!!curDesc && !Util.isContainer(curDesc.type)) {
                bIsHome = true;
                curDesc = null;
              } else {
                if (!!Util.RestAPI.getCurListComponent()) {
                  curDesc = Util.RestAPI.getCurListComponent().desc;
                }
              }
              if (!!curDesc) {
                if (!Util.isContainer(curDesc.type) || !(curDesc.name || curDesc.DOCNAME)) {
                  curDesc = null;
                } else {
                  if (!curDesc.name || curDesc.name === 'undefined') {
                    curDesc.name = curDesc.DOCNAME;
                  }
                }
              }
            }
            const name: string = curDesc ? (curDesc.name || curDesc.DOCNAME) : this.localizer.getTranslation('HEADER.HOME');
            const locDesc: any = !!curDesc ? {name, id:curDesc.id, type:curDesc.type, lib:curDesc.lib } : null;
            const loc: string = !!locDesc ? JSON.stringify(locDesc) : null;
            Util.RestAPI.setPreference('edx_start_location', loc);
            if (!!this.navsidebar) {
              this.navsidebar.startLocChanged(curDesc);
            }
            Util.Notify.success(this.localizer.getTranslation('HEADER.START'), this.localizer.getTranslation('HEADER.START_SUCCESS', [name]));
            break;
          case 'search':
            let childRouteName = 'folders';
            const searchCriteria: any = this.createSearchCriteria();
            if (Object.keys(searchCriteria.criteria).length === 1 && !!this.app.desc &&this.app.desc.type === 'searches') {
              Util.RestAPI.setCurDesc(null);
            }
            if (this.app.desc) {
              childRouteName = this.app.desc.type === 'workspaces' ? 'workspaces' : 'folders';
            }
            setTimeout(() => {
              Util.RestAPI.navToSearchURL(childRouteName, searchCriteria);
            }, Util.kPopupDismissMS); // give time for the dialog go pop on mobile
            break;
          case 'cordovaRight':
            this.rightClicked(null);
            break;
          case 'cordovaLeft':
            this.leftClicked(null);
            break;
          case 'cordovaHamburger':
            this.hamburgerClicked(null);
            break;
          case 'cordovaNavToUrl':
            if (this.cordovaCmdArg) {
              Util.RestAPI.navToURL(this.cordovaCmdArg);
            }
            break;
          case 'showSystemNotification':
            Util.RestAPI.showSystemNotification();
            break;
          case 'reloadTiles':
            Util.RestAPI.reloadTiles();
            break;
          default:
            handled = false;
            break;
        }
      }
    }
    if (!handled) {
      handled = this.app.doCommand(cmd);
    }
    return handled;
  }

  public checkCommand(cmd: string, setChecked: boolean): boolean {
    if (cmd && cmd.startsWith('search_scope_')) {
    } else {
      switch (cmd) {
      case 'FULLTEXT_PROFILE':
      case 'FULLTEXT_CONTENT_PROFILE':
      case 'FULLTEXT_EASY_SEARCH':
        this.searchOption = cmd;
        this.setSearchResources(cmd);
        Util.RestAPI.setSearchScope(cmd);
        break;
      default:
        const tile: Tile = this.getTileByCmd(cmd);
        if (tile && this.tileService) {
          if (setChecked) {
            this.tileService.showTile(tile);
          } else {
            this.tileService.hideTile(tile);
          }
        }
        return (tile !== null);
      }
    }
    return false;
  }

  public commandChecked(cmd: string): boolean {
    let checked = false;
    if (cmd && cmd.startsWith('search_scope_')) {
      switch (cmd) {
      case 'search_scope_global':
        checked = this.searchWhere === SearchWhere.Global;
        break;
      case 'search_scope_foldersubs':
        checked = this.searchWhere === SearchWhere.ContainerPlusSubs;
        break;
      case 'search_scope_folder':
        checked = this.searchWhere === SearchWhere.Container;
        break;
      }
    } else {
      const tile: Tile = this.getTileByCmd(cmd);
      if (tile) {
        checked = (tile.index >= 0);
      }
    }
    return checked;
  }

  public commandEnabled(cmd: string): boolean {
    let enabled = false;
    if (cmd && cmd.startsWith('search_scope_')) {
      switch (cmd) {
      case 'search_scope_global':
        enabled = true;
       break;
      case 'search_scope_foldersubs':
        enabled = this.app.desc.type !== 'flexfolders';
       break;
      case 'search_scope_folder':
        enabled = true;
        break;
      }
    } else {
      switch (cmd) {
        case 'about':
          enabled = true;
          break;
        case 'help':
          enabled = !Util.RestAPI.siteConfigurations.disableUserHelp;
          break;
        case 'shortcuts':
          enabled = true;
          break;
        case 'home':
          enabled = true;
          break;
        case 'preferences':
          enabled = !Util.RestAPI.isGuestUser() && this.navLoc !== Util.RestAPI.getLoginURL();
          break;
        case 'logoff':
        case 'separator':
        case 'FULLTEXT_PROFILE':
        case 'FULLTEXT_CONTENT_PROFILE':
        case 'FULLTEXT_EASY_SEARCH':
        case 'starthere':
          enabled = this.navLoc !== Util.RestAPI.getLoginURL();
          break;
        case 'newdocument':
          enabled = Util.RestAPI.canUserCreateDocument();
          break;
      }
    }
    return enabled;
  }

  public menuOpening(menuItemSetter: MenuItemSetter, id: number): void {
    if (id===MenuId.MENU_SEARCH_SCOPE) {
      const items: MenuItem[] = [];
      let item: MenuItem;
      item = new MenuItem({
        name: this.localizer.getTranslation('GLOBAL_SEARCHSCOPE.THIS_FOLDER'),
        checked: true,
        cmd: 'search_scope_folder'
      });
      items.push(item);
      item = new MenuItem({
        name: this.localizer.getTranslation('GLOBAL_SEARCHSCOPE.THIS_FOLDER_SUBS'),
        checked: true,
        cmd: 'search_scope_foldersubs'
      });
      items.push(item);
      item = new MenuItem({
        name: this.localizer.getTranslation('GLOBAL_SEARCHSCOPE.PROFILE_AND_CONTENT_SHORT'),
        checked: true,
        cmd: 'search_scope_global'
      });
      items.push(item);
      menuItemSetter.setMenuItems(items);
    }
  }

  private getTileByCmd(cmd: string): Tile {
    let theTile: Tile = null;
    const parts = cmd.split('/');
    const id: string = parts.length>1? parts[1]:'';
    const type: string = parts[0];
    if (!!this.tiles) {
      for (const tile of this.tiles) {
        if (tile.id === id && tile.type === type) {
          theTile = tile;
          break;
        }
      }
    }
    return theTile;
  }

  private doCordavaCommand(): void {
    if (this.cordovaCmd) {
      this.doCommand(this.cordovaCmd);
    }
    this.cordovaCmd = null;
    this.cordovaCmdArg = null;
  }

  private setupCordovaHeader(): void {
    if (Util.Device.bIsCordova) {
      const curHdrCmd: HeaderCommand = this.getCurHdrCommand();
      const addBack: boolean = this.hdrCommands.length>1 || this.navLoc!==Util.RestAPI.getHomeURL();
      const buttons: any[] = [];
      if (addBack) {
        if (curHdrCmd.leftText) {
          buttons.push({button: this.awHeaderBar.ButtonName.LeftOne, text: curHdrCmd.leftText, function: 'text'});
        } else {
          buttons.push({button: this.awHeaderBar.ButtonName.LeftTwo, image: this.awHeaderBar.ButtonImage.Hamburger, function: 'custom'});
          buttons.push({button: this.awHeaderBar.ButtonName.LeftOne, image: this.awHeaderBar.ButtonImage.Back, function: 'custom'});
        }
        if (curHdrCmd.rightText) {
          buttons.push({button: this.awHeaderBar.ButtonName.RightOne, text: curHdrCmd.rightText, function: 'text'});
        } else if (this.getActionMenuID()>=0) {
          buttons.push({button: this.awHeaderBar.ButtonName.RightOne, image: this.awHeaderBar.ButtonImage.Dots, function: 'custom'});
        } else {
          buttons.push({button: this.awHeaderBar.ButtonName.RightOne, text: '', function: 'text'});
        }
      } else {
        buttons.push({button: this.awHeaderBar.ButtonName.LeftTwo, image: this.awHeaderBar.ButtonImage.Hamburger, function: 'default'});
        buttons.push({button: this.awHeaderBar.ButtonName.LeftOne, image: this.awHeaderBar.ButtonImage.Hamburger, function: 'default'});
      }
      this.awHeaderBar.setHeader({title: this.getTitle()/*, backButtonVisible: addBack*/});
      this.awHeaderBar.setHeaderButtons(button => {
        if (button === this.awHeaderBar.ButtonName.RightOne) {
          this.postCordovaCommand('cordovaRight');
        } else if (button === this.awHeaderBar.ButtonName.LeftOne) {
          this.postCordovaCommand('cordovaLeft');
        } else if (button === this.awHeaderBar.ButtonName.LeftTwo) {
          this.awMenu.showMenu(true);
        }
      }, buttons);
    }
  }

  private setupCordovaMenu(offline: boolean): void {
    if (Util.Device.bIsCordova) {
      const apps: any[] = Util.RestAPI.getLoginReply() ? Util.RestAPI.getLoginReply().APPLICATIONS : null;
      const nApps: number = apps ? apps.length : 0;
      const awMenuItems: any[] = [];
      const menuDef: MenuDef = this.menuService.getMenu(MenuId.MENU_MOBILE_HAMBURGER);

      for (const item of menuDef.items) {
        if (!item.separator) {
          let okToAdd: boolean;
          switch (item.cmd) {
          case 'logoff': okToAdd = false;  break;
          case 'home': // fall thru intentional
          case 'about': okToAdd = true; break;
          default: okToAdd = !offline; break;
          }
          if (okToAdd) {
            awMenuItems.push({title: this.localizer.getTranslation(item.name), action: item.cmd});
          }
        }
      }
      if (!offline && nApps) {
        for (let i=0; i<nApps; i++) {
          const app: any = apps[i];
          awMenuItems.push({title: app.name, action: 'cordovaLoadAppIndex_'+i});
        }
      }
      this.awMenu.push(awMenuItems);
      this.awMenu.didTapMenuItem(cmd => {
        this.postCordovaCommand(cmd);
      });
    }
  }

  public postCordovaCommand(cmd: string, args?: any): void {
    this.cordovaCmdArg = args;
    if (this.cordovaCmdButton) {
      setTimeout(() => {
        const event: Event = new MouseEvent('click', { view: window, bubbles: true, cancelable: true });
        this.cordovaCmd = cmd;
        this.cordovaCmdButton.nativeElement.dispatchEvent(event);
      },1 );
    } else {
      this.doCommand(cmd);
    }
  }

  private doElectronCommand(cmd: string): void {
    this.doCommand(cmd);
    setTimeout(() => {
      this.postCordovaCommand(null);
    }, 250);
  }

  private createSearchCriteria(): any {
    const desc = this.app.desc;
    const listComponent: ListBaseComponent = Util.RestAPI.getCurListComponent();
    const set: any = !!listComponent ? listComponent.getSet() : null;
    const description: string = this.searchText;
    const searchCriteria: any = {};
    const criteria: any = {};
    this.searchOption = this.searchOption || 'FULLTEXT_CONTENT_PROFILE';
    if (this.searchOption!=='FULLTEXT_CONTENT_PROFILE' && this.searchOption!=='FULLTEXT_PROFILE' && this.searchOption!=='FULLTEXT_EASY_SEARCH') {
      this.searchText = Util.Transforms.parseMultiValueSearchText(this.searchText);
    }
    if (!!this.searchText) {
      if (this.searchOption==='FULLTEXT_EASY_SEARCH' || !Util.isDocIDs(this.searchText)) {
        criteria[this.searchOption] = this.searchText;
      } else {
        criteria['DOCNUM'] = this.searchText;
      }
      searchCriteria['DESCRIPTION'] = description;
    } else {
      // Adding criteria even when it's empty so that Searched criteria is maintained for doing empty saved search.
      criteria[this.searchOption] = '';
    }
    const searchForms: any[] = Util.RestAPI.getSearchForms();
    if (searchForms && searchForms.length) {
      searchCriteria['FORM_NAME'] = searchForms[0]['%FORM_NAME'];
    }
    if (this.searchWhere!==SearchWhere.Global && !!desc) {
      if (Util.isFolderWithId(desc)) {
        criteria['%FOLDER_ID'] = desc.lib + ',' + desc.id;
        searchCriteria['$edx_extra_query'] = Util.kSearchFolderQuery;
      } else if (Util.isFlexfolderLevelNode(desc)) {
        if (!!set && !!set.flexinfo && !!set.flexinfo.levels && set.flexinfo.levels.length) {
          for (const level of set.flexinfo.levels) {
            criteria[level['LEVEL_NAME']] = level.id;
            searchCriteria['$edx_extra_query'] = Util.kSearchFolderQuery;
          }
        }
      } else {
        const childUrl: string = Util.RestAPI.decodeChildRouteURL(this.navLoc);
        if (childUrl.indexOf(Util.kSearchFolderQuery)>0) {
          const childDesc: any = Util.RestAPI.getDescFromURL(childUrl);
          const curSearch: any = this.dataService.getSearchData(childDesc);
          if (!!curSearch && !!curSearch.criteria) {
            if (!!curSearch.criteria['%FOLDER_ID']) {
              criteria['%FOLDER_ID'] = curSearch.criteria['%FOLDER_ID'];
            } else {
              // flexfolder in this flexfolder
              const keys: string[] = Object.keys(curSearch.criteria).filter(k=> Util.RestAPI.kFullTextTypes.indexOf(k)===-1);
              for (const key of keys) {
                criteria[key] = curSearch.criteria[key];
              }
            }
            searchCriteria['$edx_extra_query'] = Util.kSearchFolderQuery;
          }
        }
      }
      if (!!criteria['%FOLDER_ID'] && this.searchWhere === SearchWhere.Container) {
        criteria['%SEARCH_LEVEL'] = 'CURRENT';
      }
    }
    searchCriteria['criteria'] = criteria;
    return searchCriteria;
  }

  public searchbarAnimationComplete(): void {
    if (this.searchbarHiding) {
      this.showSearchBar = false;
    } else if (this.searchbarShowing) {
      this.showSearchBar = true;
    }
    this.searchbarShowing = false;
    this.searchbarHiding = false;
  }

  public getDefaultTiles(): Tile[] {
    return this.tileService.getDefaultTiles();
  }

  public getTiles(): Tile[] {
    return this.tiles;
  }

  public setTiles(tiles: Tile[]): void {
    this.tiles = TileService.ReorderTiles(tiles);
    this.tiles = this.tileService.tilesChanged();
  }

  public resetTiles(): void {
    this.tileService.reset();
    this.tiles = [];
    this.tileService.getTiles();
  }

  public tilesChanged(): void {
    if (this.navsidebar) {
      this.navsidebar.reset();
    }
  }

  public isPermaTile(tile: Tile): boolean {
    return this.tileService.isPermaTile(tile);
  }

  public changePrimaryLibrary(lib?: string): void {
    if (this.navsidebar) {
      this.navsidebar.changePrimaryLibrary(lib);
    }
  }

  private setSearchTextBarFocus(): void {
    if (Util.Device.bIsOfficeAddin || !Util.Device.isMobile()) {
      const searchTextBar = this.getSearchBar();
      if (searchTextBar) {
         searchTextBar.focus();
      }
    }
  }

  public curUrlChanged(url: string): void {
    if (this.getBreadcrumbs()) {
      this.getBreadcrumbs().curUrlChanged(url);
    }
  }

  public curWindowChangedTitle(title: string, oldTitle: string): void {
    if (this.getBreadcrumbs()) {
      this.getBreadcrumbs().curWindowChangedTitle(title, oldTitle);
    }
  }

  public setTitle(title: string): void {
    this.getCurHdrCommand().title = title;
    if (Util.Device.bIsCordova) {
      this.setupCordovaHeader();
    }
  }

  public setLeftText(leftText: string): void {
    const baseCmd: HeaderCommand = this.hdrCommands && this.hdrCommands.length ? this.hdrCommands[0] : null;
    if (baseCmd) {
      baseCmd.leftText = !!leftText ? this.localizer.getTranslation(leftText) : null;
      if (Util.Device.bIsCordova) {
        this.setupCordovaHeader();
      }
    }
  }

  public setRightText(rightText: string, cmd: string): void {
    const baseCmd: HeaderCommand = this.hdrCommands && this.hdrCommands.length ? this.hdrCommands[0] : null;
    if (baseCmd) {
      baseCmd.rightText = !!rightText ? this.localizer.getTranslation(rightText) : null;
      baseCmd.cmd = cmd;
      if (Util.Device.bIsCordova) {
        this.setupCordovaHeader();
      }
    }
  }

  public setRightIcon(rightIcon: string, cmd: string): void {
    const baseCmd: HeaderCommand = this.hdrCommands && this.hdrCommands.length ? this.hdrCommands[0] : null;
    if (baseCmd) {
      baseCmd.rightText = null;
      baseCmd.actionMenuIcon = rightIcon;
      baseCmd.forceActionIcon = true;
      baseCmd.cmd = cmd;
    }
  }

  public setActionMenu(commandHandler: CommandHandler, id: number, title: string, icon: string): void {
    const baseCmd: HeaderCommand = this.hdrCommands && this.hdrCommands.length ? this.hdrCommands[0] : null;
    if (baseCmd) {
      if (this.enableRightActionIcon()) {
        baseCmd.actionMenuID = id;
      } else {
        baseCmd.actionMenuID = MenuId.MENU_MOBILE_EMPTY;
      }
      baseCmd.actionMenuTitle = title;
      baseCmd.actionMenuIcon = icon;
      baseCmd.handler = commandHandler ? commandHandler : this;
    }
    this.setupCordovaHeader();
  }

  public pushCommandHandler(commandHandler: CommandHandler, command: string, leftText: string, title: string, rightText: string, actionMenuID: number, actionMenuTitle: string, actionMenuIcon: string): void {
    title = title || (this.hdrCommands.length>0 ? this.hdrCommands[this.hdrCommands.length-1].title : this.appTitle());
    this.hdrCommands.push(new HeaderCommand(commandHandler,command,leftText,title,rightText,actionMenuID,actionMenuTitle,actionMenuIcon));
    if (this.hdrCommands.length>1) {
      window.location.hash = window.location.hash+'#ch_'+this.hdrCommands.length;
      this.setupCordovaHeader();
    }
  }

  public popCommandHandler(): void {
    if (this.hdrCommands.length>1) {
      const curCommand: HeaderCommand = this.hdrCommands.pop();
      if (curCommand && curCommand.handler) {
        curCommand.handler.doCommand('back');
      }
    }
  }

  public nullTopCommandHandler(): void {
    if (this.hdrCommands.length>1) {
      this.hdrCommands[this.hdrCommands.length-1].handler = null;
    }
  }

  public maskHeader(mask: boolean): void {
    this.maskHeaderCount += mask ? 1 : -1;
    if (this.awHeaderBar) {
      // work around stupid race condition in Cordova/Appworks where we set the header to be off - on - off and it remains on
      ++this.settingAWHeaderMask;
      setTimeout(() => {
        if (--this.settingAWHeaderMask===0) {
          this.awHeaderBar.maskHeader(this.maskHeaderCount!==0);
        }
      }, 300);
    }
  }

  public isHeaderMasked(): boolean {
    return this.maskHeaderCount !== 0;
  }

  public disableNav(disable: boolean): void {
    this.navDisabledCount += disable ? 1 : -1;
    if (!!this.appBreadcrumbs && !Util.Device.isMobile()) {
      if (this.navDisabledCount) {
        this.appBreadcrumbs.hide();
      } else {
        this.appBreadcrumbs.show();
      }
    }
  }

  public isNavDisabled(): boolean {
    return this.navDisabledCount !== 0;
  }

  public reconnected(): void {
    this.setupCordovaMenu(false);
    this.tileService.getTiles().then((tiles) => {
      this.tiles = tiles;
    });
    if (this.navsidebar) {
      this.navsidebar.reconnected();
    }
  }

  public offlineStateChange(offline: boolean): void {
    this.setupCordovaMenu(offline);
  }

}

export interface HeaderTarget {
  setListData?(data: any): void;
}
