import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';


import { WindowModalComponent } from './window-modal.component';
import { Util, OrientationListener } from '../utils/utils.module';
import { BaseDesc } from '../models/base';

@Component({
  selector: 'edx-window-modal-splitview',
  styleUrls: [ 'window-modal.component.scss', 'window-modal-splitview.component.scss' ],
  template: `
    <edx-window-view-folders class="left" [ngClass]="{portrait:portrait}" [desc]="listDesc" [name]="listName" [docView]="docView"></edx-window-view-folders>
    <edx-window-modal-metadata #docView class="right" [ngClass]="{portrait:portrait}" [splitView]="!portrait"></edx-window-modal-metadata>
  `
})
export class WindowModalSplitViewComponent extends WindowModalComponent implements OnInit, OnDestroy, OrientationListener {
  public portrait: boolean = Util.Device.isPortrait();
  public listDesc: BaseDesc;
  public listName: string;

  constructor(protected location: Location, protected router: Router, protected route: ActivatedRoute) {
    super(location,router,route);
    this.listDesc = Util.RestAPI.getCurDesc();
    this.listName = this.listDesc ? (this.listDesc as any).name : null;
    Util.Device.registerOrientationListener(this);
  }

  ngOnInit() {
    //do not call super
    //super.ngOnInit();
  }

  ngOnDestroy() {
    Util.Device.deregisterOrientationListener(this);
  }

  public deviceDidRotate(isPortrait: boolean): void {  // work around iOS/Android cordova crap
    this.portrait = isPortrait;
  }
}
