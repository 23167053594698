/* eslint-disable quote-props */
import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { WindowModalComponent } from './window-modal.component';
import { Util } from '../utils/utils.module';
import { AppComponent } from '../app.component';
import { TabItem, TabReceiver } from '../models/tab-item';
import { ListItem } from '../models/list-item';
import { CommandHandler } from '../models/command-handler';
import { SelectItem } from '../models/form-field';
import { LocalizeService } from '../services/localize.service';
import { SettingsService } from '../services/settings.service';
import { FormWrapperComponent } from '../forms/form-wrapper.component';
import { TabSelectorComponent } from '../widgets/tab-selector.component';
import { SelectComponent } from '../widgets/select.component';
import { FilelistReceiver } from '../widgets/filelist-sidebar.component';
import { ActionBarComponent } from '../widgets/action-bar.component';
import { ListTableParent, ListTableComponent } from '../lists/list-table.component';
import { KeyCommand, ShortcutsService } from '../services/shortcuts.service';
import { MenuId } from '../services/menu.service';
import { FilterSidebarComponent } from '../forms/filter-sidebar.component';
import { ListData } from '../models/base';

@Component({
  selector: 'edx-window-modal-admin',
  styleUrls: [ 'window-modal.component.scss', 'window-modal-metadata.component.scss', 'window-modal-admin.component.scss' ],
  templateUrl: 'window-modal-admin.component.html'
})
export class WindowModalAdminComponent extends WindowModalComponent implements OnInit, OnDestroy, TabReceiver, CommandHandler, FilelistReceiver, ListTableParent {
  @ViewChild('formWrapper') formWrapper: FormWrapperComponent;
  @ViewChild('tabSelector') tabSelector: TabSelectorComponent;
  @ViewChild('lookupTable') lookupTable: ListTableComponent;
  @ViewChild('actionbar') actionBar: ActionBarComponent;
  @ViewChild(FilterSidebarComponent) filters: FilterSidebarComponent;
  public tabId = 'lookuptables';
  public showSpinner = true;
  private appComponent: AppComponent = Util.RestAPI.getAppComponent();
  private primaryLib: string = Util.RestAPI.getPrimaryLibrary();
  private urlLoaded = false;
  private showSave = false;
  private currentLookupTableIndex = 0;
  private lookupTables: ListItem[] = [];
  private lookupTableSelections: ListItem[] = [];
  private listItems: ListItem[] = [];
  private librarySelectItems: SelectItem[];
  private lookupSelItems: SelectItem[];
  private currentLookup: string;
  private forLibStr: string;
  private forPrimaryStr: string;
  private lookupDesc: any;
  private lookupForm: string;
  private lookupFormType: any;
  private lookupKey: any;
  private lookupLeadingColums = [0];
  private actionId = MenuId.MENU_LOOKUP_ACTIONS;
  private shortcutsSubscription: Subscription;
  private showFilter = false;
  private filter = '*';
  private tabList: TabItem[] = [
    { title: 'ADMIN.VALIDATION_TABLES', id: 'lookuptables' }
  ];

  constructor(protected location: Location, protected router: Router, protected route: ActivatedRoute, private localizer: LocalizeService, private settingsService: SettingsService, shortcutsService: ShortcutsService) {
    super(location,router,route);
    this.forLibStr = this.localizer.getTranslation('FORMS.LOCAL.PREFERENCES.FOR_LIB_SHORT');
    this.shortcutsSubscription = shortcutsService.commands.subscribe(c => this.handleCommand(c));
  }

  handleCommand(command: KeyCommand) {
    switch (command.name) {
      case 'lookup-maintenance':
        this.tabSelector.selectTabById('lookuptables');
        break;
    }
  }

  ngOnInit() {
    const libs: any[] = Util.RestAPI.getLibraries();
    this.showSpinner = false;
    this.librarySelectItems = [];
    this.lookupSelItems = [];
    if (!!libs && !!libs.length) {
      for (const lib of libs) {
        if (lib.DISABLED === 'N') {
          const libNameUC: string = lib.LIBRARY_NAME.toUpperCase();
          this.librarySelectItems.push({ value: libNameUC, display: lib.LIBRARY_NAME, disabled: !this.userHasAccessToRemoteLibraryAdminMaintenance(lib) });
        }
      }
    }
    if (this.urlLoaded) {
      this.libraryChanged(null);
    }
    this.forPrimaryStr = this.localizer.getTranslation('FORMS.LOCAL.PREFERENCES.FOR_LIB', [Util.RestAPI.getPrimaryLibrary()]);
    if (!Util.RestAPI.isGuestUser()) {
      super.ngOnInit();
    } else {
      Util.Notify.error(this.localizer.getTranslation('RAPI_ERRORS.13'));
      this.appComponent.navBack();
    }
  }

  ngOnDestroy() {
    if (this.shortcutsSubscription) {
      this.shortcutsSubscription.unsubscribe();
    }
  }

  private okEnabled(event: any): void {
  }

  private userHasAccessToRemoteLibraryAdminMaintenance(library: any): boolean {
    return library.LIBRARY_NAME === Util.RestAPI.getPrimaryLibrary() || (!!library.EFFECTIVE_RIGHTS && library.EFFECTIVE_RIGHTS['EDIT_VTS'] === 'Y');
  }

  private libraryChanged(selectComponent: SelectComponent): void {
    let queryArgs = '';
    if (!!selectComponent) {
      this.desc.lib = selectComponent.value;
      queryArgs = '?library=' + selectComponent.value;
    }
    Util.RestAPI.get('lookups/modifiabletables', null, queryArgs).toPromise().then(data => {
      if (!!data) {
        this.lookupTables = data.map((table) => new ListItem({ id: table.formName, type: 'lookups', lib: this.desc?.lib, DOCNAME: table.formTitle, DOCNUM: table.formId }));
        this.currentLookupTableIndex = 0;
        this.lookupSelItems = this.lookupTables.map(aii => ({ value: aii.id, display: aii.DOCNAME }) as SelectItem);
        this.loadList();
      }
    }, err => { });
  }

  private loadList(): void {
    if (this.tabId === 'lookuptables') {
      this.loadLookupsList();
    }
  }

  private loadLookupsList(): void {
    this.currentLookup = this.lookupSelItems.length > 0 ? this.lookupSelItems[this.currentLookupTableIndex].value : undefined;
    this.lookupDesc = this.lookupTables[this.currentLookupTableIndex];
    this.lookupForm = this.lookupTables[this.currentLookupTableIndex]?.id;
    this.lookupKey = this.currentLookup + '_ID';
    this.lookupFormType = 'profile';
    const lib = this.desc.lib;
    this.desc = { id: '', type: '', lib: '' };
    setTimeout(() => {
      this.setDesc(lib);
      if (!!this.lookupTable) {
        this.lookupTableSelections = [];
        this.handleFilterChange();
        this.selectionsUpdated(this.lookupTable);
      }
    }, 10);
  }

  private handleFilterChange(filter = '*') {
    if (!!this.lookupTable) {
      this.filter = filter;
      this.lookupTable.handleFilterChange(filter, this.lookupKey);
      if (!!this.filters) {
        this.filters.enableOK(false, true);
        setTimeout(() => {
          if (!filter && !!this.filters) {
            this.filters.getAndSetFormData();
          }
        }, 500);
      }
      this.lookupTableSelections = [];
      if (!!this.actionBar) {
        this.actionBar.updateActions();
      }
    }
  }

  private setDesc(lib: string): void {
    this.desc = ({id:'admin',type:'admin',lib,imgPath:'assets/images/console_settings_grey.svg'} as any);
  }

  public getDesc(): any {
    return this.desc;
  }

  protected loadURL(url: string): void {
    // block the base class from loading the url
    this.appComponent.registerWindow(this, url);
    this.setDesc(Util.RestAPI.getPrimaryLibrary().toUpperCase());
    if (!this.showSpinner) {
      this.libraryChanged(null);
    }
    this.urlLoaded = true;
  }

  public initialList(table: ListTableComponent): ListItem[] {
    return this.listItems;
  }

  public initialSet(table: ListTableComponent): any {
    return { total: this.listItems.length };
  }

  public checkboxClick(table: ListTableComponent, item: ListItem, property: string): void {
  }

  public tabSelected(id: string): void {
    this.tabId = id;
    this.loadList();
  }

  public tabEnabled(id: string): boolean {
    switch (id) {
      case 'lookuptables':
        return Util.RestAPI.canUserAccessAdminMaintenance();
    }
  }

  public fileSelected(index: number): void {
    // load a file from the list into the content panel
    if (index >= 0 && index < this.lookupTables.length) {
      this.currentLookupTableIndex = index;
      this.loadList();
    }
  }

  public cancelHdrBtnClick(): void {
  }

  public rightHdrBtnClick(): void {
  }

  public doCommand(cmd: string): boolean {
    const selectedLookupItem = Util.RestAPI.deepCopy(this.lookupDesc);
    selectedLookupItem['FORM_NAME'] = selectedLookupItem.id;
    switch (cmd) {
      case 'lookup_copy':
      case 'lookup_edit':
        selectedLookupItem['DOCNUM'] = this.lookupTableSelections[0]['%PRIMARY_KEY'];
        this.appComponent.doCommand('profile_' + cmd, { desc: selectedLookupItem });
        break;
      case 'lookup_add':
        this.appComponent.doCommand('profile_' + cmd, { desc: selectedLookupItem });
        break;
      case 'lookup_filter':
        this.showFilter = !this.showFilter;
        if (!this.showFilter) {
          this.handleFilterChange();
        }
        if (!!this.filters) {
          this.filters.ngOnInit();
          this.filters.isOpen = this.showFilter;
        }
        break;
    }
    return false;
  }

  public selectionsUpdated(table: ListTableComponent): void {
    if (!!this.lookupTable) {
      this.lookupTableSelections = this.lookupTable.getSelections();
    }
    if (!!this.actionBar) {
      this.actionBar.updateActions();
    }
  }

  public refresh(cmd?: string, value?: any): void {
    this.handleFilterChange(this.showFilter ? this.filter : '*');
  }

  public commandEnabled(cmd: string): boolean {
    switch (cmd) {
      case 'lookup_add':
      case 'lookup_filter':
        return true;
      case 'lookup_separator':
      case 'lookup_copy':
      case 'lookup_edit':
        return !!this.lookupTableSelections && this.lookupTableSelections.length === 1;
    }
    return false;
  }
  public getListData(): ListData {
    const list = !!this.lookupTable && !!this.lookupTable.getList() ? this.lookupTable.getList() : [];
    const set = !!this.initialSet ? this.initialSet : {};
    if (!set['search']) {
      set['search'] = {};
    }
    set['search']['FORM_NAME'] = this.currentLookup;
    return { set, list };
  }
}
