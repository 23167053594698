import { BaseDesc } from './base';

export interface NavItemDesc extends BaseDesc {
  type: string;
  id: string;
  lib: string;
  name?: string;
  imgPath?: string;
}

export class NavItem implements BaseDesc {
  type: string;
  id: string;
  lib: string;
  name: string;
  imgPath: string;

  constructor(desc: NavItemDesc) {
    this.lib = desc.lib || '';
    this.type = desc.type || '';
    this.id = desc.id || '';
    this.name = desc.name || '';
    this.imgPath = desc.imgPath || null;
  }
}
