import { Injectable } from '@angular/core';

import { Tile } from '../models/tile';
import { BaseDesc } from '../models/base';
import { Util } from '../utils/utils.module';

const tilesStr = `
  [
    {"index":0,"size":1,"name":"hero","lib":"","type":"","id":""},
    {"index":1,"size":1,"name":"TILE_NAMES.FAVORITES","lib":"","type":"workspaces","id":"favorites"},
    {"index":2,"size":1,"name":"TILE_NAMES.RECENTLY_EDITED","lib":"","type":"folders","id":"recentedits"},
    {"index":3,"size":1,"name":"TILE_NAMES.QUICK_SEARCH","lib":"","type":"searches","id":""},
    {"index":4,"size":1,"name":"TILE_NAMES.FLEXFOLDERS","lib":"","type":"flexfolders","id":""},
    {"index":5,"size":1,"name":"TILE_NAMES.WORKSPACES","lib":"","type":"workspaces","id":""},
    {"index":6,"size":1,"name":"TILE_NAMES.PUBLIC_FOLDERS","lib":"","type":"folders","id":"public"},
    {"index":-1,"size":1,"name":"TILE_NAMES.ACTIVITIES_TRACKERS","lib":"","type":"activities","id":""},
    {"index":-1,"size":1,"name":"TILE_NAMES.FILEPLAN","lib":"","type":"fileplans","id":""},
    {"index":-1,"size":1,"name":"TILE_NAMES.RM_REQUESTS","lib":"","type":"requests","id":""},
    {"index":-1,"size":1,"name":"TILE_NAMES.TEMPLATES","lib":"","type":"folders","id":"templates"},
    {"index":-1,"size":1,"name":"TILE_NAMES.ALL_FOLDERS","lib":"","type":"folders","id":"all"},
    {"index":-1,"size":1,"name":"TILE_NAMES.CHECKED_OUT","lib":"","type":"folders","id":"checkedout"},
    {"index":-1,"size":1,"name":"TILE_NAMES.DELETED","lib":"","type":"folders","id":"deleted"}
  ]
`;

export const PermaTiles: BaseDesc[] = [
  { lib:'', type:'', id:''},
  { lib:'', type:'workspaces', id:'favorites'},
  { lib:'', type:'folders', id:'recentedits'},
  { lib:'', type:'searches', id:''},
  { lib:'', type:'flexfolders', id:''},
  { lib:'', type:'workspaces', id:''},
  { lib:'', type:'folders', id:'checkedout'},
  { lib:'', type:'folders', id:'public'},
  { lib:'', type:'folders', id:'templates'},
  { lib:'', type:'folders', id:'all'},
  { lib:'', type:'folders', id:'deleted'},
  { lib:'', type:'fileplans', id:''},
  { lib:'', type:'requests', id:''},
  { lib:'', type:'activities', id:''}
];

@Injectable()
export class TileService {
  private gettingTiles = false;
  private tiles: Tile[] = null;

  static RemoveDuplicates(tiles: Tile[]): Tile[] {
    const noDupTiles: Tile[] = [];
    for (const aTile of tiles) {
      const existingIndex = noDupTiles.findIndex(t => t.lib===aTile.lib && t.id===aTile.id && t.type===aTile.type);
      if (existingIndex === -1) {
        noDupTiles.push(aTile);
      }
    }
    return noDupTiles;
  }

  static ReorderTiles(tiles: Tile[]): Tile[] {
//    tiles = TileService.RemoveDuplicates(tiles);
    tiles = tiles.sort((a, b) => {
      if (a.index === b.index) {
        if (a.name==='hero') {
          return -1;
        } else if (b.name==='hero') {
          return 1;
        }
      }
      return a.index - b.index;
    });
    let idx = 0;
    for (const tile of tiles) {
      if (tile.index >= 0) {
        tile.index = idx++;
      }
    }
    return tiles;
  }

  private reorderTiles(): void {
    this.tiles = TileService.ReorderTiles(this.tiles);
  }

  public isPermaTile(tile: Tile): boolean {
    if (!!tile) {
      for (const perma of PermaTiles) {
        if (tile.id === perma.id && tile.type === perma.type) {
          return true;
        }
      }
    }
    return false;
  }

  public tilesChanged(): Tile[] {
    const savedTiles: Tile[] = [];
    this.reorderTiles();
    for (const tile of this.tiles) {
      if (tile.type !== 'folders' || (tile.id !== 'downloads' && tile.id !== 'imports')) {
        savedTiles.push(tile);
      }
    }
    Util.RestAPI.tilesChanged(savedTiles);
    if (Util.Device.bIsCordova || (!Util.Device.bIsOfficeAddin && Util.Device.isMobile())) {
      if (!this.tiles.find(t => t.type === 'folders' && t.id==='downloads')) {
        this.tiles.push(new Tile({index:-1, type:'folders', id:'downloads', lib:Util.RestAPI.getPrimaryLibrary(), name:'TILE_NAMES.DOWNLOADS'}));
      }
      if (!this.tiles.find(t => t.type === 'folders' && t.id==='imports')) {
        this.tiles.push(new Tile({index:-1, type:'folders', id:'imports', lib:Util.RestAPI.getPrimaryLibrary(), name:'TILE_NAMES.IMPORTS'}));
      }
    }
    return this.tiles;
  }

  public appendTile(tile: Tile): Tile[] {
    const newTileIndex = Util.RestAPI.getNewTileIndex(this.tiles.find((t) => t.name === 'hero'), this.tiles.find((t) => t.id === 'recentedits'));
    tile.index = newTileIndex !== -1 ? newTileIndex : this.tiles.length + 1;
    if (this.tiles.indexOf(tile) === -1) {
      return this.insertTile(tile);
    } else {
      this.tilesChanged();
      return this.tiles;
    }
  }

  public insertTile(tile: Tile): Tile[] {
    this.tiles.push(tile);
    this.tilesChanged();
    return this.tiles;
  }

  public hideTile(tile: Tile): Tile[] {
    if (this.isPermaTile(tile)) {
      tile.index = -1;
    } else {
      const index: number = this.tiles.indexOf(tile);
      this.tiles.splice(index, 1);
    }
    this.tilesChanged();
    return this.tiles;
  }

  public showTile(tile: Tile) {
    tile.index = this.tiles.length + 1;
    this.tilesChanged();
  }

  public openTile(tile: Tile): void {
    if (tile && tile.name !== 'hero') {
      let queryString: string = 'name=' + Util.RestAPI.encodeChildRouteName(tile.name) + '&max=' + Util.RestAPI.getDefualtMaxItems();
      if (tile.imgPath) {
        queryString += '&imgPath=' + Util.RestAPI.encodeChildRouteName(tile.imgPath);
      }
      if (tile.type === 'fileplans' && !!tile.id && tile.id.indexOf('FP-FilePart') !== -1) {
        Util.RestAPI.getFormData(tile, 'profile', 'PD_SEARCH').toPromise().then(result => {
          tile['PD_ACTIVE_STATUS'] = result['PD_ACTIVE_STATUS'];
          this.navigateToTile(tile, queryString);
        });
      } else {
        this.navigateToTile(tile, queryString);
      }
    }
  }

  public navigateToTile(tile: Tile, queryString: string): void {
    const url: string = Util.RestAPI.makeChildRouteURL('home', 'tcc_outlet', tile.type, tile, null, queryString);
    Util.RestAPI.setCurDesc(tile);
    Util.RestAPI.navToURL(url);
  }

  public reset(): void {
    this.tiles = null;
  }

  public getTiles(): Promise<Tile[]>  {
    if (this.tiles && this.tiles.length) {
      return Promise.resolve(this.tiles);
    }
    if (this.gettingTiles) {
      return new Promise<Tile[]>((resolve, reject) => {
        const waitFunc = () => {
          if (this.gettingTiles) {
            setTimeout(waitFunc,100);
          } else {
            resolve(this.tiles);
          }
        };
        setTimeout(waitFunc,100);
      });
    }
    const downloadsIndex = localStorage.getItem('tile__downloads__folders__' + Util.RestAPI.getPrimaryLibrary());
    const importsIndex = localStorage.getItem('tile__imports__folders__' + Util.RestAPI.getPrimaryLibrary());
    if (Util.Device.bIsCordova && Util.RestAPI.offline()) {
      this.tiles = [];
      this.tiles.push(new Tile({index: downloadsIndex === '0' ? -1 : this.tiles.length, type:'folders', id:'downloads', lib:Util.RestAPI.getPrimaryLibrary(), name:'TILE_NAMES.DOWNLOADS'}));
      this.tiles.push(new Tile({index: importsIndex === '0' ? -1 : this.tiles.length, type:'folders', id:'imports', lib:Util.RestAPI.getPrimaryLibrary(), name:'TILE_NAMES.IMPORTS'}));
      return Promise.resolve(this.tiles);
    }
    let bAddDownloadTile: boolean = Util.Device.bIsCordova;
    this.gettingTiles = true;
    const getEm = (): Promise<Tile[]> => {
      let queryargs = '';
      const loginReply: any = Util.RestAPI.getLoginReply();
      if (loginReply['RM_ENABLED']) {
        queryargs = 'getFilePlans';
      }
      if (!!loginReply['GUEST_USER']) {
        this.gettingTiles = false;
        this.tiles = loginReply['DEFAULT_TILES'];
        return loginReply['DEFAULT_TILES'];
      }
      return Util.RestAPI.get('/settings/tiles','',queryargs).toPromise().then((tiles: Tile[]) => {
        this.gettingTiles = false;
        this.tiles = TileService.RemoveDuplicates(tiles || []);
        const favTileIndex = this.tiles.findIndex(t => Util.Transforms.isFavoriteFolder(t.id));
        if (Util.RestAPI.IsFavoritesEnabled()) {
          if (favTileIndex === -1 && Util.RestAPI.restAPIVersion() >= 0x00230200) {
            const heroTileIndex = this.tiles.findIndex(t => t.name === 'hero');
            const favTile = { index: heroTileIndex+1, size:1, imgPath: '', tooltip: 'TILE_NAMES.FAVORITES', lib:'', type:'workspaces', id:'favorites', name: 'TILE_NAMES.FAVORITES'};
            this.tiles.splice(heroTileIndex+1, 0, favTile);
          }
        } else if (favTileIndex >= 0) {
          this.tiles.splice(favTileIndex, 1);
        }
        this.tiles.forEach(t => {
          if (!t.tooltip) {
           t.tooltip = t.name;
          }
        });
        if (loginReply['EFFECTIVE_RIGHTS']) {
          if (loginReply['EFFECTIVE_RIGHTS']['ENABLE_WORKSPACE'] === 'N') {
            // Workspace node is disabled, surprisingly you can disable it for fusionadmin also!
            this.removeWorkspaceTile(this.tiles);
          } else if (!(this.tiles.find(x=>x['type'] === 'workspaces'))) {// Admin just reneabled WORKSPACES from the server, we need to add this to the end, if user disabls the tiles, we will still get the tiles in the tiles list.
            this.tiles.push(new Tile({index:this.tiles.length -1 ,size:1,name:'TILE_NAMES.WORKSPACES',lib:'',type:'workspaces',id:''}));
          }
        }
        if (!loginReply['RM_ENABLED'] || Util.RestAPI.restAPIVersion() < 0x00160500) {
          this.tiles = this.tiles.filter(t => t.type !== 'fileplans' && t.type !== 'requests');
        }
        if (!loginReply['FLEX_RIGHTS'] || Object.keys(loginReply['FLEX_RIGHTS']).length===0) {
          this.tiles = this.tiles.filter(t => t.type !== 'flexfolders');
        }
        const heroTile: Tile = this.tiles.find((t) => t.name==='hero');
        if (heroTile) {
          heroTile.size = 1;
        }
        const dragHoleTile: Tile = this.tiles.find((t) => t.name==='edx-drag-hole');
        if (dragHoleTile) {
          dragHoleTile.name = dragHoleTile.name.toUpperCase();
        }
        if (bAddDownloadTile) {
          if (!this.tiles.find(t => t.type === 'folders' && t.id==='downloads')) {
            this.tiles.push(new Tile({index: downloadsIndex === '0' ? -1 : this.tiles.length, type:'folders', id:'downloads', lib:Util.RestAPI.getPrimaryLibrary(), name:'TILE_NAMES.DOWNLOADS'}));
          }
          if (!this.tiles.find(t => t.type === 'folders' && t.id==='imports')) {
            this.tiles.push(new Tile({index: importsIndex === '0' ? -1 : this.tiles.length, type:'folders', id:'imports', lib:Util.RestAPI.getPrimaryLibrary(), name:'TILE_NAMES.IMPORTS'}));
          }
        }
        return this.tiles;
      }, error => {
        this.gettingTiles = false;
        this.tiles = [];
        if (Util.Device.bIsCordova) {
          this.tiles.push(new Tile({index: downloadsIndex === '0' ? -1 : this.tiles.length, type:'folders', id:'downloads', lib:Util.RestAPI.getPrimaryLibrary(), name:'TILE_NAMES.DOWNLOADS'}));
          this.tiles.push(new Tile({index: importsIndex === '0' ? -1 : this.tiles.length, type:'folders', id:'imports', lib:Util.RestAPI.getPrimaryLibrary(), name:'TILE_NAMES.IMPORTS'}));
        }
        return this.tiles;
      });
    };
    return new Promise<Tile[]>(resolve => {
      const waitForLoginReply = () => {
        if (Util.RestAPI.offline() || (!!Util.RestAPI.getLoginReply() && Object.keys(Util.RestAPI.getLoginReply()).length>0)) {
          if (!Util.Device.bIsCordova && !Util.Device.bIsOfficeAddin && Util.Device.isMobile()) {
            Util.RestAPI.probePFTA().then(version => {
              if (version >= 0x00160702) {
                bAddDownloadTile = true;
              }
              resolve(getEm());
            });
          } else {
            resolve(getEm());
          }
        } else {
          setTimeout(waitForLoginReply, 100);
        }
      };
      waitForLoginReply();
    });
  }

  private removeTile(tiles: Tile[], index: number) {
    if (index !== -1) {
      tiles.splice(index, 1);
    }
  }

  private removeWorkspaceTile(tiles: Tile[]) {
    const index = tiles.findIndex(tile => tile.type === 'workspaces' && !Util.Transforms.isFavoriteFolder(tile.id));
    this.removeTile(tiles,index);
  }

  public getTile(desc: any): Tile {
    let tile: Tile = null;
    if (this.tiles && this.tiles.length) {
      tile = this.tiles.find(t => (t.id === desc.id && t.type === desc.type));
    }
    return tile;
  }

  public getDefaultTiles(): Tile[]  {
    return JSON.parse(tilesStr);
  }
}
