import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ListItemPreviewComponent } from './list-item-preview.component';
import { ListItemTileComponent } from './list-item-tile.component';
import { ListTileComponent } from './list-tile.component';
import { ListBaseComponent } from './list-base.component';
import { ListTableComponent } from './list-table.component';
import { ListSummaryComponent } from './list-summary.component';
import { ListFolderPickerComponent } from './list-folder-picker.component';
import { ListUserGroupPickerComponent } from './list-user-groups-picker.component';
import { ListMobileComponent } from './list-mobile.component';
import { ListService } from '../services/list.service';
import { DirectivesModule } from '../directives/directives.module';
import { WidgetsModule } from '../widgets/widgets.module';
import { ListSecurityComponent } from './list-security.component';

@NgModule({
  imports: [
    CommonModule,
    DirectivesModule,
    WidgetsModule
  ],
  providers: [
    ListService
  ],
  declarations: [
    ListBaseComponent,
    ListTileComponent,
    ListItemTileComponent,
    ListItemPreviewComponent,
    ListTableComponent,
    ListSummaryComponent,
    ListFolderPickerComponent,
    ListMobileComponent,
    ListSecurityComponent,
    ListUserGroupPickerComponent,
  ],
  exports: [
    ListBaseComponent,
    ListTileComponent,
    ListTableComponent,
    ListSummaryComponent,
    ListFolderPickerComponent,
    ListMobileComponent,
    ListSecurityComponent,
    ListUserGroupPickerComponent
  ]
})
export class ListsModule { }
