import { Input, Component, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { AbstractValueAccessor, MakeProvider } from './abstract-value-accessor';
import { FormField, SelectItem } from '../models/form-field';
import { DynamicFormFieldComponent } from './dynamic-form-field.component';
import { SegmentComponent } from '../widgets/segment.component';

@Component({
  selector: 'edx-segment-field',
  providers: [MakeProvider(SegmentComponent)],
  styleUrls: ['dynamic-form-field.component.scss'],
  template: `
  <div [formGroup]="form" class="controlcontainer indialog" [ngClass]="{columnview: columnView}">
    <edx-segment [value]="value" [items]="items" [required]="field.isRequired ? true : null" [id]="field.name" [disabled]="viewReadonly" (change)="segChanged($event)"></edx-segment>
  </div>
`
})
export class SegmentFieldComponent extends AbstractValueAccessor implements OnChanges {
  @Input() items: SelectItem[] = [];
  @Input() field: FormField;
  @Input() form: FormGroup;
  @Input() parent: DynamicFormFieldComponent;
  @Input() initialPlaceHolder?: string;
  @Input() formReadonly?: boolean = false;
  @Input() columnView?: boolean = false;
  public viewReadonly = false;

  ngOnChanges() {
    if (!this.value && this.field.defaultValue) {
      this.value = this.field.defaultValue;
    }
    this.viewReadonly = this.field.isReadonly || this.formReadonly;
  }

  segChanged(segmentComponent: SegmentComponent): void {
    this.value = segmentComponent.value;
    this.parent.updateControlValue(this.value, true);
    this.parent.fieldChanged();
  }
}
