import { Input, Component, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { AbstractValueAccessor, MakeProvider } from './abstract-value-accessor';
import { FormField, SelectItem } from '../models/form-field';
import { DynamicFormFieldComponent } from './dynamic-form-field.component';
import { SelectComponent } from '../widgets/select.component';
import { SegmentComponent } from '../widgets/segment.component';

@Component({
  selector: 'edx-select-field',
  providers: [MakeProvider(SelectFieldComponent)],
  styleUrls: ['dynamic-form-field.component.scss'],
  template: `
  <div [formGroup]="form" class="indialog" [ngClass]="{columnview: columnView, controlcontainer: !useSeg, fullwidth: fullWidth}">
    <edx-select *ngIf="!useSeg" [value]="value" [items]="items" [ariaLabel]="!!ariaLabel?ariaLabel:field.name" [isMultiSelect]="field.multiSelect" [multiSelectSep]="field.multiSelectSep" [required]="field.isRequired ? true : null" [id]="field.name" [disabled]="disabled" [initialPlaceHolder]="initialPlaceHolder" [justButton]="field.justComboButton" [inDialog]="inDialog" [inHeader]="inHeader" [inNotify]="inNotify" (change)="changed($event)" [tabIndex]="tabIndex"></edx-select>
    <edx-segment *ngIf="useSeg" [value]="value" [items]="items" [required]="field.isRequired ? true : null" [id]="field.name" [disabled]="disabled" (change)="changed($event)" [tabIndex]="tabIndex"></edx-segment>
  </div>
`
})
export class SelectFieldComponent extends AbstractValueAccessor implements OnChanges {
  @Input() items: SelectItem[] = [];
  @Input() field: FormField;
  @Input() form: FormGroup;
  @Input() parent: DynamicFormFieldComponent;
  @Input() initialPlaceHolder?: string;
  @Input() formReadonly?: boolean = false;
  @Input() columnView?: boolean = false;
  @Input() fullWidth?: boolean = false;
  @Input() inDialog?: boolean = false;
  @Input() inNotify?: boolean = false;
  @Input() inHeader?: boolean = false;
  @Input() rerender = 0;
  @Input() tabIndex = 0;
  @Input() ariaLabel?: string = '';
  public useSeg = false;
  private disabled = false;

  ngOnChanges() {
    if (!this.value && this.field.defaultValue) {
      this.value = this.field.defaultValue;
    }
    this.disabled = this.field.isReadonly || !this.field.isEnabled || this.formReadonly;
    this.useSeg = this.field.comboSegment;
  }

  public changed(component: SelectComponent | SegmentComponent): void {
    this.value = component.value;
    this.parent.updateControlValue(this.value, true);
    this.parent.fieldChanged();
  }
}
