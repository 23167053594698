import { Component, Input } from '@angular/core';

@Component({
  selector: 'edx-progress',
  styleUrls: ['progress.component.scss'],
  template: `
  <div class="container">
    <div class="outertrack">
      <div class="meter" [ngClass]="{infinite:indeterminate()}" [style.width]="width()"></div>
    </div>
    <div class="percent">{{percent()}}</div>
  </div>
  `
})
export class ProgressComponent {
  @Input() max?: number = 1.0;
  @Input() value = 0.0;

  public percent(): string {
    if (this.indeterminate()) {
      return '';
    }
    return Math.ceil((this.value*100.0)/this.max).toString() + '%';
  }

  public indeterminate(): boolean {
    return (this.value<0);
  }

  public width(): string {
    return this.indeterminate() ? '100%' : this.percent();
  }
}
