import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DraggableDirective } from './draggable.directive';
import { DragTargetDirective, FileDropTargetDirective } from './drag-target.directive';
import { DraggableFileDirective } from './draggable-file.directive';
import { ValidateOnBlurDirective } from './validate-onblur.directive';
import { LongPressGestureDirective, SwipeGestureDirective, PinchGestureDirective, DoubleTapGestureDirective } from './gesture.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    DraggableDirective,
    DragTargetDirective,
    FileDropTargetDirective,
    DraggableFileDirective,
    ValidateOnBlurDirective,
    LongPressGestureDirective,
    SwipeGestureDirective,
    PinchGestureDirective,
    DoubleTapGestureDirective
  ],
  exports: [
    DraggableDirective,
    DragTargetDirective,
    FileDropTargetDirective,
    DraggableFileDirective,
    ValidateOnBlurDirective,
    LongPressGestureDirective,
    SwipeGestureDirective,
    PinchGestureDirective,
    DoubleTapGestureDirective
  ]
})

export class DirectivesModule { }
