import { _RestAPI } from './../utils/restful';
import { Directive, HostListener, ElementRef, Input, Renderer2 } from '@angular/core';

import { Util } from '../utils/utils.module';
import { LocalizeService } from '../services/localize.service';

@Directive({
  selector: '[edx-drag-target]'
})
export class DragTargetDirective {
  private _dragEnterCount = 0;
  private _dragEffect = 'none';
  protected listener: any;
  @Input('edx-drag-target')
  set edxDragTarget(listener: any) {
    this.setListener(listener);
  }

  constructor(protected _elem: ElementRef, protected _renderer: Renderer2, protected localizer: LocalizeService) { }

  @HostListener('dragenter', ['$event'])
  onDragEnter(event) {
    let rc = false;
    if (this._dragEnterCount++===0) {
      if (this.listener) {
        this._dragEffect = 'copy';
        if (this.listener.acceptDrag && !this.listener.acceptDrag(event, this._elem)) {
          this._dragEffect = 'none';
          rc = true;
        }
      }
      if (event.dataTransfer) {
        event.dataTransfer.dropEffect = this._dragEffect;
      }
    }
    return false; // Call preventDefault() on the event
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event) {
    if (this.listener && this.listener.dragOver) {
      this._dragEffect = this.listener.dragOver(event, this._elem) ? 'copy' : 'none';
    }
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = this._dragEffect;
    }
    return false; // Call preventDefault() on the event
  }

  @HostListener('drop', ['$event'])
  onDrop(event) {
    this._dragEnterCount = 0;
    if (this.listener && this.listener.drop) {
      this.listener.drop(event, this._elem);
    }
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event) {
    if (--this._dragEnterCount===0) {
      if (this.listener && this.listener.dragLeave) {
        this.listener.dragLeave(event, this._elem);
      }
    }
  }

  protected setListener(listener: any): void {
    this.listener = listener;
  }
}

@Directive({
  selector: '[edx-file-drop-target]'
})
export class FileDropTargetDirective extends DragTargetDirective {
  private realListener: any;
  @Input('edx-file-drop-target')
  set edxFileDropTarget(listener: any) {
    this.setListener(listener);
  }

  protected setListener(listener: any): void {
    this.realListener = listener;
    this.listener = this;
  }

  // **** Drag Target
  acceptDrag(event: any, element: ElementRef): boolean {
    let rc = false;
    const items = event.dataTransfer && event.dataTransfer.items ? event.dataTransfer && event.dataTransfer.items : null;
    if (Util.dragHasFiles(event.dataTransfer)) {
      if (this.realListener && this.realListener.setDragOver) {
        this.realListener.setDragOver(true);
        rc = true;
      } else if (Util.RestAPI.acceptFileDrag()) {
        Util.RestAPI.showDropBanner('TILE_NAMES.RECENTLY_EDITED');
        rc = true;
      }
    } else if (Util.dragHasJSON(event.dataTransfer)) {
      rc = true;
    }
    return rc;
  }
  dragLeave(event: any, element: ElementRef): void {
    if (this.realListener && this.realListener.setDragOver) {
      this.realListener.setDragOver(false);
    }
    Util.RestAPI.showDropBanner(null);
  }
  drop(event: any, element: ElementRef): void {
    if (this.realListener && (!this.realListener.dropOK || this.realListener.dropOK())) {
      Util.getDropFiles(event.dataTransfer).then((items: any[]) => {
        const fileList = Util.RestAPI.removeZeroSizeFiles(items);
        const count = fileList ? fileList.length : 0;
        if (count || Util.Device.bIsOfficeAddin) {
          const files: File[] = (fileList && fileList.length && typeof fileList[0] !== 'string') ? fileList : null;
          const filePaths: string[] = (fileList && fileList.length && typeof fileList[0] === 'string') ? fileList : null;
          Util.RestAPI.uploadFilesWithUI(files, filePaths);
        }
      });
      if (this.realListener && this.realListener.setDragOver) {
        this.realListener.setDragOver(false);
      }
      Util.RestAPI.showDropBanner(null);
    }
  }
}
