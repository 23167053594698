<div style="display:none;">
  <form class="edx-upload-form">
    <input type="file" #uploadFilePicker multiple name="datafile" (change)="fileInputChanged()" (click)="fileInputClick()"/>
  </form>
</div>

<edx-popup #formDialog *ngIf="dialogShown" [desc]="desc" [callback]="this" [kind]="dialogKind" [extrasShown]="dialogExtrasShown"
  [desc2]="desc2" [kind2]="kind2" [width]="dialogWidth" [title]="dialogTitle" [ok]="dialogOK" [extras]="dialogExtras" [extrasAlt]="dialogExtrasAlt"
  [thirdtitle]="dialogThirdBtn" [selections]="list" [createType]="dialogCreateType" [okDisabled]="!!(formWrapper.okDisabled || (rightWrapper?rightWrapper.okDisabled:false))" [disabled]="pickerShown"
  [fileList]="fileList" [filePaths]="filePaths" [dataFiles]="dataFiles" [trustees]="dialogTrustees" [fileFormInfo]="dialogFileFormInfo" [headerformOnRight]="hasRightHeader()" [hideOK]="isFormReadonly()">
  <div>
    <edx-form-wrapper #formWrapper [formDialog]="formDialog"
                      [desc]="formDialog.desc"
                      [inDialog]="true"
                      [kind]="formDialog.kind"
                      [selections]="formDialog.selections"
                      [createType]="formDialog.createType"
                      [applyAllChecked]="dialogApplyAllChecked"
                      (applyAllChanged)="onApplyAllChanged($event)"
                      (extrasShownByChild)="extrasShownByChild($event)"
                      [fileList]="formDialog.fileList"
                      [filePaths]="formDialog.filePaths"
                      [dataFiles]="formDialog.dataFiles"
                      [formData]="dialogFormData"
                      [trustees]="formDialog.trustees"
                      [fileFormInfo]="formDialog.fileFormInfo"
                      [ngClass]="{left: desc2}"
                      [rightWrapper]="rightWrapper"
                      [headerWrapper]="headerWrapper"
                      [readOnly]="isFormReadonly()"
                      (formDataChanged)="dialogFormDataChanged($event)"
                      [tabIndex]="ui>=2?1:0">
    </edx-form-wrapper>
    <edx-form-wrapper #rightWrapper *ngIf="desc2"
                      [desc]="formDialog.desc2"
                      [inDialog]="true"
                      [kind]="formDialog.kind2"
                      [selections]="formDialog.selections"
                      [readOnly]="true"
                      [layout]="kind2!=='profile_copy'?'column':null"
                      class="right"
                      [headerWrapper]="headerWrapper">
    </edx-form-wrapper>
  </div>
  <edx-form-wrapper #headerWrapper rightheader *ngIf="hasRightHeader() && !isPhoneLook()" [formData]="dialogFormData" [desc]="formDialog.desc" [createType]="formDialog.createType" [inDialog]="true" [kind]="'__local_permissions_selector'" [layout]="'header'" [mainWrapper]="getMainWrapper()"></edx-form-wrapper>
  <edx-form-wrapper #headerWrapper phoneheader *ngIf="hasRightHeader() && isPhoneLook()" [tabIndex]="1" [formData]="dialogFormData" [desc]="formDialog.desc" [createType]="formDialog.createType" [inDialog]="true" [kind]="'__local_permissions_selector'" [layout]="'header'" [mainWrapper]="getMainWrapper()"></edx-form-wrapper>
</edx-popup>

<edx-popup #pickerPopup *ngIf="pickerShown" [callback]="this" [kind]="pickerKind" [width]="pickerWidth" [desc]="pickerDesc" [title]="pickerTitle" [ok]="pickerOK" [okDisabled]="pickerOKDisabled()" [headerform]="pickerHasFilter" [headerformOnRight]="hasRightHeader()" [levelDropdownOnRight] = "hasLevelDropdown()">
  <edx-search-filter *ngIf="pickerHasFilter && pickerDesc.type=='lookups'" #filter secondheader [desc]="pickerPopup.desc" [searchValue]="lookupInitalSearch" [list]="picker" [selValue]="pickerSelLevel" [tabIndex]="ui>=2?1:0"></edx-search-filter>
  <edx-list-mobile #picker *ngIf="ui>=2 && ((pickerDesc.type=='folders' && pickerDesc.id=='downloads') || (pickerDesc.type=='lookups' && pickerDesc.id!='_GROUPS_ENABLED'))" [desc]="pickerDesc" [lookupForm]="pickerForm" [lookupKey]="pickerKey" [leadingColums]="pickerLeadingColums" [viewKind]="(pickerDesc.type=='folders' && pickerDesc.id=='downloads')?2:0" [hasFootprint]="true" [parent]="this"></edx-list-mobile>
  <edx-list-table #picker *ngIf="ui<2 && pickerDesc.type=='lookups' && pickerDesc.id!='_GROUPS_ENABLED'" [desc]="pickerDesc" [lookupForm]="pickerForm" [lookupKey]="pickerKey" [leadingColums]="pickerLeadingColums" [parent]="this"></edx-list-table>
  <edx-user-groups-picker #picker *ngIf="pickerDesc.type=='lookups' && pickerDesc.id=='_GROUPS_ENABLED'" [desc]="pickerPopup.desc" [filterForm]="filter" [disableList]="pickerDisableList" [tabIndex]="ui>=2?2:0"></edx-user-groups-picker>
  <edx-search-filter *ngIf="pickerHasFilter && pickerDesc.type!='lookups'" secondheader [desc]="pickerPopup.desc" [searchValue]="lookupInitalSearch" [list]="picker" [globalSearch]="true"></edx-search-filter>
  <edx-list-folder-picker #picker *ngIf="pickerDesc.type!='lookups' && !(pickerDesc.type=='folders' && pickerDesc.id=='downloads')" [kind]="pickerPopup.kind" [desc]="pickerPopup.desc" [disableList]="pickerDisableList" [rights]="pickerRights" id="edx_list_picker" (dismissPicker)="popupOK()"></edx-list-folder-picker>
  <edx-form-wrapper rightheader *ngIf="hasRightHeader() && !isPhoneLook()" [desc]="pickerPopup.desc" [inDialog]="true" [kind]="'__local_locations_chooser'" [layout]="'header'"></edx-form-wrapper>
  <edx-form-wrapper phoneheader *ngIf="hasRightHeader() && isPhoneLook()" [desc]="pickerPopup.desc" [inDialog]="true" [kind]="'__local_locations_chooser'" [layout]="'header'"></edx-form-wrapper>
  <edx-select levelDropdown *ngIf="hasLevelDropdown()" [id]="edx_flexfolder_level" [value]="pickerCurFlexfoldersLevel?.display" [items]="pickerFlexfoldersLevels" class="spaced edx-select" [ngClass]="{right:ui>=2}" [justButton]="ui>=2" (change)="flexfolderLevelChanged($event)"></edx-select>
</edx-popup>

<edx-popup #pccFormDialog *ngIf="pickerCreateContainerShown" [desc]="pccDesc" [callback]="this" [kind]="pccDialogKind" [extrasShown]="pccDialogExtrasShown" [title]="pccDialogTitle" [ok]="pccDialogOK" [extras]="pccDialogExtras" [extrasAlt]="pccDialogExtrasAlt" [createType]="pccDialogCreateType" [okDisabled]="!!pccFormWrapper.okDisabled" [trustees]="pccDialogTrustees" [fileFormInfo]="pccDialogFileFormInfo" [headerformOnRight]="true">
  <div>
    <edx-form-wrapper #pccFormWrapper [formDialog]="pccFormDialog" [desc]="pccFormDialog.desc" [kind]="'profile'" [createType]="pccFormDialog.createType" [trustees]="pccFormDialog.trustees" [fileFormInfo]="pccFormDialog.fileFormInfo" [inDialog]="true" [hideChoosers]="true" [formData]="pccDialogFormData" [headerWrapper]="pccHeaderWrapper" (pccExtrasShownByChild)="pccExtrasShownByChild($event)"></edx-form-wrapper>
  </div>
  <edx-form-wrapper #pccHeaderWrapper rightheader *ngIf="!isPhoneLook()" [formData]="pccDialogFormData" [desc]="pccFormDialog.desc" [createType]="pccFormDialog.createType" [inDialog]="true" [kind]="'__local_permissions_selector'" [layout]="'header'" [mainWrapper]="pccFormWrapper"></edx-form-wrapper>
  <edx-form-wrapper #pccHeaderWrapper phoneheader *ngIf="isPhoneLook()" [formData]="pccDialogFormData" [desc]="pccFormDialog.desc" [createType]="pccFormDialog.createType" [inDialog]="true" [kind]="'__local_permissions_selector'" [layout]="'header'" [mainWrapper]="pccFormWrapper"></edx-form-wrapper>
</edx-popup>

<edx-popup #pickerPopupGroups *ngIf="pickerGroupsCreateContainerShown" [callback]="this" [kind]="'list_2'" [desc]="pickerGroupsDesc" [title]="pickerGroupsTitle" [okDisabled]="pickerGroupsOKDisabled()" [headerform]="true" [headerformOnRight]="hasRightHeader()">
  <edx-search-filter #filterGroups secondheader [desc]="pickerPopupGroups.desc" [list]="pickerGroups" [selValue]="pickerSelLevel"></edx-search-filter>
  <edx-user-groups-picker #pickerGroups [desc]="pickerPopupGroups.desc" [filterForm]="filterGroups"></edx-user-groups-picker>
</edx-popup>
