/**
 * Created by kevin on 2016-10-27.
 *
 * CommandHandler - an interface to enable and execute commands from various controls
 *
 */
import { ElementRef } from '@angular/core';
import { MenuItem } from '../models/menu-item';

export interface MenuItemSetter {
  setMenuItems(menuItems: MenuItem[]): void;
  prependMenuItems(menuItems: MenuItem[]): void;
  getMenuItems(): MenuItem[];
}

export interface CommandHandler {
  doCommand(cmd: string, disable?: boolean): boolean;
  commandEnabled?(cmd: string): boolean;
  checkCommand?(cmd: string, setChecked: boolean): boolean;
  commandChecked?(cmd: string): boolean;
  menuOpening?(menuItemSetter: MenuItemSetter, id: number): void;
  menuClosing?(menuItemSetter: MenuItemSetter, id: number): void;
  textEntered?(event: KeyboardEvent): void;
  textCleared?(): void;
  setTextInputElement?(textEl: ElementRef): void;
}

export const enum SearchWhere { Global = 0, ContainerPlusSubs = 1, Container = 2 }

export class FormResult {
  public success: boolean;
  public data: any;
}
