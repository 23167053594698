import { Injectable } from '@angular/core';

import { Util } from '../utils/utils.module';
import { LocalizeService } from './localize.service';
import { FormResult } from '../models/command-handler';

declare const Word: any;

type tUserXMLRecord = (type: string, data: any) => void;

@Injectable()
export class OOxmlService {

  constructor(private localizer: LocalizeService) {
  }

  public async addFooter(desc: any, dontRunForm?: boolean): Promise<void> {
    if (Util.Device.bIsOfficeAddinWord) {
      return this.addWordFooter(desc, dontRunForm);
    }
    return Promise.resolve();
  }

  public isAlwaysUpdateFooterEnabled(): boolean {
    const footerOptionsStr = Util.RestAPI.getPreference('edx_footer_options');
    const footerOptions = !!footerOptionsStr ? JSON.parse(footerOptionsStr) : {};
    if (footerOptions['auto'] === '1') {
      return true;
    }
    return false;
  }

  public needsDialog(): boolean {
    const footerOptionsStr = Util.RestAPI.getPreference('edx_footer_options');
    const footerOptions = !!footerOptionsStr ? JSON.parse(footerOptionsStr) : {};
    if (footerOptions['auto'] === '1' || footerOptions['dont_show'] !== '0') {
      return false;
    }
    return true;
  }

  private async addWordFooter(desc: any, dontRunForm: boolean): Promise<void> {
    if (Util.Device.bIsOfficeAddinWord) {
      const userXML = await this.getUserFooterXML(desc, dontRunForm);
      await this.addWordFooterType(desc, dontRunForm, 'FirstPage', userXML);
      await this.addWordFooterType(desc, dontRunForm, 'Primary', userXML);
    }
  }

  public async addWordFooterType(desc: any, dontRunForm: boolean, footerType: string, userXML: any): Promise<void> {
    await Word.run(async (context) => {
      const curXML = await this.getWordFooter(context, footerType);
      const bookmark = footerType === 'FirstPage' ? kDMMarkerFirstPage : kDMMarker;
      let insertedFooterRange: any;
      if (!!userXML) {
        const footer = context.document.sections.getFirst().getFooter(footerType);
        if (curXML.indexOf(bookmark) === -1) {
          insertedFooterRange = footer.getRange('Start').insertOoxml(userXML, 'Start');
        } else {
          const range = context.document.getBookmarkRange(bookmark);
          insertedFooterRange = range.insertOoxml(userXML, 'Replace');
        }
        insertedFooterRange.insertBookmark(bookmark);
        await context.sync();
      }
    });
    return Promise.resolve();
  }

  private async getWordFooter(context: any, footerType: string): Promise<string> {
    const footer = context.document.sections.getFirst().getFooter(footerType);
    const footerXML = footer.getOoxml();
    await context.sync();
    return footerXML.value;
  }

  private async getUserFooterXML(desc: any, dontRunForm?: boolean): Promise<string> {
    const footerOptionsStr = Util.RestAPI.getPreference('edx_footer_options');
    const footerOptions = !!footerOptionsStr ? JSON.parse(footerOptionsStr) : {};
    if (footerOptions['auto'] === '1' || dontRunForm) {
      return Promise.resolve(this.createUserFooterXML(footerOptions['xml'], desc));
    } else if (footerOptions['dont_show'] !== '1') {
      return await this.runUserXMLForm(desc);
    }
    return Promise.resolve(null);
  }

  private createUserFooterXML(userDMxml: string, desc: any): string {
    let xml = kPkgOuterStart + kDocumentStart + `<w:bookmarkStart w:id="0" w:name="${kDMMarker}" />`;
    const sections = {};
    const startParagraph = `<w:p>`;
    const endParagraph = `</w:p>`;
    const footerParaStyle = `<w:pPr>
                                <w:tabs>
                                  <w:tab w:val="center" w:pos="4680"/>
                                  <w:tab w:val="right" w:pos="9360"/>
                                </w:tabs>
                                <w:spacing w:after="0" w:line="240" w:lineRule="auto"/>
                              </w:pPr>`;
    const tab = `<w:r><w:tab/></w:r>`;
    try {
      if (!!userDMxml) {
        this.forEachUserPart(userDMxml, (type: string, data: any) => {
          sections[type] = data;
        });
        if (!sections['FONT']) {
          sections['FONT'] = { STYLE: 'Calibri', SIZE: '11', text: '' };
        }
      }
      const keys = Object.keys(sections);
      const nKeys = keys.length;
      if (nKeys === 0) {
        xml += startParagraph + tab + endParagraph;
      } else {
        keys.sort((a: string, b: string) => {
          if (a === 'FONT') {
            return -1;
          } else if (b === 'FONT') {
            return 1;
          } else if (a === 'PAGENUMBERS') {
            return -1;
          } else if (b === 'PAGENUMBERS') {
            return 1;
          } else {
            return 0;
          }
        });
        const alignment = { NewLine: 0, LEFT: 0, Tab: 1, CENTER: 1, RIGHT: 2 };
        const swapToNextField = (keyIndex: number): void => {
          keys[keyIndex] = keys.splice(keyIndex + 1, 1, keys[keyIndex])[0];
        };
        const pgIndex = keys.indexOf('PAGENUMBERS');
        if (pgIndex !== -1) {
          const pgAlignment = alignment[sections[keys[pgIndex]]['ALIGN']];
          const firstFieldAlignment = nKeys > pgIndex + 1 ? alignment[sections[keys[pgIndex + 1]]['ALIGN']] : 0;
          if (nKeys > pgIndex + 1 && firstFieldAlignment <= 1 && firstFieldAlignment < pgAlignment) {
            if (nKeys === pgIndex + 2) {
              swapToNextField(pgIndex);
            } else if ((nKeys >= pgIndex + 3)) {
              if (firstFieldAlignment === 0) {
                swapToNextField(pgIndex);
                const secondFieldAlignment = alignment[sections[keys[pgIndex + 2]]['ALIGN']];
                if (firstFieldAlignment < secondFieldAlignment && secondFieldAlignment < pgAlignment) {
                  swapToNextField(pgIndex + 1);
                }
              } else if (firstFieldAlignment < pgAlignment) {
                swapToNextField(pgIndex);
              }
            }
          }
        }
        const fontData = !!sections['FONT'] && !!sections['FONT']['data'] ? sections['FONT']['data'] : !!sections['FONT'] ? sections['FONT'] : { STYLE: 'Calibri', SIZE: '11' };
        const pageNumberFontData = !!sections['PAGENUMBERS'] ? sections['PAGENUMBERS'] : { FONT: 'Calibri', SIZE: '11' };
        let index = 0;
        let numberOfTabs = 0;
        let tabs = ``;
        xml += startParagraph + footerParaStyle;
        for (const type of keys) {
          const data = sections[type];
          const currentElementAllignment = data['ALIGN'];
          const prevElementallignment = index !== 0 ? sections[keys[index - 1]]['ALIGN'] : '';
          if (index !== 0 && (alignment[currentElementAllignment] === 0 || alignment[currentElementAllignment] <= alignment[prevElementallignment])) {
            xml += endParagraph;
            xml += startParagraph + footerParaStyle;
          }
          numberOfTabs = alignment[currentElementAllignment] === 2 && alignment[prevElementallignment] === 1 ? 1 : alignment[currentElementAllignment];
          tabs = !numberOfTabs ? `` : (numberOfTabs === 1 ? tab : tab + tab);
          if (type === 'DATE') {
            data.text = '';
          }
          if (type === 'PAGENUMBERS') {
            xml += tabs;
            xml += `
              <w:r><w:rPr><w:rFonts w:ascii="${pageNumberFontData.FONT}" w:hAnsi="${pageNumberFontData.FONT}" w:cs="${pageNumberFontData.FONT}" /><w:sz w:val="${parseInt(pageNumberFontData.SIZE) * 2}" /></w:rPr><w:t xml:space="preserve">${data.TEXT1 || ''}</w:t></w:r>
              <w:r><w:rPr><w:rFonts w:ascii="${pageNumberFontData.FONT}" w:hAnsi="${pageNumberFontData.FONT}" w:cs="${pageNumberFontData.FONT}" /><w:sz w:val="${parseInt(pageNumberFontData.SIZE) * 2}" /></w:rPr><w:fldChar w:fldCharType="begin" /></w:r>
              <w:r><w:rPr><w:rFonts w:ascii="${pageNumberFontData.FONT}" w:hAnsi="${pageNumberFontData.FONT}" w:cs="${pageNumberFontData.FONT}" /><w:sz w:val="${parseInt(pageNumberFontData.SIZE) * 2}" /></w:rPr><w:instrText xml:space="preserve"> PAGE \* Arabic \* MERGEFORMAT </w:instrText></w:r>
              <w:r><w:rPr><w:rFonts w:ascii="${pageNumberFontData.FONT}" w:hAnsi="${pageNumberFontData.FONT}" w:cs="${pageNumberFontData.FONT}" /><w:sz w:val="${parseInt(pageNumberFontData.SIZE) * 2}" /></w:rPr><w:fldChar w:fldCharType="separate" /></w:r>
              <w:r><w:rPr><w:rFonts w:ascii="${pageNumberFontData.FONT}" w:hAnsi="${pageNumberFontData.FONT}" w:cs="${pageNumberFontData.FONT}" /><w:sz w:val="${parseInt(pageNumberFontData.SIZE) * 2}" /></w:rPr><w:fldChar w:fldCharType="end" /></w:r>
              <w:r><w:rPr><w:rFonts w:ascii="${pageNumberFontData.FONT}" w:hAnsi="${pageNumberFontData.FONT}" w:cs="${pageNumberFontData.FONT}" /><w:sz w:val="${parseInt(pageNumberFontData.SIZE) * 2}" /></w:rPr><w:t xml:space="preserve">${data.TEXT2 || ''} </w:t></w:r>
              <w:r><w:rPr><w:rFonts w:ascii="${pageNumberFontData.FONT}" w:hAnsi="${pageNumberFontData.FONT}" w:cs="${pageNumberFontData.FONT}" /><w:sz w:val="${parseInt(pageNumberFontData.SIZE) * 2}" /></w:rPr><w:fldChar w:fldCharType="begin" /></w:r>
              <w:r><w:rPr><w:rFonts w:ascii="${pageNumberFontData.FONT}" w:hAnsi="${pageNumberFontData.FONT}" w:cs="${pageNumberFontData.FONT}" /><w:sz w:val="${parseInt(pageNumberFontData.SIZE) * 2}" /></w:rPr><w:instrText xml:space="preserve"> ${!!data.TEXT2 ? 'NUMPAGES \* Arabic \* MERGEFORMAT' : '' } </w:instrText></w:r>
              <w:r><w:rPr><w:rFonts w:ascii="${pageNumberFontData.FONT}" w:hAnsi="${pageNumberFontData.FONT}" w:cs="${pageNumberFontData.FONT}" /><w:sz w:val="${parseInt(pageNumberFontData.SIZE) * 2}" /></w:rPr><w:fldChar w:fldCharType="separate" /></w:r>
              <w:r><w:rPr><w:rFonts w:ascii="${pageNumberFontData.FONT}" w:hAnsi="${pageNumberFontData.FONT}" w:cs="${pageNumberFontData.FONT}" /><w:sz w:val="${parseInt(pageNumberFontData.SIZE) * 2}" /></w:rPr><w:fldChar w:fldCharType="end" /></w:r>`;
          } else {
            xml += tabs;
            xml += `
            <w:r><w:rPr><w:rFonts w:ascii="${fontData.STYLE}" w:hAnsi="${fontData.STYLE}" w:cs="${fontData.STYLE}" /><w:sz w:val="${parseInt(fontData.SIZE) * 2}" /></w:rPr><w:t>${data.text}${this.descDataForType(desc, type)}</w:t></w:r>`;
          }
          index++;
        }
        xml += endParagraph;
        if (nKeys > 1) {
          xml += startParagraph + endParagraph;
        }
      }
      xml += `<w:bookmarkEnd w:id="0" />` + kDocumentEnd;
      return xml + kPkgOuterEnd;
    } catch (e) {
      console.error(e);
    }
    return null;
  }

  private async runUserXMLForm(desc: any): Promise<string> {
    return await Util.RestAPI.runLocalForm(null, 'footer_options', this.localizer.getTranslation('FORMS.LOCAL.FOOTER_OPTIONS.FOOTER_OPTIONS'), null, false).then((result: FormResult) => {
      if (result.success && !!result.data) {
        return this.createUserFooterXML(result.data.xml, desc);
      }
      return null;
    });
  }

  private forEachUserPart(userDMxml: string, cb: tUserXMLRecord): void {
    const parts = userDMxml.split('</');
    let nParts = parts.length;
    if (nParts>1) {
      parts.splice(nParts-1, 1);
      --nParts;
      let i: number;
      for (i=1; i<nParts; i++) {
        const raIndex = parts[i].indexOf('>');
        parts[i] = parts[i].substring(raIndex+1);
      }
      for (i=0; i<nParts; i++) {
        const data = {};
        const term = parts[i];
        const laIndex = term.indexOf('<');
        const raIndex = term.indexOf('>');
        const spIndex = term.indexOf(' ');
        const type = term.substring(laIndex+1, spIndex);
        data['text'] = term.substring(raIndex+1);
        const argStr = term.substring(spIndex+1, raIndex);
        const argStrLen = argStr.length;
        let iArgStr = 0;
        let curArgStr = argStr;
        while (iArgStr < argStrLen) {
          const eqIndex = curArgStr.indexOf('=');
          if (eqIndex<0) {
            return;
          }
          const key = curArgStr.substring(0,eqIndex).trim();
          iArgStr += eqIndex+1;
          curArgStr = curArgStr.substr(eqIndex+1);
          let quoteIndex = curArgStr.indexOf('\'');
          if (quoteIndex<0) {
            return;
          }
          iArgStr += quoteIndex+1;
          curArgStr = curArgStr.substr(quoteIndex+1);
          quoteIndex = curArgStr.indexOf('\'');
          if (quoteIndex<0) {
            return;
          }
          data[key] = curArgStr.substr(0, quoteIndex).replace(/["']/g, '');
          iArgStr += quoteIndex+1;
          if (iArgStr < argStrLen) {
            curArgStr = curArgStr.substr(quoteIndex+1);
          }
        }
        cb(type, data);
      }
    }
  }

  private descDataForType(desc: any, type: string): string {
    switch (type) {
      case 'DATE': return Util.Transforms.formatDate(desc['LAST_EDIT_DATE'] || new Date().toString(), true);
      case 'DOCNUMBER': return desc['DOCNUM'] || desc['id'] || '';
      case 'DOCVERSION': return desc['VERSION_LABEL'] || desc['VERSION_ID'] || desc['vers'] || desc['checkout']?.VERSION_LABEL;
      case 'DOCNAME': return desc['DOCNAME'] || '';
      case 'DOCAUTHOR': return desc['AUTHOR_NAME'] || desc['AUTHOR_ID'] || Util.RestAPI.getUserFullName();
      case 'DOCTYPE': return desc['DOCTYPE'] || desc['TYPE_ID'] || '';
      case 'DOCABSTRACT': return desc['VERSION_COMMENTS'] || '';
      case 'DOCAPPLICATION': return desc['DOCAPPLICATION'] || desc['APP_ID'] || '';
      case 'DOCLIB': return desc['lib'] || Util.RestAPI.getPrimaryLibrary();
      case 'DOCCLIENTID': return desc['CLIENT_ID'] || '';
      case 'DOCCLIENTNAME': return desc['CLIENT_NAME'] || desc['X7316'] || '';
      case 'DOCMATTERID': return desc['MATTER_ID'] || '';
      case 'DOCMATTERNAME': return desc['MATTER_NAME'] || desc['X8809'] || '';
    }
    return '';
  }
}

const kDMMarker = `eDOCS_Footer`;
const kPkgOuterStart = `<pkg:package xmlns:pkg="http://schemas.microsoft.com/office/2006/xmlPackage"><pkg:part pkg:name="/_rels/.rels" pkg:contentType="application/vnd.openxmlformats-package.relationships+xml" pkg:padding="512"><pkg:xmlData><Relationships xmlns="http://schemas.openxmlformats.org/package/2006/relationships"><Relationship Id="rId1" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/officeDocument" Target="word/document.xml"/></Relationships></pkg:xmlData></pkg:part>`;
const kPkgOuterEnd = `</pkg:package>`;
const kDocumentStart = `<pkg:part pkg:name="/word/document.xml" pkg:contentType="application/vnd.openxmlformats-officedocument.wordprocessingml.document.main+xml"><pkg:xmlData><w:document xmlns:w="http://schemas.openxmlformats.org/wordprocessingml/2006/main"><w:body>`;
const kDocumentEnd = `</w:body></w:document></pkg:xmlData></pkg:part>`;
const kDMMarkerFirstPage = `eDOCS_Footer_FirstPage`;

