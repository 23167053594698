import { Component, Input } from '@angular/core';
/**
 * Created by kevin on 2016-11-09.
 *
 * Spinner component - a thing that spins on the screen to distract the user while waiting for the server to grind out
 *      some kind of response to their actions
 *
 */

@Component({
  selector: 'edx-spinner',
  styleUrls: ['spinner.component.scss'],
  template: `
  <div class="container" [ngClass]="{mini:mini}">
    <div class="outertrack">
      <div class="spinner">
        <div class="innertrack"></div>
      </div>
    </div>
  </div>
  `
})
export class SpinnerComponent {
  @Input() mini?: boolean;
}
