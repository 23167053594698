import { OnChanges, Input, Component } from '@angular/core';
import { FormField } from '../models/form-field';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'edx-progress-field',
  styleUrls: ['dynamic-form-field.component.scss'],
  template: `
  <div [formGroup]="form" class="controlcontainer" style="position:relative; top:0.725rem; left:1rem;">
    <edx-progress [value]="valueToShow"></edx-progress>
  </div>
`
})
export class ProgressFieldComponent implements OnChanges {
  @Input() field: FormField;
  @Input() form: FormGroup;
  @Input() value?: number;
  public valueToShow = 0;

  ngOnChanges() {
    if (this.value===undefined) {
      this.valueToShow = parseInt(this.form.controls[this.field.name].value);
    } else {
      this.valueToShow = this.value;
    }
    if (isNaN(this.valueToShow)) {
      this.valueToShow = 0;
    }
  }
}
