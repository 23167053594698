import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { Util } from '../utils/utils.module';
import { FormField } from '../models/form-field';
import { ListItem } from '../models/list-item';
import { DynamicFormComponent } from './dynamic-form.component';
import { LocalizeService } from '../services/localize.service';
import { ListTableComponent, ListTableParent } from '../lists/list-table.component';
import { AbstractValueAccessor } from './abstract-value-accessor';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'edx-form-field-list',
  styleUrls: ['dynamic-form-field.component.scss'],
  template: `
    <ng-template [ngIf]="fieldDataList">
      <div *ngIf="showExtras" class="form-field-list" [ngClass]="{indialog:inDialog}">
        <label *ngIf="field.schemaID==='DELETE_LIST'" class="deletelabel" attr.for="{{field.name}}">{{field.label.replace(':', '')}}</label>
        <edx-list-table #table [schemaId]="field.schemaID" [fieldDataList]="fieldDataList" [hasFootprint]="true" (selectionsList)="setSelections($event)"></edx-list-table>
      </div>
      <span *ngIf="!showExtras" [ngClass]="{indialog:inDialog,columnview:columnView}">
        <edx-list-table *ngIf="showLessList()"  #table [schemaId]="field.schemaID" [fieldDataList]="fieldDataList" [hasFootprint]="true" [showExtras]="showExtras"></edx-list-table>
        <input *ngIf="!showLessList()" type="text" [readonly]="true" [value]="getFileMessage()">
      </span>
    </ng-template>
  `
})

export class FormFieldListComponent extends AbstractValueAccessor implements OnChanges, OnInit {
  @Input() parent: DynamicFormComponent;
  @Input() field: FormField;
  @Input() showExtras: boolean;
  @Input() inDialog: boolean;
  @Input() columnView: boolean;
  @Input() rerender = 0;
  @Input() form: FormGroup;
  @ViewChild('table') private table: ListTableComponent;
  public fieldDataList: ListItem[] = [];

  constructor(private localizer: LocalizeService) {
    super();
  }

  ngOnInit() {
    this.fieldDataList = this.parent.getList(this.field.listKind);
    if (!!this.field.schemaID && this.field.schemaID === 'DELETE_LIST') {
      this.value = this.fieldDataList;
      this.parent.updateControlValue(this.field.name, this.value, true);
    }
  }

  ngOnChanges() {
    this.fieldDataList = this.parent.getList(this.field.listKind);
    if (!!this.parent.data && this.parent.data.isVersionEmail) {
      const curItem: any = Util.RestAPI.getCurItem();
      this.fieldDataList = [curItem];
    }
    setTimeout(() => {
      if (!!this.table) {
        this.table.setOpenedItem(null);
      }
    }, 1);
  }

  private getFileMessage(): string {
    const nItems = this.fieldDataList ? this.fieldDataList.length : 0;
    if (nItems===1 && this.fieldDataList[0].DOCNAME) {
      return this.fieldDataList[0].DOCNAME;
    }
    return this.localizer.getTranslation('PAGINATOR.COUNT',[''+nItems]);
  }

  private showLessList(): boolean {
    if (Util.Device.ui<2) {
      return this.fieldDataList.length===1 || (!!this.parent.formKind && this.parent.formKind === '__local_confirm_delete');
    }
    return false;
  }

  private setSelections(event: any): void {
    this.value = event;
    this.parent.updateControlValue(this.field.name,this.value,true);
    this.parent.fieldChanged(this.field);
  }
}
