import { Injectable } from '@angular/core';
import { BaseDesc } from '../models/base';
import { Util } from '../utils/utils.module';

const kFiltersType = 'filters';
const kSearchDataType = 'searchdata';
const kListStateType = 'liststate';

@Injectable()
export class DataService {
  private storage: any = {};

  private getKey(desc: BaseDesc): string {
    return !!desc ? (desc.lib.split(',')[0] + '__' + desc.type + '__' + desc.id) : undefined;
  }

  public descChanged(oldDesc: BaseDesc, newDesc: BaseDesc): void {
    const oldKey = this.getKey(oldDesc);
    const newKey = this.getKey(newDesc);
    if (!!oldKey && !!newKey && !!this.storage[oldKey]) {
      this.storage[newKey] = this.storage[oldKey];
      this.storage[oldKey] = undefined;
      delete this.storage[oldKey];
    }
  }

  public clearAll(): void {
    this.storage = {};
  }

  public clear(desc: BaseDesc, typeOrNullForALl?: string): boolean {
    const key = this.getKey(desc);
    if (!!key && !!this.storage[key]) {
      if (!!typeOrNullForALl) {
        if (!!this.storage[key] && !!this.storage[key][typeOrNullForALl]) {
          delete this.storage[key][typeOrNullForALl];
          return true;
        }
      } else {
        delete this.storage[key];
        return true;
      }
    }
    return false;
  }

  public set(desc: BaseDesc, type: string, data: any): boolean {
    const key = this.getKey(desc);
    if (!!key) {
      this.storage[key] = this.storage[key] || {};
      this.storage[key][type] = Util.deepCopy(data);
      return true;
    }
    return false;
  }

  public get(desc: BaseDesc, type: string): any {
    let data: any;
    const key = this.getKey(desc);
    if (!!key && !!this.storage[key] && this.storage[key][type]) {
      try {
        data = Util.deepCopy(this.storage[key][type]);
      } catch (e) {
        data = null;
      }
    }
    return data;
  }

  // shortcuts for convienience
  public setFilters(desc: BaseDesc, data: any): boolean {
   return this.set(desc, kFiltersType, data);
  }
  public setSearchData(desc: BaseDesc, data: any): boolean {
   return this.set(desc, kSearchDataType, data);
  }
  public setListState(desc: BaseDesc, data: any): boolean {
    return this.set(desc, kListStateType, data);
  }
  public getFilters(desc: BaseDesc): any {
    return this.get(desc, kFiltersType);
  }
  public getSearchData(desc: BaseDesc): any {
    return this.get(desc, kSearchDataType);
  }
  public getListState(desc: BaseDesc): any {
    return this.get(desc, kListStateType);
  }
  public clearFilters(desc: BaseDesc): any {
    return this.clear(desc, kFiltersType);
  }
  public clearSearchData(desc: BaseDesc): any {
    return this.clear(desc, kSearchDataType);
  }
  public clearListState(desc: BaseDesc): any {
    return this.clear(desc, kListStateType);
  }
}
