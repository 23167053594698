import { Input, Component, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { AbstractValueAccessor, MakeProvider } from './abstract-value-accessor';
import { BaseDesc } from '../models/base';
import { FormField, SelectItem } from '../models/form-field';
import { DynamicFormFieldComponent } from './dynamic-form-field.component';

@Component({
  selector: 'edx-chooser-field',
  providers: [MakeProvider(LocationChooserFieldComponent)],
  styleUrls: ['dynamic-form-field.component.scss'],
  template: `
  <div [formGroup]="form" class="indialog controlcontainer" [ngClass]="{columnview: columnView}">
    <edx-location-chooser (change)="locationChanged($event)" [ariaLabel]="ariaLabel" [id]="'locationchooserfield'" [desc]="getDesc()" [tabindex]="tabIndex"></edx-location-chooser>
  </div>
`
})
export class LocationChooserFieldComponent extends AbstractValueAccessor implements OnChanges {
  @Input() items: SelectItem[] = [];
  @Input() field: FormField;
  @Input() form: FormGroup;
  @Input() parent: DynamicFormFieldComponent;
  @Input() initialPlaceHolder?: string;
  @Input() formReadonly?: boolean = false;
  @Input() columnView?: boolean = false;
  @Input() inDialog?: boolean = false;
  @Input() inHeader?: boolean = false;
  @Input() tabIndex = 0;
  @Input() ariaLabel?: string;
  private disabled = false;

  ngOnChanges() {
    if (!this.value && this.field.defaultValue) {
      this.value = this.field.defaultValue;
    }
    this.disabled = this.field.isReadonly || !this.field.isEnabled || this.formReadonly;
  }

  public locationChanged(desc: BaseDesc): void {
    this.value = !!desc ? JSON.stringify(desc) : null;
    this.parent.updateControlValue(this.value, true);
    this.parent.fieldChanged();
  }

  public getDesc(): BaseDesc {
    let desc: BaseDesc;
    try {
      desc = !!this.value ? JSON.parse(this.value) : null;
    } catch (e) {}
    return desc;
  }
}
