/**
 * Created by kevin on 2016-11-09.
 *
 * Model for a form field
 *
 */
import { FormControl } from '@angular/forms';
import { ValidationUtilities, ValidationParameters } from '../utils/ValidationUtilities';
/*
enum EditFlags
{
    HasTableLookup          = 0x00000002,
    HasPrompt               = 0x00000004,
    AllUpper                = 0x00000008,
    AllLower                = 0x00000010,
    Password                = 0x00000020,
    Required                = 0x00000040,
    MultiLine               = 0x00000080,
    VerticalScroll          = 0x00000100,
    HorizontalScroll        = 0x00000200,
    WordWrap                = 0x00000400,
    AllowTabs               = 0x00000800,
    LongData                = 0x00001000,
    Grayed                  = 0x00002000,
    Locked                  = 0x00004000,
    Transparent             = 0x00008000,
    ReadOnly                = 0x00080000,
    ComboBox                = 0x00100000,
    Keyword                 = 0x00200000,
    NamedObj                = 0x00400000,
    ExclusiveObj            = 0x00800000,
    Hidden                  = 0x01000000,
    Disabled                = 0x02000000,
    Protected               = 0x04000000,
    SaveBits                = 0x08000000,
    Draggable               = 0x10000000,
    Searchable              = 0x20000000,
    ScreenPainter           = 0x40000000
}
*/

export enum FieldFlags {
  FIELD_HASLOOKUP = 0x00000002,   // not used
  FIELD_HASPROMPT = 0x00000004,   // has prompt
  FIELD_UPPERCASE = 0x00000008,   // display in all uppercase
  FIELD_LOWERCASE = 0x00000010,   // display in all uppercase
  FIELD_PASSWORD  = 0x00000020,   // password field
  FIELD_REQUIRED  = 0x00000040,   // field required for validation
  FIELD_MULTILINE = 0x00000080,   // field is multiline ( i.e. a <textarea>)
  FIELD_LOCKED    = 0x00004000,   // field is locked
  FIELD_READONLY  = 0x00080000,   // field is non-editable
  FIELD_COMBO     = 0x00100000,   // field is a 'combo' (i.e. a <select>)
  FIELD_HIDDEN    = 0x01000000,   // field is hidden
  FIELD_DISABLED  = 0x02000000,   // field is disabled (??)
  FIELD_PROTECTED = 0x04000000    // field is disabled a bit more (??)
}

export enum DialogKind { none=0,success=1,info=2,warning=3,progress=4,error=5,confirm=6 }

// here to avoid an angular circular reference by importing Util
export const FormUtils = {
  // eslint-disable-next-line
  isProfileForm(name: string) {
    return !!name && name.startsWith('profile') && name!=='profile_query_datefacets';
  },
  // eslint-disable-next-line
  isSearchForm(name: string) {
    return !!name && (name.startsWith('profile_query') || name === '__local_activitysearch');
  },
  // eslint-disable-next-line
  isProfileDefaultsForm(name: string) {
    return !!name && name.startsWith('profile_editdefs_');
  },
  // eslint-disable-next-line
  isLookupForm(name: string) {
    return !!name && name.startsWith('profile_lookup');
  }
};

export interface RadioFieldInterface {
  ngOnChanges(): void;
}

export interface RadioGroupInterface {
  radioButtonOn(btn: RadioFieldInterface, value: string): void;
  shiftRadioFocus?(event: KeyboardEvent,index: number): void;
}

export interface SelectItem {
  value: any;
  display: string;
  disabled?: boolean;
  separator?: boolean;
  heading?: boolean;
}

export interface Visibility {
  hidden: boolean;            // whether the trigger shows or hides
  fields: string[];           // an array of fields to hide
  values?: string[];          // an optional array of field values that trigger the hide or this field if null
}

export interface FieldScript {
  script: string;             // the name of the script to run
  values?: string[];          // an optional array of field values that trigger the script, or null to always trigger
}

export class Hits {
  parent: string;
  value: any;
  desc: string;
  rank: number;
  text: string;
}

export interface FormFieldDesc {
  name: string;               // field name for form POST
  flags: number;              // sum of FieldFlags (see above)
  fldtype: string;            // see note below
  datatype?: string;          // 1 for string, 4 for number, 8 for date
  maxchars?: number;          // max length for strings
  validation?: string;        // DM server validation pattern
  sqlinfo?: string;           // used for lookups and othere things
  mvinfo?: string;            // used to tell if a lookup can have multiple values
  x?: number;                 // position
  y?: number;
  w?: number;                 // size
  h?: number;
  prompt?: string;            // label
  label?: string;             // secondary label for checkbox
  lookup?: string;            // name of associated lookup
  format?: string;            // list of display formatting options
  selections?: SelectItem[];  // list of options for <select>
  multiselect?: boolean;      // can select more than one item in a select field
  multiselectsep?: string;    // multi select field separator
  buttons?: string;           // list of radio buttons
  value?: string;             // initial value
  checkedtrigger?: string;    // value for checked checkbox
  uncheckedtrigger?: string;  // value for unchecked checkbox
  vistriggers?: Visibility[]; // list of field names to hide when field is true (checkbox, select, radio)
  scripttrigger?: FieldScript;// key into the script form scripts object for code to run on change of field
  fields?: FormFieldDesc[];   // array of child fields in a group box
  isoption?: boolean;         // display field only when displaying form options
  schemaID?: string;          // list schema id
  listKind?: string;          // list kind such as DOCUMENTS or NAMES
  extensionparent?: string;   // this field is an extension of extensionparent
  forceextension?: boolean;   // not matter the layout type show the extension
  justcombobutton?: boolean;  // this is a combo box but just draw the drop down button
  combosegment?: boolean;     // this is a combo box but draw as a segement control
  rgcheckboxes?: boolean;     // like a radio group but rendered as checkboxes
  buttonimg?: string;         // button image path
  errormessage: string;       // error message associated with the field
  _child?: string;            // field ID of the child field in a multi-tier hierarchy
  _parent?: string;           // field ID of the parent field in a multi-tier hierarchy
  _description?: string;      // field ID of the description field
  _describes?: string;        // field ID for which this field is a description field
  _auxiliary?: string[];      // list of non-lookup fields updated by this lookup
  _lookups?: string[];        // list of lookup fields updated by this lookup
}

/***
 *
 * Note on field types:
 *
 *  'form' - the form
 *  'buffer' - not a field, just some kind of holder for complex objects
 *  'edit' - editable text, number or date, depends on datatype
 *  'checkbox' - checkbox
 *  'box' - group box
 *  'text' - static text, used as a section label
 *  'push' - button
 *  'radiogroup' - radio group
 *  'list' - list table field
 *  'path' - path picker
 *  'file' - file input field
 *  'guidetext' - full-width static guide text
 *  'progress' - custom progress field
 *  'link' - html anchor
 */

export class FormField {
  public name: string;
  public datatype: string;
  public fldtype: string;
  public controltype: string;
  public x: number;
  public y: number;
  public w: number;
  public h: number;
  public rows: number;
  public isCombo: boolean;
  public justComboButton: boolean;
  public comboSegment?: boolean;
  public isCheckboxGroup: boolean;
  public isRGCheckboxes: boolean;
  public isRequired: boolean;
  public isEnabled: boolean;
  public isReadonly: boolean;
  public isUppercase: boolean;
  public isVisible: boolean;
  public isExtension: boolean;
  public hasExtension: boolean;
  public forceExtension: boolean;
  public isMultiline: boolean;
  public isOption: boolean;
  public isPassword: boolean;
  public isLocked: boolean;
  public label: string;
  public lookup: string;
  public sqlinfo: string;
  public mvinfo: string;
  public selectionMap: SelectItem[];
  public defaultValue: string;
  public checkedValue: string;
  public uncheckedValue: string;
  public visibilityTriggers: Visibility[];
  public scriptTrigger?: FieldScript;
  public checkLabel: string;
  public maxChars: number;
  public minDate: string;
  public maxDate: string;
  public format: string;
  public formatMap: SelectItem[];
  public fields: FormField[];
  public extensionParent: string;
  public buttonMap: SelectItem[];
  public schemaID: string;
  public listKind: string;
  public statusMessage: string;
  public errormessage: string;
  public formControl: FormControl;    // the field's associated FormControl
  public isTriStateCheckbox: boolean;
  public disabledCheckbox: SelectItem[];
  public buttonImg: string;
  public lookupData: any;
  public lastLookupValue: string;
  public childField: string;
  public parentField: string;
  public descriptionField: string;
  public descriptionForField: string;
  public scheduledForRevalidation: boolean;
  public autoValidated: boolean;
  public retainDefaultValue;
  public rerender: number;
  public lastRerenderIndex: number;
  public auxiliary: string[];
  public lookups: string[];
  public hits: Hits[];
  public multiSelect: boolean;
  public multiSelectSep: string;
  public validationParameters: ValidationParameters;

  constructor(desc: FormFieldDesc, formKind: string) {
    const isSearchForm: boolean = FormUtils.isSearchForm(formKind);
    const isProfileDefaultsForm: boolean = FormUtils.isProfileDefaultsForm(formKind);
    this.name = desc.name;
    this.mvinfo = desc.mvinfo || null;
    this.decodeFlags(desc.flags, isSearchForm, desc.fldtype);
    this.isOption = desc.isoption || false;
    this.x = desc.x || 0;
    this.y = desc.y || 0;
    this.w = desc.w || 0;
    this.h = desc.h || 20;
    this.rows = Math.round(this.h / 20);
    this.fldtype = desc.fldtype;
    this.datatype = desc.datatype || '0';
    this.setControlType(desc, isSearchForm);
    this.label = desc.prompt || null;
    this.lookup = desc.lookup || (this.controltype.indexOf('date')>=0 && isSearchForm ? '$edx_date_range' : null);
    this.sqlinfo = desc.sqlinfo || null;
    this.checkLabel = desc.label || null;
    this.setFormatOptions(desc.format);
    this.setSelectOptions(desc.selections);
    this.setRadioButtons(desc.buttons);
    this.defaultValue = desc.value || null;
    this.checkedValue = this.name === 'SECURITY' ? '1' : desc.checkedtrigger!==undefined ? desc.checkedtrigger.toString() : null;
    this.uncheckedValue = this.name === 'SECURITY' ? '0' : desc.uncheckedtrigger!==undefined ? desc.uncheckedtrigger.toString() : null;
    this.visibilityTriggers = desc.vistriggers || null;
    this.scriptTrigger = desc.scripttrigger || null;
    this.schemaID = desc.schemaID || null;
    this.listKind = desc.listKind || null;
    this.fields = null;
    this.setValidationPattern(desc.validation);
    this.setMaxCharacters(desc.maxchars);
    this.errormessage = desc.errormessage;
    this.statusMessage = null;
    this.formControl = null;
    this.isExtension = false;
    this.extensionParent = desc.extensionparent || null;
    this.forceExtension = desc.forceextension || false;
    if (isSearchForm) {
      if (this.name === 'ITEM_TYPE') {
        this.label = this.label || ' ';
      }
    }
    this.multiSelect = desc.multiselect || (isSearchForm && this.name !== 'SEARCH_IN');
    this.multiSelectSep = desc.multiselectsep || (this.multiSelect ? ',' : null);
    this.isTriStateCheckbox = this.controltype==='checkbox' && ((isSearchForm && !!this.checkedValue) || isProfileDefaultsForm);
    this.disabledCheckbox = [];
    this.justComboButton = desc.justcombobutton || false;
    this.comboSegment = desc.combosegment || false;
    this.buttonImg = desc.buttonimg || null;
    this.lookupData = null;
    this.lastLookupValue = null;
    // Need this checks to avoid infinite recursions
    if (desc.name === desc._child || desc._parent === desc._child) {
      this.childField = null;
    } else {
      this.childField = desc._child || null;
    }
    // Need this checks to avoid infinite recursions
    if (desc.name === desc._parent || desc._parent === desc._child) {
      this.parentField = null;
    } else {
      this.parentField = desc._parent || null;
    }
    this.descriptionField = desc._description || null;
    this.descriptionForField = desc._describes || null;
    this.scheduledForRevalidation = false;
    this.autoValidated = false;
    this.retainDefaultValue = false;
    this.rerender = 0;
    this.lastRerenderIndex = 0;
    this.auxiliary = desc._auxiliary || null;
    this.lookups = desc._lookups || null;
    if (this.isReadonly && this.lookup) {
      this.isReadonly = false;
    }
  }

  public addExtensionField(extensionField: FormField, readonly: boolean): void {
    if (!this.fields) {
      this.fields = [];
    }
    if (this.isVisible && extensionField.fldtype !== 'push') {
      extensionField.isVisible = true;
    }
    extensionField.isRequired = false;
    extensionField.isExtension = true;
    extensionField.isReadonly = readonly;
    this.hasExtension = true;
    this.fields.push(extensionField);
  }

  private setControlType(desc: FormFieldDesc, isSearchForm: boolean): void {
    this.controltype = this.fldtype;
    if (this.isCombo) {
      this.controltype = 'combo';
    }
    if (this.isMultiline && this.fldtype === 'edit') {
      this.controltype = 'textarea';
    }
    if (this.fldtype === 'edit' && !this.isCombo && !(this.controltype === 'textarea' )) {
      if (this.datatype === '4') {
        if (desc.name==='DOCNUM' && isSearchForm) {
          this.controltype += 'text';
        } else {
          this.controltype += 'number';
        }
      }
      if (this.datatype === '8') {
        this.controltype += 'date';
      }
      // is this date time and 8 is date???
      if (this.datatype === '1') {
        this.controltype += 'date';
      }
      if (this.datatype === '0') {
        if (this.rows >= 0 || this.mvinfo) {
          this.controltype += 'text';
        }
      }
    }
    this.isCheckboxGroup = this.controltype === 'radiogroup' && this.name === 'ITEM_TYPE';
    this.isRGCheckboxes = desc.rgcheckboxes;
  }

  private setValidationPattern(validationPattern: string) {
    this.validationParameters = ValidationUtilities.getValidationParameters(validationPattern);
  }

  private setMaxCharacters(maxCharacters: number) {
    this.maxChars = this.validationParameters?.maxAcceptableLength || maxCharacters || -1;
  }

  private decodeFlags(flags: number, isSearchForm: boolean, fldtype: string): void {
    this.isPassword = (flags & FieldFlags.FIELD_PASSWORD) !== 0;
    this.isCombo = (flags & FieldFlags.FIELD_COMBO) !== 0;
    this.isUppercase = (flags & FieldFlags.FIELD_UPPERCASE) !== 0;
    this.isVisible = (isSearchForm && this.name && this.name.toUpperCase() === 'SECURITY') || (flags & FieldFlags.FIELD_HIDDEN) === 0;
    this.isEnabled = (flags & FieldFlags.FIELD_DISABLED) === 0;
    this.isMultiline = (flags & FieldFlags.FIELD_MULTILINE) !== 0;
    this.isLocked = (flags & FieldFlags.FIELD_LOCKED) !== 0;
    if (fldtype === 'push') {
      const upName = this.name.toUpperCase().replace('&','');
      const defaults: string[] = [ 'OK', 'CANCEL', 'CLOSE', 'SEARCH'];
      if (defaults.indexOf(upName) >= 0) {
        this.isVisible = false;
      }
    }
    this.isRequired = (this.isVisible || this.name==='$edx_force_invalid') && this.isEnabled && (flags & FieldFlags.FIELD_REQUIRED) !== 0;
    if (!isSearchForm && !this.isCombo && !this.mvinfo && this.name !== '%KEYWORD') {
      this.isReadonly = (flags & FieldFlags.FIELD_READONLY) !== 0;
    } else {
      this.isReadonly = false;
    }
  }

  private setRadioButtons(buttons: string): void {
    if (buttons && buttons.length) {
      this.buttonMap = [];
      const items: string[] = buttons.split('|');
      for (const item of items) {
        const parts: string[] = item.split(';');
        this.buttonMap.push({ value: parts[0], display: parts[1] });
      }
    } else {
      this.buttonMap = null;
    }
  }

  private setFormatOptions(format: string): void {
    this.format = format;
    if (!!format) {
      this.formatMap = [];
      const items: string[] = format.split(';');
      for (const item of items) {
        const subitems: string[] = item.split(']');
        if (subitems.length > 1) {
          const value = subitems[0].slice(subitems[0].indexOf('=') + 1);
          const display = subitems[1];
          this.formatMap.push({ value, display });
        }
      }
    }
  }

  private setSelectOptions(selections: SelectItem[]) {
    if (!!selections && selections.length > 0) {
      this.selectionMap = selections;
      this.selectionMap.forEach(selectItem => !selectItem.value ? (selectItem.value='') : undefined);
    } else {
      this.selectionMap = [];
    }
  }
}
