import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TilesContainerComponent } from './tiles-container.component';
import { TileComponent } from './tile.component';
import { TileService } from '../services/tile.service';
import { DirectivesModule } from '../directives/directives.module';
import { ListsModule } from '../lists/lists.module';
import { WidgetsModule } from '../widgets/widgets.module';
import { HeroTileComponent } from './hero-tile-component';

@NgModule({
  imports:      [ CommonModule, RouterModule, DirectivesModule, ListsModule, WidgetsModule ],
  providers:    [ TileService ],
  declarations: [ TilesContainerComponent, TileComponent, HeroTileComponent ],
  exports:      [ TilesContainerComponent, HeroTileComponent ]
})

export class TilesModule { }
