import { Component, Input, OnChanges, ChangeDetectionStrategy, Output, EventEmitter, OnDestroy, ChangeDetectorRef, SimpleChange, ViewChildren, ViewChild, QueryList, ElementRef, AfterViewChecked, HostListener } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { Util } from '../utils/utils.module';
import { ListItem } from '../models/list-item';
import { ListBaseComponent } from './list-base.component';
import { ListService } from '../services/list.service';
import { SchemaService, SchemaDef, SortControl } from '../services/schema.service';
import { ColFormat, ColumnDesc } from '../models/column';
import { LocalizeService } from '../services/localize.service';
import { CommandHandler } from '../models/command-handler';
import { InlineActionBarComponent } from '../widgets/inline-action-bar.component';
import { PaginatorComponent, PaginatorTarget } from '../widgets/paginator.component';
import { SelectComponent } from '../widgets/select.component';
import { DataService } from '../services/data.service';
import { SecurityControl, AccessLevel, AccessSearch } from '../models/security-control';
import { LookupService } from '../services/lookup.service';
import { SelectItem } from '../models/form-field';
import { WidgetsModule } from '../widgets/widgets.module';
import { KeyCommand, ShortcutsService } from '../services/shortcuts.service';

enum ItemClickAction { none=0, select=1, parent=2 }
const kNoFiltersSchemas = ['SECURITY', 'HISTORY', 'VERSIONS', 'WHERE_USED', 'ACTIVITY'];
const kOpenableProperties = ['APP_ID', 'DOCNAME', 'DISPLAYNAME', 'DESCRIPTION', 'ACTIVITY_TYPE', 'ACTIVITY_AUTHOR'];
const kCannedRights = [AccessLevel.ACCESS_LEVEL_NORMAL, AccessLevel.ACCESS_LEVEL_READ_ONLY, AccessLevel.ACCESS_LEVEL_VIEW_PROFILE, AccessLevel.ACCESS_LEVEL_FULL];

class SelectState {
  all: boolean;
  list: ListItem[];
  constructor(all: boolean, list: ListItem[]) {
    this.all = all;
    this.list = Array.from(list);
  }
}

@Component ({
  selector: 'edx-list-table',
  styleUrls: [ 'list-table.component.scss' ],
  providers: [ListService],
  inputs: ListBaseComponent.baseClassInputs,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'list-table.component.html'
})
export class ListTableComponent extends ListBaseComponent implements OnDestroy, OnChanges, AfterViewChecked, PaginatorTarget, CommandHandler {
  public static baseClassInputs: string[] = ['desc','params','pageSizeIncremental','schemaId','parent','lookupForm','fieldDataList','leadingColums'];
  public schema: SchemaDef = null;
  public dragOverItem: ListItem = null;
  public dragover = false;
  public hitPreviewShown = false;
  public hitPreviewShowing = false;
  public hitPreviewHiding = false;
  public draggingColumn: HTMLDivElement = null;
  public isIE: boolean = Util.Device.bIsIE;
  public isEdge: boolean = Util.Device.bIsEdge;
  public infoMessage = '';
  public allExpanded = false;
  public noneSelected = true;
  public allSelected = false;
  public altLabels: any;
  public securityAltLabels: any;
  public isChrome: boolean = Util.Device.bIsChrome;
  protected selections: ListItem[] = [];
  protected trusteeSelections: ListItem[] = [];
  protected openedItem: ListItem = null;
  protected expandedItems: ListItem[] = [];
  protected columnsHidden: number;
  protected columnsShown = 999;
  protected layoutComplete = false;
  protected hoverListItem: ListItem = null;
  protected hoverOpenTimer: number;
  protected hoverItemIndex = -1;
  protected hoverItemHidingIndex = -1;
  protected dragoverHasFiles = false;
  protected dragoverHasItems = false;
  protected dragoverTR: any = null;
  protected hitPreviewHoverID: string = null;
  protected hitPreviewData: any = null;
  protected hitPreviewLoading = false;
  protected pageSize = 25;
  protected itemClickAction: ItemClickAction = ItemClickAction.none;
  protected rightsValues: SelectItem[];
  protected historyActions: string[] = null;
  protected noHits: string;
  protected didColumnDrag = false;
  protected draggingColumnDropIndex = -1;
  protected draggingColResizer = false;
  protected draggedColResizerOriginalLeft = -1;
  protected security: SecurityControl = null;
  protected savedSelectState: SelectState = null;
  protected schemaForm: string;
  protected primaryUC: string = Util.RestAPI.getPrimaryLibrary().toUpperCase();
  private iconColWidth: string = null;
  private rowIndex = 0;
  private shortcutsSubscription: Subscription;
  @ViewChildren(InlineActionBarComponent) inlineMenus: QueryList<InlineActionBarComponent>;
  @ViewChild('tc') tc: ElementRef;
  @ViewChild('tb') tb: ElementRef;
  @ViewChild('rdbt') rdbt: ElementRef;
  @ViewChild('rdbb') rdbb: ElementRef;
  @ViewChild('rdbl') rdbl: ElementRef;
  @ViewChild('rdbr') rdbr: ElementRef;
  @ViewChild('lb') lb: ElementRef;
  @ViewChild('headerrow') headerRow: ElementRef;
  @Input() hasFootprint?: boolean = false;
  @Input() showExtras?: boolean = true;
  @Input() inlineActionMenuId?: number = -1;
  @Input() inlineActionTarget?: CommandHandler = null;
  @Input() schemaId?: string = 'BASIC_LIST';
  @Input() parent?: ListTableParent;
  @Input() paginator?: PaginatorComponent;
  @Input() lookupForm?: string;
  @Input() fieldDataList?: ListItem[];
  @Input() leadingColums?: number[] = [];
  @Input() formType?: string = '';
  @Input() selectedLookupValues?: string = '';
  @Input() searchLookup?: string = '';
  @Input() lookupKey?: string  = '';
  @Input() isParentEmpty?: boolean = false;
  @Input() tabIndex = 0;
  // When user changes to custom access, send a notification to secury control to swich to detailsview.
  @Output() detailviewnotify: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() summaryviewnotify: EventEmitter<number> = new EventEmitter<number>();
  @Output() selectionsList: EventEmitter<ListItem[]> = new EventEmitter<ListItem[]>();

  constructor(protected listService: ListService, protected changeDetector: ChangeDetectorRef, protected schemaService: SchemaService,
              protected localizer: LocalizeService, protected dataService: DataService, protected lookupService: LookupService, protected shortcutsService: ShortcutsService) {
    super(listService, dataService, localizer, changeDetector);
    this.noHits = this.localizer.getTranslation('PLACEHOLDER.NO_ITEMS');
    this.pageSize = Util.RestAPI.getDefualtMaxItems();
    this.bIsPaged = true;
    this.altLabels = {
      seeAll: this.localizer.getTranslation('TOOLTIP.SEE_ALL'),
      seeMore: this.localizer.getTranslation('TOOLTIP.SEE_MORE'),
      detailsExpanded: this.localizer.getTranslation('ALT_TEXT.DETAILS', [this.localizer.getTranslation('ALT_TEXT.EXPANDED')]),
      detailsCollapsed: this.localizer.getTranslation('ALT_TEXT.DETAILS', [this.localizer.getTranslation('ALT_TEXT.COLLAPSED')]),
      collapsed: this.localizer.getTranslation('ALT_TEXT.COLLAPSED'),
      expanded: this.localizer.getTranslation('ALT_TEXT.EXPANDED'),
      nextPage: this.localizer.getTranslation('PAGINATOR.PAGE_FORWARD'),
      previousPage: this.localizer.getTranslation('PAGINATOR.PAGE_BACK'),
      sortable: this.localizer.getTranslation('ALT_TEXT.SORTABLE'),
      unsortable: this.localizer.getTranslation('ALT_TEXT.UNSORTABLE'),
      selectAll: this.localizer.getTranslation('ALT_TEXT.SELECT_ALL'),
      accessRights: this.localizer.getTranslation('ALT_TEXT.ACCESS_RIGHTS'),
      rowSelector: this.localizer.getTranslation('ALT_TEXT.ROW_SELECTOR'),
      unselect: this.localizer.getTranslation('ALT_TEXT.UNSELECT'),
      selected: this.localizer.getTranslation('ALT_TEXT.SELECTED'),
      notSelected: this.localizer.getTranslation('ALT_TEXT.NOT_SELECTED'),
      ascending: this.localizer.getTranslation('ALT_TEXT.ASCENDING_ORDER'),
      descending: this.localizer.getTranslation('ALT_TEXT.DESCENDING_ORDER'),
      sortMenu: this.localizer.getTranslation('ALT_TEXT.SORT_MENU')
    };
    this.securityAltLabels = {
      0: this.localizer.getTranslation('FORMS.LOCAL.PERMISSIONS_SELECTOR.INHERIT'),
      1: this.localizer.getTranslation('METADATA.FOOTER.SECURITY.ALLOW'),
      2: this.localizer.getTranslation('METADATA.FOOTER.SECURITY.DENY')
    };
    this.shortcutsSubscription = shortcutsService.commands.subscribe(c => this.handleCommand(c));
  }

  handleCommand(command: KeyCommand) {
    switch (command.name) {
      case 'list-select-all':
        if (this.schema.columns.find(col => col.format === ColFormat.SELECTOR)) {
          this.headerSelectClick();
        }
        break;
    }
  }

  ngOnDestroy() {
    if (this.shortcutsSubscription) {
      this.shortcutsSubscription.unsubscribe();
    }
    if (Util.RestAPI.getCurListComponent() === this) {
      Util.RestAPI.setCurListComponent(null);
    }
  }

  public getLabel(listItem: ListItem): string {
    const attr = (!!this.desc && !!this.desc['type']) ? (this.desc['type'] === 'folders' ? 'DOCNUM' : this.desc['type'] === 'lookups' ? this.lookupKey : 'DOCNAME') : null;
    const schemasToIgnore = ['LIBRARY_MATRIX', 'APP_ID_FORMS', 'VERSIONS', 'ATTACHMENTS', 'HISTORY','SECURITY','RELATED','WHERE_USED','ACCESS_CONTROL'];
    return schemasToIgnore.indexOf(this.schemaId) === -1 && this.hasSelectLabel(listItem) ? (!!attr ? attr + ' ' + listItem[attr] + ' ' + (!this.canSelectItem(listItem) ? '' : (this.selections.indexOf(listItem) >= 0 ? this.altLabels['selected'] : this.altLabels['notSelected'])) : '') : '';
  }

  public getColumnAriaLabel(col: any, listitem: ListItem, trusteeNumber: number): string {
    let ariaLabel = '';

    if (col.property === 'rights') {
      if (this.schemaId === 'ACCESS_CONTROL') {
        ariaLabel = this.securityAltLabels[trusteeNumber];
      } else {
        ariaLabel = this.getRightsLabel(listitem);
      }
    } else if (col.property === 'APP_ID' && this.schemaId === 'WHERE_USED') {
      ariaLabel = this.formatTypeText(listitem);
    } else if ((col.property === 'LAST_EDIT_DATE' && !!this.formatDate(listitem, col.property)) || (col.property !== 'LAST_EDIT_DATE' && this.formatString(listitem, col.property, col.displayMap) !== '-')) {
      ariaLabel = this.formatString(listitem, col.property, col.displayMap);
    } else {
      return ariaLabel;
    }

    return col.label + ' ' + ariaLabel;
  }

  public hasSelectLabel(listItem: ListItem): boolean {
    return ((!!this.desc && !!this.desc['type'] && this.desc['type'] === 'activities') || (!!listItem['NODE_TYPE'] && ['%DV_ROOT_NODE', '%DV_RECENT_NODE', '%DV_SEARCH'].indexOf(listItem['NODE_TYPE']) !== -1)) ? false : true;
  }

  protected descChanged(newDesc: any): void {
    if (newDesc && (newDesc.params==='security' || this.params==='security')) {
      this.schema = this.schemaService.getSchema(this.schemaId, newDesc.lib, null, null);
      this.setSortParams(this.schema.sortAscending, this.schema.sortDescending);
      for (const column of this.schema.columns) {
        column.label = this.localizer.getTranslation(column.label);
      }
    } else if (newDesc && newDesc.type==='lookups') {
      const bIsUserOrGroups: boolean = (newDesc.id==='_GROUPS_ENABLED' || newDesc.id==='_GROUP_MANAGER');
      this.itemClickAction = bIsUserOrGroups ? ItemClickAction.parent : ItemClickAction.select;
      this.schema = this.schemaService.getLookupSchema(newDesc.id,newDesc.lib,this.lookupForm,this.formType,this.lookupKey);
      if (this.schema && !!this.schema.columns && (this.schema.columns.length > this.schema.columns.reduce((a, c) => (!!c.label || c.label === ' ') ? ++a : a, 0))) {
        if (newDesc.id==='_GROUP_MANAGER') {
          this.listReplaced();
        }
        if (!this.layoutComplete) {
          setTimeout(() => {
            this.setVisibleColumnCount();
          }, 1);
        }
      } else {
        this.lookupService.getLookupColumns(newDesc.id,newDesc.lib,this.lookupForm,this.lookupKey).then((data) => {
          this.schema = this.schemaService.buildLookupSchema(newDesc.id,newDesc.lib,this.lookupForm,data,this.leadingColums,this.formType,this.lookupKey);
          if (newDesc.id==='_GROUP_MANAGER') {
            this.listReplaced();
          } else if (newDesc.id==='_GROUPS_ENABLED') {
            this.changeDetector.markForCheck();
          }
          if (!this.layoutComplete) {
            setTimeout(() => {
              this.setVisibleColumnCount();
            }, 1);
          }
        }).catch(error => {
          this.handleError(error);
        });
      }
    } else if (newDesc && Util.isExternalLib(newDesc.lib)) {
      this.schemaService.getSchemaFromServer(newDesc, this.schemaId==='HISTORY' ? 'history' : null).then(schema => {
        this.schema = schema;
        if (!!this.schema) {
          this.setSortParams(this.schema.sortAscending, this.schema.sortDescending);
          if (!this.layoutComplete) {
            setTimeout(() => {
              this.setVisibleColumnCount();
            }, 1);
          }
        }
      });
      Util.RestAPI.setCurListComponent(this);
    } else {
      const kMetaDataSchemas: string[] = ['SECURITY', 'HISTORY', 'ATTACHMENTS', 'WHERE_USED', 'WHERE_USED', 'RELATED', 'VERSIONS', 'ACCESS_CONTROL'];
      let newSchemaId: string = this.schemaId;
      if (newDesc && newDesc.type==='searches' && !newDesc.id) {
        newSchemaId = 'SAVED_SEARCHES';
        Util.RestAPI.setCurListComponent(this);
      } else if (newDesc && newDesc.type==='flexfolders') {
        newSchemaId = newDesc.id.length===0 || newDesc.id.indexOf('DV_ROOT_NODE')!==-1 ? 'FLEXFOLDERS_ROOT' : 'FLEXFOLDERS';
        Util.RestAPI.setCurListComponent(this);
      } else if (newDesc && newDesc.type==='fileplans') {
        if (newDesc.id.length === 0 || newDesc.id.indexOf('FilePlan')!==-1 ) {
          newSchemaId  = 'FILEPLAN_ROOT';
        } else if (newDesc.id.indexOf('Term')!==-1) {
          newSchemaId  = 'FILEPLAN_TERMFILE';
        } else if (newDesc.id.indexOf('FilePart')!==-1 && kMetaDataSchemas.indexOf(this.schemaId)===-1) {
          newSchemaId = 'FILEPLAN_DOCUMENT';
        } else if (newDesc.id.indexOf('File')!==-1 && kMetaDataSchemas.indexOf(this.schemaId)===-1) {
          newSchemaId = 'FILEPLAN_FILEPART';
        }
        Util.RestAPI.setCurListComponent(this);
      } else if (newDesc && newDesc.type==='boxes' && kMetaDataSchemas.indexOf(this.schemaId)===-1) {
        newSchemaId = 'FILEPLAN_DOCUMENT';
      } else if (newDesc && newDesc.type==='requests') {
        newSchemaId = 'FILEPLAN_REQUESTS';
        Util.RestAPI.setCurListComponent(this);
      } else if (newDesc && newDesc.type==='activities') {
        const parts = newDesc.id.split('-');
        const nParts = parts.length;
        const lastPart = parts[nParts-1];
        const secondLastPart = nParts>1 ? parts[nParts-2] : '';
        if (!isNaN(parseInt(lastPart))) {
          newSchemaId = 'BASIC_LIST';
        } else {
          if (!!secondLastPart|| parts[0] === 'me') {
            newSchemaId = 'ACTIVITY';
          } else {
            newSchemaId = 'ACTIVITY_AUTHOR';
          }
        }
        Util.RestAPI.setCurListComponent(this);
      } else if (newDesc?.type === 'folders' && Util.isInteger(newDesc.id) && kMetaDataSchemas.indexOf(this.schemaId) === -1) {
        newSchemaId = 'FOLDERS';
        Util.RestAPI.setCurListComponent(this);
      } else if (newDesc?.type === 'folders' && newDesc.id === 'recentedits') {
        newSchemaId = 'BASIC_LIST';
        Util.RestAPI.setCurListComponent(this);
      } else if (newDesc?.type === 'folders' && newDesc.id === 'public') {
        newSchemaId = 'PUBLIC_LIST';
        Util.RestAPI.setCurListComponent(this);
      } else if (this.schemaId === 'BASIC_LIST' || (newDesc && newDesc.type === 'searches' && newDesc.id)) {
        Util.RestAPI.setCurListComponent(this);
        if (newDesc && newDesc.id==='checkedout') {
          newSchemaId = 'CHECKED_OUT';
        } else if (newDesc && newDesc.type==='searches') {
          newSchemaId = 'SEARCHES';
          const criteria: any = this.dataService.getSearchData(this.desc);
          if (criteria && criteria['FORM_NAME']) {
            this.schemaForm = criteria['FORM_NAME'];
          } else if (this.desc['FORM_NAME']) {
            this.schemaForm = this.desc['FORM_NAME'];
          } else if (Util.RestAPI.getDefaultSearchForm()) {
            this.schemaForm = Util.RestAPI.getDefaultSearchForm().id;
           } else {
            this.schemaForm = null;
           }
        } else {
          newSchemaId = 'BASIC_LIST';
        }
      } else if (this.schemaId==='VERSIONS' || this.schemaId==='WHERE_USED' || this.schemaId==='ATTACHMENTS') {
        Util.RestAPI.setCurListComponent(this);
      }
      if (!this.schema || newSchemaId!==this.schemaId) {
        this.loadSchema(newSchemaId, newDesc ? newDesc.lib : null);
      }
      this.validateSearchColumnForSearchForm(newDesc);
    }
    if (this.fieldDataList) {
      this.list = this.fieldDataList;
      this.listReplaced();
      if (this.schemaId === 'DELETE_LIST') {
        this.headerSelectClick();
      }
    }
    if (!this.rightsValues || !this.rightsValues.length || (newDesc && newDesc.type === 'workspaces' || newDesc.type === 'searches')) {
      this.rightsValues = [];
      if (newDesc && ((newDesc.type === 'workspaces') || newDesc['ITEM_TYPE'] === 'W' || (newDesc['isWorkspace'] === '1'))) {
        this.rightsValues.push({ value: AccessLevel.ACCESS_LEVEL_VIEW, display: this.localizer.getTranslation('SECURITY.WORKSPACE_LEVELS.VIEW')});
        this.rightsValues.push({ value: AccessLevel.ACCESS_LEVEL_COLLABORATE, display: this.localizer.getTranslation('SECURITY.WORKSPACE_LEVELS.COLLABORATE')});
        this.rightsValues.push({ value: AccessLevel.ACCESS_LEVEL_MANAGE, display: this.localizer.getTranslation('SECURITY.WORKSPACE_LEVELS.MANAGE')});
      } else if (newDesc && newDesc.type === 'searches') {
        this.rightsValues.push({ value: AccessSearch.VIEW, display: this.localizer.getTranslation('SECURITY.RIGHTS_GROUPS.VIEW')});
        this.rightsValues.push({ value: AccessSearch.VIEW_EDIT, display: this.localizer.getTranslation('SECURITY.RIGHTS_GROUPS.EDIT')});
        this.rightsValues.push({ value: AccessSearch.VIEW_EDIT_DELETE, display: this.localizer.getTranslation('SECURITY.RIGHTS_GROUPS.FULL')});
        this.rightsValues.push({ value: AccessSearch.CREATOR, display: this.localizer.getTranslation('SECURITY.RIGHTS_GROUPS.CREATOR'), disabled: true});
      } else {
        this.rightsValues.push({ value: AccessLevel.ACCESS_LEVEL_NORMAL, display: this.localizer.getTranslation('SECURITY.LEVELS.NORMAL')});
        this.rightsValues.push({ value: AccessLevel.ACCESS_LEVEL_READ_ONLY, display: this.localizer.getTranslation('SECURITY.LEVELS.READ_ONLY')});
        this.rightsValues.push({ value: AccessLevel.ACCESS_LEVEL_VIEW_PROFILE, display: this.localizer.getTranslation('SECURITY.LEVELS.VIEW_PROFILE')});
        this.rightsValues.push({ value: AccessLevel.ACCESS_LEVEL_FULL, display: this.localizer.getTranslation('SECURITY.LEVELS.FULL')});
        this.rightsValues.push({ value: AccessLevel.ACCESS_LEVEL_CUSTOM, display: this.localizer.getTranslation('SECURITY.LEVELS.CUSTOM')});
      }
    }
  }

  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    const libraryName = this.desc?.lib;
    if (Util.RestAPI.isLibraryLoaded(libraryName)) {
      this.onChanges(changes);
    } else {
      Util.RestAPI.checkLibrarySettingsByName(libraryName).subscribe((libraryInfo: any) => {
        this.onChanges(changes);
      });
    }
  }

  private onChanges = (changes: { [propertyName: string]: SimpleChange }) => {
    const descChng: any = changes['desc'];
    const showExtrasChng: any = changes['showExtras'];
    const schemaIdChng: any = changes['schemaId'];
    if (this.desc && !this.desc.lib) {
      this.desc.lib = Util.RestAPI.getPrimaryLibrary();
    }
    if (schemaIdChng && !descChng && !this.schema) {
      this.descChanged(this.desc);
    }
    if (showExtrasChng) {
      this.changeDetector.markForCheck();
    } else {
      if (!!descChng && !!descChng.currentValue) {
        this.descChanged(descChng.currentValue);
        this.selections = [];
        this.trusteeSelections = [];
        this.noneSelected = true;
        this.allSelected = false;
      }
      super.ngOnChanges(changes);
    }
  };

  ngAfterViewChecked(): void {
    if (!this.layoutComplete) {
      setTimeout(() => {
       this.setVisibleColumnCount();
      }, 300);
    }
  }

  @HostListener('window:resize')
  public onResize(): void {
    this.layoutComplete = false;
    this.setVisibleColumnCount();
  }

  @HostListener('keydown', ['$event'])
  private onKeyDown(event: KeyboardEvent): boolean {
    if (!this.hasFootprint && WidgetsModule.isOKtoHandleKeydown(this.getContainerElement()) && !this.hasFocusOnAddinMenu()) {
      switch (event.key) {
      case 'ArrowDown':
        this.nav(1, event.shiftKey);
        return false;
      case 'ArrowUp':
        this.nav(-1, event.shiftKey);
        return false;
      }
    }
    return true;
  }

  private hasFocusOnAddinMenu(): boolean {
    const activeEle = document.activeElement as HTMLElement;
    return ((this.officeAddin || this.ui >= 2) && !!activeEle && ['edx_sort_column', 'edx_settings', 'edx_configure_red'].indexOf(activeEle.id) !== -1);
  }

  protected isPostRequest(): boolean {
    return this.desc && this.desc.id.startsWith('evaluation');
  }

  protected getContainerElement(): HTMLElement {
    return this.tb ? this.tb.nativeElement : null;
  }

  protected getListElement(): HTMLElement {
    return this.tb ? this.tb.nativeElement : null;
  }

  protected scrollIntoView(index: number, goingUp: boolean): void {
    const containerEl: HTMLElement = this.getContainerElement();
    const listEl: HTMLElement = this.getListElement();
    if (containerEl && listEl) {
      const currentScrollTop: number = containerEl.scrollTop;
      const listHeight: number = containerEl.offsetHeight;
      const curEl: HTMLElement = Array.from(listEl.children)[index] as HTMLElement;
      const elTop = curEl.offsetTop - (goingUp ? curEl.offsetHeight : 0);
      if ((elTop < currentScrollTop) || (elTop > (currentScrollTop+listHeight))) {
        containerEl.scrollTop = elTop;
      }
    }
  }

  protected selectionAnchor(): number {
    let anchor = -1;
    if (this.selections.length>1) {
      const firstItemIndex: number = this.list.indexOf(this.selections[0]);
      const lastItemIndex: number = this.list.indexOf(this.selections[this.selections.length-1]);
      if (firstItemIndex<lastItemIndex) {
        anchor = firstItemIndex;
      } else {
        anchor = lastItemIndex;
      }
    }
    return anchor;
  }

  protected nav(inc: number, multiSelect: boolean): void {
    const anchor: number = this.selectionAnchor();
    const selIndex: number = this.selections.length ? this.selections.length-1 : 0;
    const item: ListItem = this.selections.length ? this.selections[selIndex] : null;
    let index: number = !!item ? this.list.indexOf(item) : inc===1 ? -1 : this.list.length;
    if (!multiSelect || anchor===-1 || (inc===1 && index>anchor) || (inc===-1 && index<=anchor)) {
      index += inc;
    }
    if (index>=0 && index<this.list.length) {
      if (!multiSelect) {
        this.selections = [];
        this.trusteeSelections = [];
      }
      this.selectorClick(this.list[index], event);
      this.scrollIntoView(index, inc===-1);
    }
  }

  protected getInitialList(): ListItem[] {
    if (this.parent && this.parent.initialList) {
      return this.parent.initialList(this);
    }
    return null;
  }

  protected getInitialSet(): any {
    if (this.parent && this.parent.initialSet) {
      return this.parent.initialSet(this);
    }
    return null;
  }

  protected isClickable(listItem: ListItem, property: string): boolean {
    if (kOpenableProperties.indexOf(property) !== -1 && this.canOpenListItem(listItem)) {
      return true;
    }
    switch (property) {
      case 'GROUP_ID':
      case 'GROUP_NAME':
      case 'is_favorite':
        return true;
    }
    return false;
  }

  protected isReadonly(row: number, property: string): boolean {
    if (!!this.desc) {
      const item: ListItem = this.list[row];
      if (this.desc['STATUS'] === '18' || this.desc['STATUS'] === '3') {
        return true;
      }
      if (!!item) {
        if (this.params === 'security') {
          const currentUser: string = Util.RestAPI.getUserID();
          if (item['USER_ID'].toUpperCase() === (this.desc['AUTHOR_ID'] || '').toUpperCase() || (!!currentUser && currentUser.toUpperCase() === item['USER_ID'].toUpperCase())) {
            return true;
          }
        } else {
          if (item['STATUS'] === '18' || item['STATUS'] === '3' || item['READONLY'] === 'Y') {
            return true;
          } else if (this.desc.id === 'preferences' && property==='remote') {
            return item.lib.toUpperCase() === this.primaryUC;
          }
        }
      }
    }
    return false;
  }

  protected setTemplatedRights(selectComponent: SelectComponent, itemIndex: number): void {
    const listItem = this.list[parseInt(selectComponent.data, 10)];
    const rightsValue = !!selectComponent.value ? parseInt(selectComponent.value, 10) : -1;
    const oldRightsValue = listItem['rights'];
    const isCustom = rightsValue === AccessLevel.ACCESS_LEVEL_CUSTOM;
    if (!isCustom || kCannedRights.indexOf(oldRightsValue) !== -1) {
      listItem['rights'] = isCustom ? 0 : rightsValue;
      this.summaryviewnotify.emit(rightsValue);
    } else if (isCustom) {
      this.summaryviewnotify.emit(rightsValue);
    }
  }

  public focusOnListHeader(): void {
    if (!!this.headerRow && !!this.headerRow.nativeElement) {
      const header = this.headerRow.nativeElement as HTMLElement;
      if (!!header) {
        const firstColumn = header.getElementsByTagName('th')[0] as HTMLElement;
        if (!!firstColumn) {
          const firstChild = firstColumn.firstChild as HTMLElement;
          if (!!firstChild && firstChild.id === 'edx_list_table_selectall') {
            firstChild.focus();
          } else {
            firstColumn.focus();
          }
        }
      }
    }
  }

  public focusOnPreviewPane(index: number): void {
    this.rowIndex = index;
    const previewNext = document.getElementById('edx_hit_preview_prev');
    if (!!previewNext) {
      previewNext.focus();
    }
  }

  public focusOnRowSelector(): void {
    if (this.rowIndex >= 0) {
      this.hitPreviewLeave(null);
      const rowSelector = document.getElementById(this.getRowSelectorId(this.rowIndex));
      if (!!rowSelector) {
        rowSelector.focus();
        this.rowIndex = -1;
      }
    }
  }

  private getRowSelectorId(index: number): string {
    return 'edx_row_selector_' + index;
  }

  public getRightsLabel(item: ListItem): string {
    const rightValue= this.setDisplayAccessLevel(item['rights']);
    return this.rightsValues.find(i=>i.value === rightValue).display;
  }

  private columIsIconic(column: ColumnDesc): boolean {
    return column.format === ColFormat.SELECTOR || column.format === ColFormat.OBJTYPE || column.format === ColFormat.IMAGE;
  }

  private getColWidth(col: ColumnDesc): string {
    if (this.columIsIconic(col)) {
      return this.iconColWidth;
    }
    return ''+col.width+'%';
  }

  protected getColDesc(key: string): any {
    return this.schema && this.schema.columns ? this.schema.columns.find(c => c.property===key) : null;
  }

  protected isFavoriteColumn(colProperty) {
    return colProperty === 'is_favorite';
  }

  protected setVisibleColumnCount(): void {
    if (this.schema  && this.schema.columns) {
      // skip the whole schmeer if trailing column is not expander or if there is no header
      if (this.headerRow && this.schema.columns.length > 0 && this.schema.columns[this.schema.columns.length - 1].format === ColFormat.EXPANDER) {
        let primaryColumn: ColumnDesc = null;
        let primaryColIdx = 0;
        let colPercentTotal = 0;
        const oldColsHidden: number = this.columnsHidden;
        this.columnsShown = this.schema.columns.length - 1;
        this.columnsHidden = 0;
        // find primary column and total width proportions
        for (let idx = 0; idx < this.schema.columns.length; idx++) {
          colPercentTotal += this.schema.columns[idx].width;
          if (this.schema.columns[idx].minwidth > 0) {
            primaryColumn = this.schema.columns[idx];
            primaryColIdx = idx;
          }
        }
        if (!primaryColumn) {
          primaryColIdx = 2;
          primaryColumn = this.schema.columns[primaryColIdx];
        }
        // get total width from header row
        const rowWidth: number = this.headerRow.nativeElement.offsetWidth;
        // calculate width of primary column
        let primaryWidth = rowWidth * primaryColumn.width / colPercentTotal;
        const minToShow = primaryColIdx + 1;
        while (primaryWidth < primaryColumn.minwidth && this.columnsShown > minToShow) {
          this.columnsShown--;
          this.columnsHidden++;
          colPercentTotal -= this.schema.columns[this.columnsShown].width;
          primaryWidth = rowWidth * primaryColumn.width / colPercentTotal;
        }
        if (oldColsHidden !== this.columnsHidden) {
          this.changeDetector.markForCheck();
        }
      } else {
        // no expander in schema
        const bIsUserOrGroups: boolean = this.desc && (this.desc.id==='_GROUPS_ENABLED' || this.desc.id==='_GROUP_MANAGER');
        if (bIsUserOrGroups && Util.Device.isPhoneLook()) {
          this.columnsShown = this.schema.columns.length - 1;
          this.columnsHidden = 1;
          this.allExpanded = false;
          this.headerExpandClick();
        } else {
          this.columnsShown = this.schema.columns.length;
          this.columnsHidden = 0;
        }
      }
      const waitForHeaderRow = () => {
        if (this.headerRow && this.headerRow.nativeElement) {
          let totalNonIconicColWidthPercent = 0;
          let nIconCols = 0;
          const nCols: number = this.schema.columns.length - (this.columnsHidden > 0 ? this.columnsHidden : 0);
          for (let i=0; i<nCols; i++) {
            const col: ColumnDesc = this.schema.columns[i];
            if (this.columIsIconic(col)) {
              ++nIconCols;
            } else {
              totalNonIconicColWidthPercent += col.width || 0;
            }
          }
          // we want icons to be no less than 48px wide
          this.iconColWidth = '' + Math.ceil((48 * totalNonIconicColWidthPercent) / (this.headerRow.nativeElement.offsetWidth - (48 * nIconCols))) + '%';
          this.changeDetector.markForCheck();
        } else {
          setTimeout(waitForHeaderRow, 100);
        }
      };
      waitForHeaderRow();
      this.layoutComplete = true;
    }
  }

  private setDisplayAccessLevel(rights: number): AccessLevel {
    let level: AccessLevel = AccessLevel.ACCESS_LEVEL_CUSTOM;

    if (this.desc.type === 'workspaces' || this.desc['ITEM_TYPE'] === 'W') {
      level = this.updateRightsForWorkspaces(rights, level);
    } else if (this.desc.type === 'searches') {
      level = rights;
    } else {
      switch (rights) {
      case AccessLevel.ACCESS_LEVEL_NORMAL:
      case AccessLevel.ACCESS_LEVEL_FULL:
      case AccessLevel.ACCESS_LEVEL_VIEW_PROFILE:
      case AccessLevel.ACCESS_LEVEL_READ_ONLY:
        level = rights;
        break;
      default:
        if ((rights & AccessLevel.ACCESS_LEVEL_FULL) === AccessLevel.ACCESS_LEVEL_FULL) {
          level = AccessLevel.ACCESS_LEVEL_FULL;
        }
      }
    }
    return level;
  }

  //security values for workspaces created or updated from cwt are different compare to extensions.
  //this function is to handle that difference.
  private updateRightsForWorkspaces(rights: number, level: AccessLevel): AccessLevel {
    if (rights === AccessLevel.ACCESS_LEVEL_VIEW_CWT) {
      level = AccessLevel.ACCESS_LEVEL_VIEW;
    } else if (rights === AccessLevel.ACCESS_LEVEL_COLLABORATE_CWT) {
      level = AccessLevel.ACCESS_LEVEL_COLLABORATE;
    } else if ((rights & AccessLevel.ACCESS_LEVEL_FULL) === AccessLevel.ACCESS_LEVEL_FULL) {
      level = AccessLevel.ACCESS_LEVEL_FULL;
    } else {
      level = rights;
    }
    return level;
  }

  protected schemaColIsDefaultSortAscending(colProp: string): boolean {
    let rc = true;
    for (const column of this.schema.columns) {
      if (column.property===colProp) {
        if (column.format===ColFormat.DATETIME) {
          rc = false;
        }
        break;
      }
    }
    return rc;
  }

  protected schemaHasCol(colProp: string): boolean {
    if (this.schema) {
      for (const column of this.schema.columns) {
        if (column.property===colProp) {
          return true;
        }
      }
    }
    return false;
  }

  protected updateSelectString(): void {
    // override
  }

  public updateLookupSelections(): void {
    const lookupName: string = this.searchLookup;
    let enableOk = false;
    if (this.selectedLookupValues) {
      // All selected lookup values are separated by | in the filter string
      const listSelectedLookups: any = this.selectedLookupValues.split('|');
      listSelectedLookups.forEach(lookupValue => {
        if (lookupValue) {
          lookupValue = lookupValue.replace(/(^"|"$)/g, '');
          this.list.forEach(item => {
            if (Util.Transforms.isCaseInsensitiveEqual(item[lookupName] || '', lookupValue)) {
              this.selections.push(item);
              enableOk = true;
            }
          });
        }
      });
    }
    if (enableOk) {
      this.okDisabled = true;
      this.hideHoverMenu(this.hoverItemIndex);
      if (this.parent && this.parent.selectionsUpdated) {
        this.parent.selectionsUpdated(this);
      }
    }
  }

  private updateSelectedLookupValues(removeSelections?: boolean): void {
    const selectedItems = this.selections;
    if (!!selectedItems && selectedItems.length) {
      const primaryValue: string[] = this.isMultiselectItem() && !!this.selectedLookupValues ? this.selectedLookupValues.split('|') : [];
      for (const item of selectedItems) {
        let lookupValue = item[this.lookupKey];
        const lookupfield = this.getField(this.lookupKey);
        if (lookupValue?.includes(',') && !lookupfield?.mvinfo) {
          lookupValue = `"${lookupValue}"`;
        }
        const lookupValueIndex = primaryValue.findIndex(p => Util.Transforms.isCaseInsensitiveEqual(p, lookupValue));
        if (lookupValue && lookupValueIndex === -1) {
          primaryValue.push(lookupValue);
        } else if (removeSelections) {
          primaryValue.splice(lookupValueIndex, 1);
        }
      }
      this.selectedLookupValues = primaryValue.join('|');
      if (['GROUP_ID', 'USER_ID'].indexOf(this.lookupKey) !== -1) {
        this.trusteeSelections = Array.from(new Set(
          selectedItems.concat(this.trusteeSelections)
            .filter(ts => primaryValue.filter(p => Util.Transforms.isCaseInsensitiveEqual(p, ts[this.lookupKey])).length > 0)
        ));
      }
    }
  }

  private removeUnselectedLookupValue(lookupValue: string, removeFromSelections?: boolean): void {
    if (!!lookupValue) {
      lookupValue = lookupValue?.replace(/(^"|"$)/g, '');
      if (removeFromSelections && !!this.lookupKey) {
        const itemIndx = this.selections.findIndex(i =>
          Util.Transforms.isCaseInsensitiveEqual(i[this.lookupKey], lookupValue)
        );
        if (itemIndx >= 0) {
          this.selections.splice(itemIndx, 1);
          this.noneSelected = (this.selections.length === 0);
          this.okDisabled = this.noneSelected;
          this.allSelected = (this.selections.length > 0 && (this.selections.length === this.list.length));
          this.hideHoverMenu(this.hoverItemIndex);
          this.changeDetector.markForCheck();
          if (this.parent && this.parent.selectionsUpdated) {
            this.parent.selectionsUpdated(this);
          }
        }
      }
      const selectedLookups: string[] = this.selectedLookupValues.split('|');
      const lookupIndex = selectedLookups.map(item => item?.replace(/(^"|"$)/g, '')).indexOf(lookupValue);
      if (lookupIndex >= 0) {
        selectedLookups.splice(lookupIndex, 1);
        this.selectedLookupValues = selectedLookups.join('|');
      }
    }
  }

  public getSelectedLookupValues(): string {
    return this.selectedLookupValues;
  }

  private loadLookupList(filter: string, key: string): void {
    if (this.schema && this.schema.columns) {
      this.listService.getLookupList(this.desc.id, this.desc.lib, this.lookupForm, this.schema, key, filter).then((data) => {
        if (this.isLookupAdminPage()) {
          this.setVisibleColumnCount();
        }
        const list: ListItem[] = data.list;
        this.set = data.set;
        this.ascending = this.set.ascending;
        this.descending = this.set.descending;
        if (this.formType && this.formType.startsWith('profile_query')) {
          this.list = data.list;
        } else {
          // remove disabled items
          this.list = [];
          for (const item of list) {
            if (item['DISABLED'] === 'Y' || item['DISABLED'] === '1') {
              continue;
            }
            this.list.push(item);
          }
        }
        this.set.total = this.list.length;
        this.allItemsIn = true;
        this.selections = [];
        if (!this.isParentEmpty) {
          this.updateLookupSelections();
        }
        this.listReplaced();
      }).catch(error => {
        this.handleError(error);
      });
    } else {
      setTimeout(() => {
        this.loadLookupList(filter, key);
      }, 500);
    }
  }

  public getSortCol(): string {
    let result = '';
    if (this.schema.sortControl !== SortControl.NONE) {
      result = this.ascending || this.descending;
    }
    return result;
  }

  public getExternalSortCol(updateSortColumn?: boolean): string {
    let result = '';
    if (!!this.schema && !!this.desc && Util.RestAPI.isExternalLib(this.desc.lib)) {
      const column = this.schema.columns.find(c => !c.nonsortable);
      if (!!column) {
        result = column.property;
      }
    }
    if (updateSortColumn) {
      this.setSortParams(null, result);
    }
    return result;
  }

  public clearSelection(): void {
    this.selections = [];
    this.trusteeSelections = [];
    this.noneSelected = true;
    this.allSelected = false;
    this.okDisabled = true;
    this.hideHoverMenu(this.hoverItemIndex);
    if (this.parent && this.parent.selectionsUpdated) {
      this.parent.selectionsUpdated(this);
    }
  }

  public setOpenedItem(item: ListItem): void {
    this.openedItem = item;
    this.changeDetector.markForCheck();
  }

  public getOpenedItem(): ListItem {
    return this.openedItem;
  }

  public setSelectedItem(item: ListItem): void {
    this.selections = [item];
    this.noneSelected = false;
    this.allSelected = this.list.length===1;
    this.changeDetector.markForCheck();
  }

  public handleFilterChange(searchFilter: string, selKey: string): boolean {
    if (this.desc && this.desc.type==='lookups') {
      this.reloading = true;
      this.loadLookupList(searchFilter, selKey);
      this.changeDetector.markForCheck();
    }
    return false;
  }

  public canHaveFilters(): boolean {
    return kNoFiltersSchemas.indexOf(this.schemaId) === -1 && (Util.RestAPI.canHaveFilters(this.desc) || (!!this.set && !!this.set.hasfacets));
  }

  public loadList(forceReload: boolean=false, start?: number, max?: number): void {
    if (this.desc && (this.desc.type!=='lookups') && (this.desc.type!=='security')) {
      let postData: any;
      const isPostRequest: boolean = this.isPostRequest();
      if (isPostRequest) {
        postData = this.dataService.getSearchData(this.desc);
        if (!postData && this.set && this.set.search && this.set.search.criteria) {
          const name: string = (this.desc as any).DOCNAME || '';
          postData = {DESCRIPTION: name, criteria: this.set.search.criteria};
        }
        postData = postData || {};
      }
      if (forceReload && this.paginator && this.paginator.reloading) {
        this.paginator.reloading();
      }
      const initialList: any = this.getInitialList();
      const loadIt = () => {
        if (this.schema) {
          super.loadList(forceReload,start,max,postData,initialList);
        } else {
          setTimeout(() => {
            loadIt();
          }, 100);
        }
      };
      loadIt();
    }
  }

  public isImageHeader(imgPath: string): boolean {
    return typeof imgPath !== 'undefined';
  }

  public getTrusteeAccess(trusteeRowId: number, trusteeRightId: number): number {
    return Util.RestAPI.getTrusteeAccess(this.list[trusteeRowId], trusteeRightId);
  }

  public setTrusteeList(list: ListItem[]): void {
    this.list = [];
    this.addTrusteeToList(list);
  }

  public addTrusteeToList(list: ListItem[]): boolean {
    let newTrusteeOrGroupAdded = false;
    const defRights = this.desc.type === 'searches' ? AccessSearch.VIEW_EDIT_DELETE : AccessLevel.ACCESS_LEVEL_FULL;
    if (!list) {
      const li: any = {
        flag: 2,
        USER_ID: Util.RestAPI.getUserID(),
        FULL_NAME: Util.RestAPI.getUserFullName(),
        rights: defRights
      };
      this.list.push(li);
    } else {
      for (const listItem of list) {
        if (listItem['flag']) {
          if (!(this.list.find(x=>x['USER_ID'] === listItem['USER_ID']))) {
            this.list.push(listItem);
            newTrusteeOrGroupAdded = true;
          }
        } else {
          const li: any = {
            flag: listItem['USER_ID'] ? 2 : 1,
            USER_ID: listItem['USER_ID'] ? listItem['USER_ID'] : listItem['GROUP_ID'],
            FULL_NAME: listItem['FULL_NAME'] ? listItem['FULL_NAME'] : listItem['GROUP_NAME'],
            rights: defRights
          };
          if (!(this.list.find(x=>x['USER_ID'] === (listItem['USER_ID'] || listItem['GROUP_ID'])))) {
            this.list.push(li);
            newTrusteeOrGroupAdded = true;
          }
        }
      }
    }
    this.set = {
      max: 25,
      ascending: 'USER_ID',
      filter: {},
      start: 0,
      descending: null,
      total: this.list.length
    };
    this.listReplaced();
    return newTrusteeOrGroupAdded;
  }

  public setTrusteeAccess(trusteeRowId: number, nMask: number, trusteeCurrentStatus: number,event?: Event): void {
    const trustee: ListItem = this.list[trusteeRowId];
    const security: SecurityControl = Util.RestAPI.setTrusteeAccess(this.desc, trustee, nMask, trusteeCurrentStatus);
    if (!!security) {
      this.security = security;
      this.detailviewnotify.emit(true);
    }
    if (!!event) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  public fetchMoreItems(): void {
    if (!this.noneSelected) {
      this.savedSelectState = new SelectState(this.allSelected, this.selections);
    }
    super.fetchMoreItems();
  }

  public listReplaced(): void {
    super.listReplaced();
    this.localSortList(); // will only sort if local sort so safe to call
    if (this.allExpanded) {
      this.allExpanded = false;
      this.headerExpandClick();
    }
    if (this.paginator) {
      this.paginator.targetUpdated(this.listService.start);
    }
    if (this.parent && this.parent.listUpdated) {
      this.parent.listUpdated(this);
    }
    this.changeDetector.markForCheck();
    if (!!this.savedSelectState) {
      setTimeout(() => {
        this.allSelected = this.savedSelectState.all;
        if (this.allSelected) {
          this.selections = Array.from(this.list);
        } else {
          this.selections = Array.from(this.savedSelectState.list);
        }
        this.noneSelected = this.selections.length===0;
        this.savedSelectState = null;
        this.updateSelectString();
        if (this.parent && this.parent.selectionsUpdated) {
          this.parent.selectionsUpdated(this);
        }
      }, 1);
    }
    if (this.desc && this.desc.type==='lookups') {
      if (!!this.set.max && !!this.set.total && this.set.max === this.set.total) {
        this.infoMessage = this.localizer.getTranslation('FORMS.LOOKUPS.INFO_MORE',[this.set.max.toString()]);
      } else {
        this.infoMessage = '';
      }
    }
  }

  public getSelections(security?: boolean): ListItem[] {
    let selections: ListItem[] = [];
    if (this.selections.length > 0) {
      if (!!security && ['GROUP_ID', 'USER_ID'].indexOf(this.lookupKey) !== -1) {
        const selectedItems = this.trusteeSelections;
        if (!!selectedItems && !!selectedItems.length) {
          const primaryValue: string[] = !!this.selectedLookupValues ? this.selectedLookupValues.split('|') : [];
          for (const item of selectedItems) {
            const exists = primaryValue.filter(p => Util.Transforms.isCaseInsensitiveEqual(p, item[this.lookupKey] || '')).length > 0;
            if (exists) {
              selections.push(item);
            }
          }
        }
      } else {
        selections = selections.concat(this.selections);
      }
    } else if (this.hoverListItem) {
      selections.push(this.hoverListItem);
    }
    return selections;
  }

  public getField(fieldName: string): any {
    if (this.parent.getField) {
      return this.parent.getField(fieldName);
    }
  }

  public getFilterColumnsFromSchema(): any[] {
    if (this.schema && this.schema.columns) {
      const filterCols: any[] = [];
      const cols: any = this.schema.columns;
      const nCols: number = cols.length;
      const nNonSchemaCols: number = this.leadingColums ? this.leadingColums.length : 0;
      for (let i=nNonSchemaCols; i<nCols; i++) {
        filterCols.push(cols[i]);
      }
      return filterCols;
    }
    return null;
  }

  public getLatestVerionItem(): ListItem {
    let latestVersion: ListItem = null;
    for (const curVers of this.list) {
      if (!latestVersion) {
        latestVersion = curVers;
      } else if (parseInt(curVers['VERSION_ID']) > parseInt(latestVersion['VERSION_ID'])) {
        latestVersion = curVers;
      }
    }
    return latestVersion;
  }

  public getHoverItem(): ListItem {
    return this.hoverListItem;
  }

  public isLookupAdminPage(): boolean {
    return Util.RestAPI.isAdminPage();
  }

  public isMultiselectItem(): boolean {
    return !!this.schema && !!this.schema.columns && this.schema.columns.length > 0 && this.schema.columns[0].property === 'select';
  }

  public getPrimaryColumn(): string {
    const index: number = this.leadingColums ? this.leadingColums.length : 0;
    return this.schema && this.schema.columns && this.schema.columns.length>index ? this.schema.columns[index].property : null;
  }

  public getSchema(): SchemaDef {
    return this.schema;
  }

  public getSearchSchemaForm(): string {
    return this.desc.type === 'searches' && !!this.desc.id ? this.schemaForm : null;
  }

  public changedSchema(): void {
    this.onResize();
    this.changeDetector.markForCheck();
  }

  private saveLocalCustomSchema(schema: SchemaDef): void {
    if (!!this.lookupKey) {
      this.schemaService.saveCustomSchema(schema, this.desc.id, this.desc.lib, this.lookupForm, this.formType, this.lookupKey);
    } else {
      this.schemaService.saveCustomSchema(schema, this.schemaId, this.desc.lib, this.schemaForm, null, null);
    }
  }

  public saveSchema(): void {
    this.saveLocalCustomSchema(this.schema);
    this.changedSchema();
  }

  public revertSchema(): void {
    this.saveLocalCustomSchema(null);
    this.loadSchema(this.schemaId, this.desc.lib);
    this.changedSchema();
    this.setSorting();
    this.resort(this.paginator ? this.paginator.getCurrentPage() : 1);
  }

  private loadSchema(id: string, lib: string): void {
    const changed: boolean = this.schemaId !== id;
    this.schemaId = id;
    this.schema = this.schemaService.getSchema(this.schemaId, lib, this.schemaForm, this.desc);
    this.validateSearchColumnForSearchForm(this.desc);
    this.setSortParams(this.schema.sortAscending, this.schema.sortDescending);
    for (const column of this.schema.columns) {
      column.label = this.localizer.getTranslation(column.label);
    }
    if (changed) {
      setTimeout(() => {
        this.changedSchema();
      }, 300);
    }
  }

  private validateSearchColumnForSearchForm(desc: any): void {
    if (!!desc && desc.type === 'searches' && !!desc.id && !!this.schemaForm) {
      const wrongColumn: string = Util.FieldMappings.getSearchFormWrongTypistColumn(this.schemaForm);
      const wrongColumnIndex = this.schema.columns.findIndex(column => column.property === wrongColumn);
      if (wrongColumnIndex !== -1) {
        this.schema.columns.splice(wrongColumnIndex, 1);
        const searchColumn: string = Util.FieldMappings.getSearchFormTypistColumn(this.schemaForm);
        let searchColumnIndex = this.schema.columns.findIndex(column => column.property === searchColumn);
        if (searchColumnIndex === -1) {
          this.schemaService.getAllColumns(this.schemaForm, desc.lib).then(allColumns => {
            if (!!allColumns && allColumns.length) {
              searchColumnIndex = allColumns.findIndex(column => column.property === searchColumn);
              if (searchColumnIndex !== -1) {
                this.schema.columns.splice(-1, 0, allColumns[searchColumnIndex]);
              }
            }
          });
        }
      }
    }
  }

  public openMetadata(containerName: string, tab?: string, queryArgs?: string): void {
    const displayList: ListItem[] = this.getMetadataDisplayList(tab);
    const selectedItem: ListItem = this.selections.length ? this.selections[0] : this.hoverListItem;
    this.listService.openMetadata(displayList, this.set, this.containerRights(), selectedItem, tab, queryArgs);
  }

  public openContainerMetadata(container: ListItem): void {
    this.listService.openMetadata([container], null, this.containerRights(), container);
  }

  private getMetadataDisplayList(tab: string): ListItem[] {
    const tableList: ListItem[] = this.selections.length>1 ? this.selections : this.list;
    return tableList.filter(i => (i.type!=='searches' || tab==='security') && i.type!=='flexfolders' && i.type!=='urls');
  }

  // Formatters
  protected formatString(item: ListItem, property: string, displayMap?: any[]): string {
    property = Util.Transforms.trimEnd(property, Util.redundantPropertyPostfix);
    let value: string = Util.RestAPI.getPropValue(item, property) as string;
    if (property === 'id') {
      if (isNaN(+value)) {
        value = '';
      }
    } else if (property === 'SECURITY') {
      if (value === '0') {
        value = this.localizer.getTranslation('SECURITY.DOCUMENT_SECURE.NO');
      } else if (!!value) {
        value = this.localizer.getTranslation('SECURITY.DOCUMENT_SECURE.YES');
      }
    } else if (property === 'PARENTMAIL_ID') {
      let threadID ='';
      let hex: string;
      if (value) {
        try {
          const binaryStr: string = atob(value);
          const len: number = binaryStr ? binaryStr.length : 0;
          const start: number = len > 6 ? (len - 6) : 0;
          for (let i=start; i<len; i++) {
            hex = binaryStr.charCodeAt(i).toString(16);
            if (hex.length === 1) {
              hex = '0' + hex;
            }
            threadID += hex;
          }
          if (threadID.length) {
            value = threadID;
          }
        } catch (e) {}
      }
    } else if (property === 'VS.VERSION_LABEL') {
      if (item['FI.VERSION_TYPE'] === 'R') {
        value = this.localizer.getTranslation('FOLDER_VERSION_TYPE.CURRENT');
      }
      if (item['FI.VERSION_TYPE'] === 'P') {
        value = this.localizer.getTranslation('FOLDER_VERSION_TYPE.PUBLISHED');
      }
    }
    if (!value && !!item['lang_key_'+property] && this.localizer.translationExists(item['lang_key_'+property])) {
      value = item[property] = this.localizer.getTranslation(item['lang_key_'+property]);
    }
    if (property === 'ACTIVITY_TYPE') {
      value = this.formatAction(item);
    }

    if (!!displayMap && displayMap.length > 0) {
      const option = displayMap.find(d => d.value === value);
      value = !!option ? option.display : value;
    }
    return value !== undefined ? value : '-';
  }

  protected formatEnum(item: ListItem, property: string, colIndex: number): string {
    const column = this.schema.columns[colIndex];
    return column.enums[Util.RestAPI.getPropValue(item, property)];
  }

  protected formatNumber(item: ListItem, property: string, colIndex: number): string {
    let outStr: string = Util.RestAPI.getPropValue(item, property) as string;
    if (!this.showExtras && this.fieldDataList && this.fieldDataList.length===1) {
      const column = this.schema.columns[colIndex];
      outStr = outStr || '';
      switch (property) {
      case 'id': outStr = column.label + ' ' + outStr; break;
      case 'VERSION_ID': outStr = column.label + ' ' + outStr; break;
      }
    }
    return outStr;
  }

  protected formatDate(item: ListItem, property: string, dateOnly?: boolean): string {
    let dateStr: string = item[property+'__cached'];
    if (!dateStr) {
      dateStr = Util.Transforms.formatDate(Util.RestAPI.getPropValue(item, property) as string, dateOnly);
      // cache it as date transforms are VERY expensive and will slow down rendering
      item[property+'__cached'] = dateStr;
    }
    return dateStr;
  }

  protected getFavoriteImageToolTip(item: ListItem): string {
    if (!item['is_favorite']) {
      return this.localizer.getTranslation('TOOLTIP.ADD_FAVORITE');
    } else {
      return this.localizer.getTranslation('TOOLTIP.REMOVE_FAVORITE');
    }
  }

  protected showFavorite(listitem: ListItem, colProperty: string): boolean {
    return (listitem.id!== '' || listitem.lib!== '') && listitem.type !== 'urls' && colProperty === 'is_favorite';
  }

  protected IsFavoriteItem(item: ListItem): boolean {
    return item['is_favorite'];
  }

  protected formatTypeIcon(item: ListItem): string {
    if (this.desc && this.desc.type==='lookups' && (this.desc.id==='_GROUPS_ENABLED' || this.desc.id==='_GROUP_MANAGER')) {
      return Util.Transforms.iconUrlFromDesc({APP_ID: this.desc.id});
    } else if (this.desc && (this.desc as any).params==='security') {
      if (item['flag']==='2') {
        return Util.Transforms.iconUrlFromDesc({APP_ID: '_GROUP_MANAGER'});
      } else {
        return Util.Transforms.iconUrlFromDesc({APP_ID: '_GROUPS_ENABLED'});
      }
    }
    return Util.Transforms.iconUrlFromDesc(item);
  }

  protected formatTypeText(item: ListItem): string {
    if (this.desc && this.desc.type==='lookups' && (this.desc.id==='_GROUPS_ENABLED' || this.desc.id==='_GROUP_MANAGER')) {
      return Util.Transforms.iconAltTextFromDesc({APP_ID: this.desc.id});
    } else if (this.desc && (this.desc as any).params==='security') {
      if (item['flag']==='2') {
        return Util.Transforms.iconAltTextFromDesc({APP_ID: '_GROUP_MANAGER'});
      } else {
        return Util.Transforms.iconAltTextFromDesc({APP_ID: '_GROUPS_ENABLED'});
      }
    }
    return Util.Transforms.iconAltTextFromDesc(item);
  }

  protected formatExtrasRow(item: ListItem): string {
    if (!!item['COMMENTS']) {
      return this.formatString(item,'COMMENTS');
    } else if (!!item['ACTIVITY_TYPE'] && !!item['REF_DOCUMENT']) {
      if (item['ACTIVITY_TYPE'] === 45 || item['ACTIVITY_TYPE'] === 46) {
        return item['REF_DOCUMENT'] + ', ' + (item['REF_LIB'] || item.lib) + ', ' + item['AUTHOR_ID'];
      } else {
        return item['REF_DOCUMENT'] + ', ' + (item['REF_LIB'] || item.lib);
      }
    }
    return '';
  }

  protected formatChicklet(item: ListItem): string {
    if (!!item['DOCNAME']) {
      return item['DOCNAME'];
    }
    if (!!this.schema && !!this.schema.columns && this.schema.columns.length) {
      for (const col of this.schema.columns) {
        if (col.format===ColFormat.STRING && !!item[col.property]) {
          return item[col.property];
        }
      }
    }
    return '';
  }

  protected labelExtrasRow(item: ListItem): string {
    if (!!item['COMMENTS']) {
      return this.localizer.getTranslation('METADATA.FOOTER.VERSIONS.COMMENT') + ': ';
    } else if (!!item['ACTIVITY_TYPE']) {
      return this.localizer.getTranslation('HISTORY_ACTIONS.' + item['ACTIVITY_TYPE']) + ': ';
    }
    return '';
  }

  protected showExtrasRow(item: ListItem): boolean {
    return !!item['COMMENTS'] || item['ACTIVITY_TYPE']===17 || item['ACTIVITY_TYPE']===18 || item['ACTIVITY_TYPE']===45 || item['ACTIVITY_TYPE']===46;
  }

  protected isHistoryExtrasRow(item: ListItem): boolean {
    return item['ACTIVITY_TYPE']===17 || item['ACTIVITY_TYPE']===18 || item['ACTIVITY_TYPE']===45 || item['ACTIVITY_TYPE']===46;
  }

  protected convertToFileSize(numStr: string): string {
    const size = Number(numStr);
    let result = '';

    if (size === 0) {
      result = '0 B';
    } else if (isNaN(size)) {
      result = '---';
    } else {
      let exp: number = Math.floor(Math.log(size) / Math.log(1024));
      if (exp > 4) {
        exp = 4;
      }
      if (exp < 0) {
        exp = 0;
      }
      const scaled: string = (size / Math.pow(1024, exp)).toFixed(2);
      result = scaled + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][exp];
    }
    return result;
  }

  protected displayMiniStatus(item: ListItem, property: string): boolean {
    return Util.RestAPI.displayMiniStatus(item, this.desc, property);
  }

  protected displayTopMiniStatus(item: ListItem): boolean {
    return Util.RestAPI.displayTopMiniStatus(item, this.desc);
  }

  protected formatStatusIcon(item: ListItem): string {
    return Util.RestAPI.formatStatusIcon(item, this.desc);
  }

  protected formatTopStatusIcon(item: ListItem): string {
    return Util.RestAPI.formatTopStatusIcon(item, this.desc);
  }

  protected formatStatusText(item: ListItem): string {
    let status: number | string = item['STATUS'];
    if (typeof status === 'number') {
      status = status.toString();
    }
    let stat: string = status;
    switch (status) {
      case '0':
        if (this.schemaId === 'VERSIONS') {
          return '';
        }
        stat = 'DOC_STATUS.AVAILABLE';
        if (item['RECORD'] === 'Y') {
          stat = 'DOC_STATUS.RECORD';
        }
        break;
      case '1':
        stat = 'DOC_STATUS.DOCEDIT';
        break;
      case '2':
        stat = 'DOC_STATUS.PROFILEEDIT';
        break;
      case '3':
        stat = 'DOC_STATUS.CHECKEDOUT';
        break;
      case '4':
        stat = 'DOC_STATUS.NOTAVILABLE';
        break;
      case '5':
        stat = 'DOC_STATUS.INDEXING';
        break;
      case '6':
        stat = 'DOC_STATUS.ARCHIVED';
        break;
      case '16':
        stat = 'DOC_STATUS.ARCHIVING';
        break;
      case '18':
        stat = 'DOC_STATUS.DELETED';
        break;
      case '19':
        stat = 'DOC_STATUS.READONLY';
        if (item['RECORD'] === 'Y') {
          stat = 'DOC_STATUS.RECORD';
        }
        break;
      case '20':
        stat = 'DOC_STATUS.PUBLISHED';
        break;
    }
    return this.localizer.getTranslation(stat);
  }

  protected formatAccess(numStr: string, propName: string, colIndex: number): string {
    const outStr: string = numStr;
    return outStr;
  }

  protected formatPath(pathStr: string): string {
    return pathStr;
  }

  protected formatAction(item: ListItem): string {
    const actionNumber = item['ACTIVITY_TYPE'];
    const activityDesc: string = item['ACTIVITY_DESC'];
    const index: number = +actionNumber;
    let result = '';

    if (!this.historyActions) {
      this.historyActions = [];
      for (let actionIndex = 0; actionIndex <= Util.kMaxActivityTypes; actionIndex++) {
        this.historyActions.push(this.localizer.getTranslation('HISTORY_ACTIONS.' + actionIndex));
      }
    }
    if (index >= 0 && index < this.historyActions.length) {
      if ((this.desc.type === 'fileplans' || actionNumber === 49) && activityDesc && parseInt(activityDesc) !== -1) {
        result = this.historyActions[index] + ': ' + (actionNumber === '45' || actionNumber === '46' ? this.formatExtrasRow(item) : activityDesc);
      } else if ((this.desc.type === 'boxes' && (actionNumber === '65' || actionNumber === '67')) && activityDesc) {
        result = activityDesc + ' ' + this.historyActions[index];
      } else if (index === 0 && activityDesc) {
        result = activityDesc;
      } else {
        result = this.historyActions[index];
      }
    }
    return result;
  }

  protected formatProgress(item: ListItem): number {
    let progress: number = item['relevance'];
    if (!progress) {
      progress = 0.0;
    }
    return progress;
  }

  protected displayStatusIcon(item: ListItem): boolean {
    return Util.RestAPI.displayStatusIcon(item, this.desc);
  }

  protected statusTextItalic(item: ListItem): boolean {
    let status: number | string = item['STATUS'];
    if (typeof status === 'number') {
      status = status.toString();
    }
    const stat: string = status;
    switch (status) {
      case '2':
      case '3':
      case '5':
      case '6':
      case '16':
      return true;
    }
    return false;
  }

  protected statusTextRed(item: ListItem): boolean {
    let status: number | string = item['STATUS'];
    if (typeof status === 'number') {
      status = status.toString();
    }
    const stat: string = status;
    switch (status) {
      case '1':
      case '4':
      case '19':
      return true;
    }
    return false;
  }

  protected statusTextBlue(item: ListItem): boolean {
    let status: number | string = item['STATUS'];
    if (typeof status === 'number') {
      status = status.toString();
    }
    const stat: string = status;
    switch (status) {
      case '0':
      return true;
    }
    return false;
  }

  protected listItemTooltip(listItem: ListItem, propName: string): string {
    let tip: string;
    if (listItem.type==='urls' && propName==='DOCNAME') {
      tip = listItem['URL_ADDRESS'];
    }
    return tip && tip.length ? tip : '';
  }

  // handle click on select icon
  public headerSelectClick(isButton?: boolean): void {
    if (this.noneSelected || (isButton && !this.allSelected)) {
      // none to all
      this.noneSelected = false;
      this.allSelected = true;
    } else {
      // all or some to none
      this.allSelected = false;
      this.noneSelected = true;
    }
    this.okDisabled = this.noneSelected;
    this.updateSelectedLookupValues(true);
    this.selections = [];
    if (this.allSelected) {
      const filteredList: ListItem[] = this.list.filter(this.canSelectItem.bind(this));
      this.selections = this.selections.concat(filteredList);
      this.updateSelectedLookupValues();
    }
    this.hideHoverMenu(this.hoverItemIndex);
    if (this.parent && this.parent.selectionsUpdated) {
      this.parent.selectionsUpdated(this);
    }
    this.selectionsList.emit(this.selections);
  }

  protected selectorClick(item: ListItem, event: Event, target?: any): void {
    if (this.canSelectItem(item)) {
      const idx: number = this.selections.indexOf(item);
      if (idx >= 0) {
        this.selections.splice(idx, 1);
        if (!!this.desc && this.desc.type==='lookups') {
          this.removeUnselectedLookupValue(item[this.lookupKey]);
        }
      } else {
        if (!!this.desc && this.desc.type==='lookups' && (!this.isMultiselectItem() || this.isLookupAdminPage())) {
          this.selections = [];
        }
        this.selections.push(item);
      }
      this.noneSelected = (this.selections.length === 0);
      this.okDisabled = this.noneSelected;
      this.allSelected = (this.selections.length > 0 && (this.selections.length === this.list.length));
      this.hideHoverMenu(this.hoverItemIndex);
      this.updateSelectedLookupValues();
      if (this.parent && this.parent.selectionsUpdated) {
        this.parent.selectionsUpdated(this);
      }
    }
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.selectionsList.emit(this.selections);
  }

  protected checkboxClick(listitem: ListItem, property: string, event: Event): void {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (this.parent && this.parent.checkboxClick) {
      this.parent.checkboxClick(this, listitem, property);
    }
  }

  public isSelectable(item?: ListItem) {
    return this.canSelectItem(item);
  }

  protected canSelectItem(item: ListItem): boolean {
    if (this.schema && this.schema.notSelectable) {
      return false;
    } else if (item) {
      if (!!item['PD_PART_STATUS'] && item['PD_PART_STATUS'].startsWith('Destroy')) {
        return false;
      }
      if (item.type==='searches' && item.id.startsWith('DV-%DV_SEARCH') ) {
        return false;
      }
      if (this.schemaId === 'WHERE_USED' && item.type === 'libraries') {
        return false;
      }
      return !this.parent || !this.parent.canSelectListItem || this.parent.canSelectListItem(this, item);
    }
    return true;
  }

  public headerExpandClick(event?: Event): void {
    this.allExpanded = !this.allExpanded;
    this.expandedItems = [];
    if (this.allExpanded) {
      this.expandedItems = this.expandedItems.concat(this.list);
    }
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  protected expanderClick(item: ListItem, event: Event) {
    const idx: number = this.expandedItems.indexOf(item);
    if (idx >= 0) {
      this.expandedItems.splice(idx, 1);
    } else {
      this.expandedItems.push(item);
    }
    this.allExpanded  = (this.expandedItems.length === this.list.length);
    event.stopPropagation();
    event.preventDefault();
  }

  protected localSortList(): boolean {
    const localSort: boolean = Util.isSharedDownloads(this.desc) || Util.isSharedImports(this.desc) || (this.schema && this.schema.sortControl===SortControl.LOCAL && this.allItemsIn);
    if (localSort && (this.descending || this.ascending)) {
      const reverse = !!this.descending;
      let key: string = this.ascending ? this.ascending : this.descending;
      const colDesc: any = this.getColDesc(key);
      const extAppInfo: any = Util.RestAPI.findExternalApp(this.desc.lib);
      if (!!extAppInfo && (['teams', 'onedrive'].indexOf(extAppInfo['apptype']) >= 0)  && key === 'sizeInKB') {
        key = 'size';
        colDesc.format = ColFormat.NUMBER;
      }
      if (colDesc) {
        this.list.sort((a: ListItem, b: ListItem) => {
          let result = 0;
          if (colDesc.format===ColFormat.NUMBER || colDesc.format===ColFormat.STATUS || colDesc.format===ColFormat.RIGHTS || colDesc.format===ColFormat.EXPANDER || colDesc.format===ColFormat.PROGRESS) {
            result = parseInt(a[key]) > parseInt(b[key]) ? 1 : -1;
          } else if (colDesc.format===ColFormat.STRING) {
            const aStr: string = a[key];
            const bStr: string = b[key];
            result = (aStr && bStr) ? aStr.localeCompare(bStr) : bStr ?  1 : aStr ? -1 : 0;
          } else {
            result = a[key] > b[key] ? 1 : -1;
          }
          return reverse ? -result : result;
        });
        return true;
      }
    }
    return false;
  }

  private maxLoadForSortList(): number {
    if (this.bIsPaged || this.pageSize > this.list.length) {
      return this.pageSize;
    }
    return this.list.length;
  }

  private resortOrNavToPage(pageNumber: number, isSort: boolean): void {
    this.paginationMax = isSort ? this.maxLoadForSortList() : this.pageSize;
    this.paginationStart = isSort ? 0 : this.pageSize * (pageNumber - 1); // Back to the first page if the user changes sort.
    let forceReload = false;
    if (!this.localSortList()) {
      this.changeDetector.markForCheck();
      // Localy sorted lists that are incomplete and have start of 0 means "reverse sort order"
      // so we have to go back to the server for the "other" end of the list
      if (this.paginationStart === 0 && !this.allItemsIn && this.schema && this.schema.sortControl === SortControl.LOCAL) {
        forceReload = true;
      }
      this.loadList(forceReload || isSort, this.paginationStart, this.paginationMax);
    }
    //Fetch the container element (from Grid or Summary view) and scroll to top
    const containerElement: any = this.getContainerElement();
    if (containerElement && !!containerElement.scrollTo) {
      containerElement.scrollTo(0, 0);
    }
  }

  protected resort(pageNumber: number): void {
    this.resortOrNavToPage(pageNumber, true);
  }

  protected headerColumnClick(event: Event, prop: string): void {
    if (this.didColumnDrag) {
      this.didColumnDrag = false;
    } else if (prop === 'expand') {
       this.headerExpandClick(event);
    } else if (prop !== 'select' && this.schema.sortControl !== SortControl.NONE) {
      if (!this.set || !this.set.limitedsorting || this.set.limitedsorting.indexOf(prop)!==-1) {
        if (this.getSortCol() === prop) {
          if (this.ascending) {
            this.descending = this.ascending;
            this.ascending = null;
          } else {
            this.ascending = this.descending;
            this.descending = null;
          }
        } else {
          if (this.schemaColIsDefaultSortAscending(prop)) {
            this.ascending = prop;
            this.descending = null;
          } else {
            this.descending = prop;
            this.ascending = null;
          }
        }
        this.resort(this.paginator ? this.paginator.getCurrentPage() : 1);  // no paginator on mobile
      }
    }
  }

  protected isTableSortable(): boolean {
    return this.schema.sortControl !== SortControl.NONE;
  }

  protected listItemDblClick(item: ListItem, event: Event) {
    let handled = false;
    if (this.parent) {
      const wasSelected: boolean = this.selections.indexOf(item)!==-1;
      if (!wasSelected) {
        this.selectorClick(item, event);
      }
      if (this.parent.handleListItemDblClick) {
        handled = this.parent.handleListItemDblClick(this, item, event, null);
      }
    }
    if (handled && event) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  protected listItemClick(item: ListItem, event: Event, property: string) {
    let handled = false;
    if (this.itemClickAction===ItemClickAction.parent || this.itemClickAction === ItemClickAction.none) {
      if (this.parent && this.parent.handleListItemClick) {
        handled = this.parent.handleListItemClick(this, item, event, property);
      }
    }
    if (!handled && !this.hasFootprint) {
      if (this.itemClickAction===ItemClickAction.select) {
        this.selections = [];
        this.selectedLookupValues = '';
        this.selectorClick(item, event);
        handled = true;
      } else {
        if (this.canOpenListItem(item) && kOpenableProperties.indexOf(property) !== -1) {
          this.listItemClicked(item, event);
          handled = true;
        }
      }
    }
    if (handled) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  protected listItemRowClick(item: ListItem, event: Event): void {
    if (this.itemClickAction === ItemClickAction.parent && this.parent && this.parent.handleListItemClick && this.parent.handleListItemClick(this, item, event, null)) {
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }
    } else if (item.lib && Util.isExternalLib(item.lib) && !!(item as any).url) {
      Util.Help.openURL((item as any).url, item.DOCNAME, event);
    } else if (['FLEXFOLDERS_ROOT', 'ACTIVITY', 'ACTIVITY_AUTHOR'].indexOf(this.schemaId) !== -1) {
      this.selections = [];
      this.selectedLookupValues = '';
    } else {
      this.selectorClick(item, event);
    }
  }

  // hover menu
  protected canShowHoverMenu(): boolean {
    return this.selections.length === 0 && !this.dragover && !this.hasFootprint;
  }

  protected enableHoverMenu(index: number): void {
    if (this.canShowHoverMenu()) {
      if (this.hoverItemIndex !== index && !this.dragover) {
        this.hoverListItem = null;
        this.hoverItemIndex = index;
        if (this.hoverOpenTimer) {
          window.clearTimeout(this.hoverOpenTimer);
        }
        this.hoverOpenTimer = window.setTimeout(() => {
          this.showHoverMenu();
        }, 600);
      } else {
        this.hoverItemHidingIndex = -1;
      }
    }
  }

  protected disableHoverMenu(index: number): void {
    this.hoverItemHidingIndex = index;
    setTimeout(() => {
      if (this.hoverItemHidingIndex === index) {
        this.hideHoverMenu(index);
      }
    }, 200);
  }

  protected showHoverMenu(): void {
    this.hoverListItem = this.list[this.hoverItemIndex];
    if (this.parent && this.parent.hoverItemUpdated) {
      this.parent.hoverItemUpdated(this);
    }
    const menu: InlineActionBarComponent = this.inlineMenus.toArray()[this.hoverItemIndex];
    if (menu) {
      menu.desc = this.desc;
      menu.listItem = this.hoverListItem;
      menu.updateActionItems();
    }
    this.changeDetector.markForCheck();
  }

  protected hideHoverMenu(index: number): void {
    if (this.hoverItemIndex === index) {
      this.hoverListItem = null;
      this.hoverItemIndex = -1;
      if (this.parent && this.parent.hoverItemUpdated) {
        this.parent.hoverItemUpdated(this);
      }
      this.changeDetector.markForCheck();
    }
  }

  public showHitPreview(item: ListItem, nextPrev?: number): void {
    const previewID: string = item ? item['preview']['id'] : this.hitPreviewHoverID ? this.hitPreviewHoverID : null;
    const criteria: any = this.set.criteria;
    const criteriaKeys: string[] = criteria ? Object.keys(criteria) : [];
    let queryValue = '';
    for (const criteriaKey of criteriaKeys) {
      if (Util.RestAPI.kFullTextTypes.indexOf(criteriaKey) >= 0) {
        queryValue = criteria[criteriaKey];
        break;
      }
    }
    if (!this.hitPreviewShown) {
      this.hitPreviewShown = true;
      this.hitPreviewShowing = true;
      this.changeDetector.markForCheck();
    }
    if (previewID && (previewID !== this.hitPreviewHoverID || !this.hitPreviewData || nextPrev !== undefined)) {
      const page: number = (nextPrev !== undefined && !!this.hitPreviewData) ? (parseInt(this.hitPreviewData.page) + nextPrev) : null;
      const query: string = '&query='+encodeURIComponent(queryValue)+(page?('&page='+page):'');
      const hitPreviewDesc: any = item || this.hitPreviewData;
      this.hitPreviewLoading = true;
      this.hitPreviewHoverID = previewID;
      if (page === null) {
        this.hitPreviewData = null;
      }
      this.changeDetector.markForCheck();
      Util.RestAPI.get(hitPreviewDesc,'previews/'+this.hitPreviewHoverID,query).subscribe((hitPreviewData: any) => {
        this.hitPreviewData = hitPreviewData;
        this.hitPreviewLoading = false;
        this.changeDetector.markForCheck();
      }, error => {
        this.hitPreviewLoading = false;
        this.hitPreviewHoverID = null;
        this.hitPreviewShown = false;
        this.changeDetector.markForCheck();
      });
    }
  }

  private hitPreviewLeave(event: MouseEvent): boolean {
    this.hitPreviewHiding = true;
    this.changeDetector.markForCheck();
    return false;
  }

  private hitPreviewAnimationComplete(): void {
    if (this.hitPreviewShowing) {
      this.hitPreviewShowing = false;
    } else if (this.hitPreviewHiding) {
      this.hitPreviewHiding = false;
      this.hitPreviewShown = false;
    }
  }

  // **** Drag Target
  private dropFolder(item: any): boolean {
    const itemRights: SecurityControl = item !== this.desc ? (new SecurityControl(item['%SECURITY'])) : null;
    return this.set.readonly !== 'Y' && Util.isUploadTarget(item) && (!itemRights || itemRights.canEditContent);
  }

  acceptDrag(event: any, element: ElementRef): boolean {
    this.dragoverHasFiles = false;
    this.dragoverHasItems = false;
    if (this.dropFolder(this.desc)) {
      if (Util.dragHasFiles(event.dataTransfer)) {
        this.dragover = true;
        this.dragoverHasFiles = true;
        return true;
      } else if (Util.dragHasJSON(event.dataTransfer)) {
        // No external files but we allow drag and drop of DM documents into folders
        this.dragoverHasItems = true;
        return true;
      }
    }
    return false;
  }

  protected getDragOverInfo(event: any): {dragChanged: boolean; rowDocName: string} {
    let target: any = event.target;
    let top: number;
    let height: number = null;
    let dragChanged = false;
    let rowDocName: string;

    while (target && target.tagName!=='TR') {
      target = target.parentElement;
    }
    if (target) {
      if (this.dragoverTR !== target) {
        this.dragLeave(null, null);
        this.dragoverTR = target;
        const bounds = this.dragoverTR.getBoundingClientRect();
        const tcbounds = this.tc.nativeElement.getBoundingClientRect();
        if (!target.classList.contains('dropfolder')) {
          dragChanged = true;
          this.dragover = this.dragoverHasFiles;
          top = 0;
          this.rdbt.nativeElement.classList.remove('row');
          this.rdbb.nativeElement.classList.remove('row');
          this.rdbr.nativeElement.classList.remove('row');
          this.rdbl.nativeElement.classList.remove('row');
        } else {
          const index: number = Array.prototype.indexOf.call(this.dragoverTR.parentNode.children, this.dragoverTR);
          if (index>=0 && index<this.list.length) {
            this.dragOverItem = this.list[index];
            rowDocName = this.dragOverItem.DOCNAME;
          }
          this.dragover = false;
          dragChanged = true;
          top = bounds.top - tcbounds.top - 3;
          this.dragoverTR.classList.add('dragover');
          this.rdbt.nativeElement.classList.add('row');
          this.rdbb.nativeElement.classList.add('row');
          this.rdbr.nativeElement.classList.add('row');
          this.rdbl.nativeElement.classList.add('row');
          height = bounds.height;
        }
        this.rdbt.nativeElement.style.top = top.toString() + 'px';
        if (height) {
          this.rdbb.nativeElement.style.top = (top + height).toString() + 'px';
          this.rdbb.nativeElement.style.bottom = '';
        } else {
          this.rdbb.nativeElement.style.bottom = '0';
          this.rdbb.nativeElement.style.top = '';
        }
        this.rdbt.nativeElement.classList.remove('edx_hidden');
        this.rdbb.nativeElement.classList.remove('edx_hidden');

        this.rdbr.nativeElement.style.top = this.rdbl.nativeElement.style.top = top.toString() + 'px';
        if (height) {
          this.rdbr.nativeElement.style.height = this.rdbl.nativeElement.style.height = height.toString() + 'px';
          this.rdbr.nativeElement.style.bottom = this.rdbl.nativeElement.style.bottom = '';
        } else {
          this.rdbr.nativeElement.style.bottom = this.rdbl.nativeElement.style.bottom = '0';
          this.rdbr.nativeElement.style.height = this.rdbl.nativeElement.style.height = '';
        }
        this.rdbl.nativeElement.classList.remove('edx_hidden');
        this.rdbr.nativeElement.classList.remove('edx_hidden');
      }
    } else {
      dragChanged = true;
    }
    return {dragChanged, rowDocName};
  }

  public dragOver(event: any, element: ElementRef): boolean {
    if (this.dragoverHasFiles || this.dragoverHasItems) {
      const info: any = this.getDragOverInfo(event);
      if (info.dragChanged) {
        if (info.rowDocName) {
          Util.RestAPI.showDropBanner(info.rowDocName, this.dragoverHasFiles ? 'HEADER.UPLOAD_FILES' : 'HEADER.DRAG_CREATE_REF');
        } else if (this.dragover && this.parent && this.parent.getName) {
          let name: string;
          if (!this.desc.id) {
            name = 'TILE_NAMES.RECENTLY_EDITED';
          } else {
            name = this.parent.getName(this);
          }
          Util.RestAPI.showDropBanner(name);
        } else {
          Util.RestAPI.showDropBanner(null);
        }
        this.changeDetector.markForCheck();
      }
      return ((this.dragover && this.dragoverHasFiles) || this.dragoverTR);
    }
    return false;
  }

  public dragLeave(event: any, element: ElementRef): void {
    this.dragover = false;
    this.dragOverItem = null;
    if (this.dragoverTR) {
      this.dragoverTR.classList.remove('dragover');
      this.dragoverTR = null;
      if (this.rdbt) {
        this.rdbt.nativeElement.classList.add('edx_hidden');
      }
      if (this.rdbl) {
        this.rdbl.nativeElement.classList.add('edx_hidden');
      }
      if (this.rdbb) {
        this.rdbb.nativeElement.classList.add('edx_hidden');
      }
      if (this.rdbr) {
        this.rdbr.nativeElement.classList.add('edx_hidden');
      }
    }
    this.changeDetector.markForCheck();
    Util.RestAPI.showDropBanner(null);
  }

  protected getDragOverIndex(): number {
    if (this.dragoverTR) {
      const index: number = Array.prototype.indexOf.call(this.dragoverTR.parentNode.children, this.dragoverTR);
      if (index>=0 && index<this.list.length) {
        return index;
      }
    }
    return null;
  }

  public drop(event: any, element: ElementRef): void {
    let intoThisFolder: ListItem = null;
    const index: number = this.getDragOverIndex();
    if (index!==null) {
      intoThisFolder = this.list[index];
      if (!this.dropFolder(intoThisFolder)) {
        intoThisFolder = null;
      }
    }
    if (this.dragoverHasFiles) {
      Util.getDropFiles(event.dataTransfer).then((items: any[]) => {
        const fileList = Util.RestAPI.removeZeroSizeFiles(items);
        const count = fileList ? fileList.length : 0;
        if (count || (!(this.dragoverHasItems && intoThisFolder) && Util.Device.bIsOfficeAddin)) {
          this.uploadFiles(fileList, intoThisFolder);
        }
       });
    } else if (this.dragoverHasItems && intoThisFolder && event.dataTransfer.items.length) { // No external files but we allow drag and drop of DM documents into folders
      const notifyTitle: string = this.localizer.getTranslation('FOLDER_ACTIONS.ADDED_TO_SINGLE', [intoThisFolder['DOCNAME']]);
      const items: DataTransferItem[] = event.dataTransfer.items;
      let jsonItem: DataTransferItem;
      if (!!items) {
        for (const item of items) {
          if (item.kind === 'string' && item.type === 'text/json') {
            jsonItem = item;
            break;
          }
        }
      }
      jsonItem.getAsString(itemJson => {
        const item: any = itemJson ? JSON.parse(itemJson) : null;
        if (item && (item.lib !== intoThisFolder.lib || item.DOCNUM !== intoThisFolder.DOCNUM)) {
          Util.RestAPI.post(item, intoThisFolder, 'references').subscribe(response => {
            this.reloadList();
            Util.Notify.success(notifyTitle);
          }, error => {
            Util.Notify.warning(this.localizer.getTranslation('RAPI_ERRORS.0'), error);
          });
        }
      });
    }
    this.dragLeave(event,element);
  }

  // **** drag source for column headings
  private thIndexAtLocation(locX: number, element: ElementRef): number {
    const th: any = element.nativeElement.parentNode;
    const tr: any = th.parentNode;
    const kids: any[] = tr.children;
    const nKids = kids.length;
    for (let i=2; i<nKids; i++) {
      const kid = kids[i];
      const x = kid.offsetLeft;
      const w = kid.offsetWidth;
      if (Util.pointInRect(locX, 1, x, 0, (w > 64 ? 64 : w), 2)) {
        return i;
      } else if (i===nKids-1) {
        if (Util.pointInRect(locX, 1, x+(w/2), 0, w, 2)) {
          return i;
        }
      }
    }
    return -1;
  }

  private firstDraggableColumnIndex(): number {
    let index = 0;
    const cols: ColumnDesc[] = !!this.schema ? this.schema.columns : [];
    if (cols.length) {
      for (const col of cols) {
        if (col.format !== ColFormat.SELECTOR && col.format !== ColFormat.OBJTYPE) {
          break;
        }
        ++index;
      }
    }
    return index;
  }

  public canDrag(x: number, y: number, element: ElementRef): boolean {
    const th: any = element.nativeElement.parentNode;
    const index: number = Array.prototype.indexOf.call(th.parentNode.children, th);
    if (index >= this.firstDraggableColumnIndex()) {
      return true;
    }
    return false;
  }

  public allowVertical(element: ElementRef): boolean {
    return false;
  }

  public preDragStart(element: ElementRef) {
    const ne = element.nativeElement;
    this.draggingColumn = ne.cloneNode(true) as HTMLDivElement;
    ne.parentNode.insertBefore(this.draggingColumn,ne);
    ne.style.height = this.tc.nativeElement.offsetHeight.toString() + 'px';
    ne.classList.add('dragger');
    this.draggingColResizer = ne.classList.contains('colresizer');
    this.changeDetector.markForCheck();
  }

  public dragMoved(x: number, y: number, element: ElementRef) {
    if (this.draggingColResizer) {
      if (this.draggedColResizerOriginalLeft === -1) {
        this.draggedColResizerOriginalLeft = x;
      }
    } else {
      this.draggingColumnDropIndex = this.thIndexAtLocation(x,element);
    }
  }

  public dragEnded(event: any, element: ElementRef) {
    const ne = element.nativeElement;
    if (event.type!=='keydown' || event.which!==27) {
      const th: any = ne.parentNode;
      const index: number = Array.prototype.indexOf.call(th.parentNode.children, th);
      if (this.draggingColResizer) {
        const newLeft: number = ne.offsetLeft;
        const oldWidth: number = th.parentNode.children[index].offsetWidth;
        const newWidth: number = oldWidth - (this.draggedColResizerOriginalLeft - newLeft);
        let totalColWidthPercent = 0;
        let col: ColumnDesc;
        const nCols: number = this.schema.columns.length - (this.columnsHidden > 0 ? this.columnsHidden : 0);
        for (let i=0; i<nCols; i++) {
          col = this.schema.columns[i];
          totalColWidthPercent += col.width || 0;
        }
        col = this.schema.columns[index];
        const colPercentOfTotal: number = col.width / totalColWidthPercent;
        const newPercent: number = Math.ceil(colPercentOfTotal * totalColWidthPercent * newWidth / oldWidth);
        const oldPercent = col.width;
        if (newPercent !== oldPercent) {
          if (col.minwidth && newPercent < oldPercent) {
            col.minwidth = Math.ceil(col.minwidth * newPercent / oldPercent);
          }
          col.width = newPercent;
          this.saveSchema();
        }
        this.changeDetector.markForCheck();
      } else if (this.draggingColumnDropIndex!==-1) {
        this.schema.columns.splice(this.draggingColumnDropIndex,0,this.schema.columns.splice(index,1)[0]);
        this.changeDetector.markForCheck();
        this.saveSchema();
      }
    }
    ne.style.height = '';
    ne.classList.remove('dragger');
    ne.parentNode.removeChild(this.draggingColumn);
    this.draggingColumn = null;
    this.didColumnDrag = true;
    this.draggingColResizer = false;
    this.draggedColResizerOriginalLeft = -1;
    setTimeout(() => {
     this.didColumnDrag = false;
    },250);
  }

  // drag directives
  private getListItemFromElement(element: ElementRef): ListItem {
    const ne = element.nativeElement;
    const index: number = !!ne.parentNode && !!ne.parentNode.children ? Array.from(ne.parentNode.children).indexOf(ne) : -1;
     //should be able to drag even the first element, so index can be zero too.
    if (index >= 0 && index < this.list.length) {
      return this.list[index];
    }
    return null;
  }

  private isDraggableFile(item: ListItem): boolean {
    if (this.canOpenListItem(item)) {
      return Util.isDraggableItem(item.type);
    }
    return false;
  }

  public getDragURL(element: ElementRef): string {
    let url: string = null;
    const draggedItem: ListItem = this.getListItemFromElement(element);
    if (this.isDraggableFile(draggedItem)) {
      if (this.selections.length > 1 && Util.RestAPI.restAPIVersion() >= 0x00160700) {
        const fileName: string = this.getDragName(element);
        const data: any = {
          filename: fileName,
          version: 'C',
          documents: this.selections.map(i => new Object({id: i.id, lib: i.lib}))
        };
        const dataStr: string = JSON.stringify(data);
        const b64Data: string = Util.Transforms.b64EncodeUnicode(dataStr);
        url = '/documents/multiple?library='+this.selections[0].lib+'&data='+encodeURIComponent(b64Data);
      } else {
        url = Util.RestAPI.makeDragURL(draggedItem);
      }
    }
    return url;
  }

  public getDragJson(element: ElementRef): string {
    let json: string = null;
    const draggedItem: ListItem = this.getListItemFromElement(element);
    if (this.isDraggableFile(draggedItem)) {
      json = JSON.stringify(draggedItem);
    }
    return json;
  }

  public getDragName(element: ElementRef): string {
    let name = '';
    if (this.selections.length > 1 && Util.RestAPI.restAPIVersion() >= 0x00160700) {
      const now = new Date();
      name = 'edocs_files'+now.getUTCSeconds()+'.zip';
    } else {
      const item: any = this.getListItemFromElement(element);
      if (item && item.DOCNAME) {
        name = Util.RestAPI.makeDragName(item);
      }
    }
    return name;
  }

  public getDragAppID(element: ElementRef): string {
    const draggedItem: ListItem = this.getListItemFromElement(element);
    return !!draggedItem ? draggedItem.APP_ID : null;
  }
  public getDragDesc(element: ElementRef): any {
    return this.getListItemFromElement(element);
  }
  public getDragPromisedFile(element: ElementRef): string {
    const draggedItem: ListItem = this.getListItemFromElement(element);
    if (Util.RestAPI.findExternalApp(this.desc.lib)) {
      return this.getDragJson(element);
    }
    return null;
  }

  // **** PaginatorTarget Implementation

  public getItemCount(): number {
    return this.getListTotal();
  }

  public getPageCount(): number {
    return Math.ceil(this.getItemCount()/this.pageSize);
  }

  public getPageSize(): number {
    return this.pageSize;
  }

  public gotoPage(pageNumber: number): void {
    this.resortOrNavToPage(pageNumber, false);
  }

  public setPageSize(pageSize: number): void {
    const oldPageSize: number = this.pageSize;
    this.pageSize = pageSize;
    if (oldPageSize < pageSize) {
      this.gotoPage(1);
    } else {
      this.list.splice(pageSize);
      this.changeDetector.markForCheck();
    }
    Util.RestAPI.setDefualtMaxItems(pageSize);
  }

  public startSearching(): void {
    this.reloading = true;
    this.listService.clearListData();
    this.changeDetector.markForCheck();
  }

  public doCommand(cmd: string): boolean {
    return false;
  }

  public commandEnabled(cmd: string): boolean {
    return false;
  }

  public updateView(): void {
    this.layoutComplete = false;
    this.schema = null;
  }

  public closePopuMenus(): void {
    this.hoverListItem = null;
  }

  public getFilterFormTemplate(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const waitFunc = () => {
        if (this.schema) {
          resolve(this.schema.filterFormTemplate);
        } else {
          setTimeout(waitFunc,500);
        }
      };
      waitFunc();
    });
  }

  public getFacets(queryArgs: string): Observable<any> {
    let postData: any;
    if (this.isPostRequest()) {
      if (!postData && this.set && this.set.search && this.set.search.criteria) {
        const name: string = (this.desc as any).DOCNAME || '';
        postData = {DESCRIPTION: name, criteria: this.set.search.criteria};
      }
    }
    return this.listService.getFacets(this.desc, postData, queryArgs);
  }

  public hasFacets(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const waitFunc = () => {
        if (this.reloading) {
          setTimeout(waitFunc,500);
        } else {
          resolve(this.set ? this.set.hasfacets : false);
        }
      };
      waitFunc();
    });
  }

  public getFacetTitles(): Promise<string[]> {
    return new Promise<string[]>((resolve, reject) => {
      const waitFunc = () => {
        if (this.reloading) {
          setTimeout(waitFunc,500);
        } else {
          resolve(this.set ? this.set.facets : null);
        }
      };
      waitFunc();
    });
  }

  public shiftFocusToCancel(event: KeyboardEvent,index: number) {
    if (!!event && index === this.list.length-1) {
      event.preventDefault();
      const element = document.getElementById('edx_hdr_btn_left');
      if (!!element) {
        element.focus();
      }
    }
  }
}

export interface ListTableParent {
  initialList?(table: ListTableComponent): ListItem[];
  initialSet?(table: ListTableComponent): any;
  getName?(table: ListTableComponent): string;
  listUpdated?(table: ListTableComponent): void;
  selectionsUpdated?(table: ListTableComponent): void;
  hoverItemUpdated?(table: ListTableComponent): void;
  handleListItemDblClick?(table: ListTableComponent, item: ListItem, event: Event, property: string): boolean;
  handleListItemClick?(table: ListTableComponent, item: ListItem, event: Event, property: string): boolean;
  canSelectListItem?(table: ListTableComponent, item: ListItem): boolean;
  checkboxClick?(table: ListTableComponent, item: ListItem, property: string): void;
  getField?(fieldName: string): any;
}
