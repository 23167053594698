/**
 * Created by kevin on 2016-12-13.
 */

import { Component, Input } from '@angular/core';


@Component({
  selector: 'edx-list-info-footer',
  styleUrls: [ 'list-info-footer.component.scss' ],
  template: `
    <div class="main">
      <div class="header" *ngIf="heading">{{heading}}</div>
      <div class="item" *ngFor="let item of displayItems">
        <div class="label" *ngIf="item.label">{{item.label}}</div>
        <span *ngIf="item.label">:</span>
        <div class="value">{{item.value}}</div>
      </div>
    </div>
  `
})
export class ListInfoFooterComponent {
  @Input() heading?: string = null;
  @Input() displayItems?: FooterDisplayItem[] = null;
}

export class FooterDisplayItem {
  label?: string;
  value: string;
}
