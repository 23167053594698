import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { AppModule } from './app/';
import * as microsoftTeams from '@microsoft/teams-js';

declare let Office;
let sbTeamsLoaded = false;
const windowConfirmOriginal = window.confirm;

const startAngular = () => {
  enableProdMode();
  platformBrowserDynamic().bootstrapModule(AppModule);
};

const getUserLanguage = () => {
  if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
    return undefined;
  }
  const nav: any = window.navigator;
  let browserLang: any = (nav.languages && nav.languages.length>0) ? nav.languages[0] : (nav.language || nav.browserLanguage || nav.userLanguage);
  if (browserLang) {
    if (browserLang.indexOf('-') !== -1) {
      browserLang = browserLang.split('-')[0];
    }
    if (browserLang.indexOf('_') !== -1) {
      browserLang = browserLang.split('_')[0];
    }
  }
  return browserLang;
};

const getRootSiteUrl = (): string  => {
  const path: string = location.pathname;
  const pathParts: string[] = path.split('/');
  pathParts.splice(pathParts.length-1, 1);
  return location.origin + pathParts.join('/');
};


const loadLang = (lang: string): Promise<void> => new Promise<void>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const url = getRootSiteUrl() + '/assets/i18n/'+lang+'.json';
    xhr.open('GET', url, true);
    xhr.onerror = reject;
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if ((xhr.status === 200 || xhr.status === 0) && !!xhr.response) {
          localStorage.setItem('edx_lang_'+lang, xhr.response);
          resolve();
        } else {
          reject('no lang');
        }
      }
    };
    xhr.send();
  });

const loadLanguages = (): Promise<void> => {
  // choose language to use
  let currentLang = 'en';
  return new Promise<void>((resolve, reject) => {
    loadLang(currentLang).then(() => {
      localStorage.setItem('edx_curlang', currentLang);
      const userLang: string = getUserLanguage();
      if (!!userLang) {
        loadLang(userLang).then(() => {
          currentLang = userLang;
          localStorage.setItem('edx_curlang', currentLang);
          resolve();
        }, err => {
          console.log(`Could not load: ${userLang} err: ${err}`);
          resolve();
        });
      } else {
        resolve();
      }
    }, reject);
  });
};

const launch = () => {
  loadLanguages().then(() => {
    startAngular();
  }, startAngular);
};

const noOfficeLaunch = () => {
  setTimeout(() => {
    (window as any).Office = undefined;
    Office = undefined;
    launch();
  }, 100);
};

const loadOffice = () => {
  const script = document.createElement('script');
  script.onerror = (error) => {
    document.head.removeChild(script);
    noOfficeLaunch();
  };
  script.onload = () => {
    window.confirm = windowConfirmOriginal;
    if (window.hasOwnProperty('Office')) {
      // Application-specific initialization code goes into a function that is
      // assigned to the Office.initialize event and runs after the Office.js initializes.
      // Bootstrapping of the AppModule must come AFTER Office has initialized.
      Office.onReady().then(info => {
        if (!!info && !!info.platform) {
          launch();
        } else {
          noOfficeLaunch();
        }
      });
    } else {
      launch();
    }
  };
  script.src = 'https://appsforoffice.microsoft.com/lib/1.1/hosted/office.debug.js';
  document.head.appendChild(script);
};

setTimeout(() => {
  // calling microsoftTeams.initialize(callback) outside of teams does NOT call callback so timeout
  if (!sbTeamsLoaded) {
    loadOffice();
  }
  sbTeamsLoaded = true;
}, 500);
microsoftTeams.initialize(() => {
  sbTeamsLoaded = true;
  noOfficeLaunch();
});
