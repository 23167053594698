import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HotkeyModule} from 'angular2-hotkeys';

import { AppComponent } from './app.component';
import { AppHeaderComponent } from './app-header.component';
import { AppBreadcrumbsComponent } from './app-breadcrumbs.component';
import { AppNavSidebarComponent } from './app-nav-sidebar.component';
import { AppNotifyDialogComponent } from './app-notify-dialog.component';
import { AppCommandsComponent } from './app-commands.component';
import { AppHomeComponent } from './app-home.component';
import { routing } from './app.routing';
import { WindowsModule } from './windows/windows.module';
import { EDXFormsModule } from './forms/edx-forms.module';
import { TilesModule } from './tiles/tiles.module';
import { ListsModule } from './lists/lists.module';
import { TranslatePipe } from './pipes/translate.pipe';
import { LocalizeService } from './services/localize.service';
import { WidgetsModule } from './widgets/widgets.module';
import { MenuService } from './services/menu.service';
import { SchemaService } from './services/schema.service';
import { FormService } from './services/form.service';
import { NavService } from './services/nav.service';
import { DataService } from './services/data.service';
import { LookupService } from './services/lookup.service';
import { SettingsService } from './services/settings.service';
import { ProfileService } from './services/profile.service';
import { DirectivesModule } from './directives/directives.module';
import { oAuth2Service } from './services/oauth2.service';
import { OOxmlService } from './services/ooxml.service';
import { ShortcutsService } from './services/shortcuts.service';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    routing,
    TilesModule,
    WidgetsModule,
    WindowsModule,
    EDXFormsModule,
    ListsModule,
    DirectivesModule,
    HotkeyModule.forRoot()
  ],
  declarations: [
    AppComponent,
    AppHeaderComponent,
    AppBreadcrumbsComponent,
    AppNavSidebarComponent,
    AppNotifyDialogComponent,
    AppCommandsComponent,
    AppHomeComponent,
    TranslatePipe
  ],
  providers: [
    LocalizeService,
    MenuService,
    SchemaService,
    FormService,
    NavService,
    DataService,
    LookupService,
    SettingsService,
    ProfileService,
    oAuth2Service,
    OOxmlService,
    ShortcutsService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [ AppComponent ]
})

export class AppModule { }
