import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WindowModalComponent } from './window-modal.component';
import { WindowModalHeaderComponent } from './window-modal-header.component';
import { WindowModalFoldersComponent } from './window-modal-folders.component';
import { WindowModalFormLoginComponent } from './window-modal-form-login.component';
import { WindowModalMetadataComponent } from './window-modal-metadata.component';
import { WindowModalSplitViewComponent } from './window-modal-splitview.component';
import { WindowViewFoldersComponent } from './window-view-folders.component';
import { WindowModalPreferencesComponent } from './window-modal-preferences.component';
import { WindowModalAdminComponent } from './window-modal-admin.component';
import { ListsModule } from '../lists/lists.module';
import { EDXFormsModule } from '../forms/edx-forms.module';
import { WidgetsModule } from '../widgets/widgets.module';
import { DirectivesModule } from '../directives/directives.module';
import { TilesModule } from '../tiles/tiles.module';

@NgModule({
  imports:      [ CommonModule, ListsModule, EDXFormsModule, WidgetsModule, DirectivesModule, TilesModule ],
  declarations: [ WindowModalComponent,
                  WindowModalHeaderComponent,
                  WindowModalFoldersComponent,
                  WindowModalFormLoginComponent,
                  WindowModalMetadataComponent,
                  WindowModalSplitViewComponent,
                  WindowViewFoldersComponent,
                  WindowModalPreferencesComponent,
                  WindowModalAdminComponent
                ],
  exports:      [ WindowModalComponent,
                  WindowModalHeaderComponent,
                  WindowModalFoldersComponent,
                  WindowModalFormLoginComponent,
                  WindowModalMetadataComponent,
                  WindowModalSplitViewComponent,
                  WindowViewFoldersComponent,
                  WindowModalPreferencesComponent,
                  WindowModalAdminComponent
                ]
})

export class WindowsModule { }
