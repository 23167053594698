import { Injectable } from '@angular/core';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { LocalizeService } from './localize.service';
import { SettingsService } from './settings.service';
import { Util } from '../utils/utils.module';
import { FormService } from './form.service';

export class KeyCommand {
  constructor(name: string, combo: string, ev: KeyboardEvent, type: string) {
    this.name = name;
    this.combo = combo;
    this.ev = ev;
    this.type = type;
  }
  name: string;
  combo: string;
  ev: KeyboardEvent;
  type: string;
}

@Injectable()
export class ShortcutsService {
  public helpFormData = {
    data: {
      fldtype: 'form',
      name: 'shortcuts',
      prompt: '',
      localonly: true,
      extrasshown: true,
      flags: 0x00080000,
      defs: []
    }
  };

  commands: Observable<KeyCommand>;
  private subject: Subject<KeyCommand>;
  private disableShortcuts = false;
  private shortcutsConfig: any;
  private shortcutsDict = {};
  public isInvalidConfig = false;

  constructor(private hotkeysService: HotkeysService, private localizer: LocalizeService, private settingsService: SettingsService) {
    this.subject = new Subject<KeyCommand>();
    this.commands = this.subject.asObservable();
    this.getShortcutsConfig().then(data => {
      if (!!data) {
        const hotkeys = data;
        if (hotkeys.length === 0) {
          this.handleConfigurationError(this.localizer.getTranslation('SHORTCUTS.NO-DATA'));
          return;
        }
        for (const keytype of hotkeys) {
          if (!keytype.type) {
            this.handleConfigurationError(this.localizer.getTranslation('SHORTCUTS.NO-TYPE'));
            break;
          }
          const localizedType = this.localizer.getTranslation('SHORTCUTS.' + keytype.type.toUpperCase());
          const isInvalidType = localizedType === 'SHORTCUTS.' + keytype.type.toUpperCase();
          if (isInvalidType) {
            this.handleConfigurationError(keytype.type + this.localizer.getTranslation('SHORTCUTS.INCORRECT-TYPE'));
            break;
          }
          this.helpFormData.data.defs.push({
            prompt: keytype.type,
            name: keytype.type,
            fldtype: 'text',
            flags: 0x00080000,
            value: localizedType
          });
          for (const key in keytype.keys) {
            const command = keytype.keys[key];
            const localizedCmd = this.localizer.getTranslation('SHORTCUTS.' + command.toUpperCase());
            const isInvalidCommand = localizedCmd === 'SHORTCUTS.' + command.toUpperCase();
            const isValidCombo = (key.length === 1 || (key.length === 3 && (key[1] === ' ' || key === 'del')));
            const errormsg = !!this.shortcutsDict[key] ? this.localizer.getTranslation('SHORTCUTS.ERROR-DUPLICATE') : isInvalidCommand ? this.localizer.getTranslation('SHORTCUTS.ERROR-CMDNAME') + command : isValidCombo ? null : this.localizer.getTranslation('SHORTCUTS.ERROR-COMBINATION');
            if (!this.shortcutsDict[key] && isValidCombo) {
              this.shortcutsDict[key] = 1;
              hotkeysService.add(new Hotkey(key, (ev, combo) => this.hotkey(ev, combo, command, keytype.type)));
            }
            this.helpFormData.data.defs.push({
              fldtype: 'edit',
              name: command,
              prompt: isInvalidCommand ? command : localizedCmd,
              value: key.replace(new RegExp(/ /g), '\n ' + this.localizer.getTranslation('SHORTCUTS.THEN') + ' \n'),
              flags: 0x00080000,
              errormessage: errormsg
            });
          }
          if (keytype.type === 'LIST-ITEM') {
            this.helpFormData.data.defs.push({
              fldtype: 'guidetext',
              name: 'item-spl-notes',
              prompt: '',
              value: this.localizer.getTranslation('SHORTCUTS.DOC-ACTION-NOTE'),
              flags: 0x00080000,
            });
          }
        }
        if (Object.keys(this.shortcutsDict).length === 0) {
          this.handleConfigurationError(this.localizer.getTranslation('SHORTCUTS.NO-DATA'));
        }
      } else {
        this.handleConfigurationError(this.localizer.getTranslation('SHORTCUTS.NO-DATA'));
      }
    }, err => {
      this.handleConfigurationError(err);
    });
  }

  public getShortcutsConfig(): Promise<any> {
    if (!!this.shortcutsConfig) {
      return Promise.resolve(this.shortcutsConfig);
    }
    return new Promise<any>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      const url: string = Util.getRootSiteUrl() + '/assets/shortcuts.json';
      xhr.open('GET', url, true);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          try {
            const responseObj: any = xhr.response ? JSON.parse(xhr.response) : null;
            if (!!responseObj) {
              this.shortcutsConfig = responseObj;
              resolve(responseObj);
            } else {
              reject(this.localizer.getTranslation('SHORTCUTS.NO-DATA'));
            }
          } catch (e) {
            reject(e);
          }
        } else if (xhr.readyState === 4 && xhr.status === 404) {
          reject(this.localizer.getTranslation('SHORTCUTS.CONFIG-NOT-FOUND'));
        }
      };
      xhr.send();
    });
  }

  public handleConfigurationError(err: any) {
    this.isInvalidConfig = true;
    this.helpFormData.data.defs = [];
    this.helpFormData.data.defs.push({
      name: 'InvalidConfiguration',
      fldtype: 'text',
      flags: 0x00080000,
      value: this.localizer.getTranslation('SHORTCUTS.INVALID-CONFIG'),
      errormessage: this.localizer.getTranslation('SHORTCUTS.INVALID-CONFIG')
    });
    this.helpFormData.data.defs.push({
      fldtype: 'guidetext',
      name: 'item-spl-notes',
      prompt: '',
      value: this.localizer.getTranslation('SHORTCUTS.INVALID-CONFIG-ERROR'),
      flags: 0x00080000,
    });
    const prefGeneralFields = FormService.localForms.filter(form => form.data.name === 'preferences_general');
    if (!!prefGeneralFields && prefGeneralFields.length > 0 && !!prefGeneralFields[0].data && !!prefGeneralFields[0].data.defs) {
      const shortcutFields = prefGeneralFields[0].data.defs.filter(field => field.name === 'shortcuts');
      if (!!shortcutFields && shortcutFields.length > 0 && !!shortcutFields[0].fields && shortcutFields[0].fields.length > 0) {
        shortcutFields[0].fields[0].flags = 0x00080000;
      }
    }
    console.error(err);
  }

  public getDisableShortcuts(): boolean {
    return this.disableShortcuts;
  }

  public setDisableShortcuts(value: boolean) {
    this.disableShortcuts = value;
  }

  hotkey(ev: KeyboardEvent, combo: string, name: string, type: string): boolean {
    if (!this.isInvalidConfig && !this.disableShortcuts && Util.RestAPI.getPreference('enable_shortcuts') !== '0') {
      const command = new KeyCommand(name, combo, ev, type);
      this.subject.next(command);
      return false;
    }
  }

}
