/**
 * Created by kevin on 2016-10-04.
 *
 * A set of reusable UI components
 *
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuComponent } from './menu.component';
import { AppNavSidebarComponent } from '../app-nav-sidebar.component';
import { ActionBarComponent } from './action-bar.component';
import { PaginatorComponent } from './paginator.component';
import { InlineActionBarComponent } from './inline-action-bar.component';
import { PopupComponent } from './popup.component';
import { FilelistSidebarComponent } from './filelist-sidebar.component';
import { TabSelectorComponent } from './tab-selector.component';
import { SpinnerComponent } from './spinner.component';
import { RefresherComponent } from './refresher.component';
import { ListInfoFooterComponent } from './list-info-footer.component';
import { SearchMenuComponent } from './search-menu.component';
import { SearchMobileComponent } from './search-mobile.component';
import { SearchOfficeComponent } from './search-office.component';
import { ProgressComponent } from './progress.component';
import { SelectComponent } from './select.component';
import { SegmentComponent } from './segment.component';
import { LocationChooserComponent } from './location-chooser.component';
import { SearchFilterComponent } from './search-filter.component';
import { CustomSecurityComponent } from './custom-security.component';
import { DirectivesModule } from '../directives/directives.module';

@NgModule({
  imports: [CommonModule, DirectivesModule, FormsModule, ReactiveFormsModule],
  providers: [],
  declarations: [
    MenuComponent,
    ActionBarComponent,
    FilelistSidebarComponent,
    InlineActionBarComponent,
    PaginatorComponent,
    PopupComponent,
    SpinnerComponent,
    RefresherComponent,
    TabSelectorComponent,
    ListInfoFooterComponent,
    SearchMenuComponent,
    SearchMobileComponent,
    SearchOfficeComponent,
    ProgressComponent,
    SelectComponent,
    SegmentComponent,
    LocationChooserComponent,
    SearchFilterComponent,
    CustomSecurityComponent
  ],
  exports: [
    MenuComponent,
    ActionBarComponent,
    FilelistSidebarComponent,
    InlineActionBarComponent,
    PaginatorComponent,
    PopupComponent,
    SpinnerComponent,
    RefresherComponent,
    TabSelectorComponent,
    ListInfoFooterComponent,
    SearchMenuComponent,
    SearchMobileComponent,
    SearchOfficeComponent,
    ProgressComponent,
    SelectComponent,
    SegmentComponent,
    LocationChooserComponent,
    SearchFilterComponent,
    CustomSecurityComponent
  ]
})
export class WidgetsModule {
  static isOKtoHandleKeydown(ele?: any): boolean {
    return MenuComponent.nMenusShown<=0 && !AppNavSidebarComponent.isActive && !SelectComponent.isActive && (PopupComponent.nPopupsShown===0 || (!ele && PopupComponent.ElementInTopPopup(ele))) && (!document.activeElement || document.activeElement.tagName.toUpperCase()!=='INPUT');
  }
}
