/**
 * Created by kevin on 2016-09-28.
 *
 *  Service to load localization libraries and provide translation values to translate pipe
 *
 */

import { Injectable } from '@angular/core';

@Injectable()
export class LocalizeService {
  private libraries: any = {};
  private defaultLang = 'en';
  private currentLang: string = null;

  constructor() {
    this.loadLanguages();
  }

  private loadLang(lang: string): boolean {
    try {
      const jsonStr = localStorage.getItem('edx_lang_'+lang);
      if (!!jsonStr) {
        const json: any = JSON.parse(jsonStr);
        localStorage.removeItem('edx_lang_'+lang);
        if (!!json) {
          this.loadLibrary(lang, json);
          return !!this.libraries[lang];
        }
      }
    } catch (e) { }
    return false;
  }

  private loadLanguages(): void {
    // choose language to use
    this.currentLang = this.defaultLang;
    this.loadLang(this.defaultLang);
    const userLang: string = localStorage.getItem('edx_curlang');
    if (!!userLang) {
      localStorage.removeItem('edx_curlang');
      if (this.loadLang(userLang)) {
        this.currentLang = userLang;
      }
    }
  }

  public loadLibrary(lang: string, srcLib: any): void {
    const library: any = {};
    const keyPath = '';
    this.addLibraryItems(srcLib, keyPath, library);
    this.libraries[lang] = library;
  }

  private addLibraryItems(libComponent: any, keyPath: string, library: any): void {
    for (const subKey in libComponent) {
      if (libComponent.hasOwnProperty(subKey)) {
        const subPath: string = keyPath + (keyPath.length > 0 ? '.' : '') + subKey;
        if (typeof libComponent[subKey] === 'string') {
          library[subPath] = libComponent[subKey];
        } else {
          this.addLibraryItems(libComponent[subKey], subPath, library);
        }
      }
    }
  }

  // returns string in current language, or string in default language if none, or the key if none in default language
  public getTranslation(key: string, params?: string[]): string {
    let value: string = null;
    if (this.libraries[this.currentLang][key]) {
      value = this.libraries[this.currentLang][key];
    } else if (this.libraries[this.defaultLang][key]) {
      value = this.libraries[this.defaultLang][key];
    }
    if (!value) {
      value = key;
    } else if (value.startsWith('~')) {
      value = value.substr(1);
    }
    if (params && params.length) {
      const nParams = params.length;
      for (let i=0; i<nParams; i++) {
        const tok: string = '$'+(i+1);
        value = value.replace(tok,params[i]);
      }
    }
    return value;
  }

  public translationExists(key: string, params?: string[]): boolean {
    let exists = false;
    if (this.libraries[this.currentLang][key]) {
      exists = !!this.libraries[this.currentLang][key];
    } else if (this.libraries[this.defaultLang][key]) {
      exists = !!this.libraries[this.defaultLang][key];
    }
    return exists;
  }
}
