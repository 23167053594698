/**
 * Created by kevin on 2016-12-16.
 *
 *   Descriptor class for security control on an eDOCS object.  A securityControl object has a 'local' access level (its
 *   own selected rights) and a 'contextual' access level, which is configured by passing in its 'parent' SecurityControl
 *   object.  This class has an interface that differentiates in that way.
 *
 */
 export enum RightsIndex {
  VIEW_PROFILE           = 0,
  EDIT_PROFILE           = 1,
  VIEW_DOCUMENT          = 2,
  RETRIEVE_DOCUMENT      = 3,
  EDIT_CONTENT           = 4,
  COPY                   = 5,
  DELETE                 = 6,
  CONTROL_ACCESS         = 7,
  CAN_ASSIGN_TO_FILEPART = 8,
  ALLOW_VIEW_PUBLISHED   = 9,
}

export enum AccessRights {
  ACCESS_VIEW_PROFILE           = 1 << RightsIndex.VIEW_PROFILE,
  ACCESS_EDIT_PROFILE           = 1 << RightsIndex.EDIT_PROFILE,
  ACCESS_VIEW_DOCUMENT          = 1 << RightsIndex.VIEW_DOCUMENT,
  ACCESS_RETRIEVE_DOCUMENT      = 1 << RightsIndex.RETRIEVE_DOCUMENT,
  ACCESS_EDIT_CONTENT           = 1 << RightsIndex.EDIT_CONTENT,
  ACCESS_COPY                   = 1 << RightsIndex.COPY,
  ACCESS_DELETE                 = 1 << RightsIndex.DELETE,
  ACCESS_CONTROL_ACCESS         = 1 << RightsIndex.CONTROL_ACCESS,
  ACCESS_CAN_ASSIGN_TO_FILEPART = 1 << RightsIndex.CAN_ASSIGN_TO_FILEPART,
  ACCESS_ALLOW_VIEW_PUBLISHED   = 1 << RightsIndex.ALLOW_VIEW_PUBLISHED,
}

export enum AccessLevel {
  ACCESS_LEVEL_CUSTOM           = -1,
  ACCESS_LEVEL_VIEW_PROFILE     = 1,
  ACCESS_LEVEL_READ_ONLY        = 45,
  ACCESS_LEVEL_NORMAL           = 63,
  ACCESS_LEVEL_FULL             = 255,
  ACCESS_LEVEL_FULL_RM          = 511,
  ACCESS_LEVEL_VIEW             = 1,
  ACCESS_LEVEL_COLLABORATE      = 63,
  ACCESS_LEVEL_MANAGE           = 255,
  ACCESS_LEVEL_COLLABORATE_CWT  = 127, // SECURITY VALUES FOR WORKSPACES CREATED OR UPDATED FROM CWT.
  ACCESS_LEVEL_VIEW_CWT         = 45,
  ACCESS_LEVEL_CREATOR          = 32255
}

export enum AccessSearch {
  VIEW                  = 1,
  EDIT                  = 2,
  VIEW_EDIT             = 3,
  DELETE                = 4,
  VIEW_EDIT_DELETE      = 7,
  CREATOR               = 255
}

export enum AccessType {
  AT_NONE   = 0,
  AT_ALLOW  = 1,
  AT_DENY   = 2
}

export enum DynamicViewRights {
  DVRIGHTS_READ_DV = 0x01,      // Bit 0 = Can see the Dynamic View
  DVRIGHTS_EDIT_DV = 0x02,	    // Bit 1 = Can edit this Dynamic View
  DVRIGHTS_READ_SUBSCR = 0x04,	// Bit 2 = Can see the Subscribed View Node
  DVRIGHTS_READ_RECENT = 0x08,  // Bit 3 = Can see the Recent View Node
  DVRIGHTS_READ_ALL = 0x10,     // Bit 4 = Can see the 'All' node for the view
  DVRIGHTS_EDIT_SUBSCR = 0x20,  // Bit 5 = Can subscribe to the view (My Subscriptions Node for the view).They would need 'Can see subscribed view' bit enabled to be able to set this extra security permission
  DVRIGHTS_EDIT_SECURITY = 0x40,// Bit 6 = Can set security against a level node in this dynamic view, for inheritance purposes when creating items under the dynamic view
  DVRIGHTS_EDIT_FOLDERS = 0x80, // Bit 7  = Can add/create folders at levels where defined as supporting adhoc foldering
}

export class SecurityControl {
  // DMIC-9092
  // High nibble is for when you Check Allow/Deny Access right
  // Low nibble is for when you Uncheck Allow/Deny Access right
  // Value of each nibble:
  // 0 = Don’t Change
  // 1 = Unset
  // 2 = Set
  // Value bits: 7 6 5 4 3 2 1 0
  // Bit 0 = On Uncheck Allow/Deny Access right, UNSET the right
  // Bit 1 = On Uncheck Allow/Deny Access right, SET the right
  // Bit 4 = On Check Allow/Deny Access right, UNSET the right
  // Bit 5= On Check Allow/Deny Access right, SET the right
  // Bit 2, 3, 6, 7 = Unused
  // Example: Column #5 Row #5 = 0x21
  // Means when we try to:
  // Check "Copy", we have to Set "Copy bit" of the Access right to 1.
  // Uncheck "Copy", we have to Unset "Copy bit" of the Access right to 0.
  private rightsTemplate = [
    // 0 ,   1 ,   2 ,  3  ,  4  ,  5  ,  6  ,  7  ,  8  ,  9
    [0x21, 0x01, 0x01, 0x01, 0x01, 0x01, 0x01, 0x01, 0x00, 0x11], // 0 - VIEW_PROFILE
    [0x20, 0x21, 0x00, 0x00, 0x00, 0x00, 0x01, 0x01, 0x00, 0x00], // 1 - EDIT_PROFILE
    [0x20, 0x00, 0x21, 0x01, 0x01, 0x01, 0x01, 0x01, 0x00, 0x11], // 2 - VIEW_DOCUMENT
    [0x20, 0x00, 0x20, 0x21, 0x01, 0x00, 0x01, 0x01, 0x00, 0x00], // 3 - RETRIEVE_DOCUMENT
    [0x20, 0x00, 0x20, 0x20, 0x21, 0x00, 0x01, 0x01, 0x00, 0x11], // 4 - EDIT_CONTENT
    [0x20, 0x00, 0x20, 0x00, 0x00, 0x21, 0x01, 0x01, 0x00, 0x00], // 5 - COPY
    [0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x21, 0x01, 0x00, 0x11], // 6 - DELETE
    [0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x20, 0x21, 0x00, 0x01], // 7 - CONTROL_ACCESS
    [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00], // 8 - CAN_ASSIGN_TO_FILEPART
    [0x20, 0x00, 0x20, 0x00, 0x10, 0x00, 0x10, 0x10, 0x00, 0x21], // 9 - ALLOW_VIEW_PUBLISHED
  ];

  private _access: number;

  constructor(access?: number) {
    this._access = (typeof access !== undefined) ? access : 0;
  }

  public rightsMask(rightsIndex: number, checkedStatus: number, setStatus: number): number {
    const selectedBit = (checkedStatus & 1) * 4 + (setStatus & 1);
    const allRights = this.rightsTemplate[rightsIndex].map(r => (r >> selectedBit) & 0x01).reverse().join('');
    const mask = parseInt(allRights, 2);
    return mask;
  }

  get access(): number {
    return this._access;
  }

  set access(access: number) {
    this._access = access;
  }

  get accessLevel(): number {
    let level: number;

    switch (this._access) {
      case AccessLevel.ACCESS_LEVEL_VIEW_PROFILE:
      case AccessLevel.ACCESS_LEVEL_READ_ONLY:
      case AccessLevel.ACCESS_LEVEL_NORMAL:
      case AccessLevel.ACCESS_LEVEL_FULL:
        level = this._access; break;
      default:
        level = AccessLevel.ACCESS_LEVEL_CUSTOM;
    }
    return level;
  }

  // **** access flag accessors

  get canViewProfile(): boolean {
    return ((this._access & AccessRights.ACCESS_VIEW_PROFILE) === AccessRights.ACCESS_VIEW_PROFILE);
  }

  get canEditProfile(): boolean {
    return ((this._access & AccessRights.ACCESS_EDIT_PROFILE) === AccessRights.ACCESS_EDIT_PROFILE);
  }

  get canViewDocument(): boolean {
    return ((this._access & AccessRights.ACCESS_VIEW_DOCUMENT) === AccessRights.ACCESS_VIEW_DOCUMENT);
  }

  get canRetrieveDocument(): boolean {
    return ((this._access & AccessRights.ACCESS_RETRIEVE_DOCUMENT) === AccessRights.ACCESS_RETRIEVE_DOCUMENT);
  }

  get canEditContent(): boolean {
    return ((this._access & AccessRights.ACCESS_EDIT_CONTENT) === AccessRights.ACCESS_EDIT_CONTENT);
  }

  get canCopy(): boolean {
    return ((this._access & AccessRights.ACCESS_COPY) === AccessRights.ACCESS_COPY);
  }

  get canDelete(): boolean {
    return ((this._access & AccessRights.ACCESS_DELETE) === AccessRights.ACCESS_DELETE);
  }

  get canControlAccess(): boolean {
    return ((this._access & AccessRights.ACCESS_CONTROL_ACCESS) === AccessRights.ACCESS_CONTROL_ACCESS);
  }

  get canAssignToFilePart(): boolean {
    return ((this._access & AccessRights.ACCESS_CAN_ASSIGN_TO_FILEPART) === AccessRights.ACCESS_CAN_ASSIGN_TO_FILEPART);
  }

  get canAllowViewPublished(): boolean {
    return ((this._access & AccessRights.ACCESS_ALLOW_VIEW_PUBLISHED) === AccessRights.ACCESS_ALLOW_VIEW_PUBLISHED);
  }
}
