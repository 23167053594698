import { DialogKind } from '../models/form-field';
import { _Transforms } from './transforms';
import { AppNotifyDialogComponent } from '../app-notify-dialog.component';

export class _Notify {
  private appNotifyDialog: AppNotifyDialogComponent = null;
  private transforms: _Transforms = null;

  constructor(transforms: _Transforms) {
    this.transforms = transforms;
  }

  public setAppNotifyDialog(appNotifyDialog: AppNotifyDialogComponent): void {
    this.appNotifyDialog = appNotifyDialog;
  }

  private doIt(kind: DialogKind, blocking: boolean, cancelBtn: boolean, oKBtn: boolean, title: string, body: any, okText: string, subFormData: any): Promise<any> {
    if (this.appNotifyDialog) {
      body = this.transforms.formatError(body);
      return this.appNotifyDialog.doShow(kind, blocking, cancelBtn, oKBtn, title, body, okText, subFormData);
    }
    return Promise.resolve(false);
  }

  public success(title: string, body?: any): Promise<any> {
    return this.doIt(DialogKind.success, false, false, false, title, body, null, null);
  }

  public info(title: string, body?: any, okText?: string, subFormData?: any, cancelBtn?: boolean, oKBtn?: boolean, blocking?: boolean): Promise<any> {
    return this.doIt(DialogKind.info, blocking, cancelBtn, oKBtn, title, body, okText, subFormData);
  }

  public warning(title: string, body?: any, okText?: string, subFormData?: any, cancelBtn?: boolean, oKBtn?: boolean, blocking?: boolean): Promise<any> {
    return this.doIt(DialogKind.warning, blocking, cancelBtn, oKBtn, title, body, okText, subFormData);
  }

  public progress(title: string, body?: any, okText?: string, subFormData?: any, cancelBtn?: boolean, oKBtn?: boolean, blocking?: boolean): Promise<any> {
    return this.doIt(DialogKind.progress, blocking, cancelBtn, oKBtn, title, body, okText, subFormData);
  }

  public error(title: string, body?: any): Promise<any> {
    return this.doIt(DialogKind.error, true, false, false, title, body, null, null);
  }

  public confirm(title: string, body?: any, okText?: string, subFormData?: any, cancelBtn?: boolean, oKBtn?: boolean, blocking?: boolean): Promise<any> {
    return this.doIt(DialogKind.confirm, blocking, cancelBtn, oKBtn, title, body, okText, subFormData);
  }

  public close(event?: Event): void {
    if (this.appNotifyDialog) {
      this.appNotifyDialog.doClose(event);
    }
  }

  public showHide(show: boolean): void {
    if (this.appNotifyDialog) {
      this.appNotifyDialog.showHide(show);
    }
  }

  public confirmOK(): void {
    if (this.appNotifyDialog) {
      this.appNotifyDialog.confirmOK();
    }
  }

  public updateFormData(formData: any): void {
    if (this.appNotifyDialog) {
      this.appNotifyDialog.updateFormData(formData);
    }
  }

  public updateTitle(title: string): void {
    if (this.appNotifyDialog) {
      this.appNotifyDialog.updateTitle(title);
    }
  }
}
