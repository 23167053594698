import { BaseDesc } from './base';
import { SelectItem } from './form-field';

export interface ListItemDesc extends BaseDesc {
  imgPath?: string;
  DOCNAME?: string;
  DOCNUM?: string;
  AUTHOR_ID?: string;
  APPLICATION?: string;
  TYPIST_ID?: string;
  APP_ID?: string;
  LAST_EDIT_DATE?: string;
  IS_SHARED?: string;
  checkout?: any;
  correlator?: string;
}

export class ListItem implements BaseDesc {
  type: string;
  id: string;
  lib: string;
  imgPath?: string;
  DOCNAME: string;
  DOCNUM: string;
  AUTHOR_ID: string;
  TYPIST_ID: string;
  APP_ID: string;
  LAST_EDIT_DATE: string;
  IS_SHARED: string;
  checkout: any;
  correlator: string;

  constructor(desc: ListItemDesc) {
    this.lib = desc.lib || '';
    this.type = desc.type || '';
    this.id = desc.id || '';
    this.imgPath = desc.imgPath || null;
    this.DOCNAME = desc.DOCNAME || '';
    this.DOCNUM = desc.DOCNUM || '';
    this.AUTHOR_ID = desc.AUTHOR_ID || '';
    this.TYPIST_ID = desc.TYPIST_ID || '';
    this.APP_ID = desc.APP_ID || desc.APPLICATION || '';
    this.LAST_EDIT_DATE = desc.LAST_EDIT_DATE || '';
    this.IS_SHARED = desc.IS_SHARED || '';
    this.checkout = desc.checkout || null;
    this.correlator = desc.correlator || null;
  }
}

export class ListState {
  ascending: string;
  descending: string;
  start: number;
  index: number;
  constructor(ascending?: string, descending?: string, start: number=0, index: number=0) {
    this.ascending = ascending;
    this.descending = descending;
    this.start = start;
    this.index = index;
   }
}

export class ListInterface {
  public okDisabled = true;

  public getSelections(): ListItem[] {
    // overridalbe to return selections
    return null;
  }
  public handleFilterChange(searchFilter: string, selKey: string): boolean {
    // override return true to clear the filter string in the search control
    return false;
  }
  public handleFilterSearch(searchString: string): boolean {
    // override return true to clear the filter string in the search control
    return false;
  }
  public getFilterColumnsFromSchema(): any[] {
    // override
    return null;
  }
  public getField?(fieldName: string): any;
  public clearSelection(): void {
  }
  public updateView(): void {
  }
  public closePopuMenus(): void {
  }
  public getGroupTable?(): any {
    //override
    return null;
  }
}

export class FilterListInterface {
  public clear(event?: Event): void {
  }
  public setSelKey(selKey: string): void {
  }
  public setList(items: SelectItem[]): void {
  }
  public getList(): SelectItem[] {
    return null;
  }
  public updateCols(): void {
  }
}
