import { FormField } from '../models/form-field';
import { _Device } from './device';

const descMap: any = {
  APP_ID: ['X4137', 'APP_DESCRIPTION', 'APP_DESC'],
  TYPE_ID: ['DESCRIPTION', 'TYPE_DESCRIPTION', 'TYPE_DESC'],
  AUTHOR_ID: ['AUTHOR_FULL_NAME', 'FULL_NAME01', 'FULL_NAME'],
  USER_ID: ['FULL_NAME'],
  TYPIST_ID: ['FULL_NAME'],
  LAST_EDIT_ID: ['FULL_NAME'],
  CLIENT_ID: ['X7316', 'CLIENT_NAME'],
  MATTER_ID: [ 'X8809', 'MATTER_NAME'],
  DOCNAME: ['DOCNUM'],
  DEPT_ID: ['DEPT_NAME'],
  ORG_ID: ['ORG_NAME'],
  ACCT_NUM: ['ACCT_DESC'],
  PD_FILEPT_NO: ['PD_TITLE','PD_Title','FP.PD_TITLE']
};

const fieldMap: any = {
  X4137: 'APP_ID',
  APP_DESCRIPTION: 'APP_ID',
  APP_DESC: 'APP_ID',
  DESCRIPTION: 'TYPE_ID',
  TYPE_DESCRIPTION: 'TYPE_ID',
  TYPE_DESC: 'TYPE_ID',
  AUTHOR_FULL_NAME: 'AUTHOR_ID',
  FULL_NAME01: 'AUTHOR_ID',
  FULL_NAME: 'AUTHOR_ID',
  X7316: 'CLIENT_ID',
  CLIENT_NAME: 'CLIENT_ID',
  X8809: 'MATTER_ID',
  MATTER_NAME: 'MATTER_ID',
  DEPT_NAME: 'DEPT_ID',
  ORG_NAME: 'ORG_ID',
  ACCT_DESC: 'ACCT_NUM'
};

const aliasMap: any = {
  CLIENT_ID01: 'CLIENT_ID',
  TYPE_ID01: 'TYPE_ID',
  ZERO_SLOT: 'USER_ID',
  AUTHOR_ID: 'USER_ID',
  TYPIST_ID: 'USER_ID',
  LAST_EDIT_ID: 'USER_ID',
  DOCTYPE_RETENTION: 'RETENTION',
  DOCTYPE_STORAGE: 'STORAGE',
  DOCTYPE_FULLTEXT: 'FULLTEXT'
};

const lookupSecondaryKey: any = {
  CLIENT_ID: 'CLIENT_NAME',
  MATTER_ID: 'MATTER_NAME',
  DOCNUM: 'DOCNAME',
  USER_ID: 'FULL_NAME',
  AUTHOR_ID: 'FULL_NAME',
  TYPIST_ID: 'FULL_NAME',
  LAST_PROF_EDIT_ID: 'FULL_NAME',
  LAST_EDIT_ID: 'FULL_NAME',
  DUMMY01: 'FULL_NAME01',// Need here for reverse lookup in "islookupSecondarykey"
  DUMMY02: 'AUTHOR_FULL_NAME', // Need here to filter out Full Name search selector
  TYPE_ID: 'DESCRIPTION',
  APP_ID: 'DESCRIPTION',
  APP_ID_01:'APP_DESC',
  APP_ID_02:'X4137',
  APP_ID_03:'TYPE_DESC',
  GROUP_ID: 'GROUP_NAME'
};

const lookupContactsFilterKey: any = {
  $edx_outlook_email_to : 'EMAIL',
  $edx_outlook_email_cc : 'EMAIL',
  $edx_outlook_email_bcc: 'EMAIL'
};

const lookupSearchSelectorText: any = {
  CLIENT_ID: 'SEARCH_SELECTOR.CLIENT',
  MATTER_ID: 'SEARCH_SELECTOR.MATTER',
  USER_ID: 'SEARCH_SELECTOR.USER',
  AUTHOR_ID: 'SEARCH_SELECTOR.USER',
  TYPIST_ID: 'SEARCH_SELECTOR.USER',
  LAST_PROF_EDIT_ID: 'SEARCH_SELECTOR.USER',
  LAST_EDIT_ID: 'SEARCH_SELECTOR.USER',
  TYPE_ID: 'SEARCH_SELECTOR.CONTENT',
  APP_ID: 'SEARCH_SELECTOR.APPLICATION',
  GROUP_ID: 'SEARCH_SELECTOR.GROUP'
};

const lookupColumnPropName: any = {
  X7316: 'CLIENT_NAME',
  X8809: 'MATTER_NAME',
  FULL_NAME01: 'FULL_NAME',
  DESCRIPTION:'TYPE_DESC'
};

const searchFormTypistMap: any = {
  LAWQBE: 'TYPIST_ID',
  LEGALQBE: 'CREATED_BY'
};

const searchFormWrongTypistMap: any = {
  LAWQBE: 'CREATED_BY',
  LEGALQBE: 'TYPIST_ID'
};

const fieldExists = (fields: any[], target: string): boolean => {
  let rc = false;
  for (const field of fields) {
    if (field.name===target) {
      rc = true;
    } else if (field.fields) {
      rc = fieldExists(field.fields, target);
    }
    if (rc) {
      break;
    }
  }
  return rc;
};

const getField = (fields: any[], target: string): any => {
  let rc: any = null;
  for (const field of fields) {
    if (field && (field.name === target)) {
      rc = field;
    } else if (field && (field.fields)) {
      rc = getField(field.fields, target);
    }
    if (rc) {
      break;
    }
  }
  return rc;
};

export class _FieldMappings {
  private device: _Device = null;

  constructor(device: _Device) {
    this.device = device;
    const waitInit = () => {
      if (this.device.initialized()) {
        if (this.device.ui < 2) {
          fieldMap['DOCNUM'] = 'DOCNAME';
        }
      } else {
        setTimeout(waitInit, 100);
      }
    };
    waitInit();
  }

  public descMapForField(fieldName: string): string[] {
    return descMap[fieldName];
  }

  public aliasToField(alias: string): string {
    const fieldName: string = aliasMap[alias] ? aliasMap[alias] : alias;
    return fieldName;
  }

  public fieldForDescription(field: FormField): string {
    let desc: string;
    if (!!field) {
      desc = field.descriptionForField;
    if (!desc) {
      desc = fieldMap[field.name];
    }
    }
    return desc;
  }

  public mappedDescriptionForField(template: any, fieldName: string): string {
    const descriptions: string[] = descMap[fieldName];
    if (descriptions && descriptions.length) {
      for (const desc of descriptions) {
        if (fieldExists(template.defs, desc)) {
          return desc;
        }
      }
    }
    return null;
  }

  public descriptionForField(template: any, field: FormField): string {
    let fieldName: string = field ? this.mappedDescriptionForField(template,field.name) : null;
    if (!fieldName) {
      // If not found check if the field has been defined by REST API
      if (field && field.descriptionField) {
        if (fieldExists(template.defs, field.descriptionField)) {
          fieldName = field.descriptionField;
        }
      }
    }
    return fieldName;
  }
  // At times description fields in the returned data do not match the description fields
  // in the template so we need to cycle throuhg all possible description field IDs to
  // find the actual value
  public descriptionValueForField(data: any, field: FormField): string {
    let descValue: string = null;
    const descriptions: string[] = field ? descMap[field.name] : null;
    if (descriptions && descriptions.length) {
      for (const desc of descriptions) {
        descValue = data[desc];
        if (descValue) {
          break;
        }
      }
    }
    if (!descValue && field && field.descriptionField) {
      descValue = data[field.descriptionField];
    }
    return descValue;
  }

  public lookNameForField(template: any, fieldName: string): string {
    const field: any = getField(template.defs, fieldName);
    if (field && field.lookup) {
      return field.lookup;
    }
    return null;
  }

  public templateField(fields: any, fieldName: string): any {
    return getField(fields, fieldName);
  }

  public secondaryKeyForField(item: any, field: FormField): string {
    let secondaryKey: string = null;
    const descriptions: string[] = field ? descMap[field.name] : null;
    if (descriptions && descriptions.length) {
      for (const desc of descriptions) {
        for (const key in item) {
          if (key === desc) {
            secondaryKey = desc;
            break;
          }
        }
      }
    }
    if (!secondaryKey && field && field.descriptionField) {
        secondaryKey = field.descriptionField;
    }
    return secondaryKey;
  }

  public forEachTemplateField(fields: any, allTypes: boolean, callBack: (field) => boolean): void {
    const parseFields = (theFields: any[]): boolean => {
      let rc = false;
      for (const field of theFields) {
        if (field.fields && (field.fldtype==='box' || allTypes)) {
          rc = parseFields(field.fields);
        } else {
          rc = callBack(field);
        }
        if (!rc) {
          break;
        }
      }
      return rc;
    };
    parseFields(fields);
  }

  public getLookupSecondaryKey(fieldName: string): any {
    return lookupSecondaryKey[fieldName];
  }

  public getLookupContactsFilterKey(fieldName: string): any {
    return lookupContactsFilterKey[fieldName];
  }

  public mergePrimaryAndSecondary(cols: any[], localizer: any) {
    cols.every(col => {
      if (!!lookupSecondaryKey[col.property]) {
        const secondKeyIndex = cols.findIndex((column) => column.property === lookupSecondaryKey[col.property]);
        if (secondKeyIndex > -1) {
          const colLabel: string = localizer.getTranslation(this.getLookupSearchSelectorText(col.property)) || col.label || col.text || col.property;
          col.label = colLabel;
          cols.splice(secondKeyIndex, 1);
        }
      }
      return true;
    });
  }

  public isLookupColumnPropName(fieldName: string): any {
    let is = false;
    for (const key in lookupColumnPropName) {
      if (fieldName === lookupColumnPropName[key]) {
        is = true;
        break;
      }
    }
    return is;
  }

  public getLookupColumnPropName(fieldName: string): any {
    let columnProp: string;
    for (const key in lookupColumnPropName) {
      if (fieldName === lookupColumnPropName[key]) {
        columnProp = key;
        break;
      }
    }
    return columnProp;
  }

  public getLookupSearchSelectorText(fieldName: string): any {
    return lookupSearchSelectorText[fieldName];
  }

  public mapLookupColumnProperty(serverData: string): any {
    return lookupColumnPropName[serverData] ? lookupColumnPropName[serverData] : serverData;
  }

  public isSearchFormTypistColumn(columnName: string): boolean {
    for (const key in searchFormTypistMap) {
      if (columnName === searchFormTypistMap[key]) {
        return true;
      }
    }
    return false;
  }

  public getSearchFormTypistColumn(formName: string): string {
    return searchFormTypistMap[formName];
  }

  public getSearchFormWrongTypistColumn(formName: string): string {
    return searchFormWrongTypistMap[formName];
  }

  public isSqlInfoMatchesTillParentNode(sqlInfo1: string, sqlInfo2: string): boolean {
    let matchingSqlInfo = false;
    if (!!sqlInfo1 && !!sqlInfo2) {
      const sqlParts1: string[] = sqlInfo1.split(';');
      const sqlParts2: string[] = sqlInfo2.split(';');
      if (sqlParts1.length === sqlParts2.length && (sqlParts1.length === 1 || sqlParts1[sqlParts1.length - 1] === sqlParts2[sqlParts1.length - 1])) {
        const linkParts1: string[] = sqlParts1[0].split('.');
        const linkParts2: string[] = sqlParts2[0].split('.');
        if ((!!linkParts1 && !!linkParts2) && linkParts1.length === linkParts2.length) {
          let matchCount = 0;
          for (let i = 0; i < linkParts1.length - 1; i++) {
            if (linkParts1[i] === linkParts2[i]) {
              matchCount++;
            }
          }
          if (matchCount === linkParts1.length - 1) {
            matchingSqlInfo = true;
          }
        }
      }
    }

    return matchingSqlInfo;
  }
}
