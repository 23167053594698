import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppHomeComponent } from './app-home.component';
import { WindowModalMetadataComponent } from './windows/window-modal-metadata.component';
import { WindowModalSplitViewComponent } from './windows/window-modal-splitview.component';
import { WindowModalFoldersComponent } from './windows/window-modal-folders.component';
import { WindowModalFormLoginComponent } from './windows/window-modal-form-login.component';
import { WindowModalPreferencesComponent } from './windows/window-modal-preferences.component';
import { WindowModalAdminComponent } from './windows/window-modal-admin.component';

const appRoutes: Routes = [
  { path: 'login', component: WindowModalFormLoginComponent },
  { path: 'home',
    children: [
      { path: '', component: AppHomeComponent },
      { path: 'iclink', component: AppHomeComponent },
      { path: 'folders', component: WindowModalFoldersComponent, outlet: 'tcc_outlet' },
      { path: 'searches', component: WindowModalFoldersComponent, outlet: 'tcc_outlet' },
      { path: 'workspaces', component: WindowModalFoldersComponent, outlet: 'tcc_outlet' },
      { path: 'flexfolders', component: WindowModalFoldersComponent, outlet: 'tcc_outlet' },
      { path: 'fileplans', component: WindowModalFoldersComponent, outlet: 'tcc_outlet' },
      { path: 'boxes', component: WindowModalFoldersComponent, outlet: 'tcc_outlet' },
      { path: 'activities', component: WindowModalFoldersComponent, outlet: 'tcc_outlet' },
      { path: 'metadata', component: WindowModalMetadataComponent, outlet: 'tcc_outlet' },
      { path: 'requests', component: WindowModalFoldersComponent, outlet: 'tcc_outlet' },
      { path: 'preferences', component: WindowModalPreferencesComponent, outlet: 'tcc_outlet' },
      { path: 'admin', component: WindowModalAdminComponent, outlet: 'tcc_outlet' },
      { path: 'splitview', component: WindowModalSplitViewComponent, outlet: 'tcc_outlet' }
    ]
  }
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(appRoutes, { useHash: true, relativeLinkResolution: 'legacy' });
