import { _Transforms } from './transforms';

const RANGE_PREFIX = 'range';
const MATCH_PREFIX = 'match';
const MATCH_HTTP = 'http';
const INVALID_PATTERN = 'invalid';

export enum ValidationType {
    INVALID,
    RANGE,
    MATCH,
    URL
}

export class ValidationParameters {
    constructor(public validationType: ValidationType, public pattern) {
    }
    public validCharacterRegex: string;
    public matchRegex: string;
    public rangeMinimum: number;
    public rangeMaximum: number;
    public maxAcceptableLength: number;

    public toString(): string {
        let stringValue = this.pattern;
        if (this.validationType === ValidationType.RANGE) {
            stringValue += ' ' + [this.rangeMinimum, this.rangeMaximum].join(' ');
        }
        return stringValue.toUpperCase();
    }
}

export class ValidationResult {
    constructor(public isValid: boolean, public messageKey: string, public messageParameters?: string[]) {
    }
}

export class ValidationUtilities {
    static Transforms = new _Transforms(null);

    public static getValidationParameters(validationPattern: string): ValidationParameters {
        let validationParameters: ValidationParameters = null;
        if (!!validationPattern) {
            const parts = validationPattern.trim().split(' ').filter(n => n);
            if (parts.length >= 3 && parts[0].toLowerCase() === RANGE_PREFIX) {
                validationParameters = this.getValidationRange(parts[1], parts[2]);
            } else if (parts.length >= 2 && parts[0].toLowerCase() === MATCH_PREFIX) {
                validationParameters = this.getValidationMatch(parts[1]);
            } else {
                validationParameters = new ValidationParameters(ValidationType.INVALID, `${INVALID_PATTERN}: ${validationPattern}`);
            }
        }
        return validationParameters;
    }

    private static getValidationRange(minString: string, maxString: string): ValidationParameters {
        const validationParameters = new ValidationParameters(ValidationType.RANGE, RANGE_PREFIX);
        const minimum = parseInt(minString);
        const maximum = parseInt(maxString);
        if (!isNaN(minimum) && !isNaN(maximum)) {
            const range = [Math.abs(minimum), Math.abs(maximum)].sort((a, b) => a - b);
            validationParameters.rangeMinimum = range[0];
            validationParameters.rangeMaximum = range[1];
            validationParameters.validCharacterRegex = '\\d';
            validationParameters.maxAcceptableLength = validationParameters.rangeMaximum.toString().length;
        } else {
            validationParameters.validationType = ValidationType.INVALID;
            validationParameters.pattern = `${INVALID_PATTERN}: ${RANGE_PREFIX} ${[minString, maxString].join(' ')}`;
        }
        return validationParameters;
    }

    private static getValidationMatch(matchPattern: string): ValidationParameters {
        const validationParameters = new ValidationParameters(ValidationType.MATCH, `${MATCH_PREFIX} ${matchPattern}`);
        if (this.IsValidationMatchURL(matchPattern)) {
            validationParameters.validationType = ValidationType.URL;
            validationParameters.validCharacterRegex = `[A-Za-z0-9\\-\\.\\_\\~\\:\\/\\?\\#\\[\\]\\@\\!\\$\\&\\'\\(\\)\\*\\+\\,\\;\\=\\%]*$`;
            validationParameters.matchRegex = `(http|https):\\/\\/(\\w+:{0,1}\\w*@)?(\\S+)(:[0-9]+)?(\\/|\\/([\\w#!:.?+=&%@!\\-\\/]))?`;
        } else {
            const matchRegexList = this.CreateValidationRegexList(matchPattern);
            validationParameters.validCharacterRegex = this.ToMatchValidCharacterRegex(matchRegexList);
            validationParameters.matchRegex = this.ToMatchRegex(matchRegexList);
            validationParameters.maxAcceptableLength = this.MaxAcceptableLength(matchRegexList);
        }
        return validationParameters;
    }

    public static IsValidCharacterInput(inputCharacter: string, validationParameters: ValidationParameters): boolean {
        let isValid = true;
        if (!!validationParameters?.validCharacterRegex) {
            isValid = this.isValidMatchByRegex(inputCharacter, validationParameters.validCharacterRegex);
        }
        return isValid;
    }

    public static IsValidInput(inputString: string, validationParameters: ValidationParameters): ValidationResult {
        let validationResult = null;
        if (!!inputString && !!validationParameters) {
            switch (validationParameters.validationType) {
                case ValidationType.RANGE: {
                    validationResult = this.isValidRange(inputString, validationParameters);
                    break;
                }
                case ValidationType.MATCH:
                case ValidationType.URL: {
                    validationResult = this.isValidMatch(inputString, validationParameters);
                    break;
                }
            }
        }
        return validationResult;
    }

    private static isValidRange(inputString: string, validationParameters: ValidationParameters): ValidationResult {
        const value = parseInt(inputString);
        const isValid = (!isNaN(value) && value >= validationParameters.rangeMinimum && value <= validationParameters.rangeMaximum);
        const messageKey = isValid ? null : 'FORMS.ERRORS.INVALID_RANGE';
        const messageParameters = isValid ? null : [validationParameters.rangeMinimum, validationParameters.rangeMaximum].map(String);
        const validationResult = new ValidationResult(isValid, messageKey, messageParameters);
        return validationResult;
    }

    private static isValidMatch(inputString: string, validationParameters: ValidationParameters): ValidationResult {
        const isValid = this.isValidMatchByRegex(inputString, validationParameters.matchRegex);
        const messageKey = isValid ? null :
            validationParameters.validationType === ValidationType.MATCH ? 'FORMS.ERRORS.INVALID_MATCH' : 'FORMS.ERRORS.INVALID_URL';
        const validationResult = new ValidationResult(isValid, messageKey);
        return validationResult;
    }

    private static isValidMatchByRegex(inputString: string, matchRegex: string): boolean {
        const regEx = new RegExp(matchRegex, 'g');
        const matches = inputString.match(regEx);
        const isValid = !!matches && matches.length && (matches[0] === inputString);
        return isValid;
    }

    private static CreateValidationRegexList(matchPattern: string): string[] {
        let itemRegexList = [];
        if (!!matchPattern) {
            itemRegexList = this.GetValidationList(matchPattern);
        }
        return itemRegexList;
    }

    private static IsValidationMatchURL(matchPattern: string): boolean {
        return matchPattern.toLowerCase().startsWith(MATCH_HTTP); //this will include https.
    }

    private static ToMatchValidCharacterRegex(itemRegexList: string[]): string {
        let regExPattern = '';
        if (!!itemRegexList && (itemRegexList.length > 0)) {
            regExPattern = itemRegexList.map(x => this.Transforms.trimEnd(x, '*'))
                .filter((value, index, self) => self.indexOf(value) === index)
                .join('|');
        }
        return regExPattern;
    }

    private static ToMatchRegex(itemRegexList: string[]): string {
        let regExPattern = '';
        if (!!itemRegexList && (itemRegexList.length > 0)) {
            regExPattern = `^${itemRegexList.join('')}$`;
        }
        return regExPattern;
    }

    private static MaxAcceptableLength(itemRegexList: string[]): number {
        const isInfinite = itemRegexList.filter(x => x.includes('*')).length > 0;
        const maxLength = (isInfinite ? 0 : itemRegexList.length);
        return maxLength;
    }

    private static ToEncodedUtf8(text: string): string {
        const encodedText = text.split('').map(c => `\\u${c.charCodeAt(0).toString(16).padStart(4, '0')}`).join('');
        return encodedText;
    }

    private static GetNextValidationItem(matchPattern: string, index: number): string {
        let nextItem = matchPattern.charAt(index);
        if ((nextItem === '#') || (nextItem === '!')) {
            const secondItem = this.GetNextValidationItem(matchPattern, index + 1);
            nextItem = nextItem + secondItem;
        }
        if (!!nextItem) {
            let nextChar = matchPattern.charAt(index + nextItem.length);;
            if (nextItem === '\\') {
                nextItem = nextItem + nextChar;
                nextChar = matchPattern.charAt(index + nextItem.length);
            }
            if (nextChar === '*') {
                nextItem = nextChar + nextItem; // Reverse order to use prefix as indicator
            }
        }
        return nextItem;
    }

    /* DM designer Match Validations
    http Match an absolute URL
    ? 	 Matches any single character in the expression.
    @ 	 Matches a single character of white space, meaning one space or one tab.
    #@ 	 Matches multiple white space characters.
    9 	 Matches a single digit.
    A	 Matches a single alphabetical character.
    # 	 Followed by any digit, or character matches 0 or more instances of that digit or character.
    *	 Matches any combination of characters found in 0 or more instances.
    !	 Functions as a NOT operand.
    \ 	 Preceding any one of these operators listed indicates you are trying to match that actual value (@, #, 9, A, etc.) instead of its search definition.
    */
    private static GetValidationList(matchPattern: string): string[] {
        const matchItems = [];
        let index = 0;
        while (index < matchPattern.length) {
            const matchItem = this.GetNextValidationItem(matchPattern, index);
            index = index + matchItem.length;
            let regExItem = '';
            if (!!matchItem) {
                switch (matchItem[0]) {
                    case '?': {
                        regExItem = '\\w';
                        break;
                    }
                    case '@': {
                        regExItem = '\\s';
                        break;
                    }
                    case '9': {
                        regExItem = '\\d';
                        break;
                    }
                    case 'A': {
                        regExItem = '[a-zA-Z]';
                        break;
                    }
                    case '#':
                    case '*': {
                        const nextItems = this.GetValidationList(matchItem.substring(1));
                        if (!!nextItems) {
                            regExItem = `${nextItems[0]}*`;
                        }
                        break;
                    }
                    case '\\': {
                        const nextItems = matchItem.substring(1);
                        if (!!nextItems) {
                            regExItem = this.ToEncodedUtf8(nextItems);
                        }
                        break;
                    }
                    case '!': {
                        const nextItems = this.GetValidationList(matchItem.substring(1));
                        if (!!nextItems) {
                            regExItem = `[^${nextItems[0]}]`;
                        }
                        break;
                    }
                    default: {
                        regExItem = this.ToEncodedUtf8(matchItem);
                        break;
                    }
                }
            }
            if (!!regExItem) {
                matchItems.push(regExItem);
            }
        }
        return matchItems;
    }
}
