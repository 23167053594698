import { ElementRef } from '@angular/core';
import { BaseDesc } from './base';

export const dragHoleKey = 'edx-drag-hole';

export const enum TileSize {
  oneXone = 1,
  oneXtwo,
  twoXone,
  twoXtwo
}

export interface TileDesc extends BaseDesc {
  index?: number;
  size?: number;
  name?: string;
  imgPath?: string;
  tooltip?: string;
  docNumber?: string;
  IS_SHARED?: string;
  SECURITY?: string;
  key?: string;
  PD_ACTIVE_STATUS?: string;
}

export class Tile implements BaseDesc {
  type: string;
  id: string;
  lib: string;
  index: number;
  size: number;
  name: string;
  imgPath: string;
  tooltip: string;
  docNumber?: string;
  IS_SHARED?: string;
  SECURITY?: string;
  key?: string;
  PD_ACTIVE_STATUS?: string;

  constructor(desc: TileDesc) {
    this.lib = desc.lib || '';
    this.type = desc.type || '';
    this.id = desc.id || '';
    this.index = desc.index || 0;
    this.size = desc.size || TileSize.oneXone;
    this.name = desc.name || '';
    this.imgPath = desc.imgPath || null;
    this.tooltip = desc.tooltip || desc.name || '';
    this.docNumber = desc.docNumber || null;
    this.IS_SHARED = desc.IS_SHARED || '';
    this.SECURITY = desc.SECURITY || '';
    this.key = desc.key || '';
    this.PD_ACTIVE_STATUS = desc.PD_ACTIVE_STATUS || '';
  }
}
