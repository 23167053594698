import { Component, Input, Output, OnChanges, EventEmitter, SimpleChange } from '@angular/core';

import { SelectItem } from '../models/form-field';

@Component({
  selector: 'edx-segment',
  styleUrls: ['segment.component.scss'],
  template: `
    <div #seg class="edx-segment" [ngClass]="{disabled:disabled}" id="edx_select_segment">
      <div class="edx-segment-item" *ngFor="let item of items; let first=first; let last=last;" [style.width]="segWidth()" [ngClass]="{selected:(item==currentItem), first:first, last:last}" [tabindex]="tabIndex" (click)="itemSelected(item, $event)" (keydown.space)="itemSelected(item, $event)">
        {{item.display}}
      </div>
    </div>
  `
})
export class SegmentComponent implements OnChanges {
  @Input() items: SelectItem[] = [];
  @Input() value?: any = null;
  @Input() disabled?: boolean = false;
  @Input() required?: boolean;
  @Input() id?: string;
  @Input() tabIndex = 0;
  @Output() change: EventEmitter<SegmentComponent> = new EventEmitter<SegmentComponent>();
  private initialItem: SelectItem = null;
  private currentItem: SelectItem = null;

  ngOnChanges(changes: {[propertyName: string]: SimpleChange}) {
    const valueChng: any = changes['value'];
    const itemsChng: any = changes['items'];
    if (valueChng || itemsChng) {
      if (this.value!=null) {
        this.initialItem = this.currentItem = this.items.find(i => i.value === String(this.value));
      }
    }
  }

  private itemSelected(item: SelectItem, event: Event): boolean {
    this.initialItem = this.currentItem = (item || this.currentItem);
    this.value = item.value;
    this.change.emit(this);
    return false;
  }

  private segWidth(): string {
    return (100.0 / this.items.length).toString() + '%';
  }
}
