<div #lb class="list-base" [ngClass]="{single:(!showExtras && fieldDataList && fieldDataList.length==1), ie:isIE, edge:isEdge}">
  <div *ngIf="infoMessage" class="celltext info-message" [tabindex]="tabIndex" role="status" [attr.aria-label]="infoMessage">{{infoMessage}}</div>
  <div *ngIf="isMultiselectItem() && !isLookupAdminPage() && !!selectedLookupValues && selectedLookupValues.length" class="chicklets">
    <div *ngFor="let chicklet of selectedLookupValues.split('|')" class="chicklet">
      <span class="del" [tabindex]="tabIndex" role="button" [attr.aria-label]="altLabels['unselect']+' '+chicklet" (keydown.space)="removeUnselectedLookupValue(chicklet, true)" (click)="removeUnselectedLookupValue(chicklet, true)"></span>
      <span class="celltext">{{chicklet}}</span>
    </div>
  </div>
  <div #tc class="table-container" [ngClass]="{dragover:dragover, draggingcolumn:draggingColumn!=null, hasfootprint:hasFootprint, web:ui==0, readonly:!!schema && schema.readOnly, displaymessage:infoMessage, displaychicklets:isMultiselectItem() && !!selectedLookupValues && selectedLookupValues.length && !isLookupAdminPage(), fullwidth:isIE, slidright: hitPreviewShown, slidingleft: hitPreviewHiding, slidingright: hitPreviewShowing}">
    <edx-spinner *ngIf="reloading"></edx-spinner>
    <table *ngIf="schema && schema.columns && schema.columns.length">
      <thead *ngIf="!fieldDataList || showExtras || fieldDataList.length>1" [ngClass]="{tophead:(schema.columnHeadings && schema.columnHeadings.length)}">
        <tr *ngIf="schema.columnHeadings && schema.columnHeadings.length">
          <ng-template ngFor let-columnHeading [ngForOf]="schema.columnHeadings" let-k="index">
            <th [colSpan]="columnHeading.colspan" class="topheading">
              <div *ngIf="columnHeading.bottomcolor" class="topheadinglabel" [style.borderBottom]="'solid 3px '+columnHeading.bottomcolor">{{this.localizer.getTranslation(columnHeading.label)}}</div>
            </th>
          </ng-template>
        </tr>
        <tr #headerrow>
          <ng-template ngFor let-column [ngForOf]="schema.columns" let-k="index">
            <th *ngIf="k < columnsShown || ((column.format === 10 || isFavoriteColumn(column.property)) && columnsHidden > 0)" width="{{getColWidth(column)}}" [ngClass]="{numeric:column.format===1, iconic: columIsIconic(column), sortcolumn:getSortCol()==column.property,topheading:column.nonsortable}" [tabindex]="isTableSortable()&&column.property!=='select'?tabIndex:-1" (click)="column.nonsortable ? null : headerColumnClick($event, column.property)" (keydown.space)="column.nonsortable ? null : headerColumnClick($event, column.property)" [title]="column.label">
              <div *ngIf="!isLookupAdminPage() && column.property === 'select'" [tabindex]="tabIndex" (click)="headerSelectClick()" (keydown.space)="headerSelectClick()" role="checkbox" [ngClass]="{headerselect:!!list.length, partialselect:!noneSelected, allselect:allSelected}" id="edx_list_table_selectall" [title]="column.label" [attr.aria-label]="altLabels['selectAll']" [attr.aria-checked]="!noneSelected && !allSelected ? 'mixed': allSelected"></div>
              <div *ngIf="column.property === 'expand' && columnsHidden > 0" [ngClass]="{headerexpand:true, allexpand: allExpanded}" title="{{this.localizer.getTranslation('TOOLTIP.SEE_ALL')}}" [attr.aria-label]="(column.property === 'expand' && columnsHidden > 0)?(altLabels['seeAll']+' '+(allExpanded?altLabels['expanded']:altLabels['collapsed'])):none" [attr.aria-expanded]="(column.property === 'expand' && columnsHidden > 0)?allExpanded:none"></div>
              <div *ngIf="column.property === 'APP_ID'" class="headerobjtype rowicon" [title]="column.label" [attr.aria-label]="column.label+' '+altLabels['sortable']">
                <div *ngIf="getSortCol()==column.property" class="sortarrow" [ngClass]="{sortascending:ascending}" [title]="(ascending ? this.localizer.getTranslation('TOOLTIP.SORT_ARROW_DESCENDING',[column.label]) : this.localizer.getTranslation('TOOLTIP.SORT_ARROW_ASCENDING',[column.label]))"></div>
              </div>
              <div *ngIf="column.property !== 'select' && column.property !== 'expand' && column.property !== 'APP_ID' && k < columnsShown" class="headercolumn" [edx-draggable]="this">
                <span *ngIf="!isImageHeader(column.img)" [ngClass]="{sortcolumn:getSortCol()==column.property}" [attr.aria-label]="column.label+' '+(schema.sortControl !== 0?altLabels['sortable']:altLabels['unsortable'])" >{{column.label}}</span>
                <span *ngIf="isImageHeader(column.img)" [ngClass]="{sortcolumn:getSortCol()==column.property}">
                  <img class="rightsicon" [src]="column.img" [title]="column.label" [alt]="column.label">
                </span>
                <span [attr.aria-live]="isChrome ? 'polite':'off'">
                    <div *ngIf="getSortCol()==column.property" class="sortarrow" [ngClass]="{sortascending:ascending}" [attr.aria-sort]="ascending?'ascending':'descending'" [title]="(ascending ? this.localizer.getTranslation('TOOLTIP.SORT_ARROW_DESCENDING',[column.label]) : this.localizer.getTranslation('TOOLTIP.SORT_ARROW_ASCENDING',[column.label]))"></div>
                </span>
              </div>
              <div *ngIf="column.property !== 'select' && column.property !== 'expand' && column.property !== 'APP_ID'" class="colresizer"  [edx-draggable]="this"><div class="colresizerline"></div></div>
            </th>
          </ng-template>
        </tr>
      </thead>
      <tbody #tb class="table-scroller" [edx-drag-target]="this">
        <tr *ngIf="!reloading && (!list || !list.length)" [attr.aria-label]="noHits" [tabindex]="tabindex"><td [colSpan]="schema.columns.length" class="center"><div class="celltext">{{noHits}}</div></td></tr>
        <ng-template ngFor let-listitem [ngForOf]="list" let-i="index">
          <tr #tr [tabindex]="tabIndex" class="table-rows" (keydown.space)="selectorClick(listitem, $event)" [ngClass]="{selected:selections.indexOf(listitem)>=0 && isSelectable(listitem), open:listitem==openedItem, dropfolder:dropFolder(listitem), expanded: (expandedItems.indexOf(listitem)>=0 && columnsHidden > 0)}" (mouseover)="enableHoverMenu(i)" (mouseleave)="disableHoverMenu(i)" (focus)="enableHoverMenu(i)" (click)="listItemRowClick(listitem, $event)" (dblclick)="listItemDblClick(listitem, $event)" (keyup.arrowleft)="hitPreviewShown?focusOnPreviewPane(i):null" [edx-draggable-file]="this" [draggable]="isDraggableFile(listitem)" ng-readonly="true" [attr.aria-label]="getLabel(listitem)">
            <ng-template ngFor let-col [ngForOf]="schema.columns" let-j="index">
              <td *ngIf="(j < columnsShown || ((col.format===10 || isFavoriteColumn(col.property)) && columnsHidden > 0))"
                  [ngSwitch]="col.format" width="{{getColWidth(col)}}"
                  [ngClass]="{numeric:col.format===1, iconic: columIsIconic(col), oai: officeAddin}"
                  [title]="listItemTooltip(listitem, col.property)"
                  [attr.aria-label]="getColumnAriaLabel(col,listitem, this.schemaId === 'ACCESS_CONTROL' && col.property === 'rights' ? getTrusteeAccess(i,j-1) : null)">
                <div *ngSwitchCase="0" class="selector rowselector" role="checkbox" [attr.aria-label]="isChrome ? (selections.indexOf(listitem)>=0 ? altLabels['rowSelector'] : altLabels['rowSelector'] + ' ') : altLabels['rowSelector']" 
                  [attr.aria-checked]="selections.indexOf(listitem)>=0" [ngClass]="{disabled:!canSelectItem(listitem)}" [tabindex]="!canSelectItem(listitem)?-1:tabIndex" (click)="selectorClick(listitem, $event)" (keydown.space)="selectorClick(listitem, $event)" [id]="getRowSelectorId(i)"></div>
                <div *ngSwitchCase="1" class="cellnumeric">{{formatNumber(listitem, col.property, j)}}</div>
                <div *ngSwitchCase="2"
                      [tabindex]="isClickable(listitem,col.property)?tabIndex:-1"
                      role="link" (click)="listItemClick(listitem, $event, col.property)"
                      (keyup.enter)="listItemClick(listitem, $event, col.property)"
                      (keydown.tab)="ui>=2?shiftFocusToCancel($event,i):undefined"
                      (keydown.space)="listItemClick(listitem, $event, col.property)"
                      [ngClass]="{celltext: true, oai: officeAddin, clickable: isClickable(listitem,col.property)}"
                      title="{{formatString(listitem,col.property,col.displayMap)}}">
                  <span>{{schemaId==='VERSIONS'?formatString(listitem,col.property,col.displayMap).toUpperCase():formatString(listitem,col.property,col.displayMap)}}</span>                  
                </div>
                <edx-inline-action-bar *ngIf="col.property=='DOCNAME' && inlineActionMenuId!=-1" [resid]="inlineActionMenuId" [shown]="listitem==hoverListItem" [target]="inlineActionTarget"></edx-inline-action-bar>
                <div *ngSwitchCase="3" class="celltext">{{formatDate(listitem, col.property)}}</div>
                <div *ngSwitchCase="4">
                  <edx-spinner *ngIf="listitem.id=='' && listitem.lib=='' " [mini]="true"></edx-spinner>
                  <div *ngIf="showFavorite(listitem, col.property); else otherThanFavorite" 
                        [tabindex]="tabIndex"
                        [ngClass]="{rowicon: true, oai: officeAddin, clickable: isClickable(listitem,col.property), favoriteIcon: IsFavoriteItem(listitem), notFavoriteIcon: !IsFavoriteItem(listitem)}" 
                        [title]="getFavoriteImageToolTip(listitem, col.property)"
                        (click)="listItemClick(listitem, $event, col.property)">
                  </div>
                  <ng-template #otherThanFavorite>
                    <img *ngIf="listitem.id!='' || listitem.lib!='' " 
                      [ngClass]="{rowicon: true, oai: officeAddin, clickable: isClickable(listitem,col.property)}" 
                      [src]="formatTypeIcon(listitem)"
                      [alt]="formatTypeText(listitem)"
                      (click)="listItemClick(listitem, $event, col.property)">
                    <img *ngIf="displayMiniStatus(listitem, col.property)" class="overlaystatusicon clickable" src="{{formatStatusIcon(listitem)}}" alt="{{formatStatusText(listitem)}}" (click)="listItemClick(listitem, $event, 'APP_ID')">
                    <img *ngIf="displayTopMiniStatus(listitem, col.property)" class="overlaystatusicon top clickable" src="{{formatTopStatusIcon(listitem)}}" alt="{{formatStatusText(listitem)}}" (click)="listItemClick(listitem, $event, 'APP_ID')">
                  </ng-template>
                </div>
                <div *ngSwitchCase="5" class="status">
                  <img *ngIf="displayStatusIcon(listitem)" class="statusicon" src="{{formatStatusIcon(listitem)}}" alt="{{formatStatusText(listitem)}}">
                  <div class="celltext" [ngClass]="{italic:statusTextItalic(listitem), red:statusTextRed(listitem), blue:statusTextBlue(listitem)}">{{formatStatusText(listitem)}}</div>
                </div>
                <div *ngSwitchCase="6" class="status">
                  <img *ngIf="getTrusteeAccess(i,j-1)=='2'" class="rightsicon" src="assets/images/col_hdr_deny24.svg" [alt]="getColumnAriaLabel(col,listitem, 2)" [tabindex]="tabIndex" (click)="setTrusteeAccess(i,j-1,2,$event)" (keydown.space)="setTrusteeAccess(i,j-1,2,$event)">
                  <img *ngIf="getTrusteeAccess(i,j-1)=='1'" class="rightsicon" src="assets/images/checkmark_green24.svg" [alt]="getColumnAriaLabel(col,listitem, 1)" [tabindex]="tabIndex" (click)="setTrusteeAccess(i,j-1,1,$event)" (keydown.space)="setTrusteeAccess(i,j-1,1,$event)">
                  <img *ngIf="getTrusteeAccess(i,j-1)=='0'" class="rightsicon" src="assets/images/col_hdr_inherit24.svg" [alt]="getColumnAriaLabel(col,listitem, 0)" [tabindex]="tabIndex" (click)="setTrusteeAccess(i,j-1,0,$event)" (keydown.space)="setTrusteeAccess(i,j-1,0,$event)">
                </div>
                <div *ngSwitchCase="7" class="celltext">{{formatPath(listitem[col.property])}}</div>
                <edx-select *ngSwitchCase="8" [id]="'edx_access_right'" [ariaLabel]="altLabels['accessRights']" [data]="i" [tabIndex]="isReadonly(i,col.property)?-1:tabIndex" [items]="rightsValues" [value]="setDisplayAccessLevel(listitem[col.property])" (change)="setTemplatedRights($event, i)" [disabled]="isReadonly(i,col.property)" [inAccessRights]="true"></edx-select>
                <div *ngSwitchCase="9" [tabindex]="tabIndex" class="celltext" (click)="listItemClick(listitem, $event, col.property)" role="link" (keyup.enter)="listItemClick(listitem, $event, col.property)" [ngClass]="{clickable: isClickable(listitem,col.property)}">{{formatAction(listitem)}}</div>
                <div *ngSwitchCase="10" class="expander" [tabindex]="tabIndex" role="button" [attr.aria-label]="altLabels['seeMore']+' '+((expandedItems.indexOf(listitem)>=0 && columnsHidden > 0)?altLabels['expanded']:altLabels['collapsed'])" [attr.aria-expanded]="expandedItems.indexOf(listitem)>=0 && columnsHidden > 0" (keydown.space)="expanderClick(listitem, $event)" (click)="expanderClick(listitem, $event)" title="{{this.localizer.getTranslation('TOOLTIP.SEE_MORE')}}"></div>
                <edx-progress *ngSwitchCase="11" max="1.0" [value]="listitem[col.property] || 0"></edx-progress>
                <div *ngSwitchCase="12" class="celltext" title="{{formatEnum(listitem,col.property,j)}}">
                  <span>{{formatEnum(listitem,col.property,j)}}</span>
                </div>
                <img *ngSwitchCase="13" src="listitem[col.property]" alt="{{listitem[col.property]}}">
                <div *ngSwitchCase="14" [attr.aria-label]="listitem['id']+col.property" role="checkbox" [attr.aria-checked]="!!listitem[col.property]" class="selector schemacb" [tabindex]="(col.readonly||isReadonly(i,col.property))?-1:tabIndex" [ngClass]="{on:!!listitem[col.property], readonly:col.readonly||isReadonly(i,col.property)}" (click)="checkboxClick(listitem,col.property,$event)" (keydown.space)="(col.readonly||isReadonly(i,col.property))?null:checkboxClick(listitem,col.property,$event)"></div>
                <div *ngSwitchCase="15" class="celltext">{{formatDate(listitem, col.property, true)}}</div>
                <div *ngIf="!showExtrasRow(listitem) && !((isIE || isEdge) && hasFootprint)" class="cellborder"></div>
              </td>
            </ng-template>
          </tr>
          <tr *ngIf="expandedItems.indexOf(listitem)>=0 && columnsHidden > 0" class="expansion">
            <td [colSpan]="columnsShown" [ngClass]="{oai: officeAddin}">
              <ng-template ngFor let-extracol [ngForOf]="schema.columns" let-n="index">
                <div *ngIf="n >= columnsShown && extracol.format!==10" [ngSwitch]="extracol.format" class="expandedrow">
                  <div class="expandedlabel">{{extracol.label}}</div>
                  <div *ngSwitchCase="1" class="expandedvalue">{{formatNumber(listitem, extracol.property, j)}}</div>
                  <div *ngSwitchCase="2" class="expandedvalue">
                    <span>{{formatString(listitem,extracol.property,extracol.displayMap)}}</span>
                  </div>
                  <div *ngSwitchCase="3" class="expandedvalue">{{formatDate(listitem, extracol.property)}}</div>
                  <div *ngSwitchCase="5" class="expandedvalue">
                    <img *ngIf="displayStatusIcon(listitem)" class="expandstaticon" src="{{formatStatusIcon(listitem)}}" alt="{{formatStatusText(listitem)}}">
                    <div class="expandstatvalue" [ngClass]="{italic:statusTextItalic(listitem), red:statusTextRed(listitem), blue:statusTextBlue(listitem)}">{{formatStatusText(listitem)}}</div>
                  </div>
                  <div *ngSwitchCase="7" class="expandedvalue">{{formatPath(listitem[extracol.property])}}</div>
                  <edx-select *ngSwitchCase="8" [id]="'edx_access_right'" [data]="i" [tabIndex]="isReadonly(i,extracol.property)?-1:tabIndex" [items]="rightsValues" [value]="setDisplayAccessLevel(listitem[extracol.property])" (change)="setTemplatedRights($event, i)" [disabled]="isReadonly(i,extracol.property)" [inAccessRights]="true"></edx-select>
                  <div *ngSwitchCase="9" class="expandedvalue">{{formatAction(listitem[extracol.property])}}</div>
                  <progress *ngSwitchCase="11" max="1.0" [value]="listitem[extracol.property] || 0"></progress>
                  <div *ngSwitchCase="12" class="expandedvalue">
                    <span>{{formatEnum(listitem,col.property,j)}}</span>
                  </div>
                  <img *ngSwitchCase="13" class="image" src="listitem[col.property]" alt="{{listitem[col.property]}}">
                  <div *ngSwitchCase="14" [attr.aria-label]="listitem['id']+col.property" class="selector schemacb" role="checkbox" [attr.aria-checked]="!!listitem[col.property]" [tabindex]="(col.readonly||isReadonly(i,col.property))?-1:tabIndex" [ngClass]="{on:!!listitem[col.property], readonly:col.readonly||isReadonly(i,col.property)}" (click)="checkboxClick(listitem,col.property,$event)" (keydown.space)="checkboxClick(listitem,col.property,$event)"></div>
                  <div *ngSwitchCase="15" class="expandedvalue">{{formatDate(listitem, extracol.property, true)}}</div>
                </div>
              </ng-template>
              <div *ngIf="!showExtrasRow(listitem)" class="cellborder"></div>
            </td>
          </tr>
          <tr *ngIf="showExtrasRow(listitem)" class="expansion previewrow">
            <td *ngIf="isHistoryExtrasRow(listitem)" width="{{getColWidth(schema.columns[0])}}"><div class="cellborder"></div></td>
            <td [colSpan]="isHistoryExtrasRow(listitem)?(columnsShown-1):columnsShown">
              <div class="comments" [ngClass]="{historyextrasrow:isHistoryExtrasRow(listitem)}">
                <span class="label">{{labelExtrasRow(listitem)}}</span>
                <span>{{formatExtrasRow(listitem)}}</span>
              </div>
              <div class="cellborder"></div>
            </td>
          </tr>  
        </ng-template>
        <tr *ngIf="(pageSizeIncremental) > 0 && !allItemsIn && (list.length > 0)" class="showmore">
          <td [attr.colspan]="schema.columns.length">
            <button [ngClass]="{mobile:ui>=2, oai:officeAddin}" (click)="fetchMoreItems()" class="secondary">{{loadMore}}</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div #rdbt class="edx_hidden row-dragover-border top"></div>
    <div #rdbb class="edx_hidden row-dragover-border bottom"></div>
    <div #rdbl class="edx_hidden row-dragover-border left"></div>
    <div #rdbr class="edx_hidden row-dragover-border right"></div>
  </div>
  <div *ngIf="hitPreviewShown" class="hitpreviewpopup" [ngClass]="{shown: hitPreviewShown, hiding: hitPreviewHiding, showing: hitPreviewShowing}" (mouseleave)="hitPreviewLeave($event)" (animationend)="hitPreviewAnimationComplete()" (keyup.arrowright)="focusOnRowSelector()">
    <div class="hitpreviewheader">
      <div class="hitpreviewtitle">{{hitPreviewData?hitPreviewData.DOCNAME:""}}</div>
      <div class="hitpreviewpager">
        <span>{{hitPreviewData?this.localizer.getTranslation('PAGINATOR.OF_PAGES',[hitPreviewData.page,hitPreviewData.totalpages]):""}}</span>
        <div class="hitpreviewbuttons">
          <div class="rowicon prev" tabindex="0" role="button" [attr.aria-label]="altLabels['previousPage']" id="edx_hit_preview_prev" (click)="showHitPreview(null, -1)" (keydown.space)="showHitPreview(null, -1)"></div>
          <div class="rowicon next" tabindex="0" role="button"  [attr.aria-label]="altLabels['nextPage']" id="edx_hit_preview_next" (click)="showHitPreview(null, 1)" (keydown.space)="showHitPreview(null, -1)"></div>
        </div>
      </div>
    </div>
    <div class="hitpreviewcontainer" tabindex="0">
      <div class="hitpreviewbody">
        <edx-spinner *ngIf="hitPreviewLoading"></edx-spinner>
       <edx-list-item-preview *ngIf="hitPreviewData" [data]="hitPreviewData.runs"></edx-list-item-preview>
      </div>
    </div>
  </div>
</div>
