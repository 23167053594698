import { filter } from 'rxjs/operators';

import { Directive, Input, HostListener } from '@angular/core';
import {  NgControl, ValidatorFn, AsyncValidatorFn } from '@angular/forms';

@Directive({
  selector: '[edx-validate-onblur]'
})
export class ValidateOnBlurDirective {
  @Input('edx-validate-onblur') engage?: boolean = true;
  private validator: ValidatorFn;
  private asyncValidator: AsyncValidatorFn;
  private hasFocus = false;

  constructor(public formControl: NgControl) { }

  @HostListener('focus', ['$event'])
  onFocus($event) {
    if (this.engage) {
      this.hasFocus = true;
      this.validator = this.formControl.control.validator;
      this.asyncValidator = this.formControl.control.asyncValidator;
      this.formControl.control.clearAsyncValidators();
      this.formControl.control.clearValidators();
      this.formControl.control.valueChanges.pipe(filter(() => this.hasFocus)).subscribe(() => this.formControl.control.markAsPending());
    }
  }

  @HostListener('blur', ['$event'])
  onBlur($event) {
    this.enableValidation();
  }

  enableValidation(): void {
    if (this.engage) {
      this.hasFocus = false;
      this.formControl.control.setAsyncValidators(this.asyncValidator);
      this.formControl.control.setValidators(this.validator);
      this.formControl.control.updateValueAndValidity();
    }
  }
}
