/**
 * Created by kevin on 2016-10-13.
 *
 * Column item for list display schema; display order set by schema's column list order
 *
 */

const DEFAULT_WIDTH = 200;

// enumeration of column formatters
export const enum ColFormat {
  SELECTOR = 0,     // selection checkbox
  NUMBER = 1,       // plain old number
  STRING = 2,       // plain old string
  DATETIME = 3,     // datetime string
  OBJTYPE = 4,      // obj type (icon with future overlays)
  STATUS = 5,       // item status (icon and enumeration string)
  RIGHTS = 6,       // access rights enumeration
  PATH = 7,         // library/path with extender control
  EDITRIGHTS = 8,   // access rights edit control
  ACTION = 9,       // history action enumeration
  EXPANDER = 10,    // expander for collapsed columns
  PROGRESS = 11,    // progress bar indicator
  ENUMTEXT = 12,    // text enumeration list stored as dictionary,
  IMAGE = 13,       // icon or image path,
  CHECKBOX = 14,    // checkbox but not the selector
  DATE = 15,        // date string
  RADIO = 16        // Radio button
}

export interface ColumnDesc {
  label?: string;
  property?: string;
  format?: ColFormat;
  width?: number;
  minwidth?: number;
  img?: string;
  nonsortable?: boolean;
  readonly?: boolean;
  enums?: any;
  displayMap?: any[];
}

export class Column {
  public label: string;           // column label - string or localization key
  public property: string;        // name of column's primary property in associated data source
  public format: ColFormat;       // data type
  public width: number;           // display width in percent of table
  public img: string;             // is an icon or image
  public minwidth: number;        // minimum pixel width for 'master' column -> controls collapsing of subsequent columns
  public nonsortable: boolean;    // not sortable if true
  public readonly: boolean;       // things like checkboxes are not clickable if true
  public enums: any;
  public displayMap?: any[];      // combobox, radio buttons and checkboxes have Key and Text map. Text needs to be displayed in search results

  constructor(desc: ColumnDesc) {
    this.label = desc.label || '';
    this.property = desc.property || null;
    this.format = desc.format || ColFormat.STRING;
    this.width = desc.width || 1;
    this.minwidth = desc.minwidth || -1;
    this.img = desc.img || null;
    this.nonsortable = desc.nonsortable || false;
    this.readonly = desc.readonly || false;
    this.enums = desc.enums || {};
    this.displayMap = desc.displayMap || [];
  }
}
