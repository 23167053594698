import { Injectable } from '@angular/core';

import { Util } from '../utils/utils.module';

class IPCData {
  public command: string;
  public buf: any;
}

@Injectable()
export class ProfileService {
  private autoProfileWorker: Worker;
  private bWorkerInitDone = false;
  private workerResponse: any = null;

  constructor() {
    setTimeout(() => {
      if (Util.Device.bIsOfficeAddinWord || Util.Device.bIsOfficeAddinOutlook) {
        this.autoProfileWorker = new Worker('assets/autoprofile/autoprofile.js');
        this.autoProfileWorker.onmessage = this.message.bind(this);
      }
    }, 1);
  }

  public async init(): Promise<boolean> {
    return await new Promise<any>((resolve, reject) => {
      setTimeout(() => {
        if (Util.Device.bIsOfficeAddinWord || Util.Device.bIsOfficeAddinOutlook) {
          this.sendWorkerMessage({command: 'init', buf: {}}).then(data => {
            resolve(true);
          }).catch(err => {
            reject(err);
          });
        } else {
          reject('not word');
        }
      }, 100);
    });
  }

  public async destroy(): Promise<boolean> {
    if (this.bWorkerInitDone) {
      await this.sendWorkerMessage({command: 'destroy', buf: ''}).catch(e =>  {
        Promise.reject(e);
      });
      return true;
    } else {
      return Promise.resolve(true);
    }
  }

  public async getSuggestions(messageBody: any): Promise<any> {
    console.log('Called profile.service.getSuggestions()');
    const data = await this.sendWorkerMessage({command: 'input', buf: messageBody}).catch(e =>  {
      console.log('Error in profile.service.getSuggestions(): ' + e);
      Promise.reject(e);
    });
    return data;
  }

  private async sendWorkerMessage(ipcData: IPCData): Promise<any> {
    return await new Promise<any>((resolve, reject) => {
      const waitForDone = async () => {
        if (this.workerResponse===null) {
          setTimeout(waitForDone, 100);
        } else {
          resolve(this.workerResponse);
        }
      };
      if (this.bWorkerInitDone || ipcData.command==='init') {
        this.workerResponse = null;
        this.autoProfileWorker.postMessage(ipcData);
        waitForDone();
      } else {
        reject('worker not initialized');
      }
    });
  }

  private message(e: MessageEvent): void {
    const ipcData: IPCData = e.data;
    const command = ipcData.command;
    switch (command) {
      case 'init':
        this.bWorkerInitDone = true;
        break;
      case 'destroy':
        this.bWorkerInitDone = false;
        break;
      case 'output':
        // TODO: RON remove console.log('ProfileService output: ' + ipcData.buf);
        break;
    }
    this.workerResponse = ipcData.buf;
  }
}
